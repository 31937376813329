import React, {useState} from "react";
import season from "../../system/season";
import {
    AtlasBannerContainerDiv,
    AtlasBannerCurrentSeasonDiv,
    AtlasBannerDatesDiv,
    AtlasBannerProgressDiv,
    AtlasBannerProgressBarDiv,
    AtlasBannerSeasonDiv, AtlasBannerPercentDiv
} from "./AtlasBannerStyle";

export const AtlasBanner = () => {
    const [seasonMonths] = useState(season.staticData[season.current].months);

    return (
        <AtlasBannerContainerDiv>
            <AtlasBannerCurrentSeasonDiv>
                <AtlasBannerDatesDiv>{seasonMonths}</AtlasBannerDatesDiv>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <AtlasBannerProgressDiv>
                        <AtlasBannerProgressBarDiv progress={season.percentComplete}/>
                    </AtlasBannerProgressDiv>
                    <AtlasBannerPercentDiv>
                        {season.percentComplete}%
                    </AtlasBannerPercentDiv>
                </div>
            </AtlasBannerCurrentSeasonDiv>
        </AtlasBannerContainerDiv>
    )
};