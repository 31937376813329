import { useState, useEffect } from 'react';
import { getAccessoryByAbility } from "../data/items";

/**
 * Custom hook to fetch the player's abilities.
 *
 * @param {function} gameMetadataProvider - The provider to get game metadata.
 * @param {object} self - The current player data.
 * @returns {object} Abilities and active ability.
 */
const useSelfAbilities = (gameMetadataProvider, self) => {
    const [sandboxData, setSandboxData] = useState({ abilities: [], activeAbility: null });

    useEffect(() => {
        // Players in the lobby are represented as teams.teamA[0]
        // Players in a match are referenced in redux and specified by a server message
        const player = gameMetadataProvider.teams[self.team ?? 'teamA'][self.teamIndex ?? 0];

        // Production bug: There's been a common error in production where player was undefined.
        if (player) {
            const abilities = player.abilityNames.map(abilityName => {
                const ability = player.abilities[abilityName];
                return {
                    ...ability,
                    ...getAccessoryByAbility(abilityName),
                };
            });

            setSandboxData({
                abilities: abilities,
                activeAbility: player.activeAbility,
            });
        }
    }, [gameMetadataProvider, self]);

    return sandboxData;
};

export default useSelfAbilities;
