import initialState from "./selfInitialState";
import {createReducer} from "@reduxjs/toolkit";
import {ItemCategoryTypes} from "../../../system/types";

export const selectTickets = (state) => state.self.tickets;
export const selectUsername = (state) => state.self.username;

const selfReducers = createReducer(initialState, {
    SELF_LOAD_INITIAL_STATE: (state, action) => {
        Object.keys(action.payload).map(key => {
            if(key !== 'event') {
                state[key] = action.payload[key];
            }
        });
        state.rating = {
			current: action.payload.ratings,
			previous: action.payload.ratings,
		}
    },
    SELF_UPDATE_CONSUMABLE_ITEM: (state, action) => {
        const itemIndex = state.items.accessory.map(item => item.userItemId).indexOf(action.payload.userItemId);
        const item = state.items.accessory[itemIndex];
        state.items.accessory[itemIndex] = {
            ...item,
            quantity: action.payload.quantity
        };
    },
    SELF_ADD_ITEM: (state, action) => {
        // This fires for slot item drops, present drops,
        const newItems = [...action.payload];
        newItems.forEach((item) => {
            const category = item.category.toLowerCase();
            if (category === ItemCategoryTypes.FACE) {
                // We do this separately because faces all faces are already present, just locked.
                const index = state.items.face.findIndex(face => face.name === item.name);
                if (state.items.face[index]) {
                    state.items.face[index].locked = 0;
                }
            }
            if ([
                ItemCategoryTypes.PAINT,
                ItemCategoryTypes.ACCESSORY,
                ItemCategoryTypes.SKIN,
                ItemCategoryTypes.CAP,].includes(category)) {
                const itemIndex = state.items.accessory.map(item => item.userItemId).indexOf(item.userItemId);
                const userHasItem = itemIndex > -1;

                if (!userHasItem) {
                    item = {
                        ...item,
                        category: category
                    };
                    let updated = {...state.items};
                    updated[category] = updated[category].concat(item);
                    state.items = updated;
                } else {
                    // The only scenario where the user would already have the item is if its a consumable, only update the quantity.
                    state.items.accessory[itemIndex] = {
                        ...state.items.accessory[itemIndex],
                        quantity: item.quantity
                    };
                }
            }
        });
    },
    SELF_REMOVE_ITEM: (state,action) => {
        // When a consumable is completely used, remove it from the items
        state.items = {
            ...state.items,
            accessory: state.items.accessory.filter(item => item.userItemId !== action.payload)
        };
    },
    // newItems
    SELF_ADD_NEW_ITEM: (state, action) => {
        state.newItems = state.newItems.concat(action.payload);
    },
    SELF_REMOVE_NEW_ITEM: (state) => {
        const update = state.newItems;
        update.shift();
        state.newItems = update;
    },

    UPDATE_COINS: (state, action) => {
        state.coins = action.payload;
    },
    SELL_ITEM: (state, action) => {
        const { category, itemId } = action.payload;
        // If the object was keyed by the userItemId we would be able to find the first instance of the matching index,
        // however - we can't use some() and return the index because we can't assume a consistently ordered object.
        const updatedItems = state.items[category].filter((item) => item.userItemId !== itemId);
        state.items = {
            ...state.items,
            [category]: updatedItems
        }
    },
    UPDATE_PORTRAIT: (state, action) => {
        const { portrait, portraitCanvas } = action.payload;
        state.portrait = portrait;
        state.portraitCanvas = portraitCanvas;
    },
    UPDATE_STATS: (state, action) => {
        state.stats = action.payload;
    },
    UPDATE_TICKETS: (state, action) => {
        state.tickets = action.payload;
    },
    UPDATE_METRICS: (state, action) => {
        state.metrics = action.payload;
    },
    UPDATE_PREMIUM: (state, action) => {
        state.premium = action.payload;
    },
    UPDATE_COLOR_NAME: (state, action) => {
        state.colorName = action.payload;
    },
    UPDATE_STARTER_COLOR: (state, action) => {
        state.starterColor = action.payload;
    },
    UPDATE_RATING: (state,action) => {
        const { ratings } = action.payload;
        state.rating.previous = state.rating.current;
        state.rating.current = ratings;
    },
    UPDATE_TEAM: (state, action) => {
        const { team, teamIndex } = action.payload;
        state.team = team;
        state.teamIndex = teamIndex;
    },
    UPDATE_XSOLLA_TOKEN: (state, action) => {
        state.xsollaToken = action.payload;
    },
    UPDATE_ITEM_SALE_VALUE: (state, action) => {
        state.itemSellValue = action.payload;
    },

    UPDATE_ACTIVE_ABILITY: (state, action) => {
        state.activeAbility = action.payload;
    },

    UPDATE_OUTFIT: (state, action) => {
        const message = action.payload;

        if (message.equipped) {
            if (message.item.category === 'paint') {
                state.outfit.paint = message.item;
            }
            else if (['skin','cap'].includes(message.item.category)) {
                state.outfit[message.item.category] = message.item;
                state.items[message.item.category].forEach(item => {
                    // Find the equipped item and set the image and imageSrc attribute in the store
                    if (message.item.userItemId === item.userItemId) {
                        state.outfit[message.item.category] = {
                            ...state.outfit[message.item.category],
                            imageSrc: item.imageSrc,
                            image: new Image()
                        }
                    }
                });
            }
            else if (['face','accessory'].includes(message.item.category)) {
                state.outfit[message.item.category] = message.item;
            }
        } else {
            state.outfit[message.category] = null;
        }
    },

    OUTFIT_UPDATE: (state, action) => {
        const { index, newOutfit } = action.payload;
        state.outfits[index] = newOutfit;
    },
    LOAD_OUTFITS: (state, action) => {
        const { outfits, outfit, activeOutfitId } = action.payload;
       state.outfits = outfits;
       state.outfit =  outfit;
       state.outfitActiveId = activeOutfitId;
    },

    UPDATE_LOADOUT: (state, action) => {
        const index = state.items.accessory.findIndex(obj => obj.userItemId === action.payload.userItemId);
        // The enabled flag is used to populate the loadout
        state.items.accessory = [
            ...state.items.accessory.slice(0,index),
            {
                ...state.items.accessory[index],
                slot: action.payload.slot
            },
            ...state.items.accessory.slice(index+1)
        ];
    }
});

export default selfReducers;