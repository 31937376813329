import React from "react";
import styled from 'styled-components';
import palette from "../styled/Palette";
import {useSelector} from "react-redux";

const HotkeyContainerDiv = styled.div`
    position: absolute;
    right: .7em;
    bottom: .7em;
    z-index: 4;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: bold;
    background-color: ${palette.base5};
    color: ${palette.base4};
    
    padding: .25em .5em;
    font-size: .75em;
    
    border-top-left-radius: 4px;
`;

const HotkeyIndicator = ({key, index}) => {
    const gamepad = useSelector(state => state.gamepad);

    const value = () => {
        if (gamepad.connected && index !== null) {
            switch (index) {
                case 0: return 'LB';
                case 1: return 'RB';
                case 2: return 'LT';
                case 3: return 'RT';
                default: return 0;
            }
        }
        return key ? key : index+1;
    };


    return (
        <HotkeyContainerDiv>
            {value()}
        </HotkeyContainerDiv>
    )
};

export default HotkeyIndicator;