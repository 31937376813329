import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { getMatchId } from '../../data/matchScope';

let particlesExist = {
    teamA: [false,false,false,false],
    teamB: [false,false,false,false],
};

let currentMatchId;

export function createGhostParticles(room, team, teamIndex) {
    if(currentMatchId !== getMatchId()){
        currentMatchId = getMatchId();
        particlesExist = {
            teamA: [false,false,false,false],
            teamB: [false,false,false,false],
        };      
    }
    if(!particlesExist[team][teamIndex]){
        particlesExist[team][teamIndex] = true;
        let player = room.teams[team][teamIndex];
        let ghostEmitter = new Proton.Emitter();
        ghostEmitter.matchId = JSON.parse(JSON.stringify(getMatchId()));
        currentMatchId = JSON.parse(JSON.stringify(getMatchId()));
        ghostEmitter.rate = new Proton.Rate(4, updatePerFrame);
        ghostEmitter.addInitialize(new Proton.Mass(1));
        ghostEmitter.addInitialize(new Proton.Radius(25, 35));
        const particleLife = 1;
        ghostEmitter.addInitialize(new Proton.Life(.5,1.3));
        ghostEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
        ghostEmitter.addInitialize(
            new Proton.Velocity(new Proton.Span(2, 4), new Proton.Span(0, 360), 'polar')
        );
        ghostEmitter.addBehaviour(new Proton.Color('000000'));
        ghostEmitter.addBehaviour(new Proton.Gravity(-3));
        ghostEmitter.addBehaviour(new Proton.Scale(0.05, 0.5));
        ghostEmitter.addBehaviour(new Proton.Alpha(0.6, 0));
        ghostEmitter.p.x = player.position.x;
        ghostEmitter.p.y = player.position.y;
        ghostEmitter.damping = 0.04;
        const duration = 30000;
        ghostEmitter.emit(duration, true);
        protonBackground.addEmitter(ghostEmitter);
    
        let endParticles = () => {
            particlesExist[team][teamIndex] = false;
            ghostEmitter.rate = new Proton.Rate(0, 1000);
            clearInterval(interval);
            setTimeout(() => {
                protonBackground.removeEmitter(ghostEmitter);
            }, 1500);
        };
        let interval = setInterval(() => {
            player = room.teams[team][teamIndex];
            if (player && getMatchId() === ghostEmitter.matchId) {
                let position = player.position;
                ghostEmitter.p.x = position.x;
                ghostEmitter.p.y = position.y;
                if (player.activeAbility !== 'ghost') {
                    endParticles();
                }
            } else {
                endParticles();
            }
        }, 1000 / 60);
    }
}
