import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import coin from "../../src/images/coin.png";
import chest from "../../src/images/chest.png";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 88vh;
  width: 100%;
  position: absolute;
`;

/* Parabolic animation for coins */
const createParabolicAnimation = (xDistance, peakHeight) => keyframes`
  0% {
    transform: translateX(0) translateY(0);
  }
  25% {
    transform: translateX(-${xDistance * 0.25}vw) translateY(${peakHeight * 0.25}vh);
  }
  50% {
    transform: translateX(-${xDistance * 0.5}vw) translateY(${peakHeight * 0.5}vh);
  }
  75% {
    transform: translateX(-${xDistance * 0.75}vw) translateY(${peakHeight * 0.25}vh);
  }
  100% {
    transform: translateX(-${xDistance}vw) translateY(0);
  }
`;

/* Falling animation for chests */
const fallAnimation = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Coin = styled.div`
  position: absolute;
  right: -50px;
  width: 30px;
  height: 30px;
  top: ${({ top }) => top};
  animation: ${({ xDistance, peakHeight }) =>
    css`
      ${createParabolicAnimation(xDistance, peakHeight)} 10s linear infinite
    `};
  animation-delay: ${({ delay }) => delay};

  img {
    width: 100%;
    height: 100%;
  }
`;

const Chest = styled.div`
  position: absolute;
  bottom: 0; /* Anchor to bottom */
  width: 100px;
  height: 100px;
  left: ${({ left }) => left};
  animation: ${fallAnimation} 1s ease-out;
  img {
    width: 100%;
    height: 100%;
  }
`;

const MockLobbyEvents = () => {
    const [mode, setMode] = useState("chests"); // Toggle between "coins" and "chests"
    const [coins, setCoins] = useState([]);
    const [chests, setChests] = useState([]);

    useEffect(() => {
        const modeSwitchInterval = setInterval(() => {
            setMode((prevMode) => (prevMode === "coins" ? "chests" : "coins"));
        }, 60000); // Switch mode every 60 seconds

        return () => clearInterval(modeSwitchInterval);
    }, []);

    useEffect(() => {
        if (mode === "coins") {
            const coinInterval = setInterval(() => {
                if (Math.random() < 0.3) {
                    setCoins((prevCoins) => {
                        if (prevCoins.length < 10) {
                            const xDistance = 100;
                            const peakHeight = Math.random() * 20 + 10;
                            const delay = Math.random() * 5 + "s";
                            const top = Math.random() * 50 + 5 + "%";

                            return [...prevCoins, { xDistance, peakHeight, delay, top }];
                        }
                        return prevCoins;
                    });
                }
            }, 5000);

            return () => clearInterval(coinInterval);
        } else {
            setCoins([]); // Clear coins when switching mode
        }
    }, [mode]);

    useEffect(() => {
        if (mode === "chests") {
            // Function to generate 3 chests evenly spaced
            const createChests = () => {
                const chestPositions = [20, 50, 80]; // Evenly spaced positions (in %)
                const newChests = chestPositions.map((position) => ({
                    left: `${position}%`,
                }));

                setChests(newChests); // Add the 3 chests at once
            };

            setTimeout(() => {
                createChests(); // Spawn the chests immediately when in "chests" mode
            }, 6000);

        } else {
            setChests([]); // Clear chests when switching mode
        }
    }, [mode]);


    return (
        <Container>
            {mode === "coins" &&
            coins.map((coinProps, index) => (
                <Coin key={index} {...coinProps}>
                    <img src={coin} alt="coin" />
                </Coin>
            ))}

            {mode === "chests" &&
            chests.map((chestProps, index) => (
                <Chest key={index} {...chestProps}>
                    <img style={{
                    }} src={chest} alt="chest" />
                </Chest>
            ))}
        </Container>
    );
};

export default MockLobbyEvents;
