import React, { useState, useEffect } from 'react';
import { getTierData } from'../../data/tier';
import { getDivision } from '../../data/rating';
import Button, {STANDARD_BUTTON_WIDTH} from "../../styled/Button";
import {
    RankUpContainerDiv,
    RankUpBodyDiv,
    RankUpRankTextDiv,
    RankUpPromotionTextDiv,
    RankUpFooterDiv,
} from './RankUpStyle';
import { playSound } from '../../data/sound';
import { gameSocket } from '../../redux/middleware/wsMatch';
import { useSelector } from "react-redux";
import {PageTypes} from "../../system/types";
import {StarPan} from "../../styled/SlimePan";

const RankUp = ({history}) => {
    const rating = useSelector(state => state.self.rating);
    const { promotion, sport, word, rank, division, winSchema } = history.location.state;
    const data = getDivision(rating.current[sport]);

    const handleAccept = () => {
        let target = document.getElementById('animateTarget');
        if (target) {
            target.classList.remove('fadeIn');
            target.classList.add('fadeOutForeverFast');
        }
        setTimeout(() => {
            // If the user is still in the match
            if (gameSocket) {
                history.push({
                    pathname: PageTypes.WIN,
                    state: winSchema
                });
            } else {
                history.push(PageTypes.MAIN);
            }
        }, 1000);
    };

    useEffect(() => {
        playSound(promotion ? 'SpaceBall_Done5' : 'SpaceBall_Error2', 1);
    }, []);

    return (
        <RankUpContainerDiv
            id="animateTarget"
            className="animated fadeIn">
            <RankUpBodyDiv
                className="animatedFast fadeIn"
            >
                <div style={{textAlign: 'left', color: 'gray', fontSize: '50px'}}>
                    <RankUpPromotionTextDiv
                        promotion={!promotion} //absolutely no idea why value seems to be backwards
                        className={`animatedSlow slideInDown`}
                        style={{paddingLeft: '5px', fontWeight: 'bold', fontSize: '20px'}}
                    >
                        { promotion ? 'PROMOTED' : 'DEMOTION' }
                    </RankUpPromotionTextDiv>
                    <RankUpRankTextDiv
                        color={getTierData(getDivision(rating.current[sport]).tier).color}
                        className="animated fadeIn"
                    >
                        {word} {rank}
                    </RankUpRankTextDiv>
                    {division &&
                    <div className="animatedSlow slideInUp"
                         style={{paddingLeft: '5px', fontSize: '40px'}}
                    >
                        {sport.toUpperCase()} DIVISION {division}
                    </div>
                    }
                </div>
                <div
                    id="rankIconTarget"
                    className="animatedFast slideInRight"
                    style={{marginLeft: '5em'}}>
                    <img
                        alt='rankImage'
                        width="256px"
                        height="256px"
                        src={data.image}
                    />
                </div>
            </RankUpBodyDiv>
            <RankUpFooterDiv>
                <Button
                    styles={{width: STANDARD_BUTTON_WIDTH}}
                    title="Cool"
                    click={() => handleAccept()}
                />
            </RankUpFooterDiv>
            <StarPan/>
        </RankUpContainerDiv>
    )
};

export default RankUp;