import React from "react";
import ticket from "../../../images/ticket.png";
import ticket2 from "../../../images/ticket2.png";
import {DropContainer, DropFooter} from "../style";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import {PageTypes} from "../../../system/types";
import {selfRemoveNewItem} from "../../../redux/modules/self/selfActions";
import {useDispatch} from "react-redux";

const ticketPair = () => <img className="animated animationFlip" src={ticket2} alt='Tickets'/>;
const ticketSingle = () => <img className="animated animationFlip" src={ticket} alt='Tickets'/>;

const DropTicket = ({history, currentViewItem, handleReturn}) => {
    const dispatch = useDispatch();

    const body = () => {
        if (currentViewItem.amount === 6) {
            return (
                <>{ticketPair()}{ticketPair()}{ticketPair()}</>
            )
        }
        if (currentViewItem.amount === 3) {
            return (
                <>{ticketSingle()}{ticketSingle()}{ticketSingle()}</>
            )
        }
        if (currentViewItem.amount === 2) {
            return (
                <>{ticketPair()}</>
            )
        }
        if (currentViewItem.amount === 1) {
            return (
                <>{ticketSingle()}</>
            )
        }
    };

    return (
        <>
            <div>
                {body()}
            </div>
            <DropFooter>
                <div style={{display: 'flex'}}>
                    <Button
                        styles={{width: STANDARD_BUTTON_WIDTH, alignItems: 'center', height: '100%', fontWeight: 'bold', justifyContent: 'center'}}
                        title="Use Now"
                        click={() => {
                            dispatch(selfRemoveNewItem());
                            history.push(PageTypes.SLOTS)
                        }}
                        className="buttonGeneric"
                    />
                    <Button
                        styles={{width: STANDARD_BUTTON_WIDTH}}
                        title="Cool"
                        click={() => handleReturn()}
                    />
                </div>
            </DropFooter>
        </>
    )
};

export default DropTicket;