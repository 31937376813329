import React, { useEffect, useRef, useState } from 'react';
import { TabDiv, TabsContainerDiv } from "../../styled/Tabs";
import CashItems from "./CashItems";
import CashItemView from "./CashItemView";
import Header from "../../component/Header/Header";
import SlimePan from "../../styled/SlimePan";
import { drawerToggle } from "../../redux/modules/socket/socketActions";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import InfoBanner from "../../component/InfoBanner/InfoBanner";
import { PageTypes } from "../../system/types";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {MarketBodyDiv} from "../Market/MarketStyle";
import {CARD_SIZE_MEDIUM, CARD_SIZE_SMALL} from "../../data/globals";

const CashContainerDiv = styled.div`
`;

const CashBodyDiv = styled.div`
    background-color: ${palette.base1};
    padding: .25em;
    margin-bottom: 2em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: inline-flex;  /* Change here */
    justify-content: center;
    align-items: center;
    width: ${({screenIsSmall}) => screenIsSmall ? '41.5em' : '55em' };
`;


const cashTabs = ['REGULARS', 'SEASONAL', 'POTIONS'];

const loadTabs = (activeTab, setActiveTab) => {
    return (
        <TabsContainerDiv>
            {
                cashTabs.map((tab) => {
                    return (
                        <TabDiv
                            tabIndex={0}
                            key={tab}
                            active={activeTab === tab}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.toUpperCase()}
                        </TabDiv>
                    )
                })
            }
        </TabsContainerDiv>
    )
};

const Cash = ({ dispatch }) => {
    const [viewingItem, setView] = useState(null);
    const [activeTab, setActiveTab] = useState('REGULARS');
    const [tabs, setTabs] = useState(loadTabs(activeTab, setActiveTab));

    const { screenIsSmall, width } = useWindowDimensions();

    // Refs for container and CashBodyDiv
    const cashBodyDivRef = useRef(null);
    const [cashBodyDivWidth, setcashBodyDivWidth] = useState(0);

    useEffect(() => {
        const updatePadding = () => {
            if (cashBodyDivRef.current) {
                const cashBodyDivWidth = cashBodyDivRef.current.offsetWidth;
                setcashBodyDivWidth(cashBodyDivWidth); // Save width for MarketBodyDiv
            }
        };

        updatePadding();
    }, [width]); // Trigger when width changes (due to the useWindowDimensions hook)
    
    useEffect(() => {
        setTabs(loadTabs(activeTab, setActiveTab));
        setView(null);
    }, [activeTab]);

    useEffect(() => {
        dispatch(drawerToggle({ open: true }));
    }, []);

    return (
        <div className="fullScreen animatedFast slideInRight">
            <Header closeRoute={PageTypes.MAIN} title="(╯°□°)╯︵ ┻━┻ "/>
            <SlimePan
                className="styleDarkScrollSquare"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <CashContainerDiv
                    style={{
                        width: cashBodyDivWidth ? `${cashBodyDivWidth}px` : 'auto',
                    }}

                >
                    <InfoBanner
                        styles={{
                            marginTop: screenIsSmall ? '2em' : '5em'
                        }}
                        title={`Cash Shop `}
                        body={
                            <div>
                                Hate fair play and hard work? With moms credit card and her permission you too can pay your way to the top! Most of the items found here naturally have a chance to drop following any match.
                            </div>
                        }
                    />
                    <div style={{ marginTop: screenIsSmall ? '2em' : '5em' }}>
                        {tabs}
                        <CashBodyDiv
                            screenIsSmall={screenIsSmall}
                            ref={cashBodyDivRef}
                        >
                            {viewingItem ?
                                <CashItemView dispatch={dispatch} setView={setView} item={viewingItem}/>
                                :
                                <CashItems activeTab={activeTab} setView={setView} />}
                        </CashBodyDiv>
                    </div>
                </CashContainerDiv>
            </SlimePan>
        </div>
    );
};

export default Cash;
