import styled from 'styled-components';
import palette from '../../styled/Palette';
import {shadow} from "../../data/globals";

export const BodyDiv = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    font-family: Montserrat;
    flex-grow: 1; /* Take the available space in the parent */
    height: calc(100vh - 3.5em); /* Subtract the height of the header */
`;


export const FeatsUserInfoDiv = styled.div`
    flex-grow: 2;
    flex-basis: 0;

    position: relative;
    flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-family: Luckiest Guy;
    text-align: left;
    
    background-color: rgba(52,73,94,.5);
    width: ${({screenIsSmall}) => screenIsSmall ? '100%' : '35em'};
`;

export const FeatsUserDetailDiv = styled.div`
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: .9em;
    text-align: left;
    font-family: sans-serif;
    width: 100%;
    padding: .5em;
    font-family: Montserrat;
    ul {
        border: 1px solid ${palette.wetAsphalt};
    }
    li {
        align-items: center;
        padding: .5em 1em;
        display: flex;
        justify-content: space-between;
    }
`;

export const RowItemLi = styled.li`
    cursor: pointer;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const InfoDiv = styled.div`
    display: flex;
    justify-content: space-between;
    
    div:last-child {
        margin-left: 20px;
    }
`;

export const AchievementsModalBodyDiv = styled.div`
    padding: 1em;
    color: ${palette.base4};
`;

export const FeatsUserInfoItemDiv = styled.div`
    margin-top: .5em;
    font-family: Luckiest Guy;
    font-size: 1.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: ${props => props.hue};
`;

export const FeatsUserDetailTitleText = styled.span`
    font-weight: bold;
`;
export const FeatsUserDetailValueText = styled.span`
`;