
export const stats = ['jump','power','size','speed'];

export const mapStats = (stat, returnType) => {
    switch (stat) {
        case 'jump':
            if (returnType === 'label') return 'Jump';
            break;
        case 'power':
            if (returnType === 'label') return 'Power';
            break;
        case 'size':
            if (returnType === 'label') return 'Size';
            break;
        case 'speed':
            if (returnType === 'label') return 'Speed';
            break;
    }
};

export const definitionStats = {
    size: 'The big or smallness of your slime',
    jump: 'The height of your jump',
    power: 'The oomf applied to the ball on collision with your slime',
    speed: 'The movement speed and agility of your slime',
};

const renderTransforms = (statNames) => {
    const r = {...statNames};
    delete r.airJumps;
    delete r.height;
    delete r.width;
    delete r.tumble;
    delete r.mass;
    return r;
};
export const statsSortAlphabetical = (statNames) => {
    statNames = renderTransforms(statNames);

    const statsOrdered = {};
    Object.keys(statNames).sort().forEach(function(key) {
        statsOrdered[key] = statNames[key];
    });
    return statsOrdered;
};