/*
Drop Types:
Starter Paint
Luck (tickets)
Bet Winner (coins)
CardLoad Purchase (paint, skin, cap)
MTX (tickets, paint, skin, cap, face)
Unlock (face)

item.type (e.g. start, luck, bet, market, mtx, unlock)
item.name (e.g. baby, kawaii, Chat Bubble, Cheetah Print, Jester Cap)
item.category (e.g. paint, skin, cap, face, coins, tickets)
item.amount (exclusive to tickets and coins)
item.colorName (exclusive to paint)
item.colorHex (exclusive to paint)
item.opponent (exclusive to bet)
 */

import {addNewItem, selfAddNewItem} from "../redux/modules/self/selfActions";
import {cashShopData} from "../data/items";

export const mock = [
    {...cashShopData.regulars.chatBubble},
    {
        type: 'achievement',
        name: 'challenger3',
        category: 'trophy',
    },
    {
        type: 'unlock',
        name: 'Skeletal',
        category: 'face',
    },
    {
        type: 'doublePurchase',
        amount: 6,
        category: 'Tickets'
    },
    {
        type: "luck",
        userItemId: 38006,
        description: "These can be found randomly as you play the game.",
        name: "Paint",
        colorName: "Unrenewed Mouthwash",
        colorHex: "#64D",
        adjective: "Unrenewed",
        adjectiveDefinition: "not revived",
        color: "Mouthwash",
        colorDefinition: "a medicated solution used for gargling and rinsing the mouth",
        category: "paint",
        tier: 0,
        stats:
            {
                size: 0,
                jump: 0,
                power: 0,
                speed: 0
            },
    },
    {
        type: 'luck',
        name: 'Punk',
        category: 'skin',
        colorHex: '#1c1',
        tier: 1,
        stats: {
            size: 7,
            jump: 5,
            power: 0,
            speed: 0
        },
    },
    {
        type: 'start',
        colorName: 'Orbiculate Gloom',
        category: 'paint',
        colorHex: '#2ce'
    },
    {
        type: 'luck',
        category: 'tickets',
        amount: 2
    },
    {
        type: 'luck',
        category: 'paint',
        colorHex: '#ffa',
        colorName: 'Ugly Daisy',
    },
    {
        type: 'bet',
        category: 'coins',
        amount: 1000,
        opponent: 'Joey Rich'
    },
    {
        type: 'market',
        category: 'cap',
        name: 'Jester Cap',
    },
    {
        type: 'premium',
        category: 'premium',
    }
];

const MockDrop = (dispatch) => {
    dispatch(selfAddNewItem(mock));
};

export default MockDrop;