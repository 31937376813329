import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';

let particlesExist = {
    teamA: [],
    teamB: [],
};

export function createGhostParticles(room, team, teamIndex) {
    let player = room.teams[team][teamIndex];
    let ghostEmitter = new Proton.Emitter();
    ghostEmitter.rate = new Proton.Rate(4, updatePerFrame);
    ghostEmitter.addInitialize(new Proton.Mass(1));
    ghostEmitter.addInitialize(new Proton.Radius(25, 35));
    const particleLife = 1;
    ghostEmitter.addInitialize(new Proton.Life(particleLife));
    ghostEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    ghostEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(2, 4), new Proton.Span(0, 360), 'polar')
    );
    ghostEmitter.addBehaviour(new Proton.Color('000000'));
    ghostEmitter.addBehaviour(new Proton.Gravity(-3));
    ghostEmitter.addBehaviour(new Proton.Scale(0.05, 0.5));
    ghostEmitter.addBehaviour(new Proton.Alpha(0.6, 0));
    ghostEmitter.p.x = player.position.x;
    ghostEmitter.p.y = player.position.y;
    ghostEmitter.damping = 0.04;
    const duration = 30000;
    ghostEmitter.emit(duration, true);
    protonBackground.addEmitter(ghostEmitter);

    let endParticles = () => {
        ghostEmitter.rate = new Proton.Rate(0, 1000);
        clearInterval(interval);
        setTimeout(() => {
            protonBackground.removeEmitter(ghostEmitter);
        }, 1500);
    };
    let interval = setInterval(() => {
        player = room.teams[team][teamIndex];
        if (player) {
            let position = player.position;
            ghostEmitter.p.x = position.x;
            ghostEmitter.p.y = position.y;
            if (player.activeAbility !== 'ghost') {
                endParticles();
            }
        } else {
            endParticles();
            particlesExist[team][teamIndex] = false;
        }
    }, 1000 / 60);
}
