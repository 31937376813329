import Proton from './proton';
import { protonForeground } from './protonForeground';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { getSound } from '../../data/sound';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createWindParticles(room, team, teamIndex) {
    let windSound = new Audio(getSound('wind').noise);
    let dampener = getSound('wind').dampener;
    let fadeOutTime = 1000;
    let fadeTime = null;
    windSound.currentTime = 0;
    windSound.volume = 1 * dampener;
    windSound.play();

    let playerEmitter = new Proton.Emitter();
    playerEmitter.rate = new Proton.Rate(5, updatePerFrame);
    playerEmitter.addInitialize(new Proton.Mass(1));
    playerEmitter.addInitialize(new Proton.Radius(4, 8));
    playerEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-1, 1), new Proton.Span(-1, 1), 'vector')
    );
    playerEmitter.damping = 0;
    playerEmitter.addBehaviour(new Proton.RandomDrift(5, 0, 0.05));
    playerEmitter.addBehaviour(new Proton.Color('999999', 'aaaaaaa'));
    playerEmitter.addBehaviour(new Proton.Scale(1, 1.5));
    playerEmitter.addInitialize(new Proton.Life(1, 1.5));
    playerEmitter.addBehaviour(new Proton.Alpha(1, 0));
    let playerAttractionVector = new Proton.Vector(0, 0);
    playerEmitter.addBehaviour(new Proton.Attraction(playerAttractionVector, 100, 400));

    let windEmitter = new Proton.Emitter();
    windEmitter.rate = new Proton.Rate(webGLEnabled ? 45 : 5, updatePerFrame);
    windEmitter.addInitialize(new Proton.Mass(1));
    windEmitter.addInitialize(new Proton.Radius(1, 3));
    windEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-1, 1), new Proton.Span(-1, 1), 'vector')
    );
    windEmitter.damping = 0;
    let zone = new Proton.RectZone(0, 0, 1920, 900);
    windEmitter.addInitialize(new Proton.Position(zone));

    windEmitter.addBehaviour(new Proton.RandomDrift(5, 0, 0.05));
    windEmitter.addBehaviour(
        new Proton.Color(['aaaaaa', 'bbbbbb', 'cccccc', 'dddddd', 'eeeeee', 'ffffff'])
    );
    windEmitter.addBehaviour(new Proton.Scale(1, 1.5, 2));
    windEmitter.addBehaviour(new Proton.Alpha(0, 1, 3));
    windEmitter.addBehaviour(new Proton.CrossZone(zone, 'cross'));

    let windRepulsionVector = new Proton.Vector(0, 0);
    windEmitter.addBehaviour(new Proton.Repulsion(windRepulsionVector, 100, 400));

    windEmitter.addBehaviour({
        initialize: function (particle) {
            particle.start = Date.now();
            particle.maxSpeed = 1000 - Math.random() * 300;
        },

        applyBehaviour: function (particle) {
            let player = room.teams[team][teamIndex];
            if(player){
                let activeAbility = player.activeAbility;
                let ability = player.abilities[activeAbility];
                let valueChanged = Math.random() * 100;
    
                let oldV = particle.v;
                if (ability.horDirection === 'right' || (ability.horDirection === null && oldV.x < 0)) {
                    if (oldV.x < particle.maxSpeed)
                        particle.v = new Proton.Vector(oldV.x + valueChanged, oldV.y);
                }
                oldV = particle.v;
                if (ability.horDirection === 'left' || (ability.horDirection === null && oldV.x > 0)) {
                    if (oldV.x > -particle.maxSpeed)
                        particle.v = new Proton.Vector(oldV.x - valueChanged, oldV.y);
                }
                oldV = particle.v;
                if (ability.vertDirection === 'up' || (ability.vertDirection === null && oldV.y > 0)) {
                    if (oldV.y > -particle.maxSpeed)
                        particle.v = new Proton.Vector(oldV.x, oldV.y - valueChanged);
                }
                oldV = particle.v;
                if (
                    ability.vertDirection === 'down' ||
                    (ability.vertDirection === null && oldV.y < 0)
                ) {
                    if (oldV.y < particle.maxSpeed)
                        particle.v = new Proton.Vector(oldV.x, oldV.y + valueChanged);
                }
                if (!ability.active) {
                    particle.alpha -= 0.02;
                }
            }
            else{
                particle.alpha -= 0.02;
            }
        },
    });

    windEmitter.emit(1);
    playerEmitter.emit();

    protonForeground.addEmitter(windEmitter);
    protonBackground.addEmitter(playerEmitter);
    let endParticles = () => {
        if (fadeTime === null) {
            fadeTime = Date.now();
            windEmitter.stop();
            playerEmitter.stop();
            setTimeout(() => {
                protonForeground.removeEmitter(windEmitter);
                protonBackground.removeEmitter(playerEmitter);
                clearInterval(interval);
            }, 3000);
        }
    }
    let adjustSound = () =>{
        if (!windSound.paused || windSound.currentTime !== 0) {
            let volume = (1 - (Date.now() - fadeTime) / fadeOutTime) * dampener;
            if (volume < 0) {
                volume = 0;
                windSound.pause();
                windSound.currentTime = 0;
            }
            if (volume > 1) {
                volume = 1;
            }
            windSound.volume = volume;
        }
    }
    let interval = setInterval(() => {
        let player = room.teams[team][teamIndex];
        if(player){
            let activeAbility = player.activeAbility;
            let ability = player.abilities[activeAbility];
            if (activeAbility === 'wind' && ability.active) {
                playerEmitter.p.x = player.position.x;
                playerEmitter.p.y = player.position.y;
                playerAttractionVector.x = player.position.x;
                playerAttractionVector.y = player.position.y;
                windRepulsionVector.x = player.position.x;
                windRepulsionVector.y = player.position.y;
                let velocity = { x: 0, y: 0 };
                //windEmitter.addBehaviour(new Proton.Attraction(new Proton.Vector2D(attractionPoint.x,attractionPoint.y),25,1920));
            } else {
                endParticles();
                adjustSound();
            }
        }
        else{
            endParticles();
            adjustSound();
        }
    }, 1000 / 60);
}
