import React from "react";
import {color} from "../../data/format";
import palette from '../../styled/Palette';
import {
    GlobalChatTime,
    GlobalChatMessageInstance, GlobalChatTimeDiv, GlobalChatMessageDiv, GlobalChatTalkDiv
} from "./GlobalChatStyle";
import {useIcon} from "../../data/icon";
import ModalBanUser from "../../component/Modal/Type/ModalBanUser";
import styled from "styled-components";

let IconWhisper = require('react-icons/lib/md/group');

const GlobalChatMessage = ({keyProp ,message,userModerator,dispatch}) => {
    const { squad, queue, players, colorHex, context, timestamp, from, ban} = message;
    const isWhisper = ['whisperSent', 'whisperReceived'].includes(message.context);
    const time = new Date(message.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

    const displayMessageType = () => {
        if (context === 'global') {
            return 'PUBLIC';
        }
        if (context === 'queue') {
            return 'EVENT';
        }
        if (isWhisper) {
            return 'DIRECT';
        }
    };

    const displayModeratorButton = () => {
        return (
            <div
                style={{color: palette.pomegranate}}
                className={'buttonZoom'}
                tabIndex={0}
                onClick={() => ModalBanUser(dispatch,from)}
            >
                {from && from !== 'MODERATOR' && userModerator && useIcon('ban')}
            </div>
        )
    };

    const displayUserMessage = () => {
        if (ban) {
            return (
                <GlobalChatMessageInstance>
                    <span style={{color: color(colorHex, 'light')}}>
                        {message.msg}
                    </span>
                </GlobalChatMessageInstance>
            )
        }
        if (from) {
            return (
                <GlobalChatMessageInstance>
                    <span style={{color: color(colorHex, 'light')}}>
                        <b>{from}</b> {message.msg}
                    </span>
                </GlobalChatMessageInstance>
            )
        }
        if (context === 'queue') {
            return (
                <GlobalChatMessageInstance>
                    <span style={{color: color(players[0].colorHex, 'light')}}>
                        {players[0].username}&nbsp;
                    </span>
                    is searching for {queue}{squad ? squad : null}!
                </GlobalChatMessageInstance>
            )
        }
    };

    return (
        <GlobalChatMessageDiv
            key={keyProp}
        >
            <GlobalChatTimeDiv
                key={keyProp}
            >
                <div style={{fontSize: '12px', display: 'flex'}}>
                    <div>
                        {displayMessageType()}
                    </div>
                    &nbsp;
                    {timestamp && <GlobalChatTime>{time}</GlobalChatTime>}
                </div>
                {isWhisper && <IconWhisper />}
                {displayModeratorButton()}
            </GlobalChatTimeDiv>
            <GlobalChatTalkDiv>
                {displayUserMessage()}
            </GlobalChatTalkDiv>
        </GlobalChatMessageDiv>
    )
};

export default GlobalChatMessage;