import {DropTypes} from "../../system/types";
import DropPaint from "./type/paint";
import DropCoin from "./type/coin";
import DropTicket from "./type/ticket";
import DropAchievement from "./type/achievement";
import DropPremium from "./type/premium";
import DropCard from "./type/card";
import React from "react";

const DropBody = ({ currentViewItem, theme, username, backgroundColor, history, items, handleReturn }) => {
    return {
        [DropTypes.PAINT]: (
            <DropPaint
                handleReturn={handleReturn}
                currentViewItem={currentViewItem}
            />
        ),
        [DropTypes.COINS]: (
            <DropCoin
                handleReturn={handleReturn}
                currentViewItem={currentViewItem}
            />
        ),
        [DropTypes.TICKETS]: (
            <DropTicket
                history={history}
                handleReturn={handleReturn}
                currentViewItem={currentViewItem}
            />
        ),
        [DropTypes.DOUBLE_PURCHASE]: (
            <DropTicket
                history={history}
                handleReturn={handleReturn}
                currentViewItem={currentViewItem}
            />
        ),
        [DropTypes.TROPHY]: (
            <DropAchievement
                handleReturn={handleReturn}
                currentViewItem={currentViewItem}
                theme={theme}
            />
        ),
        [DropTypes.PREMIUM]: (
            <DropPremium
                handleReturn={handleReturn}
                history={history}
                theme={theme}
                username={username}
            />
        )
    }[currentViewItem.category] || (
        <DropCard
            handleReturn={handleReturn}
            items={items}
            currentViewItem={currentViewItem}
            backgroundColor={backgroundColor}
            username={username}
            history={history}
        />
    )
};

export default DropBody;