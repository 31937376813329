import Proton from './proton';
import { protonForeground } from './protonForeground';
import { updatePerFrame } from './protonConfig';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createBubblePopParticles(position, size) {
    let bubblePopEmitter = new Proton.Emitter();
    bubblePopEmitter.rate = new Proton.Rate(webGLEnabled ? 140 : 20, updatePerFrame);
    bubblePopEmitter.addInitialize(new Proton.Mass(1));
    bubblePopEmitter.addInitialize(new Proton.Radius(5, 15));
    bubblePopEmitter.addInitialize(new Proton.Life(.5,1.5));
    bubblePopEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    bubblePopEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-2, 2), new Proton.Span(-2, 2), 'vector')
    );

    // bubblePopEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
    bubblePopEmitter.addBehaviour(new Proton.Color(['8cc6db','1cb0e6','638591','b3efff','5d8acf','c2d4f0','6499e8']));
    bubblePopEmitter.addBehaviour(new Proton.Gravity(-1));
    bubblePopEmitter.addBehaviour(new Proton.Scale(1, 4));
    bubblePopEmitter.addBehaviour(new Proton.Alpha(1, 0, 'easeIn'));
    bubblePopEmitter.addInitialize(
        new Proton.Position(
            new Proton.CircleZone(
                position.x,
                position.y,
                size / 2
            )
        )
    );
    bubblePopEmitter.damping = 0.04;
    bubblePopEmitter.emit(updatePerFrame * 2, 5);

    protonForeground.addEmitter(bubblePopEmitter);
    
}
