import { slowBallParticles } from '../particles/slowBallParticles';
import { speedBallParticles } from '../particles/speedBallParticles';
export default (sandbox, protonBackground, protonForeground) => {
    let allActiveAbilities = [];
    let shouldUpdate = true;
    sandbox.teams.teamA.concat(sandbox.teams.teamB).forEach(player =>{
        let activeAbility = player.activeAbility;
        if(activeAbility){
            let ability = player.abilities[activeAbility];
            if(ability){
                if(ability.active){
                    if(ability.name === 'pause')
                        shouldUpdate = false;
                    allActiveAbilities.push(ability.name);
                }
            }
        }
    })
    if (allActiveAbilities.includes('slowBall')) {
        slowBallParticles.enable();
        slowBallParticles.update(sandbox.ball);
    } else {
        slowBallParticles.disable();
    }
    if(slowBallParticles.emitterLoaded){
        slowBallParticles.update(sandbox.ball);
    }
    if (allActiveAbilities.includes('speedBall')) {
        speedBallParticles.enable();
    } else {
        speedBallParticles.disable();
    }
    if(speedBallParticles.emitterLoaded){
        speedBallParticles.update(sandbox.ball);
    }
    if(shouldUpdate){
        if(protonBackground.emitters.length > 0){
            protonBackground.update();
        }
        if(protonForeground.emitters.length > 0){
            protonForeground.update();
        }
    }
};
