import React from "react";
import styled from "styled-components";
import palette from "../../styled/Palette";
import {CARD_SIZE_MEDIUM, CARD_SIZE_SMALL, shadow} from "../../data/globals";
import {playSound} from "../../data/sound";
import loader from "../../images/load2.svg";
import {getIcon} from "../../component/Item/Item";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const CashItemTitleDiv = styled.div`
    font-family: Luckiest Guy;
    font-size: 1.5em;
    margin-top: .5em;
    color: ${palette.base4};
`;
const CashItemFooterDiv = styled.div`
    height: 6em;
    background-color: ${palette.base5};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.35s ease-in-out;
`;

const CashItemContainerDiv = styled.div`
    position: relative;
    flex-shrink: 0;
    
    width: ${({screenIsSmall}) => screenIsSmall ? CARD_SIZE_SMALL.width : CARD_SIZE_MEDIUM.width};
    height: ${({screenIsSmall}) => screenIsSmall ? CARD_SIZE_SMALL.height : CARD_SIZE_MEDIUM.height};
    
    margin: 0 .1em;
    ${props => props.detailedView && `
        flex-grow: 1;
        height: 100%;
        pointer-events: none;
        margin: 0 .2em 0 0;
    `}
    
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: ${shadow};
    flex-shrink: 0;
    
    transition: all 0.35s ease-in-out; 
    
    border: 1px solid ${palette.base7};
    background-color: ${palette.base6};
    &:hover, &:focus {
    
        cursor: pointer;
        background-color: ${palette.midnightBlue};
        border: 1px solid ${palette.belizeHole};
        
        svg, img {
          transform: scale(1.25);
        }
        
        ${CashItemFooterDiv} {
            background-color: ${palette.wetAsphalt};
        }
    }
`;
const CashItemBodyDiv = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const CashItemPriceDiv = styled.div`
    border-radius: 50%;
    padding: 1.5em .75em;
    margin-top: -7.5em;
    position: absolute;
    color: ${palette.base4};
    background-color: ${palette.base3};
    box-shadow: ${shadow};
    font-weight: bold;
    font-size: .8em;
`;
const CashItemCountDiv = styled.div`
    color: ${palette.base4};
    font-family: Luckiest Guy;
    font-size: 1.5em;
    position: absolute;
    bottom: .75em;
    right: .75em;
`;
const CashItemUnitPriceDiv = styled.div`
    background-color: ${palette.base7};
    color: ${palette.base4};
    position: absolute;
    top: .5em;
    left: .5em;
    padding: .5em;
    font-size: .75em;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

const CashItem = ({image,imageSize,price,title,count,click,detailedView}) => {
    const lessThanPenny = price/count < .009;
    const unitPrice = (price/count).toFixed(lessThanPenny ? 3 : 2);
    const { screenIsSmall } = UseWindowDimensions();

    return (
        <CashItemContainerDiv
            screenIsSmall={screenIsSmall}
            detailedView={detailedView}
            onClick={click}
            onMouseEnter={() => playSound('itemHover',1)}
            tabIndex={0}
        >
            <CashItemBodyDiv>
                {count &&
                    <CashItemUnitPriceDiv>
                        <div>{unitPrice}₵ each</div>
                    </CashItemUnitPriceDiv>
                }
                {
                    getIcon(image,count,imageSize)
                }
                {count &&
                    <CashItemCountDiv>
                        <span style={{fontSize: '.5em'}}>x</span> {count}
                    </CashItemCountDiv>
                }
            </CashItemBodyDiv>
            <CashItemFooterDiv>
                <CashItemPriceDiv>
                    ${price}
                </CashItemPriceDiv>
                <CashItemTitleDiv>
                    {title}
                </CashItemTitleDiv>
            </CashItemFooterDiv>
        </CashItemContainerDiv>
    )
};

export default CashItem;