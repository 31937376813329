
const HOOP_TALL = 10;
const HOOP_DIAMETER = 130;
const RIM_WIDTH = 15;

export const ballInitialState = {
    position: {
        x: -100,
        y: -100
    },
    latestPosition: {
        x: -100,
        y: -100,
    },
    lastPosition: {
        x: -100,
        y: -100,
    },
    circleRadius: 20,
    radius: 20,
};

export default {
    abilityEffects: [],
    contextMessages: [],
    bets: [],
    modifiers: {
        "Center Wall": {
            "votes": {
                "teamA": [
                    false,
                    false,
                    false
                ],
                "teamB": [
                    false,
                    false,
                    false
                ]
            },
            "status": "on",
            "type": "bool",
            "default": false,
            "voteCount": 0
        },
        "Ceiling": {
            "votes": {
                "teamA": [
                    false,
                    false,
                    false
                ],
                "teamB": [
                    false,
                    false,
                    false
                ]
            },
            "status": "on",
            "type": "bool",
            "default": false,
            "voteCount": 0
        },
        "Reset Player Positions After Point Scored": {
            "votes": {
                "teamA": [
                    false,
                    false,
                    false
                ],
                "teamB": [
                    false,
                    false,
                    false
                ]
            },
            "status": "off",
            "type": "bool",
            "default": false,
            "voteCount": 0
        },
        "Tumble": {
            "votes": {
                "teamA": [
                    false,
                    false,
                    false
                ],
                "teamB": [
                    false,
                    false,
                    false
                ]
            },
            "status": "off",
            "type": "bool",
            "default": false,
            "voteCount": 0
        },
        "Double Jump": {
            "votes": {
                "teamA": [
                    false,
                    false,
                    false
                ],
                "teamB": [
                    false,
                    false,
                    false
                ]
            },
            "status": "off",
            "type": "bool",
            "default": false,
            "voteCount": 0
        },
        "Ball Size": {
            "votes": {
                "teamA": [
                    "Random",
                    "Random",
                    "Random"
                ],
                "teamB": [
                    "Random",
                    "Random",
                    "Random"
                ]
            },
            "status": "Random",
            "type": "string",
            "allowedTypes": [
                "Small",
                "Big",
                "Random",
                "Normal"
            ],
            "default": "Normal",
            "voteCount": 6
        }
    },
    private: null,
    p1Score: 0,
    p2Score: 0,
    ranked: null,
    roomId: 'lobby',
    results: null,
    sport: null,
    status: null,
    spectators: null,
    timeUntilStart: null,
    timeUntilNextMatchStart: null,
    server: {
        name: 'default slime server',
        region: 'slimeville',
        provider: 'slime hosting llc',
        userRated: false
    },
    sessionId: null,
    teams: {
        teamA: [],
        teamB: []
    },
    teamSize: null,
    timeLeft: null,
    timeLeftTimestamp: null,
    view: {
        // Which components are being rendered
        options: false,
        chat: false,
        rules: true,
    },
};