import React, { useState } from 'react';
import styled from 'styled-components';
import palette from '../../../styled/Palette';
import {FONT_BIG, FONT_NORMAL, FONT_SMALL, FONT_SMALLER} from "../../../data/font";
import {useIcon} from "../../../data/icon";
import {shadow} from "../../../data/globals";

// Define the styled container for FaceAchievementMount
const AchievementContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; // Ensures the items are centered
    box-sizing: border-box;
`;

const ItemTitleTextDiv = styled.div`
    font-size: ${FONT_NORMAL};
    font-weight: bold;
    color: ${palette.base2};
    margin-bottom: 5px;
    color: ${palette.base4};
`;

const ItemBodyTextDiv = styled.div`
    word-break: break-word;
    padding: .75em;
    font-size: ${FONT_SMALLER};
    border-radius: 4px;
    display: flex;
    
    color: ${palette.base4};
    background-color: ${({ locked }) => (locked ? palette.wetAsphalt : palette.base5)};
    border: 1px solid ${({ locked }) => (locked ? palette.wetAsphalt : palette.base3)};
`;

const ItemWrapper = styled.div`
    display: flex;
    width: 40%;
    min-width: ${({ screenIsSmall }) => (!screenIsSmall ? '330px' : '450px')};
    box-sizing: border-box;
    background-color: ${({ isEvenRow }) => (isEvenRow ? palette.base3 : palette.base5)};
    height: 100%;
`;

const ACHIEVEMENT_DESCRIPTIONS = {
    'cat': 'Present exclusive from cash shop',
    'bird': 'Present exclusive from cash shop',
    'doggo': 'Present exclusive from cash shop',
    'serpent': 'Present exclusive from cash shop',
    'good boy': 'Present exclusive from cash shop',
    'generic': 'Achive 100 wins in every game mode',
    'jerk': 'Achive 100 total losses',
    'limesqueeze': 'Achieve a 10-loss streak',
    'cartoon': 'Achieve a 10 win streak',
    'skeletal': 'Achieve a 30 win streak',
    'mustachio': 'Achieve 1000 total wins',
    'standard': 'Achive 100 total wins',
    'baby': 'Win any game mode match',
    'vintage': '1/100 chance rare match drop',
    'seduction': '1/100 chance rare match drop',
    'cyclops': '1/100 chance rare match drop',
    'stitch': 'Join a squad and play with a friend',
    'kawaii': '10 total wins',
    'chibi': '25 wins in all game modes',
    'derp': '100 total loses'
};

const FaceAchievementMount = ({ screenIsSmall, slots, style, isEvenRow, filteredItems, slotIndex }) => {


    return (
        <AchievementContainer style={style}>
            {slots.map((itemSlot, index) => {
                const currentItem = filteredItems[index];  // Use the map index

                return (
                    <ItemWrapper
                        screenIsSmall={screenIsSmall}
                        isEvenRow={isEvenRow}
                        key={slotIndex}
                    >
                        <div>{itemSlot}</div>
                        <div style={{ margin: '1em', display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'flex-start' }}>
                            <ItemTitleTextDiv>{currentItem?.name}</ItemTitleTextDiv>
                            <ItemBodyTextDiv locked={currentItem?.locked}>
                                <div style={{ marginRight: '.5em' }}>
                                    {useIcon(currentItem?.locked ? 'new' : 'approve')}
                                </div>
                                {ACHIEVEMENT_DESCRIPTIONS[currentItem?.name.toLowerCase()]}
                            </ItemBodyTextDiv>
                        </div>
                    </ItemWrapper>
                );
            })}
        </AchievementContainer>
    );
};

export default FaceAchievementMount;

