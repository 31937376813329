import React, { useState, useEffect } from "react";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import ItemSlot from '../../../component/Item/ItemSlot';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import palette from '../../../styled/Palette';
import coin from "../../../images/coin.png";
import { playSound } from '../../../data/sound';
import { masterSocket } from "../../../redux/middleware/wsMaster";
import {useDispatch, useSelector} from "react-redux";
import {outfitUpdate, sellItem} from "../../../redux/modules/self/selfActions";
import {
    SellItemDiv,
    TitleDiv,
    ItemNameDiv,
    YieldDiv,
    BodyMount,
    YieldValueDiv,
    ValueDiv,
    LabelDiv,
    ResultDiv,
} from './SellItemStyle';
import {capitalize} from "../../../data/generic/generic";
import season from "../../../system/season";
import InfoBanner from "../../../component/InfoBanner/InfoBanner";
import {FONT_NORMAL} from "../../../data/font";

const SellItem = (props) => {
    const dispatch = useDispatch();
    const { itemSellValue, outfit, } = useSelector(state => state.self);

    let [sellState,setSellState] = useState(0);
    let [selling,setSelling] = useState(false);

    const handleSell = () => {
        playSound('Glass_Rise1',1);
        setSelling(true);
        //Make sell request to back-end
        masterSocket.send(JSON.stringify({
            'event': 'sellItem',
            'userItemId': props.item.userItemId
        }));
        setTimeout(() => {
            setSellState(1);
        }, 1250);

        // Update items optimistically assuming the server processed the request
        dispatch(sellItem({
            category: props.item.category,
            itemId: props.item.userItemId,
        }));
    };
    const handleCancel = () => {
        props.setSellWindow(false);
        playSound('screenClose',1);
    };
    const getItemValue = () => {
        /**
         * This function needs to remain in parity with the same function on the back-end
         */
        let stats = [];
        //put all user stats in one array to determine the total stat value
        Object.keys(props.item.stats).forEach((key) => stats.push(props.item.stats[key]));
        //get total stat value
        const statTotal = stats.reduce((a, b) => a + b);
        //set min and max values based on stats
        let itemMaxValue = Math.abs(statTotal) * 50;
        //incorporate tier into item value
        if (props.item.tier > 0) itemMaxValue = itemMaxValue * props.item.tier;
        //no value items will yield 100 coin
        if (itemMaxValue === 0) itemMaxValue = 100;
        return itemMaxValue;
    };

    useEffect(() => {
        playSound('screenOpen',1);
    }, []);

    const itemName = props.item.name.toLowerCase() === 'paint' ? props.item.colorName : props.item.name;
    return (
        <SellItemDiv
            className="animatedFast slideInRight"
        >
            <div style={{zIndex: 1}}>
                {sellState === 0 &&
                <InfoBanner
                    canClose={false}
                    title={`Pawn your ${itemName}?`}
                    body={
                        <ValueDiv>
                            You will earn&nbsp;
                            <YieldValueDiv>
                                {getItemValue() * .2}
                            </YieldValueDiv>
                            &nbsp;to&nbsp;
                            <YieldValueDiv>
                                {getItemValue()}
                            </YieldValueDiv>
                            &nbsp;slime coin
                            <div><img src={coin} alt='Coins'/></div>
                        </ValueDiv>
                    }
                />
                }
                {sellState === 1 &&
                <InfoBanner
                    canClose={false}
                    title={'YOU HAVE EARNED'}
                    body={
                        <ResultDiv>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div><img src={coin} alt='Coins'/></div>
                                <YieldValueDiv style={{fontSize: FONT_NORMAL}}>{itemSellValue}</YieldValueDiv>
                            </div>
                            <Button
                                click={() => handleCancel()}
                                styles={{
                                    width: STANDARD_BUTTON_WIDTH,
                                    marginTop: '1em'
                                }}
                                title={'Okay'}
                            />
                        </ResultDiv>
                    }
                />
                }
                <YieldDiv>
                    {sellState === 0 &&
                    <div style={{display: 'flex',flexDirection:'column'}}>
                        <ItemSlot
                            disabled
                            item={props.item}
                            category={props.category}
                        />
                    </div>
                    }
                </YieldDiv>
                <div style={{fontSize: '1.5em', zIndex: 1, display: 'flex', justifyContent: 'center'}}>
                    {sellState === 0 &&
                    <>
                        <InfoBanner
                            canClose={false}
                            body={
                               <div style={{display: 'flex', justifyContent: 'center'}}>
                                   <Button
                                       click={() => selling ? null: handleCancel()}
                                       styles={{width: STANDARD_BUTTON_WIDTH}}
                                       title={'Cancel'}
                                   />
                                   <Button
                                       click={() => selling ? null: handleSell()}
                                       styles={{width: STANDARD_BUTTON_WIDTH}}
                                       title={'Confirm'}
                                       color={'green'}
                                   />
                               </div>
                            }
                        />
                    </>
                    }
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 0
            }}>
                <div>
                    {sellState === 1 &&
                    <FontAwesomeIcon
                        className="animated fadeIn animationSpinSlow"
                        icon={['fas', 'badge']}
                        style={{
                            fontSize: '30em',
                            color: palette.base3,
                            zIndex: 0,
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        }}
                    />
                    }
                </div>
            </div>
        </SellItemDiv>
    )
};

export default SellItem;