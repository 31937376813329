import React, {useState, useEffect, useCallback, useRef} from 'react';
import loader from "../../images/load2.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { timerGeneric } from '../../data/format';
import { playSound } from '../../data/sound';
import { debounce } from "../../data/generic/generic";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import palette from "../../styled/Palette";
import { MainNotifyQueueDiv } from "./MainStyle";
import { requestLeaveQueue } from "../../system/endpoints/master";
import Spinner from "../../component/Spinner";

const QuickPlayDiv = styled.div`    
        width: 6em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5em 0;
        border-radius: 4px;
        padding: 0 1em;
        cursor: pointer;
        
        background-color: ${palette.base5};
        
        border: 1px solid ${props => props.theme.interfaceColor};
        color:${props => props.theme.interfaceColor};
        &:hover {
        background-color: ${palette.base6};
            border: 1px solid ${props => props.theme.interfaceColorBright};
            color: ${props => props.theme.interfaceColorBright};
        }
    `;

const QuickPlay = () => {
    const theme = useSelector(state => state.theme);
    const inQueue = useSelector(state => state.queue.active);
    const queueWait = useSelector(state => state.queue.wait);
    const [queueCooldown, setQueueCooldown] = useState(0);
    const [userHovering, setUserHovering] = useState(false);
    const queueInterval = useRef(null);
    const [queueTime, setQueueTime] = useState('0:00');

    const handlePlayNow = () => debouncePlayNow(inQueue);
    const debouncePlayNow = useCallback(debounce((inQueue) => {
        return leaveQuickMatch();
    }, 250), []);

    const leaveQuickMatch = () => {
        playSound('leftQueue', 1);
        requestLeaveQueue();
        setCooldown();
    };

    const setCooldown = () => {
        const quickPlay = document.getElementById('quickPlay');
        if (quickPlay) {
            quickPlay.style.pointerEvents = 'none';
        }
        setQueueCooldown(3); // Starting cooldown time
    };

    const tickQueue = () => {
        if (queueCooldown > 0) {
            setQueueCooldown(prev => prev - 1); // Update cooldown
        } else {
            const quickPlay = document.getElementById('quickPlay');
            if (quickPlay) quickPlay.style.pointerEvents = null;
        }
    };

    useEffect(() => {
        if (queueCooldown > 0) {
            queueInterval.current = setInterval(tickQueue, 1000);
        } else {
            clearInterval(queueInterval.current); // Clear the interval when cooldown ends
        }
        return () => {
            clearInterval(queueInterval.current); // Cleanup interval
        };
    }, [queueCooldown]);

    useEffect(() => {
        if (inQueue) {
            const timeInterval = setInterval(() => {
                const time = timerGeneric(Math.round((Date.now() - queueWait) / 1000));
                setQueueTime(time);
            }, 1000);

            return () => clearInterval(timeInterval);
        }
    }, [inQueue, queueWait]);

    return (
        <QuickPlayDiv
            id="quickPlay"
            theme={theme}
            onMouseEnter={() => setUserHovering(true)}
            onFocus={() => setUserHovering(true)}
            onBlur={() => setUserHovering(false)}
            onMouseLeave={() => setUserHovering(false)}
            onClick={handlePlayNow}
            tabIndex={0}
        >
            <Spinner size='25px' display={inQueue} />
            <div style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                {queueCooldown > 0 ? `${queueCooldown}s` : queueTime}
            </div>
        </QuickPlayDiv>
    );
};

export default QuickPlay;
