import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import SquadUser from "./SquadUser";
import {
    SquadContainerDiv,
    SquadBodyDiv,
    SquadHeaderDiv
} from "./SquadStyle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SquadLeadOptions from "./SquadLeadOptions";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const MAX_SQUAD_PLAYERS_DISPLAYED = 6;

const Squad = ({fullWidth,history}) => {
    const theme = useSelector(state => state.theme);
    const squad = useSelector(state => state.squad);
    const self = useSelector(state => state.self);
    const dispatch = useDispatch();
    const { screenIsSmall } = UseWindowDimensions();

    const getDisplaySquad = () => {
        const squadPlayers = [...squad.current.slice(0,MAX_SQUAD_PLAYERS_DISPLAYED+1)];
        // Display the maximum number of squad players (4).
        if (squad.current <= MAX_SQUAD_PLAYERS_DISPLAYED) {
            return squadPlayers;
        } else {
            const displaySquad = [...squadPlayers];
            // Determine the number of available slots to show a pending invite.
            const availablePendingSlots = MAX_SQUAD_PLAYERS_DISPLAYED - squad.current.length;
            // Display the pending users in the same squad list.
            for (let x=0; x < availablePendingSlots; x++) {
                // Display only as many slots as there are pending invites.
                if (squad.pending.length > x) {
                    displaySquad.push({
                        ...squad.pending[x],
                        pending: true
                    });
                }
            }
            return displaySquad;
        }
    };

    if (squad.current.length < 1) {
        return null;
    }

    return (
        <SquadContainerDiv
            screenIsSmall={screenIsSmall}
            fullWidth={fullWidth}
        >
            <SquadBodyDiv>
                {
                    getDisplaySquad().map((user) => {
                        return (
                            <SquadUser
                                key={user}
                                user={user}

                                squad={squad}
                                self={self}


                                pendingUser={user.pending}
                            />
                        )
                    })
                }
            </SquadBodyDiv>
            <SquadHeaderDiv>
                <div style={{display:' flex', flexGrow: 1}}>
                    <div style={{display: 'flex'}}>
                        <FontAwesomeIcon icon={['fas', 'user-friends']}/>
                        <div style={{margin: '0 1em'}}>Squad</div>
                    </div>
                </div>
                <SquadLeadOptions
                    squad={squad}
                    self={self}
                    history={history}
                    dispatch={dispatch}
                    theme={theme}
                />
            </SquadHeaderDiv>
        </SquadContainerDiv>
    )
};

export default Squad;
