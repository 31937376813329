let webGLEnabled;

const canvas = document.createElement('canvas');
const gl = canvas.getContext('webgl', { failIfMajorPerformanceCaveat: true });

if (!gl) {
    console.log('WebGL not supported, using canvas-based rendering');
    webGLEnabled = false;
} else {
    webGLEnabled = true;
}

// Clean up canvas and WebGL context
if (gl) {
    const ext = gl.getExtension('WEBGL_lose_context');
    if (ext) {
        ext.loseContext(); // Explicitly lose the WebGL context
    }
}

canvas.width = 0;
canvas.height = 0;
canvas.remove(); // Remove the canvas element from memory

export { webGLEnabled };