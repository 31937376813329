
import React, {useEffect, useState} from "react";
import Dialogue from "../index";
import {AvatarTypes, PageTypes} from "../../../system/types";
import UseWindowDimensions from "../../../hooks/useWindowDimensions";
import {randomItem} from "../../../utility";

const dialogueSequence = [
    `Heads up folks, there is an enormous coin storm coming in from the west!`,
    `The forecast calls for shiny showers—get your buckets ready!`,
    `Incoming wealth alert! Coins incoming at terminal velocity!`,
    `They said money doesn't grow on trees, but it sure falls from the sky!`,
    `Hold onto your hats and wallets; it's a full-blown coin hurricane!`,
    `It’s not just change in the air; it’s literal coins!`,
    `Duck and cover! These coins have no regard for personal space!`,
    `Grab an umbrella—or maybe a piggy bank—it's coin storm season!`,
    `We're experiencing a 100% chance of bling-bling precipitation!`,
];

const DialogueCoinEvent = ({ history,  }) => {
    const [dialogueVisible] = useState(true);
    const [dialogueIndex, setDialogueIndex] = useState(0);
    const [renderDialogue,setRenderDialogue] = useState(true);
    const { screenIsSmall } = UseWindowDimensions();
    const [selectedDialogue] = useState(() => randomItem(dialogueSequence));

    useEffect(() => {
        if (dialogueIndex === selectedDialogue.length) {
            history.push(PageTypes.MAIN)
        }

        // Note: I'm forcing a component rerender here. Not the best, but not the worst
        setRenderDialogue(false);
        setTimeout(() => {
            setRenderDialogue(true);
        }, 0);
    }, [dialogueIndex]);

    return (
        <>
            {renderDialogue &&
            <Dialogue
                avatar={AvatarTypes.METEOROLOGIST}
                styles={`bottom: ${screenIsSmall ? '-12em' : '-20em'};`}
                history={history}
                dialogueIndex={dialogueIndex}
                dialogueSequence={[selectedDialogue]}
                visible={dialogueVisible}
            />
            }
        </>
    )
};

export default DialogueCoinEvent;