import palette from "../../styled/Palette";
import React from "react";
import {AvatarTypes} from "../../system/types";
import slimeWizard from "../../images/avatars/slimeWizard.png";


const DialogueAvatarNameplate = ({dialogueIndex,avatar}) => {
    const avatarNameplate = () => {
        if (avatar === AvatarTypes.WIZARD) {
            return 'Slime Wizard';
        } else if (avatar === AvatarTypes.METEOROLOGIST) {
            return 'Dr. Ooze Forecast';
        }
    };

    return (
        <div
            className={dialogueIndex === 0 ? 'animated slideInLeft' : null}
            style={{
                fontSize: '.75em',
                backgroundColor: palette.midnightBlue,
                borderRadius: '4px',
                padding: '.5em',
            }}>
            <div>
                {avatarNameplate()}
            </div>
        </div>
    )
};

export default DialogueAvatarNameplate;