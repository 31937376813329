import styled from 'styled-components';
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import palette from "../styled/Palette";
import Icon from "../data/icon";

const GamepadNavigateOverlayDiv = styled.div`
    font-family: Montserrat;
    position: absolute;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
    backdrop-filter: blur(4px);
    color: white;
    top:0;left:0;right:0;bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${props => props.drawerOpen && 'padding-left: 6em;'}
`;
const GamepadNavigateHeaderDiv = styled.div`
    font-weight:bold;
    font-size: 1.5em;
`;
const GamepadNavigateDetailDiv = styled.div`
    margin-top: .5em;
    font-size: .75em;
`;

const GamepadNavigateOverlay = ({}) => {
    const navigating = useSelector(state => state.gamepad.navigating);
    const drawerOpen = useSelector(state => state.layout.drawer.open);

    if (navigating) {
        return (
            <GamepadNavigateOverlayDiv drawerOpen={drawerOpen}>
                <div style={{fontSize: '2em', color: palette.nephritis}}>
                    {Icon('gamepad-alt')}
                </div>
                <GamepadNavigateHeaderDiv>Navigating</GamepadNavigateHeaderDiv>
                <GamepadNavigateDetailDiv>
                    Press&nbsp;&nbsp;<b>SELECT</b>&nbsp;&nbsp;to resume
                </GamepadNavigateDetailDiv>
            </GamepadNavigateOverlayDiv>
        )
    } else {
        return null;
    }
};

export default GamepadNavigateOverlay;