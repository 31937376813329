import React, { useEffect, useRef } from 'react';
import {
    ChatDiv,
    MatchChatHeaderDiv,
    MatchChatBodyDiv,
    MatchChatObserversDiv
} from './MatchChatStyle';
import { scrollBottom, stickFloor, getUserKeystroke, censor } from '../../system/chat';
import { masterSocket } from "../../redux/middleware/wsMaster";

import { color } from '../../data/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coin from "../../images/coin.png";
import MatchBetDisplay from './MatchBetDisplay';
import {useDispatch, useSelector} from "react-redux";
import { gameSocket } from '../../redux/middleware/wsMatch';
import { sendMessage } from '../../redux/modules/chat/chatThunks';
import {PublicChatInput} from "../../page/PublicChat/PublicChatStyle";
import palette from "../../styled/Palette";
import {getTierData} from "../../data/tier";
import {CardContainerDiv} from "../Card/CardStyle";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import {history} from "../../index";
import {PageTypes} from "../../system/types";

let IconChat = require('react-icons/lib/md/forum');

// This is the explicit approach to tracking possible states of a single attribute.
export const chatTypes = {
    userMessage: 'userMessage',
    bets: 'bets',
    isReady: 'isReady',
    gameEnd: 'gameEnd',
    // this attribute is poorly named because it applies only to a user leaving the match, so its not explicit enough.
    event: 'event',
    drop: 'drop'
};

const rarityTierMap = {
    '1/1000': 4,
    '1/500': 3,
    '1/250': 2,
    '1/100': 1,
    '1/25': 0,
};

const MatchChat = ({page,history}) => {
    const dispatch = useDispatch();
    const chat = useSelector(state => state.chat);
    const theme = useSelector(state => state.theme);
    const { teams, roomId, spectators } = useSelector(state => state.match);
    const { team, playerId, sessionId } = useSelector(state => state.self);
    const { screenIsSmall } = UseWindowDimensions();
    const onMatchScreen = history.location.pathname === PageTypes.MATCH;

    const refChatInput = useRef(null);

    useEffect(() => {
        // A higher order function is created to use as a reference to the function instance.
        const keystrokeInstance = (e) => getUserKeystroke(e,chat.banned, refChatInput.current);

        scrollBottom();

        if (refChatInput.current) {
            window.addEventListener('keyup', keystrokeInstance);
            return () => {
                window.removeEventListener('keyup', keystrokeInstance);
            };
        }
    }, [chat.banned, refChatInput]);

    useEffect(() => {
        stickFloor();
    }, [chat.match]);
    useEffect(() => {
        scrollBottom();
    }, []);

    return (
        <ChatDiv
            screenIsSmall={screenIsSmall}
            page={page}
            className={`animated${page !== 'win' && ' fadeIn'}`}>
            <MatchChatHeaderDiv
                page={page}
            >
                <IconChat style={{fontSize: '22px', color: theme.interfaceColorBright}}
                          className="iconChat"
                />
                <input autoComplete="off"
                       tabIndex={0}
                       className={`chatInput ${chat.banned ? 'disabled':null}`}
                       style={{margin: '0 1em'}}
                       ref={refChatInput}
                       maxLength="64"
                       placeholder={`${chat.banned ? 'You talk too much' : 'Send message'}`}
                       onKeyUp={e => dispatch(sendMessage(e.target.value, e.keyCode, history.location.pathname, roomId, gameSocket, masterSocket, team, playerId, sessionId, refChatInput))}
                />
                <MatchChatObserversDiv
                    interfaceColorBright={theme.interfaceColorBright}
                    className="animated bounceIn"
                >
                    {spectators > 0 &&
                    <>
                        <FontAwesomeIcon
                            style={{marginRight: '.5em'}}
                            icon={['fas', 'eye']}
                        />
                        <div>{spectators}</div>
                    </>
                    }
                </MatchChatObserversDiv>
            </MatchChatHeaderDiv>
            <MatchChatBodyDiv
                onMatchScreen={onMatchScreen}
                page={page}
                className="chatStickFloor styleDarkScrollSquare">
                {chat.match && chat.match.map((chatInstance, index) => {

                    switch(chatInstance.chatType) {
                        case chatTypes.userMessage: {
                            return (
                                <div key={index} style={{overflowWrap: 'break-word', fontSize: '16px', color: color(chatInstance.colorHex, 'light')}}>
                                    <span style={{fontSize: '12px'}}>{chatInstance.colorName}&nbsp; </span>{censor(chatInstance.message)}
                                </div>
                            )
                        }
                        case chatTypes.bets: {
                            const winner = chatInstance.won ? chatInstance.maker : chatInstance.taker;
                            const loser = chatInstance.won ? chatInstance.taker : chatInstance.maker;

                            // Calculate winnings based on who won
                            const winnings = chatInstance.won
                                ? chatInstance.amount * chatInstance.numerator // Maker wins
                                : chatInstance.amount * chatInstance.numerator; // Taker wins

                            return (
                                <>
                                    {winner} won{" "}
                                    <span style={{ fontWeight: "bold", color: palette.sunFlower }}>
                                        {winnings} coins
                                    </span>{" "}
                                    from {loser}.
                                </>
                            );
                        }
                        case chatTypes.event: {
                            return (
                                <div key={index} style={{fontSize: '16px'}}>
                                    <span style={{fontSize: '12px', color: color(chatInstance.colorHex, 'light')}}>{chatInstance.message}</span><span style={{color: 'white'}}> has left the match!</span>
                                </div>
                            )
                        }
                        case chatTypes.isReady: {
                            return (
                                <div key={index} style={{fontSize: '16px'}}>
                                    <span style={{fontSize: '12px', color: color(chatInstance.colorHex, 'light')}}>{chatInstance.colorName}</span><span style={{color: 'white'}}> is ready!</span>
                                </div>
                            )
                        }
                        case chatTypes.gameEnd: {
                            let winningTeam = chatInstance.winningTeam;
                            let losingTeam = 'teamA';
                            if (chatInstance.winningTeam === 'teamA') losingTeam = 'teamB';
                            let reason = null;
                            if (winningTeam && teams.teamA[0]) {
                                if (winningTeam === 'none') {
                                    reason = 'Match will not begin. The opposing team has left the stadium.';
                                } else {
                                    let winnerLabel = null;
                                    let loserLabel = null;
                                    if (teams[winningTeam].length > 1 || teams[losingTeam].length > 1) {
                                        winnerLabel = winningTeam === 'teamA' ? 'Left Team' : 'Right Team';
                                        loserLabel = winningTeam === 'teamA' ? 'Right Team' : 'Left Team';
                                    } else {
                                        winnerLabel = teams[winningTeam][0].colorName;
                                        loserLabel = teams[winningTeam === 'teamA' ? 'teamB' : 'teamA'][0].colorName;
                                    }
                                    if (chatInstance.reason === 'forfeit') reason = `${loserLabel} has fled. ${winnerLabel} wins!`;
                                    if (chatInstance.reason === 'reachPoints') reason = `${winnerLabel} has bested ${loserLabel}!`;
                                    if (chatInstance.reason === 'outOfTime') reason = `${winnerLabel} wins for acquiring the most points!`;
                                }
                            } else {
                                if (chatInstance.reason === 'outOfTime') reason = `Out of time! Players tied.`;
                            }

                            return (
                                <div key={index}>
                                    <div key={index}>
                                        <div key={index} style={{fontSize: '16px'}}>
                                            <span style={{color: 'white'}}>{reason}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        case chatTypes.drop: {
                            const { rarity, colorName, colorHex, item } = chatInstance;
                            const {quantity, tier, name, category} = item;

                            return (
                                <div
                                    key={index}
                                    style={{
                                        fontSize: '16px',
                                        color: color(getTierData(category === 'accessory' ? rarityTierMap[rarity] : tier ).color, 'light')
                                    }}
                                >
                                        <span style={{fontSize: '12px', color: color(colorHex, 'light')}}>
                                            {colorName}
                                        </span>
                                    <span>
                                            {` just found `}
                                        </span>
                                    <span style={{fontWeight: 'bold'}}>
                                            {name}
                                        </span>
                                    {quantity &&
                                    <span>
                                                {` x${quantity}`}
                                            </span>
                                    }
                                    {rarity &&
                                    <span>
                                                {` (${rarity} odds)`}
                                            </span>
                                    }
                                </div>
                            )
                        }
                    }
                })
                }
            </MatchChatBodyDiv>
        </ChatDiv>
    )

};
export default MatchChat;
