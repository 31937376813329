import styled from "styled-components";
import React, {useState} from "react";
import { useIcon } from "../../../data/icon";
import palette from "../../../styled/Palette";
import {HomeButtonDiv, HomeHeaderDiv, HomeIconDiv} from "../MainStyle";
import {useDispatch, useSelector} from "react-redux";
import {requestQuickJoinQueue} from "../../../system/endpoints/master";
import {PageTypes} from "../../../system/types";
import ModalMembersRanked from "../../../component/Modal/Type/ModalMembersRanked";
import QuickPlay from "../QuickPlay";
import Header from "../../../component/Header/Header";

// Container for all buttons, ensuring the buttons are laid out in a flexible grid-like format
export const FindMatchContainerDiv = styled.div`
    display: flex;
    flex-wrap: wrap;  // Allow buttons to wrap onto the next line
    width: 100%;
    z-index: 2;
    background-color: ${palette.base6};
    height: calc(100vh - 3.5em);
`;

// Each button container will fill one quadrant of the available space
const FindMatchButtonDiv = styled.div`
    ${({ styles }) => styles}

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 45%;  // This means each button will take up 50% of the container's width (2 per row)
    height: 50%;  // This ensures the buttons fill 50% of the height, creating a quadrant layout
    box-sizing: border-box;
    background-color: ${palette.base1};
    font-size: 1.25em; 
    
     &:hover, :focus {
            background-color: ${palette.midnightBlue} !important;
            color: ${palette.base4};
            cursor: pointer;
            border: ${palette.belizeHole} 1px solid;
            
            ${HomeHeaderDiv} {
                color: white;
                background-color: ${palette.wetAsphalt};
            }
              ${HomeIconDiv} {
                transform: scale(2);
                color: ${props => props.interfaceColor};
            }
        }
`;

const FindMatchButton = ({ tabIndex, click, title, icon, styles }) => {
    const theme = useSelector(state => state.theme);

    return (
        <FindMatchButtonDiv
            tabIndex={tabIndex}
            onClick={click}
            interfaceColor={theme.interfaceColor}
            styles={styles}
        >
            <HomeHeaderDiv>
                <div>
                    {title}
                </div>
            </HomeHeaderDiv>
            <HomeIconDiv>
                {icon}
            </HomeIconDiv>
        </FindMatchButtonDiv>
    );
};

const FindMatch = ({history}) => {
    const dispatch = useDispatch();
    const acceptableServers = useSelector(state => state.socket.acceptableServers);
    const squad = useSelector(state => state.squad);
    const username = useSelector(state => state.self.username);
    const inQueue = useSelector(state => state.queue.active);

    const handleSelectQuickPlay = () => {
        if (acceptableServers.length > 0) {
            requestQuickJoinQueue(squad, acceptableServers);
        }
        history.push({pathname: PageTypes.MAIN});
    };
    const handleSelectRanked = () => {
        if (username) {
            history.push({pathname: PageTypes.GAME_SELECT, state: { mode: 'ranked'}})
        } else {
            ModalMembersRanked(dispatch,history);
        }
    };
    const handleSelectCasual = () => {
        history.push({pathname: PageTypes.GAME_SELECT, state: { mode: 'casual'}});
    };
    const handleSelectTutorial = () => {
        history.push({pathname: PageTypes.TUTORIAL});
    };

    if (inQueue) {
        return null;
    }

    return (
        <FindMatchContainerDiv>
            <Header
                title="GAME SELECT"
                closeRoute={PageTypes.MAIN}
            />
            <FindMatchButton
                tabIndex={0}
                click={() => handleSelectQuickPlay()}
                title={'QUICK PLAY'}
                icon={useIcon('quickPlay')}
                styles={{
                    borderRight: '1px solid black',
                    borderBottom: '1px solid black'
                }}
            />
            <FindMatchButton
                tabIndex={0}
                click={() => handleSelectTutorial()}
                title={'TUTORIAL'}
                icon={useIcon('wizard')}
                styles={{
                    borderBottom: '1px solid black'
                }}
            />
            <FindMatchButton
                tabIndex={0}
                click={() => handleSelectCasual()}
                title={'CASUAL'}
                icon={useIcon('optionSelect')}
                styles={{
                    borderRight: '1px solid black',
                }}
            />
            <FindMatchButton
                tabIndex={0}
                click={() => handleSelectRanked()}
                title={'RANKED'}
                icon={useIcon('ranked')}
            />
        </FindMatchContainerDiv>
    );
};

export default FindMatch;
