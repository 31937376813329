import styled from 'styled-components';
import palette from '../../../styled/Palette';
import {shadow} from "../../../data/globals";
import {FONT_LARGE, FONT_NORMAL} from "../../../data/font";

export const SellItemDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${palette.base0};
`;


export const TitleDiv = styled.div`
    z-index: 1;
    font-size: 3em;
    margin: 0 .25em;
    color: ${palette.base4};
`;

export const ItemNameDiv = styled.div`
    z-index: 1;
    font-size: 3em;
    color: ${props => props.interfaceColor};
`;
export const YieldDiv = styled.div`
    position: relative;

    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;
export const ValueDiv = styled.div`
    align-items: center;
    flex-grow: 1;
    display: flex;
    color: white;
    font-weight: bold;
`;
export const LabelDiv = styled.div`
    font-weight: bold;
    color: ${palette.base4};
`;
export const YieldValueDiv = styled.div`
    color: ${palette.sunFlower};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ResultDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    z-index: 1;
    font-size: 1em;
`;

export const BodyMount = styled.div`
    box-shadow: ${shadow};
    display: flex;
    flex-direction: column;
    margin: .25em;
    z-index: 1;
    color: ${palette.base4};
    text-align: left;
    background-color: ${palette.midnightBlue};
    border: 1px solid ${palette.belizeHole};
    padding: 1em;
    
    min-width: 220px;
`;