import styled from 'styled-components';

export const RadioContainer = styled.div`
   z-index: 5;
   display: flex;
   position: absolute;
   top: ${props => props.source === 'match' ? '2em' : '6em'};
   right: 1em;
   min-height: 70px;
`;

export const RadioClose = styled.span`
    margin-left: 1em;
    font-size: 2em;
    margin-top: -.5em;
    z-index: 6;
    height: 0;
    cursor: pointer;
    color: #c0c0c0;
    &:hover {
        color: #ffffff;
    }
`;


export const RevealHoverDiv = styled.div`
    transition: all .5s ease;
    display: none;
    justify-content: center;
    width: 100%;
`;

export const RevealMountDiv = styled.div`
    display: flex;
    flex-direction: column;
    &:hover ${RevealHoverDiv} {
        visibility: visible;
        display: flex;
        opacity: 1;
        padding-top: 10px;
    }
`;