import React, { useEffect, useRef, useState } from 'react';
import Card from "../../component/Card/Card";
import Header from "../../component/Header/Header";
import RestockClock from "./RestockClock";
import MarketRestockOverlay from "./MarketRestockOverlay/MarketRestockOverlay";
import { TabDiv, TabsContainerDiv } from '../../styled/Tabs';
import SlimePan from "../../styled/SlimePan";
import { setActiveTab } from "../../redux/modules/shop/shopActions";
import { playSound } from '../../data/sound';
import { useDispatch, useSelector } from "react-redux";
import { drawerToggle } from "../../redux/modules/socket/socketActions";
import palette from "../../styled/Palette";
import { MarketMountDiv, MarketBodyDiv } from './MarketStyle';
import { PageTypes } from "../../system/types";
import { MARKET_TABS } from "../../data/globals";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import InfoBanner from "../../component/InfoBanner/InfoBanner";

const Market = ({ history }) => {
    const dispatch = useDispatch();
    const shopItems = useSelector(state => state.shop.gear);
    const activeTab = useSelector(state => state.shop.activeTab);
    const [restocking, setRestocking] = useState(false);
    const refOverlayDiv = useRef();
    const { screenIsSmall, width } = useWindowDimensions();

    // Refs for container and MarketMountDiv
    const marketMountDivRef = useRef(null);
    const [marketMountDivWidth, setMarketMountDivWidth] = useState(0);

    useEffect(() => {
        const updatePadding = () => {
            if (marketMountDivRef.current) {
                const marketMountDivWidth = marketMountDivRef.current.offsetWidth;
                setMarketMountDivWidth(marketMountDivWidth); // Save width for MarketBodyDiv
            }
        };

        updatePadding();
    }, [width]); // Trigger when width changes (due to the useWindowDimensions hook)

    const handleCategory = (tab) => {
        if (tab !== activeTab) {
            dispatch(setActiveTab(tab));
            playSound('pressButton', 1);
        }
    };

    const renderTabs = () => {
        return (
            <TabsContainerDiv style={{ alignSelf: 'flex-end' }}>
                {
                    MARKET_TABS.map((tab) => {
                        return (
                            <TabDiv
                                tabIndex={0}
                                key={tab}
                                active={activeTab === tab}
                                onClick={() => handleCategory(tab)}
                            >
                                {tab.toUpperCase()}
                            </TabDiv>
                        )
                    })
                }
            </TabsContainerDiv>
        )
    };

    useEffect(() => {
        dispatch(drawerToggle({ open: true }));
    }, []);

    return (
        <div className="animatedFast slideInRight fullScreen" style={{ fontSize: '10px' }}>
            <MarketRestockOverlay refOverlayDiv={refOverlayDiv} />
            <Header closeRoute={PageTypes.MAIN} title="MARKET" />
            <SlimePan
                className="styleDarkScrollSquare"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                {/* Set MarketBodyDiv width to MarketMountDiv width */}
                <MarketBodyDiv
                    screenIsSmall={screenIsSmall}
                    style={{
                        width: marketMountDivWidth ? `${marketMountDivWidth}px` : 'auto',
                    }}
                >
                    <InfoBanner
                        title={'Live Marketplace'}
                        body={
                            <div>
                                Compete with other players to grab up the best items! Whether you're trying to look your best or play your best, all the items you need can be found here. Items restock every few hours so keep your eye out for a steal.
                            </div>
                        }
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            {renderTabs()}
                        </div>
                        <RestockClock refOverlayDiv={refOverlayDiv} setRestocking={setRestocking} />
                    </div>
                    <div style={{ paddingBottom: '2em' }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: palette.base1,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                        }}>
                            <MarketMountDiv ref={marketMountDivRef}>
                                {!restocking &&
                                shopItems.map((item, index) => {
                                    if (item.category === activeTab) {
                                        return (
                                            <div
                                                className="animated animatedSlow"
                                                key={item.key}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Card
                                                    disabled={false}
                                                    key={item.key}
                                                    flipped={false}
                                                    history={history}
                                                    index={index}
                                                    item={item}
                                                    backgroundColor={
                                                        item.category === "paint"
                                                            ? item.colorHex
                                                            : palette.base7
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </MarketMountDiv>
                        </div>
                    </div>
                </MarketBodyDiv>
            </SlimePan>
        </div>
    );
};

export default Market;
