import styled from 'styled-components';
import palette from '../../styled/Palette';

export const GlobalChatContainer = styled.div`
    width: 100%;
    min-width: 100px;
    background-color: #3D3D3D;
    text-align: left;
    display: flex;
    flex-grow: 1;
`;


export const GlobalChatTimeDiv = styled.div`
    align-items: center;
    display: flex;
    color: ${palette.base2};
`;
export const GlobalChatTime = styled.span`
    width: 5em;
`;

export const GlobalChatMessageInstance = styled.span`
    color: ${palette.base4};
    font-size: 16px;
    font-family: Montserrat;
`;

export const GlobalChatMessageDiv = styled.div`
    color: ${palette.base4};
    padding: .5em;
`;

export const GlobalChatTalkDiv = styled.div`
    word-break: break-word;
`;