import Proton from './proton';
import { protonForeground } from './protonForeground';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { getSound } from '../../data/sound';
import { angle } from './particleHelpers';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createMagnetParticles(room, team, teamIndex) {
    let magnetSound = new Audio(getSound('magnet').noise);
    let dampener = getSound('magnet').dampener;
    let fadeOutTime = 1000;
    let fadeTime = null;
    magnetSound.currentTime = 0;
    magnetSound.volume = 1 * dampener;
    magnetSound.play();

    let magnetEmitter = new Proton.Emitter();
    magnetEmitter.rate = new Proton.Rate(webGLEnabled ? 5: 2, updatePerFrame);
    magnetEmitter.addInitialize(new Proton.Radius(4, 8));
    magnetEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(1, 2), new Proton.Span(0, 360), 'polar')
    );
	magnetEmitter.addBehaviour(new Proton.Alpha(0.7,0));
	magnetEmitter.addInitialize(new Proton.Life(.2,.3));
    magnetEmitter.damping = .1;

    magnetEmitter.addBehaviour(
        new Proton.Color([
            'ff0000',
            '0000ff',
        ])
    );
    magnetEmitter.p.x = room.ball.position.x;
    magnetEmitter.p.y = room.ball.position.y;

	magnetEmitter.addBehaviour({
        initialize: function (particle) {
            let player = room.teams[team][teamIndex];
            particle.playerPosition = player.position;
        },
        applyBehaviour: function (particle) {
            let player = room.teams[team][teamIndex];
            if(player){
				let lastPosition = particle.playerPosition;
				let positionChange = {
					x: player.position.x - lastPosition.x,
					y: player.position.y - lastPosition.y
				}
				particle.playerPosition = player.position;
				particle.p.x += positionChange.x;
				particle.p.y += positionChange.y;
            }
            else{
                particle.alpha -= 0.02;
            }
        },
    });

    magnetEmitter.emit();

    protonForeground.addEmitter(magnetEmitter);
    let endParticles = () => {
        if (fadeTime === null) {
            fadeTime = Date.now();
            magnetEmitter.stop();
            setTimeout(() => {
                protonForeground.removeEmitter(magnetEmitter);
                clearInterval(interval);
            }, 1000);
        }
    };
    let adjustSound = () => {
        if (!magnetSound.paused || magnetSound.currentTime !== 0) {
            let volume = (1 - (Date.now() - fadeTime) / fadeOutTime) * dampener;
            if (volume < 0) {
                volume = 0;
                magnetSound.pause();
                magnetSound.currentTime = 0;
            }
            if (volume > 1) {
                volume = 1;
            }
            magnetSound.volume = volume;
        }
    };
    let interval = setInterval(() => {
        let player = room.teams[team][teamIndex];
        if (player) {
            let activeAbility = player.activeAbility;
            let ability = player.abilities[activeAbility];
            if (activeAbility === 'sticky' && ability.connected) {
                let ball = room.ball;
                let ballRadius = ball.radius;
                let angleF = angle(ball.position, player.position);
                let x = ball.position.x + Math.cos(angleF) * ballRadius;
                let y = ball.position.y + Math.sin(angleF) * ballRadius;
                magnetEmitter.p.x = x;
                magnetEmitter.p.y = y;
            } else {
                endParticles();
                adjustSound();
            }
        } else {
            endParticles();
            adjustSound();
        }
    }, 1000 / 60);
}
