import styled from 'styled-components';
import palette from '../../../styled/Palette';

export const PhaserDiv = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 2;
    
    justify-content: center;
    align-items: center;
    color: ${palette.base4};

    background-color: ${palette.base3};
`;

export const TitleDiv = styled.div`
    font-size: 110px;
`;

export const Title2Div = styled.div`
    font-size: 70px;
    letter-spacing: 10px;
`;

const num = '28px';
export const RulesBodyDiv = styled.div`
    color: ${palette.base4};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    animation: slide 20s linear infinite;
    background: repeating-linear-gradient(
        45deg,
        ${palette.base6},
        ${palette.base6} 10px,
        ${palette.base7} 10px,
        ${palette.base7} 20px
    );
    background-size: ${num} ${num};
`;
export const RulesDescriptionDiv = styled.div`
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51px;
    font-size: 2em;
    background-color: ${palette.midnightBlue};
`;
export const RulesContentDiv = styled.div`
    display: flex;
    height: calc(100vh - 3.5em - 2em); /* Subtract the height of the header */
    width: 100%;
    border: 1px solid black;
    margin: 1em;
`;
export const BallotSideDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    flex-basis: 0;
    background-color: ${palette.base6};
`;

export const UserSideDiv = styled.div`
    border-left: 1px solid black;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    flex-basis: 0;
`;
export const RulesTimeToStartDiv = styled.div`
    flex-grow: 1;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const RulesReadyButton = styled.button`
    color: ${palette.base4};
    border-radius: 4px;
    padding: 0 5em;
    font-weight: bold;
    height: 65%;
    width: 50%;
    background-color: ${({voted}) => voted ? palette.midnightBlue : palette.belizeHole};
`;
export const RulesFooterDiv = styled.div`
    padding: 0 1em;
    border-top: 1px solid black;
    height: 101px;
    display: flex;
    align-items: center;
    background-color: ${palette.base5};
`;
