import React, {useState, useRef, useEffect} from 'react';
import coin from "../../images/coin.png";
import coins from "../../images/slimeCoin.png";
import ticket2 from "../../images/ticket2.png";
import ticket3 from "../../images/ticket3.png";
import {colorContrast, colorSaturate, monoColor} from "../../data/format";
import workerGetPortrait from "../../webworker/main";
import palette from "../../styled/Palette";
import {
    CardDiv,
    HeaderDiv,
    FooterDiv,
    BodyDiv,
    CoinsDiv, CardFrontSoldStamp, CardFrontQuantity
} from './CardStyle';
import {animated, useSpring} from "react-spring";
import Item from "../Item/Item";
import {getItemByName} from "../../data/items";
import {numWords} from "../../data/generic/numToWord";

const CardFront = ({tierColor, backgroundColor, item, disabled,isFlipped,handleUserFlip,handleHover, tierStyle,history}) => {
    const refCardFront = useRef(null);
    const [iconSrc, setIconSrc] = useState(null);
    const [itemMonoColor] = useState(monoColor(item.colorHex));
    const [itemContrast] = useState(colorContrast(item.colorHex).toHexString());
    const itemIsColored = ['cap','skin'].includes(item.category);
    const mounted = useRef(false);
    const [isMarketPage] = useState(history.location.pathname === '/market');

    const contentProps = useSpring({
        top: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        transform: (mounted && isFlipped) ? 'rotateY(180deg)' : 'rotateY(0deg)',
    });

    useEffect(() => {
        if (itemIsColored && !iconSrc) {
            workerGetPortrait(null, item).then(dataUrl => {
                setIconSrc(dataUrl);
            });
        }

        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    return (
        <animated.div style={contentProps}>
            <CardDiv
                ref={refCardFront}
                disabled={disabled}
                backgroundColor={backgroundColor}
                tabIndex={isFlipped ? -1 : 0}
                onMouseLeave={() => handleHover(false)}
                onFocus={() => handleHover(true)}
                onBlur={() => handleHover(false)}
                onClick={() => handleUserFlip()}
                tier={tierStyle}
            >
                {item.sold &&
                <CardFrontSoldStamp>
                    <div
                        style={{
                            transform: 'rotate(-10deg)',
                        }}
                    >
                        SOLD
                    </div>
                </CardFrontSoldStamp>
                }
                <HeaderDiv
                    color={item.category === 'paint' ? itemMonoColor : tierStyle}
                    bg={item.category === 'paint' ? itemContrast : null}
                >
                    <div style={{paddingTop: '1em', fontSize: '.9em'}}>
                        {item.category === 'paint' ? item.colorName : item.name}
                    </div>
                </HeaderDiv>
                {item.quantity &&
                    <CardFrontQuantity>
                        YOU JUST FOUND {numWords(item.quantity).toUpperCase()}!
                    </CardFrontQuantity>
                }
                <BodyDiv sold={item.sold}>
                    <Item
                        size={10}
                        item={{
                            ...item,
                            // Append the client data for the item
                            ...getItemByName(item.name)
                        }}
                        portrait={iconSrc}
                    />
                </BodyDiv>
                <FooterDiv
                    sold={item.sold}
                    color={item.category === 'paint' ? 'black' : palette.base4}
                >
                    {isMarketPage &&
                        <CoinsDiv color={item.category === 'paint' ? itemMonoColor : tierColor}>
                            <img
                                style={{margin: '0 .25em'}}
                                src={coin}
                                alt='Coins'/>
                            {item.price}
                        </CoinsDiv>
                    }
                </FooterDiv>
            </CardDiv>
        </animated.div>
    )
};

export default React.memo(CardFront);