import React from 'react';
import styled from 'styled-components';
import palette from "../../styled/Palette";
import {shadow} from "../../data/globals";

export const AtlasContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
     background: repeating-linear-gradient(
      45deg,
      ${palette.base6},
      ${palette.base6} 10px,
      ${palette.base7} 10px,
      ${palette.base7} 20px
    );
`;
export const AtlasShadowDiv = styled.div`
     box-shadow: ${shadow};
`;
export const AtlasColumnOverlayDiv = styled.div`
    background-color: rgba(0,0,0,.15);
    height: 100%;
    width: 100%;
    position: absolute;
`;
export const AtlasBlockDiv = styled.div`
    height: .1em;
    background-color: ${palette.belizeHole};
`;