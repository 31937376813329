import styled from 'styled-components';
import palette from '../../styled/Palette';

export const WinTitleDiv = styled.div`
    height: 100px;
    font-family: Montserrat;
    display: flex;
    align-items: center;
    color: ${palette.base4};
    background-color: ${palette.midnightBlue};
    font-size: 2em;
    border-bottom: 1px solid black;
`;
export const WinTeamsMountDiv = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    font-size: 1em;
    color: ${palette.base4};
    background-color: ${palette.base0};
`;
export const WinTeamsTitleDiv = styled.div`
    text-align: left;
    font-family: Montserrat;
    font-size: 1em;
    color: ${palette.base4};
    padding: .5em;
    background-color: ${palette.midnightBlue};
`;

export const WinRootDiv = styled.div`
   display: flex;
   flex-grow: 1;
   justify-content: center;
   align-items: center;
`;

export const WinContainerDiv = styled.div`
   width: ${({screenIsSmall}) => screenIsSmall ? '100%' : '70em'};
   height: ${({screenIsSmall}) => screenIsSmall ? '100%' : 'auto'};
   border: 1px solid black;
   display: flex;
   flex-direction: column;
`;

export const WinDetailsDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

export const WinFooterDiv = styled.div`
    background-color: ${palette.base3};
    color: ${palette.base4};
    display: flex;
    border-top: 1px solid black;
    padding: 1em;
`;

export const WinScoreDiv = styled.div`
    border: 1px solid ${palette.base0};
    width: 2em;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    font-weight: bold;
`;

export const WinTeamsDiv = styled.div`
    background-color: ${palette.base5};
    flex-direction: column;
    padding: 5px;
    display: flex;
    flex-grow: 1;
`;

export const WinRematchTimerDiv = styled.div`
    display: flex;
    width: 2em;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    background-color: #2B2B2B;
    font-size: 30px;
    padding: 0 10px;
    margin: 0 10px 0 0;
`;
