
import RenderSkin from "./skin";
import RenderTexture from "./texture";
import RenderCap from "./cap";
import RenderFace from "./face";
import RenderBase from "./base";
import RenderGhostAura from "./ghost";
import ApplyCamouflag from '../../avatar/ability';

const AvatarLerp = (teams, context, percentageOfFrame, status, team, teamIndex) => {
    teams.teamA.concat(teams.teamB).forEach((player) => {
        let size = player.stats.size * player.scale;
        if (player.phased) {
            context.globalAlpha = 0.2;
        }
        if (!player.active) {
            let transparency = (player.disconnectTime + 3000 - Date.now()) / 3000;
            if (transparency < 0) transparency = 0;
            context.globalAlpha = transparency;
            player = {
                ...player,
                emote: 'shocked',
            };
        }
        let lerpX =
            status === 'live'
                ? (player.position.x - player.lastPosition.x) * percentageOfFrame +
                  player.position.x
                : player.position.x;
        let lerpY =
            status === 'live'
                ? (player.position.y - player.lastPosition.y) * percentageOfFrame +
                  player.position.y
                : player.position.y;
        let lerpAngle =
            status === 'live'
                ? (player.angle - player.lastAngle) * percentageOfFrame + player.angle
                : player.angle;
        if(player.activeAbility === 'ghost'){
            RenderGhostAura(context,lerpX,lerpY,size);
        }
        ApplyCamouflag(player, context, team, teamIndex);
        RenderBase(player, context, lerpX, lerpY, lerpAngle, size, team, teamIndex);
        RenderCap(player, context, lerpX, lerpY, lerpAngle, size);
        context.globalAlpha = 1;
    });
};

export const RenderLobbyAvatarLerp = (teams, context, percentageOfFrame, team, teamIndex) => {
    teams.teamA.concat(teams.teamB).forEach((player) => {
        let size = player.stats.size * player.scale;
        let lerpX =
            (player.position.x - player.lastPosition.x) * percentageOfFrame + player.position.x;
        let lerpY =
            (player.position.y - player.lastPosition.y) * percentageOfFrame + player.position.y;
        let lerpAngle = (player.angle - player.lastAngle) * percentageOfFrame + player.angle;
        if(player.activeAbility === 'ghost'){
            RenderGhostAura(context,lerpX,lerpY,size);
        }
        ApplyCamouflag(player, context, team, teamIndex);
        RenderBase(player, context, lerpX, lerpY, lerpAngle, size, team, teamIndex);
        RenderCap(player, context, lerpX, lerpY, lerpAngle, size);
        context.globalAlpha = 1;
    });
};

export default AvatarLerp;