import Gamepad from './gamepad';
import { notificationGamepad } from '../redux/modules/notification/notificationActions';
import store from '../redux/store';
import {
    gamepadToggleConnected,
    gamepadToggleNavigation,
    gamepadUpdate,
} from '../redux/modules/gamepad/gamepadActions';
import { playableScreen } from '../component/Manager/InputManager'
import {requestTakeUserInputs} from "../system/endpoints/match";

export const POSSIBLE_GAME_CONTROLS = ['left', 'right', 'up', 'down', '1', '2', '3', '4', 'shift','control'];

// The keys currently being pressed by the user
let activeKeys = {};
let activeKeysP2 = {};
let gamePadUp = false;

// The keys the user has already pressed
let previousKeys = {};
let previousActiveKeys = {};

// This retrieves all input and release actions from the keyboard
onkeydown = onkeyup = function (e) {
    if(e.repeat) return;
    //activeKeys[e.keyCode] = e.type == 'keydown';
    if (['ShiftLeft', 'ShiftRight'].includes(e.code)) {
        activeKeys['shift'] = e.type == 'keydown';
    } else {
        activeKeys[e.code] = e.type == 'keydown';
    }
    if('KeyE' === e.code){
        activeKeys['control'] = e.type == 'keydown';
    }
    if ('Space' === e.code) {
        activeKeys['up'] = e.type == 'keydown';
    }
    if ('KeyW' === e.code) {
        activeKeys['up'] = e.type == 'keydown';
    }
    if ('KeyA' === e.code) {
        activeKeys['left'] = e.type == 'keydown';
    }
    if ('KeyS' === e.code) {
        activeKeys['down'] = e.type == 'keydown';
    }
    if ('KeyD' === e.code) {
        activeKeys['right'] = e.type == 'keydown';
    }
    if ('ArrowUp' === e.code) {
        activeKeys['up'] = e.type == 'keydown';
    }
    if ('ArrowDown' === e.code) {
        activeKeys['down'] = e.type == 'keydown';
    }
    if ('ArrowLeft' === e.code) {
        activeKeys['left'] = e.type == 'keydown';
    }
    if ('ArrowRight' === e.code) {
        activeKeys['right'] = e.type == 'keydown';
    }
    if (['Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5'].includes(e.code)) {
        const rawDigit = e.code.replace(/^\D+/g, '');
        activeKeys[rawDigit] = e.type == 'keydown';
    }

    //This prevents windows from scrolling when using the spacebar
    if (e.keyCode == 32 && e.target == document.body) {
        e.preventDefault();
    }
    updateMatch();
};

// Determine if a key is or was being pressed
export const keyTest = (control, keys) => {
    keys = keys || activeKeys || activeKeysP2;
    return keys[control] || false;
};

// This assures that we only process the users control press one time.
export const processed = (control) => {
    if (keyTest(control, activeKeys) && !keyTest(control, previousKeys)) {
        previousKeys = activeKeys;
        return true;
    }
    return false;
};

// Wrapper to confirm the user is currently in the navigation mode before processing input
const handleNextMove = (direction) => {
    const gamepad = store.getState().gamepad;
    if (gamepad.connected && gamepad.navigating) {
        focusNextElement(direction);
    }
};

// Do not call directly, used by handleNextMove
const focusNextElement = (direction) => {
    const focusedElement = document.activeElement.getBoundingClientRect();
    const elementPool = document.querySelectorAll('[tabIndex="0"]');
    let nextElement = { element: null, distance: 99999999 };
    Array.prototype.slice.call(elementPool).map((element) => {
        const elementInstance = element.getBoundingClientRect();

        let condition = false;
        switch (direction) {
            case 'right':
                condition = focusedElement.left - elementInstance.left < 0;
                break;
            case 'left':
                condition = focusedElement.left - elementInstance.left > 0;
                break;
            case 'up':
                condition = focusedElement.top - elementInstance.top > 0;
                break;
            case 'down':
                condition = focusedElement.top - elementInstance.top < 0;
                break;
        }

        if (condition) {
            const distance = Math.sqrt(
                Math.pow(Math.abs(elementInstance.left - focusedElement.left), 2) +
                    Math.pow(Math.abs(elementInstance.top - focusedElement.top), 2)
            );
            if (distance < nextElement.distance) {
                nextElement = { element, distance };
            }
        }
    });

    if (nextElement.element) {
        nextElement.element.focus();
    }
};

// Kicks off event handling for all gamepad inputs
export let gamepad;
export const htmlJoystick = (dispatch,history) => {
    gamepad = new Gamepad();
    gamepad.on('connect', (e) => {
        //console.log(`controller ${e.index} connected!`);
        dispatch(gamepadToggleConnected(true));
        dispatch(notificationGamepad('DETECTED'));
    });
    gamepad.on('disconnect', (e) => {
        //console.log(`controller ${e.index} disconnected!`);
        dispatch(gamepadToggleConnected(false));
        dispatch(gamepadToggleNavigation(false));
        dispatch(notificationGamepad('LOST'));
    });

    // Event handling
    const dpad = () => {
        gamepad.on('hold', 'd_pad_left', (e) => {
            const keyboard = e.player === 'keyboard';
            activeKeys['d_pad_left'] = true;
            activeKeys['left'] = true;

            if (processed('d_pad_left') && !keyboard) {
                handleNextMove('left');
            }
            updateMatch();
        });
        gamepad.on('hold', 'd_pad_right', (e) => {
            const keyboard = e.player === 'keyboard';
            activeKeys['d_pad_right'] = true;
            activeKeys['right'] = true;
            if (processed('d_pad_right') && !keyboard) {
                handleNextMove('right');
            }
            updateMatch();
        });
        gamepad.on('hold', 'd_pad_up', (e) => {
            const keyboard = e.player === 'keyboard';
            activeKeys['d_pad_up'] = true;
            activeKeys['up'] = true;
            if (processed('d_pad_up') && !keyboard) {
                handleNextMove('up');
            }
            updateMatch();
        });
        gamepad.on('hold', 'd_pad_down', (e) => {
            const keyboard = e.player === 'keyboard';
            activeKeys['d_pad_down'] = true;
            activeKeys['down'] = true;
            if (processed('d_pad_down') && !keyboard) {
                handleNextMove('down');
            }
            updateMatch();
        });
        gamepad.on('hold', 'shoulder_top_left', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_top_left', pressed: true}));
            const keyboard = e.player === 'keyboard';
            activeKeys[1] = true;
            updateMatch();
        });
        gamepad.on('hold', 'shoulder_bottom_left', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_bottom_left', pressed: true}));
            const keyboard = e.player === 'keyboard';
            activeKeys[2] = true;
            updateMatch();
        });
        gamepad.on('hold', 'shoulder_top_right', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_top_right', pressed: true}));
            const keyboard = e.player === 'keyboard';
            activeKeys[3] = true;
            updateMatch();
        });
        gamepad.on('hold', 'shoulder_bottom_right', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_bottom_right', pressed: true}));
            const keyboard = e.player === 'keyboard';
            activeKeys[4] = true;
            updateMatch();
        });
        gamepad.on('release', 'd_pad_left', () => {
            activeKeys['d_pad_left'] = false;
            activeKeys['left'] = false;
            previousKeys = {};
            updateMatch();
        });
        gamepad.on('release', 'd_pad_right', () => {
            activeKeys['d_pad_right'] = false;
            activeKeys['right'] = false;
            previousKeys = {};
            updateMatch();
        });
        gamepad.on('release', 'd_pad_up', () => {
            activeKeys['d_pad_up'] = false;
            activeKeys['up'] = false;
            previousKeys = {};
            updateMatch();
        });
        gamepad.on('release', 'd_pad_down', () => {
            activeKeys['d_pad_down'] = false;
            activeKeys['down'] = false;
            previousKeys = {};
            updateMatch();
        });
        gamepad.on('release', 'shoulder_top_left', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_top_left', pressed: false}));
            const keyboard = e.player === 'keyboard';
            activeKeys[1] = false;
            updateMatch();
        });
        gamepad.on('release', 'shoulder_bottom_left', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_bottom_left', pressed: false}));
            const keyboard = e.player === 'keyboard';
            activeKeys[2] = false;
            updateMatch();
        });
        gamepad.on('release', 'shoulder_top_right', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_top_right', pressed: false}));
            const keyboard = e.player === 'keyboard';
            activeKeys[3] = false;
            updateMatch();
        });
        gamepad.on('release', 'shoulder_bottom_right', (e) => {
            dispatch(gamepadUpdate({button: 'shoulder_bottom_right', pressed: false}));
            const keyboard = e.player === 'keyboard';
            activeKeys[4] = false;
            updateMatch();
        });
    };
    const stickAxisLeft = () => {
        gamepad.on('hold', 'stick_axis_left', (eventObject) => {
            const xValue = eventObject.value[0];
            const yValue = eventObject.value[1];
            const left = xValue < -0.6;
            const right = xValue > 0.6;
            const up = yValue < -0.5;
            const down = yValue > 0.5;
            activeKeys['left'] = false;
            activeKeys['right'] = false;
            activeKeys['up'] = false;
            activeKeys['down'] = false;
            if (left) {
                activeKeys['stickAxisLeft'] = true;
                activeKeys['left'] = true;
                if (processed('stickAxisLeft')) {
                    handleNextMove('left');
                }
            }
            if (right) {
                activeKeys['stickAxisRight'] = true;
                activeKeys['right'] = true;
                if (processed('stickAxisRight')) {
                    handleNextMove('right');
                }
            }
            if (up || gamePadUp) {
                activeKeys['stickAxisUp'] = true;
                activeKeys['up'] = true;
                if (processed('stickAxisUp')) {
                    handleNextMove('up');
                }
            }
            if (down) {
                activeKeys['stickAxisDown'] = true;
                activeKeys['down'] = true;
                if (processed('stickAxisDown')) {
                    handleNextMove('down');
                }
            }
            updateMatch();
        });
        gamepad.on('release', 'stick_axis_left', () => {
            activeKeys['stickAxisLeft'] = false;
            activeKeys['stickAxisRight'] = false;
            activeKeys['stickAxisDown'] = false;
            activeKeys['stickAxisUp'] = false;
            activeKeys['left'] = false;
            activeKeys['right'] = false;
            activeKeys['down'] = false;
            activeKeys['up'] = false;
            previousKeys = {};
            updateMatch();
        });
    };
    const buttons = () => {
        // Xbox A
        gamepad.on('press', 'button_1', () => dispatch(gamepadUpdate({button: 'button_1', pressed: true,})));
        gamepad.on('hold', 'button_1', () => {
            activeKeys['up'] = true;
            gamePadUp = true;
            updateMatch();
        });
        gamepad.on('release', 'button_1', () => {
            dispatch(gamepadUpdate({button: 'button_1', pressed: false,}));
            activeKeys['up'] = false;
            gamePadUp = false;
            previousKeys = {};
            updateMatch();
        });

        // Xbox B
        gamepad.on('press', 'button_2', () => dispatch(gamepadUpdate({button: 'button_2', pressed: true,})));
        gamepad.on('hold', 'button_2', () => {
            activeKeys['button_2'] = true;
            // For abilities
            activeKeys['control'] = true;
            updateMatch();
        });
        gamepad.on('release', 'button_2', () => {
            dispatch(gamepadUpdate({button: 'button_2', pressed: false,}));
            activeKeys['button_2'] = false;
            // For abilities
            activeKeys['control'] = false;
            previousKeys = {};
            updateMatch();
        });

        // Xbox X
        gamepad.on('press', 'button_3', () => dispatch(gamepadUpdate({button: 'button_3', pressed: true,})));
        gamepad.on('hold', 'button_3', () => {
            activeKeys['button_3'] = true;
            activeKeys['shift'] = true;
            updateMatch();
        });
        gamepad.on('release', 'button_3', () => {
            dispatch(gamepadUpdate({button: 'button_3', pressed: false,}));
            activeKeys['button_3'] = false;
            activeKeys['shift'] = false;
            previousKeys = {};
            updateMatch();
        });

        // Xbox X
        gamepad.on('press', 'button_4', () => dispatch(gamepadUpdate({button: 'button_4', pressed: true,})));
        gamepad.on('hold', 'button_4', () => {
            activeKeys['button_4'] = true;
            updateMatch();
        });
        gamepad.on('release', 'button_4', () => {
            dispatch(gamepadUpdate({button: 'button_4', pressed: false,}));
            activeKeys['button_4'] = false;
            previousKeys = {};
            updateMatch();
        });
    };
    const start = () => {
        gamepad.on('press', 'start', () => {
            //dispatch(gamepadUpdate({button: 'start', pressed: true,}));
            activeKeys['start'] = true;
        });
        gamepad.on('release', 'start', () => {
            //dispatch(gamepadUpdate({button: 'start', pressed: false,}));
            activeKeys['start'] = false;
            previousKeys = {};
        });
    };
    const select = () => {
        gamepad.on('press', 'select', () => {
            //dispatch(gamepadUpdate({button: 'select', pressed: true,}));
            activeKeys['select'] = true;
            if (processed('select')) {
                const navigating = store.getState().gamepad.navigating;
                //todo: This should only toggle if the user is on the sandbox or in a match
                if (playableScreen.includes(history.location.pathname)) {
                    dispatch(gamepadToggleNavigation(!navigating));
                }
            }
        });
        gamepad.on('release', 'select', () => {
            //dispatch(gamepadUpdate({button: 'select', pressed: false,}));
            activeKeys['select'] = false;
            previousKeys = {};
        });
    };

    start();
    select();
    buttons();
    dpad();
    stickAxisLeft();
};

export const toggleAllNodes = (enable) => {
    const elementPool = document.querySelectorAll('[tabIndex="0"], [tabIndex="-1"]');
    elementPool.forEach((element) => {
        element.setAttribute('tabindex', enable ? '0' : '-1');
    });
};

export let updateMatch = () => {
    let controlsHaveChanged = false;
    let controls = {};
    POSSIBLE_GAME_CONTROLS.forEach(control => {
        if(activeKeys[control]){
            controls[control] = true;
        }
        if(activeKeys[control] != previousActiveKeys[control]){
            controlsHaveChanged = true;
        }
    });
    previousActiveKeys = {
        'left': activeKeys['left'], 
        'right': activeKeys['right'],
        'up': activeKeys['up'], 
        'down': activeKeys['down'], 
        '1': activeKeys['1'], 
        '2': activeKeys['2'],
        '3': activeKeys['3'], 
        '4': activeKeys['4'], 
        'shift': activeKeys['shift'],
        'control': activeKeys['control']
    }
    if(controlsHaveChanged){
        requestTakeUserInputs(controls);
    } 
};
