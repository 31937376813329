import React, {useEffect} from 'react';
import Header from "../../component/Header/Header";
import WatchMatchPanel from "./WatchMatchPanel/WatchMatchPanel";

import {
    WatchDiv,
    WatchHeaderDiv,
    WatchHeaderContentDiv, WatchBodyContentDiv,
} from './WatchStyle';
import { useSelector } from 'react-redux';

import { masterSocket } from "../../redux/middleware/wsMaster";
import Button from "../../styled/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InfoBanner from "../../component/InfoBanner/InfoBanner";
import {PageTypes} from "../../system/types";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

/*
 let stubRooms = {
 '803d514a4d2ad14f3b0cdce29e24223a7f47efe5': {
 bets:[],
 localExpiration:  2,
 mmr: 0,
 port: "8003",
 ranked: false,
 roomId: "803d514a4d2ad14f3b0cdce29e24223a7f47efe5",
 score: "0 - 0",
 sport: "volley",
 teamColors: {a: ['flavoursome guitar'], b: 'Jamaican Orchid'},
 teamSizes: {a: 4, b: 4},
 timeLeft: "Starting"
 },
 }; */

const getMatches = () =>{
    masterSocket.send(JSON.stringify({
        'event': 'getActiveMatches',
    }));
};

const Watch = ({history}) => {
    const theme = useSelector(state => state.theme);
    const rooms = useSelector(state => state.socket.rooms);
    const { screenIsSmall } = UseWindowDimensions();

    const liveRooms = Object.keys(rooms).length > 0;
    const getExpiration = (match) => {
        if (!isNaN(match.timeLeft)) {
            let secondsLeft = Math.floor((match.timeLeft - (Date.now() - match.timeLeftTimestamp)) / 1000);
            if (secondsLeft > 0) {
                const minutes = Math.floor(secondsLeft / 60);
                let seconds = secondsLeft % 60;
                if (seconds < 10) seconds = '0' + seconds;
                return minutes + ':' + seconds;
            } else {
                if(match.score.teamA === match.score.teamB){
                    return 'OVERTIME';
                }
                //todo: This is not a valid response when the match has entered overtime
                return 'OVER';
            }
        }
        else{
            if(match.timeLeft === 'Starting'){
                return 'STARTING';
            }
            return 'OVER';
        }
    };

    useEffect(() => {
        getMatches();
    }, []);

    return (
        <WatchDiv
            interfaceColor={theme.interfaceColor}
            className="fullScreen animatedFast slideInRight">
            <Header
                closeRoute={PageTypes.MAIN}
                title="WATCH"
            />
            <WatchBodyContentDiv
                screenIsSmall={screenIsSmall}
            >
                <InfoBanner
                    title={'Featured Matches'}
                    body={
                        <div>
                            These are the most formidable slime athletes, battling right now. We curate all live matches based on criteria such as <b>average player rating</b> so that you can be sure not to miss the latest and greatest matches in slime history.
                        </div>
                    }
                />
                {liveRooms &&
                <WatchHeaderDiv>
                    <WatchHeaderContentDiv>
                        <div>Mode</div>
                        <div>Score</div>
                        <div>Rating</div>
                        <div>Viewers</div>
                        <div>Status</div>
                    </WatchHeaderContentDiv>
                    <Button
                        history={history}
                        click={() => getMatches()}
                        icon={<FontAwesomeIcon icon={['fas', 'sync']}/>}
                        styles={{width: '4em', justifySelf: 'flex-end'}}
                    />
                </WatchHeaderDiv>
                }
                <div
                    style={{
                        flex: 1
                    }}
                >
                    <WatchMatchPanel
                        liveRooms={liveRooms}
                        getExpiration={getExpiration}
                        history={history}
                    />
                </div>
            </WatchBodyContentDiv>
        </WatchDiv>
    )
};

export default Watch;

