import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';

export function createSpeedPotionParticles(lobby, team, teamIndex) {
    let player = lobby.teams[team][teamIndex];
    let speedPotionEmitter = new Proton.Emitter();
    speedPotionEmitter.rate = new Proton.Rate(10, updatePerFrame);
    speedPotionEmitter.addInitialize(new Proton.Mass(1));
    speedPotionEmitter.addInitialize(new Proton.Radius(5, 17));
    const particleLife = 1;
    speedPotionEmitter.addInitialize(new Proton.Life(particleLife));
    speedPotionEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    speedPotionEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(2, 4), new Proton.Span(0, 360), 'polar')
    );
    speedPotionEmitter.addBehaviour(new Proton.Color('ff0000'));
    speedPotionEmitter.addBehaviour(new Proton.Gravity(-4));
    speedPotionEmitter.addBehaviour(new Proton.Scale(.05, .5));
    speedPotionEmitter.addBehaviour(new Proton.Alpha(0.6, 0));
    speedPotionEmitter.p.x = player.position.x;
    speedPotionEmitter.p.y = player.position.y;
    speedPotionEmitter.damping = 0.04;
    const duration = 30;
    speedPotionEmitter.emit(duration, duration + particleLife);
    protonBackground.addEmitter(speedPotionEmitter);
    let interval = setInterval(() => {
        player = lobby.teams[team][teamIndex];
        if (player && speedPotionEmitter.p) {
            let position = player.position;
            speedPotionEmitter.p.x = position.x;
            speedPotionEmitter.p.y = position.y;
        } else {
            speedPotionEmitter.rate = new Proton.Rate(0, 5);
            clearInterval(interval);
        }
    }, 1000 / 60);
    setTimeout(() => {
        speedPotionEmitter.rate = new Proton.Rate(0, 5);
        clearInterval(interval);
    }, 30000);
}
