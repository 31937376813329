import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

// This should not be used outside this file scope
const _icon = (iconName,styles,brand = 'fas') => {
    return <FontAwesomeIcon style={{margin: '0 .25em',...styles}} icon={[brand, iconName]}/>
};

// This properly abstracts the relational meaning we are associating with certain icons
export const useIcon = (symbol,styles,brand) => {
    const selection = {
        //item
        'new': 'unlock-alt',
        'achievementInfo': 'star-exclamation',
        'profile': null,

        'directMessage': null,
        'playerModerator': 'ankh',
        'viewProfile': null,
        'addFriend': null,

        // account type badges
        'premiumUser': 'gem',
        'memberUser': 'duck',
        'guestUser': 'duck',

        quickPlay: 'signal-stream',
        casual: 'bullseye-pointer',
        ranked: 'boxing-glove',
        wizard: 'hat-wizard',

        optionSelect: 'bullseye-pointer',

        info: 'info-circle',
        //network
        connection: 'plug',

        //actions
        exit: 'times',
        search: 'search',
        kill: 'skull',
        ban: 'fire-extinguisher',
        approve: 'check',

        promote: 'user-crown',
        lead: 'crown',

        tag: 'tag',
        create: 'plus',

        paint: 'tint',
        skin: 'pencil-paintbrush',
        face: 'grin',
        cap: 'hat-winter',
        accessory: 'puzzle-piece',
        non: 'star-of-life',

        change: 'arrow-alt-right',
        chatBubble: 'comment-alt',

        cog: 'cog',
        // These are for the options screen
        'chatHeavy': 'tired',
        'chatModerate': 'grin-tongue',
        'chatNone': 'meh',

        filter: 'filter',

        sell: 'dollar-sign',
        burn: 'fire',

        add: 'caret-down',
        subtract: 'caret-up',

        play: 'window-maximize'
    };
    return _icon(selection[symbol],styles,brand)
};

export default _icon;