import styled from 'styled-components';
import palette from '../../styled/Palette';
import {WATCH_MATCH_PANEL_SLOT_SIZE} from "../../data/globals";

export const WatchDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const num = '28px';
export const WatchBodyContentDiv = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    color: ${palette.base4};
    width: 100%;
    padding: ${({ screenIsSmall }) => (screenIsSmall ? '1em' : '4em 10em')};
    
    animation: slide 20s linear infinite;
    background: repeating-linear-gradient(
        45deg,
        ${palette.base6},
        ${palette.base6} 10px,
        ${palette.base7} 10px,
        ${palette.base7} 20px
    );
    background-size: ${num} ${num};

`;

export const MatchDiv = styled.div`
    width: 100%;
    height: ${WATCH_MATCH_PANEL_SLOT_SIZE}px;
    transition: all .25s ease;
    padding: 1em 2em 1em 1em;
    display: flex;
    border: 1px solid ${palette.base3};
    background-color: ${palette.base3};
    &:hover, :focus {
        cursor: pointer;
        background-color: ${palette.wetAsphalt} !important;
        border: 1px solid ${palette.belizeHole};
    }
`;

export const WatchHeaderDiv = styled.div`
    font-weight: bold;
    display: flex;
    text-align: left;
    background-color: ${palette.base0};
    justify-content: space-between;
    padding: .5em;
`;

const tableWidth = 5;
export const WatchHeaderContentDiv = styled.div`
    display: flex;
    padding: 1em 0;
    > div {
        padding: 0 1em;
        width: ${tableWidth}em;
    }
`;

export const WatchMatchInfoDiv = styled.div`
    align-items: center;
    display: flex;
    text-align: left;
    > div {
        padding: 0 1em;
        width: ${tableWidth}em;
   }
`;


export const SlimesDiv = styled.div`
    margin-left: 2em;
    flex-basis: 0;
    flex-grow: 1;
   align-items: center;
   display: flex;
`;

export const SlimeContainerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    background-color: ${palette.base5};
    border-radius: 4px;
    padding: 0 1em;
    overflow: hidden;
`;

export const VersusDiv = styled.div`
  font-family: Pacifico;
  font-size: 24px;
  color: ${palette.base2};
  padding: 0 10px;
`;
