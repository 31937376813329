import Proton from './proton';
import { playerColorHexToParticleColor } from './particleHelpers';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createSpinnerParticles(room, team, teamIndex) {
    let player = room.teams[team][teamIndex];
    let spinnerEmitter = new Proton.Emitter();
    spinnerEmitter.rate = new Proton.Rate(webGLEnabled ? 16 : 4, updatePerFrame);
    spinnerEmitter.addInitialize(new Proton.Mass(1));
    spinnerEmitter.addInitialize(new Proton.Radius(2, 4));
    spinnerEmitter.addInitialize(new Proton.Life(0.5, 1));
    spinnerEmitter.addBehaviour(new Proton.RandomDrift(30, 0, 0.05));
    spinnerEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(3, 6), new Proton.Span(0, 360), 'polar')
    );
    let color = playerColorHexToParticleColor(player.colorHex);
    spinnerEmitter.addBehaviour(new Proton.Color('random', 'random'));
    spinnerEmitter.addBehaviour(new Proton.Scale(1, 1.5));
    spinnerEmitter.addBehaviour(new Proton.Alpha(0.8, 0));
    spinnerEmitter.p.x = player.position.x;
    spinnerEmitter.p.y = player.position.y;
    spinnerEmitter.damping = 0.04;
    spinnerEmitter.emit();

    protonBackground.addEmitter(spinnerEmitter);

    let endParticles = () => {
        spinnerEmitter.rate = new Proton.Rate(0, 1000);
        clearInterval(interval);
        setTimeout(() => {
            protonBackground.removeEmitter(spinnerEmitter);
        }, 1500);
    };
    let interval = setInterval(() => {
        player = room.teams[team][teamIndex];
        if (player) {
            let position = player.position;
            spinnerEmitter.p.x = position.x;
            spinnerEmitter.p.y = position.y;
            if (!player.abilities.spinner?.active || player.abilities.spinner == null) {
                endParticles();
            }
        } else {
            endParticles();
        }
    }, 1000 / 60);
}
