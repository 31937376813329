import React from 'react';
import Header from '../../component/Header/Header.js';
import ButtonStandard from '../../component/ButtonStandard/ButtonStandard.js';
import {
    ReasonDiv
} from './ConnectionTimeoutStyle';
import { useSelector } from 'react-redux';
import {useIcon} from "../../data/icon";
import UseWindowDimensions from "../../hooks/useWindowDimensions";


export const ConnectionTimeout = () => {
    const disconnectReason = useSelector(state => state.socket.disconnectReason);
    const { screenIsSmall } = UseWindowDimensions();

    return (
        <div
            className="windowed animatedFast slideInRight"
            style={{height: screenIsSmall ? '100%' : '30em'}}
        >
            <Header title="DISCONNECTED"/>
            <ReasonDiv className="styleDarkScrollSquare">
                <div>{disconnectReason}</div>
            </ReasonDiv>
            <div
                onClick={() => window.location.reload()}
            >
                <ButtonStandard
                    styles={{width: '100%'}}
                    title='RECONNECT TO SERVER'
                    icon={<i className="fas fa-plug"> </i>}
                />
            </div>
        </div>
    )
};
export default ConnectionTimeout;