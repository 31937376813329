import React from "react";
import styled from "styled-components";
import palette from "../styled/Palette";
import {FONT_FINE, FONT_SMALL, FONT_SMALLER} from "../data/font";
import SlimeLogo from "../component/SlimeLogo";
import {useIcon} from "../data/icon";

const RedirectDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 3em;
    background-color: ${palette.peterRiver};
    color: ${palette.wetAsphalt};
    font-size: ${FONT_FINE};
`;

const MobileRedirect = () => {
    return (
        <RedirectDiv>
            <SlimeLogo />
            <div>The minimum supported device width is 1024 pixels</div>
            <div style={{marginTop: '.5em', fontStyle: 'italic', fontSize: FONT_SMALLER}}>(You need {1024-window.innerWidth} more)</div>
        </RedirectDiv>
    )
};

export default MobileRedirect;