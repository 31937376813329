import Proton from './proton';
import { protonForeground } from './protonForeground';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { getSound } from '../../data/sound';
import { angle } from './particleHelpers';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createVacuumParticles(room, team, teamIndex) {
    let vacuumSound = new Audio(getSound('vacuum').noise);
    let dampener = getSound('vacuum').dampener;
    let fadeOutTime = 1000;
    let fadeTime = null;
    vacuumSound.currentTime = 0;
    vacuumSound.volume = 1 * dampener;
    vacuumSound.play();

    let vacuumEmitter = new Proton.Emitter();
    vacuumEmitter.rate = new Proton.Rate(webGLEnabled ? 5: 1, updatePerFrame);
    vacuumEmitter.addInitialize(new Proton.Radius(8, 15));
    vacuumEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-1, 1), new Proton.Span(-1, 1), 'vector')
    );
    vacuumEmitter.damping = 0;

    vacuumEmitter.addBehaviour(
        new Proton.Color(['777777', '888888', '666666', '999999', 'aaaaaa', 'bbbbbb', 'cccccc'])
    );
    vacuumEmitter.p.x = room.ball.position.x;
    vacuumEmitter.p.y = room.ball.position.y;

    vacuumEmitter.addBehaviour({
        initialize: function (particle) {
            particle.start = Date.now();
        },

        applyBehaviour: function (particle) {
            let player = room.teams[team][teamIndex];
            if (player) {
                let particleSpeed = 2000;
                let angleF = angle(player.position, particle.p);
                let particleV = {
                    x: -Math.cos(angleF) * particleSpeed,
                    y: -Math.sin(angleF) * particleSpeed,
                };

                particle.v = new Proton.Vector(particleV.x, particleV.y);
                if (
                    Math.abs(particle.p.x - player.position.x) < 50 &&
                    Math.abs(particle.p.y - player.position.y) < 50
                ) {
                    particle.destroy();
                }
            }
        },
    });

    vacuumEmitter.emit();

    protonBackground.addEmitter(vacuumEmitter);
    let endParticles = () => {
        if (fadeTime === null) {
            fadeTime = Date.now();
            vacuumEmitter.stop();
            setTimeout(() => {
                protonBackground.removeEmitter(vacuumEmitter);
                clearInterval(interval);
            }, 1500);
        }
    };
    let adjustSound = () => {
        if (!vacuumSound.paused || vacuumSound.currentTime !== 0) {
            let volume = (1 - (Date.now() - fadeTime) / fadeOutTime) * dampener;
            if (volume < 0) {
                volume = 0;
                vacuumSound.pause();
                vacuumSound.currentTime = 0;
            }
            if (volume > 1) {
                volume = 1;
            }
            vacuumSound.volume = volume;
        }
    };
    let interval = setInterval(() => {
        let player = room.teams[team][teamIndex];
        if (player) {
            let activeAbility = player.activeAbility;
            let ability = player.abilities[activeAbility];
            if (activeAbility === 'vacuum' && ability.active) {
                let ball = room.ball;
                vacuumEmitter.p.x = 0;
                vacuumEmitter.p.y = 0;
                vacuumEmitter.addInitialize(
                    new Proton.Position(
                        new Proton.CircleZone(ball.position.x, ball.position.y, ball.radius)
                    )
                );
            } else {
                endParticles();
                adjustSound();
            }
        } else {
            endParticles();
            adjustSound();
        }
    }, 1000 / 60);
}
