import { webGLEnabled } from "../../../data/webGLEnabled";
import { speedBallParticles } from "../../particles/speedBallParticles";

let opacityMaster = 1;

const TrailLerp = (trail, context, ball) => {
	let lerpRatio = 10;
	let lerpedTrail = [];

	if(speedBallParticles.enabled){
		opacityMaster -= .05
	}
	else {
		opacityMaster += .01
	}
	if(opacityMaster < 0) opacityMaster = 0;
	if(opacityMaster > 1) opacityMaster = 1;

	if (trail?.length > 0) {
		if(webGLEnabled){
			for (let i = 0; i < trail.length - 1; i++) {
				let point = trail[i];
				let nextPoint = trail[i + 1];
	
				// Add the original point
				lerpedTrail.push(point);
				if(point && nextPoint){
					// Interpolate between point and nextPoint
					for (let j = 1; j <= lerpRatio; j++) {
						let t = j / (lerpRatio + 1);
						let x = point.x + t * (nextPoint.x - point.x);
						let y = point.y + t * (nextPoint.y - point.y);
						let opacity =
							point.opacity + t * (nextPoint.opacity - point.opacity);
		
						lerpedTrail.push({
							x,
							y,
							opacity
						});
					}
				}
			}
			lerpedTrail.push(trail[trail.length - 1]);
		}
		else {
			for(let i = 2; i < trail.length; i+=2){
				let point = trail[i];
				if(point){
					lerpedTrail.push(point);
				}
			}
		}


		for (var i = lerpedTrail.length - 1; i >= 0; i--) {
			let season = 'spring';
			let point = lerpedTrail[i];
			let opacity = point.opacity * opacityMaster;

			let hue;
			if (season === 'spring') {
				let hue = Math.round((360 * i) / lerpedTrail.length);
				context.fillStyle =
					'hsla(' +
					hue +
					', 100%, 50%,' +
					opacity +
					')';
			}
			if (season === 'winter') {
				hue = 200;
				context.fillStyle =
					'hsla(' +
					hue +
					', 50%, 50%,' +
					opacity +
					')';
			}
			if(opacity > .01){
				context.beginPath();
				context.arc(
					point.x,
					point.y,
					(ball.radius * (lerpedTrail.length - i)) / lerpedTrail.length,
					Math.PI * 2,
					0
				);
				context.fill();
			}

		}
	}

	context.fillStyle = '#fff';
	context.globalAlpha = 1;
};

export default TrailLerp;