import React, {useEffect, useRef, useState} from 'react';
import isElectron from 'is-electron';
import { masterSocket } from "../../redux/middleware/wsMaster";
import {getCookie, userOnChrome} from "../../data/generic/generic";
import {useDispatch, useSelector} from "react-redux";
import {loaded, promiseImages} from "../../data/images";
import {DURATION_WAIT_AUTO_SIGN_IN_BEFORE_FADE} from "../../data/globals";
import ModalBrowserWarning from "../../component/Modal/Type/ModalBrowserWarning";
import Icon from "../../data/icon";
import {
    GamepadDiv, GamepadMountDiv,
    TitleDiv,
    TitleLoadingDiv,
    TitleLoadingValueDiv,
    TitlePrimaryTextDiv,
    TitleSecondaryTextDiv, TitleSubTextDiv
} from './TitleStyle';
import {gamepad, processed} from "../../gamepad/controller";
import SlimeLogo from "../../component/SlimeLogo";
import {PageTypes} from "../../system/types";

const Title = ({ history }) => {
    const gamepadConnected = useSelector(state => state.gamepad.connected);
    const gamepad = useSelector(state => state.gamepad);

    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);

    const refTitle = useRef();
    const refLoadingDiv = useRef();

    const loadingPercentFormatted = `${Math.floor((loaded / promiseImages.length) * 100)}%`;
    const navigating = useSelector(state => state.gamepad.navigating);

    const assetsLoaded = useSelector(state => state.socket.assetsLoaded);
    const connectedToMaster = masterSocket?.readyState === 1;

    // Triggers main screen via the controller pressing select
    useEffect(() => {
        if (navigating) {
            routeMain();
        }
    }, [navigating]);

    const routeMain = () => {
        if (assetsLoaded && connectedToMaster) {
            history.push(PageTypes.MAIN);
        }
    };

    useEffect(() => {
        const userOnAlternativeBrowser = !userOnChrome() && !isElectron();
        if (userOnAlternativeBrowser) {
            ModalBrowserWarning(dispatch);
        }
    }, []);

    // Fade game in once assets are loaded
    useEffect(() => {
        if (refLoadingDiv.current) {
            setTimeout(() => {
                refLoadingDiv?.current?.classList.add('fadeOut');
            }, DURATION_WAIT_AUTO_SIGN_IN_BEFORE_FADE);
        }
    }, [assetsLoaded]);

    useEffect(() => {
        if (gamepad) {
            for (const [button, isPressed] of Object.entries(gamepad)) {
                if (isPressed) {
                    routeMain();
                    break;
                }
            }

        }
    }, [gamepad]);

    return (
        <TitleDiv
            ref={refTitle}
            tabIndex={0}
            color={theme.interfaceColor}
            onClick={() => routeMain()}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
                <TitleLoadingDiv
                    className="animated"
                    ref={refLoadingDiv}
                > </TitleLoadingDiv>
                <div
                    style={{zIndex: 4, fontFamily: 'Modak', fontSize: '20px'}}>
                    {assetsLoaded && connectedToMaster &&
                    <div style={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        bottom: '0',
                        left: '0'
                    }}>
                        <div
                            className="bounceInDown animated"
                            style={{animationDelay: DURATION_WAIT_AUTO_SIGN_IN_BEFORE_FADE + 'ms'}}
                        >
                            <SlimeLogo theme={theme} />
                            <TitleSubTextDiv className="hideText animationFaderLoadText" theme={theme}>
                                USE
                                <GamepadDiv>
                                    <GamepadMountDiv>
                                        {Icon('mouse-pointer')}
                                    </GamepadMountDiv>
                                </GamepadDiv>
                                OR
                                <GamepadDiv>
                                    <GamepadMountDiv>
                                        {Icon('gamepad-alt')}
                                    </GamepadMountDiv>
                                </GamepadDiv>
                                TO CONTINUE
                            </TitleSubTextDiv>
                        </div>
                    </div>
                    }
                    {connectedToMaster && !assetsLoaded &&
                        <TitleLoadingValueDiv>
                            {loadingPercentFormatted}
                        </TitleLoadingValueDiv>
                    }
                </div>
            </div>
        </TitleDiv>
    )
};
export default Title;