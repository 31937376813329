import styled from 'styled-components';
import palette from './Palette';

export const TabsContainerDiv = styled.div`
  display: flex;
`;

export const TabDiv = styled.div`
    cursor: ${props => props.active ? 'default' : 'pointer'};
    font-size: 20px;
    padding: 10px 50px;
    margin: 0 1px 0 0;
    color: ${props => props.active ? palette.base4 : palette.base2};
    background-color: ${props => props.active ? palette.base1 : palette.base3};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;