import {HomeButtonDiv, HomeHeaderDiv, HomeIconDiv} from "../MainStyle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import QuickPlay from "../QuickPlay";
import {useDispatch, useSelector} from "react-redux";
import ModalMembersRanked from "../../../component/Modal/Type/ModalMembersRanked";
import {requestQuickJoinQueue} from "../../../system/endpoints/master";
import {PageTypes} from "../../../system/types";

const MainBody = ({username, theme, history,inQueue}) => {
    const dispatch = useDispatch();
    const acceptableServers = useSelector(state => state.socket.acceptableServers);
    const squad = useSelector(state => state.squad);
    const self = useSelector(state => state.self);
    const [dateCreated] = useState(self.dateCreated);
    const [date] = useState(new Date());
    const [weekAgo] = useState(date.setDate(date.getDate() - 7));
    const [accountCreated] = useState(new Date(dateCreated).getTime());
    const [userIsNoob] = useState(accountCreated > weekAgo);
    const userIsAnonymous = !self.username;

    const handleSelectQuickPlay = () => {
        if (acceptableServers.length > 0) {
            requestQuickJoinQueue(squad, acceptableServers);
        }
    };
    const handleSelectRanked = () => {
        if (username) {
            history.push({pathname: PageTypes.GAME_SELECT, state: { mode: 'ranked'}})
        } else {
            ModalMembersRanked(dispatch,history);
        }
    };
    const handleSelectCasual = () => {
        history.push({pathname: PageTypes.GAME_SELECT, state: { mode: 'casual'}});
    };
    const handleSelectTutorial = () => {
        history.push({pathname: PageTypes.TUTORIAL});
    };

    const HomeButton = ({tabIndex, click, title, icon}) => {
        return (
            <HomeButtonDiv
                tabIndex={tabIndex}
                onClick={() => click()}
                interfaceColor={theme.interfaceColor}
            >
                <HomeHeaderDiv>
                    <div>
                        {title}
                    </div>
                </HomeHeaderDiv>
                <HomeIconDiv>
                    <FontAwesomeIcon
                        className="animated"
                        icon={icon}
                    />
                </HomeIconDiv>
            </HomeButtonDiv>
        )
    };

    if (!inQueue) {
        return (
            <div style={{display: 'flex', height: '10em', }}>
                {!inQueue &&
                <div style={{display: 'flex'}}>
                    <HomeButton
                        title={'QUICK PLAY'}
                        icon={['fal', 'signal-stream']}
                        tabIndex={0}
                        click={() => handleSelectQuickPlay()}
                    />
                    {!userIsAnonymous ?
                        <HomeButton
                            title={'MODE SELECT'}
                            icon={['fal', 'bullseye-pointer']}
                            tabIndex={0}
                            click={() => handleSelectCasual()}
                        />
                        :
                        <HomeButton
                            title={'TUTORIAL'}
                            icon={['fas', 'hat-wizard']}
                            tabIndex={0}
                            click={() => handleSelectTutorial()}
                        />
                    }
                    <HomeButton
                        title={'RANKED'}
                        icon={['fas', 'boxing-glove']}
                        tabIndex={0}
                        click={() => handleSelectRanked()}
                    />
                </div>
                }
            </div>
        )
    }

    return null;
};

export default MainBody;