import React from "react";
import {getTierData} from "../../data/tier";
import {FONT_BIG, FONT_HUGE, FONT_LARGE} from "../../data/font";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const RightPanelDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    width: 50%;
    
    text-align: left;
    
    border: solid ${palette.belizeHole};
    border-width: 0 0 0 2px;
`;

const RightPanel = ({ refCurrentItemContainer, refCrosshairText, currentItem }) => {
    const { screenIsSmall } = UseWindowDimensions();

    const getTierName = () => {
        let itemTierName;
        if (typeof(currentItem.tier) === 'number') {
            itemTierName = getTierData(currentItem.tier).name;
            itemTierName = itemTierName.toUpperCase();
        }
        if (currentItem.category === 'Coins') {
            itemTierName = 'NICE!'
        }
        if (currentItem.category === 'Tickets') {
            itemTierName = 'SWEET!'
        }
        return itemTierName;
    };

    return (
        <RightPanelDiv
            ref={refCurrentItemContainer}
            className="animated"
        >
            {currentItem &&
            <div style={{padding: '0 1em', }}>
                <div
                    style={{
                        color: palette.base4,
                        fontSize: screenIsSmall ? '1.5em' : '3em',
                        fontWeight: 'bold'
                    }}
                >
                    {currentItem.name || currentItem.category.toUpperCase() /* Tickets and Coins don't have a name */}
                </div>
                <div
                    style={{
                        fontSize: screenIsSmall ? '1em' : '1.5em'
                    }}
                    ref={refCrosshairText}
                    className="transitionFast"
                >
                    {getTierName()}
                </div>
            </div>
            }
        </RightPanelDiv>
    )
};

export default RightPanel;