import React, { useState } from 'react';
import Page from "../../styled/Page";
import { ItemDetail } from "./ItemDetail";
import { AtlasBanner } from "./AtlasBanner";
import Header from "../../component/Header/Header";
import Drops from "../../data/items";
import Tabs from "../../component/Tabs";
import {
    AtlasBlockDiv,
    AtlasColumnOverlayDiv,
    AtlasContainerDiv,
    AtlasShadowDiv
} from "./AtlasStyle";
import { PageTypes } from "../../system/types";
import palette from "../../styled/Palette";
import {FONT_BIG, FONT_HUGE, FONT_LARGE, FONT_NORMAL} from "../../data/font";
import {seasons, shadow} from "../../data/globals";
import season from '../../system/season';
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import InfoBanner from "../../component/InfoBanner/InfoBanner";
import {capitalize} from "../../data/generic/generic";

// Retrieves the items for a provided season
const SeasonItems = (season, history) => {
    return Drops[season].map((item, i) => (
        <ItemDetail
            key={item.itemId}
            history={history}
            size="small"
            item={item}
            index={i}
            slotMargin={0}
        />

    ));
};

// The view for all seasons
const AllSeasonRollUp = ({ history, screenIsSmall }) => {
    const [activeTab, setActiveTab] = useState(sections[0]);

    return (
        <div style={{
            marginTop: '2em',
        }}>
            <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                sections={sections}
            />
            <AtlasBlockDiv />
            <AtlasShadowDiv>
                <div
                    style={{
                        backgroundColor: palette.base7,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '16px', // Add spacing between items
                        justifyContent: 'center',
                        padding: '2em 0',
                    }}
                >
                    {Drops.all[activeTab].map((item, index) => (
                        <ItemDetail
                            key={item.itemId}
                            history={history}
                            size="medium"
                            item={item}
                            index={index}
                            slotMargin={0}
                        />
                    ))}
                </div>
            </AtlasShadowDiv>
        </div>
    )
};

// The view for each season
const SeasonRollUp = ({ history, screenIsSmall }) => {
    return (
        <div
            style={{
                marginTop: '2em',
                marginBottom: '2em',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
        >
            {seasons.map((s, i) => (
                <div
                    key={s}
                    style={{
                        flexBasis: screenIsSmall ? 'calc(50%)' : 'calc(25%)', // 2 columns or 4 columns
                        maxWidth: screenIsSmall ? 'calc(50%)' : 'calc(25%)', // Same as flex-basis
                        position: 'relative',
                        backgroundColor: s === season.current ? palette.midnightBlue : palette.base7,
                        border: s === season.current
                            ? `1px solid ${palette.belizeHole}`
                            : `1px solid ${palette.base7}`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: shadow,
                        paddingBottom: '2em',
                    }}
                >
                    {i % 2 === 0 && <AtlasColumnOverlayDiv />}
                    <div style={{ width: '100%' }}>
                        <div
                            style={{
                                textTransform: 'uppercase',
                                color: palette.base4,
                                fontSize: FONT_NORMAL,
                                backgroundColor: s === season.current ? palette.belizeHole : palette.base3,
                                height: '3em',
                                marginBottom: '2em',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            <div>{s}</div>
                        </div>
                    </div>
                    {SeasonItems(s, history)}
                </div>
            ))}
        </div>
    );
};


// The final composed view
const sections = ['ability', 'cap', 'skin', 'consumable', 'other'];

export const Atlas = ({ history }) => {
    const { screenIsSmall } = UseWindowDimensions();
    const [seasonAlias] = useState(season.staticData[season.current].alias[season.iteration]);

    return (
        <Page className="animatedFast slideInRight">
            <Header closeRoute={PageTypes.MAIN} title="GAME ITEMS" />
            <AtlasContainerDiv className="styleDarkScrollSquare">
                <div style={{ margin: screenIsSmall ? '2em' : '5em' }}>
                    <AtlasBanner seasonAlias={seasonAlias} />
                    <InfoBanner
                        title={`${seasonAlias} ${capitalize(season.current)} Season `}
                        body={
                            <div>Win matches now for a chance to earn <span style={{fontWeight: 'bold'}}>{season.current}</span> items.</div>
                        }
                    />
                    <SeasonRollUp
                        screenIsSmall={screenIsSmall}
                        history={history}
                    />
                    <InfoBanner
                        title={`The Item Atlas`}
                        body={
                            <div>Many items are not seasonal and can drop by chance following a match win.</div>
                        }
                    />
                    <AllSeasonRollUp
                        screenIsSmall={screenIsSmall}
                        history={history} />
                </div>
            </AtlasContainerDiv>
        </Page>
    );
};
