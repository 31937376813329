import styled from 'styled-components';
import palette from '../../styled/Palette';

export const MarketBodyDiv = styled.div`
    margin-top: ${({screenIsSmall}) => screenIsSmall ? '2em' : '5em'};
    position: relative; 
    display: flex;
    flex-direction: column;
`;

export const MarketMountDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(6, min-content); /* Cells resize to fit content */
    gap: 0;
    position: relative;
    text-align: center;
    padding: 2em;
`;


export const ClockDiv = styled.div`
    margin: 10px;
    display: flex;
    color: ${palette.base4};
    font-size: 1.5em;
    
`;

export const RestockDiv = styled.div`
    font-size: 1em;
    z-index: 1;
`;
export const RestockTimeLeftDiv = styled.div`
    font-size: 1em;
    font-weight: bold;
`;

export const RestockOverlayDiv = styled.div`
    display: none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: ${palette.base1};
    font-size: .75em;
    position: absolute;
    z-index: 2;
    align-items: center;
    justify-content: center;
`;

export const RestockMountDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;
export const ShopFlipButton = styled.button`
    font-weight: bold;
    background: none;
    align-self: flex-end;
    margin: .25em;
    
    color: ${palette.base2};
    &:hover {
        color: ${palette.base4}
    }
    &:active {
        color: white;
    }
    
`;

export const TruckDeliveryDiv = styled.div`
    z-index: 3;
    position: absolute;
    animation: deliveryTruck 2s linear infinite;
    font-size: 15em;
    color: ${palette.base4};
`;
export const RestockTextDiv = styled.div`
    margin-top: -.9em;
    font-weight: bold;
    font-size: 5em;
    color: ${palette.base4};
`;
export const OverlayContentDiv = styled.div`
    margin-top: -5em;
    display: align-items: center;
`;