import React from "react";
import styled from 'styled-components';
import palette from "../../styled/Palette";

export const ErrorFallbackDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;  
    align-items: center; 
    background-color: ${palette.wetAsphalt};
    color: ${palette.base4};
    text-align: left;
    height: 100%;
    font-family: Montserrat;
`;

export const ErrorFallbackMainTextDiv = styled.div`
    z-index: 1;
    font-size: 2em;
    margin-bottom: 1em;
`;

export const ErrorFallbackMainShortTextDiv = styled.div`
    z-index: 1;
    font-size: 4em;
    font-weight: bold;
`;