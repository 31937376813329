import Icon, {useIcon} from "../../../data/icon";
import styled from 'styled-components';
import React from "react";
import palette from "../../../styled/Palette";
import {playSound} from "../../../data/sound";
import {requestUnequipItem} from "../../../system/endpoints/master";

export const ButtonUnequip = styled.div`
    color: ${palette.base4};
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    padding: .3em .2em;
    border-bottom-left-radius: 3px;
`;

const UnequipItem = ({self, category}) => {
    const userHasBirthColorEquipped = category === 'Paint' && self.outfit.paint;
    const noItemEquipped = !self.outfit[category];
    const isDisabled = userHasBirthColorEquipped || noItemEquipped;

    return (
        <ButtonUnequip
            style={{
                opacity: isDisabled ? 0 : 1
            }}
            onClick={() => {
                playSound('disable', 1);
                requestUnequipItem(category);
            }}
            className={`buttonZoom ${isDisabled ? 'disabled':''}`}
        >
            {useIcon('exit')}
        </ButtonUnequip>
    );
};

export default UnequipItem;