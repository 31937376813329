import React from 'react';
import SearchFeedback from "./SearchFeedback";
import FriendUsers from "./FriendUsers";
import Search from "./Search";
import palette from '../../../styled/Palette';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useSelector} from "react-redux";
import SquadInvite from "../Squad/SquadInvite";
import {FONT_BIG, FONT_FINE, FONT_NORMAL, FONT_SMALL} from "../../../data/font";
import styled from 'styled-components';
import Icon from "../../../data/icon";

const BannerDiv = styled.div`
            display: flex;
            justify-content: center;
            align-items: flex-end;
            color: ${palette.wetAsphalt};
            flex-grow: 1;
            padding: 2em 0;
        `;
const Friends = ({setWhisper,history,dispatch}) => {
    const friends = useSelector(state => state.friends);
    const squad = useSelector(state => state.squad);
    const { online, offline, sent, received } = friends;

    const userHasFriends = online.concat(offline).concat(sent).concat(received).length > 0;

    const DiscordBanner = () => {
        return (
          <BannerDiv
          >
              <div
                  style={{display: 'flex',alignItems: 'center',justifyContent:'center',cursor: 'pointer'}}
                  onClick={() => window.open("https://discord.me/slimelol", "_blank")}
              >
                  <FontAwesomeIcon
                      style={{
                          fontSize: '4em',
                      }}
                      icon={['fab','discord']}/>
                  <div style={{fontWeight: 'bold', fontSize: '1.5em', marginLeft: '1em' }}>
                      Join us on Discord
                  </div>
              </div>
          </BannerDiv>
        )
    };

    return (
        <div className="animatedFast slideInLeft" style={{fontSize: '10px', display: 'flex', flexDirection: 'column', minWidth: '19em', width: '27.5em', borderLeft: '1px solid black'}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: palette.base6}}>
                <div style={{minHeight: '5.60em', backgroundColor: palette.midnightBlue, alignItems: 'center', color: palette.base4, fontWeight: 'bold', display: 'flex'}}>
                    <div style={{paddingLeft: '1em', fontSize: '1.5em'}}>CHAT</div>
                </div>
                <Search />
                <SquadInvite
                    dispatch={dispatch}
                    squad={squad}
                />
                <SearchFeedback
                    friends={friends}
                />
                <div className="styleDarkScrollSquare" style={{flexDirection: 'column', display: 'flex', overflowY: 'auto'}}>
                    {userHasFriends ?
                        <FriendUsers
                            history={history}
                            setWhisper={setWhisper}
                            friends={friends}
                        />
                        :
                        <div style={{borderRadius: '3px', fontSize: FONT_FINE, margin: '10px', textAlign: 'left', color: palette.base4, backgroundColor: palette.wetAsphalt, padding: '1em', border: `1px solid ${palette.belizeHole}`}}>
                            Your friends list is empty<br/><br/> Select an active player from the player list and choose &nbsp;{Icon('user-plus')} in order to make a friend request.
                        </div>
                    }
                </div>
                <DiscordBanner />
            </div>
        </div>
    )
};

export default Friends;
