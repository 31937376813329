import {useSelector} from "react-redux";
import InfoBanner from "../../component/InfoBanner/InfoBanner";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {PageTypes} from "../../system/types";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import {SCREEN_BREAK_MEDIUM} from "../../data/globals";

export const MainInfoBannerWrapperDiv = styled.div`
  width: ${(props) => (props.screenIsSmall ? '97%' : '60.5em')};
`;


const MainInfoBanner = ({ history }) => {
    const isPremium = useSelector(state => state.self.premium);
    const isMember = useSelector(state => state.self.username);
    const [title,setTitle] = useState(null);
    const [body,setBody] = useState(null);
    const [click,setClick] = useState(null);
    const { screenIsSmall } = UseWindowDimensions();

    useEffect(() => {
        if (isMember) {
            setTitle(`The Early Bird`);
            setBody(`We noticed you are not yet a premium member. A $5 pledge gets you instant access to all of the content Slime has to offer. Consider supporting the on-going development of Slime. It may not be as cheap in the future!`);
            setClick(PageTypes.LEARN);
        } else {
            setTitle(`Get Item Upgrades`);
            setBody(`Register now for a chance to win items following each match. It's totally free to sign-up and only takes a moment. Plus you will unlock access to a ton of content not available to guests. Achievements, hiscores, ranked play, and more!`);
            setClick(PageTypes.SIGN_IN);
        }
    }, []);

    if (isPremium) {
        return null;
    }
    return (
        <MainInfoBannerWrapperDiv
            screenIsSmall={screenIsSmall}
        >
            <InfoBanner
                click={() => history.push(click)}
                title={title}
                body={body}
            />
        </MainInfoBannerWrapperDiv>
    );
};

export default MainInfoBanner;