import React, {useState} from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import PrivateMatch from './PrivateMatch/PrivateMatch';
import Main from './Main/Main';
import Options from './Options';
import Affiliates from './Affiliates';
import Match from './Match/Match';
import Rules from './Match/Rules/Rules';
import Win from './Win/Win';
import ConnectionTimeout from "./ConnectionTimeout/ConnectionTimeout";
import Highscores from './Highscores/Highscores';
import Loot from './Loot/Loot';
import PublicChat from './PublicChat/PublicChat';
import Market from './Market/Market';
import Feats from './Feats/Feats';
import Title from './Title/Title';
import Drop from './Drop';
import Present from './Present/Present';
import RankUp from './RankUp/RankUp';
import SignIn from './SignIn/SignIn';
import ChangeLog from './ChangeLog/ChangeLog';
import GameGuide from './GameGuide';
import Watch from './Watch/Watch';
import GameSelect from './GameSelect/GameSelect';
import {useDispatch, useSelector} from 'react-redux';
import ErrorFallback from "./ErrorFallback/ErrorFallback";
import Tutorial from "./Tutorial";
import {Atlas} from "./Atlas/Atlas";
import Cash from "./Cash/Cash";
import Learn from "./Learn";
import {PageTypes} from "../system/types";
import FortuneWheel from "./FortuneWheel";
import FindMatch from "./Main/FindMatch/FindMatch";


/**
 * Contains the router for accessing different screens
 */
const Nav = ({ history }) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const self = useSelector(state => state.self);
    const squad = useSelector(state => state.squad);
    const disconnectReason = useSelector(state => state.socket.disconnectReason);
    let [chatRender] = useState(true);

    return (
        <div
            className="nav"
            id="nav"
            style={{transition: 'all .5s', position: 'relative'}}
        >
            <Router history={history}>
                <Switch>
                    <Route exact render={() => (<Title
                        history={history}
                    /> )} path={PageTypes.TITLE} />

                    <Route render={() => (<Main
                        history={history}
                    /> )} path={PageTypes.MAIN} />

                    <Route render={() => (<ChangeLog/>)} path={PageTypes.CHANGE_LOG} />

                    <Route render={() => (<GameGuide/>)} path={PageTypes.GAME_GUIDE} />

                    <Route render={() => (<SignIn
                        history={history}
                    /> )} path={PageTypes.SIGN_IN} />

                    <Route render={() => (<Options
                        dispatch={dispatch}
                        history={history}
                    /> )} path={PageTypes.OPTIONS} />

                    <Route render={() => (<Affiliates/> )} path={PageTypes.AFFILIATES} />

                    <Route render={() => (<Feats
                        history={history}
                    /> )} path={PageTypes.FEATS} />

                    <Route render={() => (<Loot
                        history={history}
                    /> )} path={PageTypes.LOOT} />

                    <Route render={() => (<PublicChat
                        history={history}
                    /> )} path={PageTypes.PUBLIC_CHAT} />

                    <Route render={() => (<Watch
                        history={history}
                    /> )} path={PageTypes.WATCH} />

                    <Route render={() => (<Market
                        history={history}
                    /> )} path={PageTypes.MARKET} />

                    <Route render={() => (<Highscores
                        dispatch={dispatch}
                        history={history}
                    /> )} path={PageTypes.HIGH_SCORES} />

                    <Route render={() => (<FortuneWheel
                        theme={theme}
                        history={history}
                    /> )} path={PageTypes.SLOTS} />

                    <Route render={() => (
                        <Cash
                            dispatch={dispatch}
                        />
                    )} path={PageTypes.CASH_SHOP} />

                    <Route render={() => (<Drop
                        history={history}
                    /> )} path={PageTypes.DROP} />

                    <Route render={() => (<Present
                        history={history}
                    /> )} path={PageTypes.PRESENT} />

                    <Route render={() => (<RankUp
                        history={history}
                    /> )} path={PageTypes.RANK_UP} />

                    <Route render={() => (<PrivateMatch
                            dispatch={dispatch}
                            history={history}
                        />)} path={PageTypes.PRIVATE_MATCH} />

                    <Route render={() => (<GameSelect
                            squadPlayers={squad.current}
                            history={history} /> //for the user message
                    )} path={PageTypes.GAME_SELECT} />

                    <Route render={() => (<Rules
                        history={history}
                    /> )} path={PageTypes.RULES} />

                    <Route render={() => (<Win
                        history={history}
                    /> )} path={PageTypes.WIN} />

                    <Route render={() => (<Match
                        history={history}
                        chatRender={chatRender}
                    /> )} path={PageTypes.MATCH} />

                    <Route render={() => (<Tutorial
                        history={history}
                    /> )} path={PageTypes.TUTORIAL} />

                    <Route render={() => (<Atlas
                        history={history}
                    /> )} path={PageTypes.ATLAS} />

                    <Route render={() => (<ConnectionTimeout
                        disconnectReason={disconnectReason}
                        history={history}
                    /> )} path={PageTypes.CONNECTION_TIMEOUT} />

                    <Route render={() => (<ErrorFallback
                        history={history}
                        userIssueReport={true}
                    /> )} path={PageTypes.ERROR_FALLBACK} />

                    <Route render={() => (<Learn
                        history={history}
                        theme={theme}
                        self={self}
                    /> )} path={PageTypes.LEARN} />

                    <Route render={() => (<FindMatch
                        history={history}
                    /> )} path={PageTypes.FIND_MATCH} />
                </Switch>
            </Router>
        </div>
    )
};
export default Nav;