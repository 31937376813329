import React from "react";
import {FeatsUserDetailDiv, FeatsUserDetailTitleText, FeatsUserDetailValueText} from "../FeatsStyle";
import {getDivision} from "../../../data/rating";

const ProfileDetails = ({ powerLevel, profile, date, playerBestSport }) => {
    const displayScheme = [
        {
            title: 'Username',
            value: profile.username
        },
        {
            title: 'Power level',
            value: powerLevel
        },
        {
            title: 'Member since',
            value: date.accountCreation === '12/31/1969' ? 'A while' : date.accountCreation
        },
        {
            title: 'Last online',
            value: date.lastOnline === '12/31/1969' ? 'A while' : date.lastOnline
        },
        {
            title: 'Best career division',
            value: <div style={{display:'flex',justifyContent: 'flex-end'}}><img style={{width: '12%'}} src={getDivision(profile.ratings[playerBestSport]).image}/></div>
        }
    ];

    return (
        <FeatsUserDetailDiv>
            <ul>
                {displayScheme.map(metric => (
                    <li>
                        <FeatsUserDetailTitleText>
                            {metric.title}
                        </FeatsUserDetailTitleText>
                        <FeatsUserDetailValueText>
                            {metric.value}
                        </FeatsUserDetailValueText>
                    </li>
                ))}
            </ul>
        </FeatsUserDetailDiv>
    );
};

export default ProfileDetails;