import {createReducer} from "@reduxjs/toolkit";
import initialState from "./radioInitialState";
import songs from "../../../data/songs";
import {changeColorCanvas} from "../../../generateSlimePortrait";
import tinycolor from "tinycolor2";
import { imageAssets } from '../../../data/images';
import {setCookie} from "../../../data/generic/generic";

export const FADE_OUT_SONG_DURATION = 2000;

const radioReducers = createReducer(initialState, {
    LOAD_RADIO: (state, action) => {
        const { song, title, image, analyser, src , paused} = action.payload;
        if (song) state.song = song;
        if (title) state.title = title;
        if (image) state.image = image;
        if (analyser) state.analyser = analyser;
        if (src) state.src = src;
        if (paused) state.paused = paused;
    },
    GET_NEXT_SONG: (state, action) => {
        // Check if there are any songs remaining to play
        const endOfSongHistory = state.history.length === state.index+1;
        if (endOfSongHistory) {
            let next;
            // There's a song currently playing.
            if (state.song !== null) {

                // The next song will be a randomly selected different song.
                let songsNotPlaying = songs.filter(song => { return song.name !== state.title });
                next = songsNotPlaying[Math.floor(Math.random() * songsNotPlaying.length)];

                // Fade out the active song.
                //state.song.fade(state.volume, 0, FADE_OUT_SONG_DURATION);
                state.song.stop();

                // The next song is ready to play.
                state.song = null;
            } else {
                // There's no song currently playing. Sets the first song to play.
                next = songs[Math.floor(Math.random() * songs.length)];
            }

            state.next = next;
            // Add the song to the history.
            state.history.push(next);
        } else {
            // Move forward one song
            state.next = state.history[state.index+1];

            if (state.song) {
                // Fade out the active song.
                //state.song.fade(state.volume, 0, FADE_OUT_SONG_DURATION);
                state.song.stop();
            }

            // The next song is ready to play.
            state.song = null;
        }
        // Keep track of the current song index.
        state.index++;
    },
    GET_PREVIOUS_SONG: (state, action) => {
        if (state.song !== null) {
            // Set the song which is about to play, to the previous song.
            state.next = state.history[state.index-1];

            // Fade out the active song.
            //state.song.fade(state.volume, 0, FADE_OUT_SONG_DURATION);
            state.song.stop();

            // The next song is ready to play.
            state.song = null;

            // Keep track of the location
            state.index--;
        }
    },
    TOGGLE_PLAYING: (state, action) => {
        if (state.song) {
            if (state.paused) {
                // Provide some sound to the user
                if (state.volume === '0') {
                    const jumpTo = .25;
                    state.song.fade(state.volume, jumpTo, 100);
                    state.volume = jumpTo;
                    document.getElementById('radioSlider').value = jumpTo;
                }

                state.song.play();
            } else {
                state.song.pause();
            }

            state.paused = !state.paused;
        }
    },
    CHANGE_VOLUME: (state, action) => {
        state.song.fade(state.volume, action.payload, 100);
        state.volume = action.payload;
    },
    CHANGE_CANVAS_COLOR: (state, action) => {
        const hex = action.payload;
        let radioImageSource = imageAssets.Other.Radio.base;
        state.canvas = changeColorCanvas(radioImageSource, tinycolor(hex).toRgb());
    },
    GET_CANVAS_NODE: (state, action) => {
        state.canvasNode = action.payload;
    },
    RADIO_MOUNTED: (state, action) => {
        state.mounted = action.payload;
    }
});

export default radioReducers;