import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import isElectron from "is-electron";
import {requestCashShopItemToken, requestSteamPurchase} from "../../system/endpoints/master";
import env from "../../env/env";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import CashItem from "./CashItem";
import Icon, {useIcon} from "../../data/icon";
import {itemModalEnable} from "../../redux/modules/itemModal/itemModalActions";
import {CARD_SIZE_MEDIUM, CARD_SIZE_SMALL} from "../../data/globals";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import {LinePan} from "../../styled/SlimePan";

const CashItemContainerDiv = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
    height: ${({screenIsSmall}) => screenIsSmall ? CARD_SIZE_SMALL.height : CARD_SIZE_MEDIUM.height};
    color: ${palette.base4};
`;
const CashItemContentColumnDiv = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    flex-basis: 0;
    align-items: flex-start;
    background-color: ${palette.midnightBlue};
    border: 1px solid ${palette.base7};
    border-width: 1px;
`;
const CashItemImageDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const CashItemNameDiv = styled.div`
    padding: .5em 1em;
    font-weight: bold;
    width: 100%;
    background-color: ${palette.wetAsphalt};
    justify-content: space-between;
    align-items: center;
    display: flex;
`;
const CashItemDescriptionDiv = styled.div`
    width: 100%;
    color: ${palette.peterRiver};
    background-color: ${palette.midnightBlue};
    padding: 1em;
`;
const CashItemExtraDiv = styled.ul`
    background-color: ${palette.base5};
    width: 100%;
`;
const CashItemOptionsDiv = styled.div`
    display: flex;
    width: 100%;
`;
const CashItemBackDiv = styled.div`
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;
    padding: 1em;
    font-weight: bold;
    text-align: center;
    background-color: ${palette.midnightBlue};
    
    border: 1px solid ${palette.midnightBlue};
    &:hover, &:focus {  
        border: 1px solid ${palette.belizeHole};
    }
`;
const CashItemHasDiv = styled.div`
    font-weight: bold;
    flex-grow: 2;
    flex-basis: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.base5};
    border: 1px solid ${palette.base5};
`;
const CashItemBuyDiv = styled.div`
    cursor: pointer;
    flex-basis: 0;
    font-weight: bold;
    flex-grow: 2;
    padding: 1em;
    background-color: ${palette.wetAsphalt};
    text-align: center;
    border: 1px solid ${palette.wetAsphalt};
    &:hover, &:focus {  
        border: 1px solid ${palette.belizeHole};
    }
`;
const CashDemoButtonDiv = styled.div`
    font-size: 1.5em;
    font-family: Ubuntu;
    
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: .5em;
    width: 100%;
    cursor: pointer;
    
    border: 1px solid ${palette.wetAsphalt};
    background-color: ${palette.base6};
    
    transition: all 0.35s ease-in-out;
    &:hover, :focus {
        border: 1px solid ${palette.belizeHole};
        background-color: ${palette.wetAsphalt};
    }
`;
const CashDemoDiv = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: stretch;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
    background: repeating-linear-gradient(
      45deg,
      ${palette.base6},
      ${palette.base6} 10px,
      ${palette.base7} 10px,
      ${palette.base7} 20px
    );
`;
export const loadXsollaInterface = (xsollaToken) => {
    let options = {
        sandbox: env.environment !== 'prod',
        access_token: xsollaToken,
        lightbox: {spinner: 'round', spinnerColor: '#808080'}
    };
    let s = document.createElement('script');
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://static.xsolla.com/embed/paystation/1.0.7/widget.min.js";
    //todo: This event listener doesn't look like it ever gets destroyed
    s.addEventListener('load', (e) => {
        window.XPayStationWidget.init(options);
    }, true);
    let head = document.getElementsByTagName('head')[0];
    head.appendChild(s);
};

const CashItemView = ({item,setView,dispatch}) => {
    const { xsollaToken, items } = useSelector(state => state.self);
    const chatBubble = useSelector(state => state.setting.chatBubble);
    const { screenIsSmall } = UseWindowDimensions();

    // Currently this is the only item that there can not be multiple purchases of
    const userHasChatBubble = item.name === 'Chat Bubble' && chatBubble.has;

    const handleTransactionPlatform = () => {
        if (isElectron()) {
            //todo: idk what is expected here by the endpoint, need to test this
            requestSteamPurchase(item.steamName);
        } else {
            window.XPayStationWidget.open()
        }
    };

    useEffect(() => {
        requestCashShopItemToken(item.xsollaSku);
    }, []);

    // Captures the server response for the current item token request
    useEffect(() => {
        if (xsollaToken) {
            loadXsollaInterface(xsollaToken);
        }
    }, [xsollaToken]);

    return (
        <CashItemContainerDiv screenIsSmall={screenIsSmall}>
            <CashItemImageDiv>
                <CashItem
                    detailedView={true}
                    title={item.name}
                    image={item.image}
                    imageSize={item.imageSize}
                    price={item.price}
                    count={item.count}
                />
            </CashItemImageDiv>
            <CashItemContentColumnDiv className="styleDarkScrollSquare">
                <CashItemNameDiv>
                    <div>ABOUT THIS PURCHASE</div>
                </CashItemNameDiv>
                {item.specifics &&
                <CashItemExtraDiv>
                    {item.specifics.map(detail => (
                        <li style={{padding: '.5em 1em'}}>{detail}</li>
                    ))}
                </CashItemExtraDiv>
                }
                <CashDemoDiv>
                    {item.sourceItem &&
                    <CashDemoButtonDiv
                        onClick={() => dispatch(itemModalEnable(item.sourceItem))}
                    >
                        {item.sourceItem.name} {useIcon('info')}

                    </CashDemoButtonDiv>
                    }
                    {item.alternative}
                </CashDemoDiv>
                <CashItemOptionsDiv>
                    <CashItemBackDiv
                        onClick={() => setView(null)}
                    >
                        BACK
                    </CashItemBackDiv>
                    {
                        userHasChatBubble ?
                            <CashItemHasDiv>YOU HAVE THIS ITEM</CashItemHasDiv>
                            :
                            <CashItemBuyDiv
                                onClick={() => handleTransactionPlatform()}
                            >
                                BUY
                            </CashItemBuyDiv>
                    }
                </CashItemOptionsDiv>
            </CashItemContentColumnDiv>

        </CashItemContainerDiv>
    )
};

export default CashItemView;