import {
    match,
    updateMatchView,
    updateReadyStatus,
    updateRematchReadyStatus,
    updateMatchStatus,
    updateModifiers,
    updateMatchScore,
    updateTimeUntilMatchStart,
    updateUserActiveStatus,
    addMatchContextMessage,
    updateMatchResults,
    matchStartRebootTimer, matchStopRebootTimer
} from "../modules/match/matchActions";
import {
    updateLoadingStatus, updateLoadingState, drawerToggle, setBackdrop
} from "../modules/socket/socketActions";
import {updateTeam} from "../modules/self/selfActions";
import { playSound } from "../../data/sound";
import {
    resetMatch,
    updatePositions,
    updateAbilityEffects,
    generatePlayers,
    handlePointScore,
    handleToggleWall,
    leaveGame,
    timeLeft,
    setBets, publishDropToChat
} from "./wsMatchThunks";
import {getDivision} from "../../data/rating";
import { history } from '../../index';
import matchScope from "../../data/matchScope";
import palette from "../../styled/Palette";
import {formatMatchMessage} from "../modules/chat/chatThunks";
import {sports} from "../../data/globals";
import {toggleUserInput} from "../../data/generic/generic";
import {
    notifyChatBetOutcomes,
    notifyChatMatchEnd,
    notifyChatPlayerGone,
    notifyChatReadyUpdate
} from "../modules/chat/chatActions";
import {PageTypes} from "../../system/types";

export let gameSocket;
const socketCreator = ({ joinUrl, roomId, sessionId }) => {
    if (gameSocket != null) {
        gameSocket.close();
    }
    gameSocket = new WebSocket(joinUrl);
    gameSocket.onopen = () => {
        gameSocket.send(JSON.stringify({
            'event': 'join',
            roomId,
            sessionId,
        }));
    };
    gameSocket.onclose = () => {
        resetMatch();
        gameSocket = null;
    };
};

/**
 * If we push the user to a division up screen versus the win screen.
 */
export const handleLoadPostMatch = (ranked, rating) => {
    return (dispatch, getState) => {
        if (gameSocket) {
            const matchResults = getState().match.results;
            const getRankUpData = () => {
                for (let i=0; i < sports.length; i++) {
                    const sport = sports[i];
                    const currentRank = getDivision(rating.current[sport]);
                    const previousRank = getDivision(rating.previous[sport]);
                    const isRankUp = currentRank.minimum !== previousRank.minimum;

                    if (isRankUp) {
                        return {
                            promotion: previousRank.minimum < currentRank.minimum,
                            sport: sport,
                            word: currentRank.word,
                            division: currentRank.division,
                            rank: currentRank.rank,
                            matchResults
                        };
                    }
                }
                return null;
            };

            let rankUp;

            if (rating) {
                rankUp = getRankUpData();
            }

            if (ranked && rankUp) {
                history.push({
                    pathname: PageTypes.RANK_UP,
                    state: rankUp
                });
            } else {
                history.push({
                    pathname: PageTypes.WIN,
                    state: matchResults
                });
            }
        } else {
            history.push(PageTypes.MAIN);
        }
    }
};

const formatModifiers = (modifiers, selfTeam, selfTeamIndex) => {
    // Enumerates the number of votes for each modifier and assigns a property to the modifier to represent that total
    // number of votes.
    for (const modifierName in modifiers) {
        const modifierInstance = modifiers[modifierName];
        let voteCount = 0;
        let votes = modifierInstance['votes']['teamA'].concat(modifierInstance['votes']['teamB']);
        votes.forEach(vote => {
            if (vote) {
                voteCount++;
            }
        });
        // Determines if the client user has voted for the particular modifier.
        if (selfTeam != null) {
            modifierInstance.myVote = modifierInstance['votes'][selfTeam][selfTeamIndex];
        }
        modifiers = {
            ...modifiers,
            [modifierName]: {
                ...modifierInstance,
                voteCount: voteCount
            }
        }
    }
    return modifiers;
};
const alertPlayerVote = (modifier) => {
    // Highlights the rule row when a player has voted
    playSound('pressButton',1);
    let votedElement = document.getElementById(modifier.replace(/\s+/g, '-'));
    if (votedElement) votedElement.style.backgroundColor = palette.base0;
    setTimeout(() => {
        if (votedElement) votedElement.style.backgroundColor = palette.base3;
    }, 750)
};


function wsMatch() {

    return ({dispatch, getState}) => {

        const handlerAlertPlayerGoalTending = (message) => {
            const sameTeam = message.team === getState().self.team;
            const samePlayer = message.teamIndex === getState().self.teamIndex;
            const isClientUser = sameTeam && samePlayer;
            if (isClientUser) {
                // Inform the player the reason they have phased

                dispatch(addMatchContextMessage({action: "goalTending" }));
            }
        };

        return next => action => {
            if (action.type === 'INITIALIZE_SOCKET_MATCH') {
                const { ping, ranked } = getState().match;
                socketCreator(action.payload);
                gameSocket.onmessage = (event) => {
                    let message = JSON.parse(event.data);
                    switch (message.event) {
                        case "assignTeam":
                            dispatch(updateTeam({
                                team: message.team,
                                teamIndex: message.teamIndex
                            }));
                            break;
                        case 'assignPlayerNames':
                            dispatch(generatePlayers(message));
                            dispatch(resetMatch());

                            //Load the newly randomized modifiers and match data
                            dispatch(updateModifiers(formatModifiers(message.modifiers)));
                            dispatch(updateTimeUntilMatchStart(Math.round(message.timeToStart / 1000)));
                            dispatch(match({payload: {
                                    teamSize: message.teamSize,
                                    sport: message.sport,
                                    ranked: message.ranked,
                                    spectators: message.spectators,
                                    private: message.private,
                                    teams: message.teams,
                                    bets: message.bets,
                                    timeLeft: message.timeLeft,
                                    timeLeftTimestamp: Date.now(),
                                    server: {
                                        name: message.server.name,
                                        region: message.server.region,
                                        provider: message.server.provider,
                                    }
                                }}));
                            dispatch(setBackdrop(message.background));
                            matchScope.matchId = crypto.randomUUID();

                            setTimeout(() => {
                                // This threshold of time is what hides the initial loading of the game screen
                                // And allows a smooth transition into the rules component
                                dispatch(updateLoadingState(false));
                            }, 1000);
                            break;
                        case 'ballCrit': {
                            const change = {
                                angle: message.angle,
                                size: message.size,
                                time: Date.now(),
                                animationDuration: 350,
                                totalFrames: 7,
                            };
                            matchScope.ballCrit = [...matchScope.ballCrit, change];
                            break;
                        }
                        case 'chatUpdate': {
                            dispatch(formatMatchMessage(message.data));
                            break;
                        }
                        case 'displayMessage': {
                            dispatch(addMatchContextMessage(message));
                            break;
                        }
                        case 'gameEnd':
                            const bets = getState().match.bets;
                            // Begin the timer that sends the user back to main if they don't ready up for a rematch.
                            dispatch(matchStartRebootTimer());
                            // Store the match results in state.
                            dispatch(updateMatchResults(message));
                            // Display in chat who the winner is.
                            dispatch(notifyChatMatchEnd(message));
                            // Display in chat who the bet winners are.
                            dispatch(notifyChatBetOutcomes({...message, bets}));
                            matchScope.matchId = crypto.randomUUID();
                            break;
                        case 'hoopSize':
                            matchScope.hoopSize = message.hoopSize;
                            break;
                        case 'matchCanceled':
                            playSound('playerJoinFailed', 1);
                            dispatch(updateLoadingState(true));

                            switch (message.reason) {
                                case 'matchDoesNotExist': {
                                    dispatch(updateLoadingStatus('MATCH HAS ENDED'));
                                    break;
                                }
                                case 'playerFailedToJoin': {
                                    dispatch(updateLoadingStatus('PLAYER FAILED TO JOIN'));
                                    break;
                                }
                                case 'playerLeftBeforeGameStart': {
                                    // This only fires for 1v1 games
                                    dispatch(updateLoadingStatus('OPPONENT LEFT BEFORE MATCH START'));
                                    break;
                                }
                            }

                            setTimeout(() => {
                                dispatch(updateLoadingState(false));
                                toggleUserInput(true);
                                dispatch(leaveGame({teamA: [], teamB: []}));
                            }, 1000);
                            break;
                        case 'matchDrop':
                            dispatch(publishDropToChat(message));
                            break;
                        case 'netHeight':
                            matchScope.netHeight = message.netHeight;
                            break;
                        case 'playerPhaseIn':
                            matchScope.teams[message.team][message.teamIndex].phased = false;
                            break;
                        case 'playerPhaseOut':
                            matchScope.teams[message.team][message.teamIndex].phased = true;
                            handlerAlertPlayerGoalTending(message);
                            break;
                        case "playerDisconnect":
                            gameSocket.close();
                            break;
                        case 'powerShot': {
                            const change = {
                                x: message.position.x,
                                y: message.position.y,
                                time: Date.now(),
                                animationDuration: 333,
                                totalFrames: 5,
                                angle: message.angle,
                                team: message.team,
                                teamIndex: message.teamIndex,
                            };
                            matchScope.powerShot = [...matchScope.powerShot, change];
                            break;
                        }
                        case 'removePlayer': {
                            const teams = getState().match.teams;
                            let player = matchScope.teams[message.team][message.teamIndex];
                            if (player) {
                                // player doesn't exist if they disconnect before the game
                                player.active = false;
                                player.disconnectTime = Date.now();
                            }
                            //remove(teams[message.team][message.teamIndex].body);

                            // Also inform the redux store that the player left
                            dispatch(updateUserActiveStatus({
                                team: message.team,
                                teamIndex: message.teamIndex,
                                status: false
                            }));

                            dispatch(notifyChatPlayerGone({
                                team: message.team,
                                teamIndex: message.teamIndex,
                                status: false,
                                teams: teams
                            }));
                            break;
                        }
                        case "rematch":
                            dispatch(matchStopRebootTimer());
                            dispatch(updateMatchScore({
                                p1Score: 0,
                                p2Score: 0
                            }));
                            history.push(PageTypes.MATCH);
                            break;
                        case 'rematchStatusUpdate': {
                            dispatch(updateRematchReadyStatus(message));
                            break;
                        }
                        case 'readyStatusUpdate':
                            dispatch(updateReadyStatus({
                                team: message.team,
                                teamIndex: message.teamIndex,
                                status: message.status
                            }));
                            const teams = getState().match.teams;
                            dispatch(notifyChatReadyUpdate({
                                ...message,
                                teams: teams
                            }));
                            break;
                        case 'start':
                            history.push(PageTypes.MATCH);
                            dispatch(updateMatchView({rules: false}));
                            break;
                        case "sound":
                            playSound(message.sound, message.volume);
                            break;
                        case 'turnOffCenterWall':
                            dispatch(handleToggleWall(false));
                            break;
                        case 'turnOnCenterWall':
                            dispatch(handleToggleWall(true));
                            break;
                        case 'updateBets':
                            dispatch(setBets(message.bets));
                            break;
                        case 'updateGameTime':
                            dispatch(timeLeft(message.timeLeft));
                            break;
                        case 'updateModifiers':
                            alertPlayerVote(message.modifierChanged);
                            dispatch(updateModifiers(formatModifiers(message.modifiers, getState().self.team, getState().self.teamIndex)));
                            break;
                        case 'updateBallSize':
                            matchScope.ball = {
                                ...matchScope.ball,
                                circleRadius: message.radius,
                                radius: message.radius
                            };
                            break;
                        case 'updateSpectatorCount':
                            dispatch(match({spectators: message.spectators}));
                            break;
                        case 'updateScore':
                            dispatch(handlePointScore(message));
                            break;
                        case 'abilityEffect': {
                            dispatch(updateAbilityEffects(message));
                            break;
                        }
                        case 'updatePositions':
                            dispatch(updatePositions(message));
                            break;
                        case 'updateGameStatus':
                            dispatch(updateMatchStatus(message.status));
                            break;
                    }
                };
            }

            return next(action); // Proceed
        };
    }
}

export default wsMatch();