import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useSelfAbilities from "../../hooks/useSelfAbilities";
import abilitySwap from './images/ability-swap.png';
import dash from './images/dash.png';
import fireworks from './images/fireworks.png';
import levitate from './images/levitate.png';
import powerShot from './images/power-shot.png';
import useAbility from './images/use-ability.png';
import palette from "../../styled/Palette";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import useCanvasSize from "../../hooks/useCanvasSize";

const ControlHintsWrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  position: absolute;
`;

const HintItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const HintImageImg = styled.img`
    width: 96px; 
  height: 88px; 
  margin-bottom: .25em;
`;

const HintTextDiv = styled.div`
    font-size: ${({ gamepadConnected }) => gamepadConnected ? '10px' : '12px'};
    bottom: ${({ gamepadConnected }) => gamepadConnected ? '0' : '5px'};
    padding: 2px 4px;
    border-radius: 2px;
    color: ${palette.base4};
    background-color: ${palette.base5};
    border-bottom: 2px solid ${palette.base6};
    position: absolute;
    max-width: 6em;
`;

const ControlHints = ({gameMetadataProvider}) => {
    const self = useSelector(state => state.self);
    const gamepad = useSelector((state) => state.gamepad);
    const sandboxData = useSelfAbilities(gameMetadataProvider, self);
    const currentAbilityItems = sandboxData?.abilities;
    const currentActiveAbility = sandboxData?.activeAbility;
    const [activeAbilityName, setActiveAbilityName] = useState(null);

    // Track active ability change using useEffect
    useEffect(() => {
        if (currentAbilityItems && currentActiveAbility) {
            const ability = currentAbilityItems.find(ability => ability.abilityName === currentActiveAbility);
            setActiveAbilityName(ability ? ability.name : 'No Active Ability');
        }
    }, [currentActiveAbility, currentAbilityItems, gameMetadataProvider, self]);

    // Get window and canvas size
    const { width, height } = UseWindowDimensions();
    const { width: canvasWidth, height: canvasHeight } = useCanvasSize(width, height);

    const imageMap = {
        'ability-swap': abilitySwap,
        'dash': dash,
        'fireworks': fireworks,
        'levitate': levitate,
        'power-shot': powerShot,
        'use-ability': useAbility,
    };

    const HINTS = [
        {
            label: 'Power Shot',
            key: 'SHIFT',
            controllerKey: 'LEFT BUTTON',
            condition: () => true,
        },
        {
            label: 'Use Ability',
            key: 'E',
            controllerKey: 'RIGHT BUTTON',
            condition: () => currentAbilityItems[0] && !['Fireworks', 'Dash', 'Levitate'].includes(activeAbilityName), // Hide if Fireworks, Dash, or Levitate are active
        },
        {
            label: 'Fireworks',
            key: 'HOLD E',
            controllerKey: 'HOLD RIGHT',
            condition: () => activeAbilityName === 'Fireworks',
        },
        {
            label: 'Dash',
            key: 'A ➜ A',
            controllerKey: 'LEFT ➜ LEFT',
            condition: () => activeAbilityName === 'Dash',
        },
        {
            label: 'Levitate',
            key: 'HOLD W',
            controllerKey: 'HOLD JUMP',
            condition: () => activeAbilityName === 'Levitate',
        },
        {
            label: 'Ability Swap',
            key: 'Q',
            controllerKey: 'TOP BUTTON',
            condition: () => currentAbilityItems.length > 1, // Check abilities
        },
    ];

    return (
        <ControlHintsWrapperDiv style={{ top: `${canvasHeight - 150}px`, right: '25px'}}>
            {HINTS.map(({ label, key, controllerKey, condition }) => {
                const imageSrc = imageMap[label.toLowerCase().replace(/\s+/g, '-')];
                if (!condition(self)) return null;
                return (
                    <HintItemDiv key={label}>
                        <HintImageImg
                            src={imageSrc}
                            alt={label}
                        />
                        <HintTextDiv
                            gamepadConnected={gamepad.connected}
                        >
                            {gamepad.connected ? controllerKey : key}
                        </HintTextDiv>
                    </HintItemDiv>
                );
            })}
        </ControlHintsWrapperDiv>
    );
};

export default ControlHints;
