import Proton from './proton';
import { playerColorHexToParticleColor } from './particleHelpers';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { getSound } from '../../data/sound';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createJetPackParticles(room, team, teamIndex) {
    let jetPackSound = new Audio(getSound('jetPack').noise);
    let dampener = getSound('jetPack').dampener;
    jetPackSound.currentTime = 0;
    jetPackSound.volume = 1;
    let fadeOutTime = 1000;
    let fadeTime = null;
    jetPackSound.currentTime = 0;
    jetPackSound.volume = 1 * dampener;
    jetPackSound.play();

    let player = room.teams[team][teamIndex];
    let jetPackEmitter = new Proton.Emitter();
    jetPackEmitter.rate = new Proton.Rate(webGLEnabled ? 20 : 2, updatePerFrame);
    jetPackEmitter.addInitialize(new Proton.Mass(1));
    jetPackEmitter.addInitialize(new Proton.Radius(20, 35));
    jetPackEmitter.addInitialize(new Proton.Life(.5,1));
    jetPackEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    jetPackEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-4, 4), new Proton.Span(-4, 4), 'vector')
    );
    // jetPackEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
    let color = playerColorHexToParticleColor(player.colorHex);
    jetPackEmitter.addBehaviour(
        new Proton.Color(color, [
            '777777',
            '888888',
            '666666',
            '999999',
            'aaaaaa',
            'bbbbbb',
            'cccccc',
        ])
    );
    jetPackEmitter.addBehaviour(new Proton.Gravity(-1));
    jetPackEmitter.addBehaviour(new Proton.Scale(1, 4));
    jetPackEmitter.addBehaviour(new Proton.Alpha(0.5, 0));
    jetPackEmitter.p.x = player.position.x;
    jetPackEmitter.p.y = player.position.y;
    jetPackEmitter.damping = 0.04;
    jetPackEmitter.emit();

    protonBackground.addEmitter(jetPackEmitter);
    let endParticles = () => {
        if (fadeTime === null) {
            jetPackEmitter.stop();
            fadeTime = Date.now();
            setTimeout(() => {
                protonBackground.removeEmitter(jetPackEmitter);
                clearInterval(interval);
            }, 1500);
        }
    };
    let endSound = () => {
        if (!jetPackSound.paused || jetPackSound.currentTime !== 0) {
            let volume = (1 - (Date.now() - fadeTime) / fadeOutTime) * dampener;
            if (volume < 0) {
                volume = 0;
                jetPackSound.pause();
                jetPackSound.currentTime = 0;
            }
            if (volume > 1) {
                volume = 1;
            }
            jetPackSound.volume = volume;
        }
    };
    let interval = setInterval(() => {
        player = room.teams[team][teamIndex];
        if (player) {
            let activeAbility = player.activeAbility;
            let ability = player.abilities[activeAbility];
            if (activeAbility === 'jetPack' && ability.active) {
                let position = player.position;
                jetPackEmitter.p.x = position.x;
                jetPackEmitter.p.y = position.y;
            } else {
                endParticles();
                endSound();
            }
        }
        else{
            endParticles();
            endSound();
        }

    }, 1000 / 60);
}
