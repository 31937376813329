import {HomeUserMetrics} from "./MainStyle";
import palette from "../../styled/Palette";
import React from "react";
import styled from 'styled-components';
import {useSelector} from "react-redux";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const UserMetricsHeaderDiv = styled.div`
    height: 100%;
    font-weight: bold;
    background-color: ${palette.base1};
    display: flex;
    align-items: center;
    padding: 0 2em;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
`;

const UserMetricsBodyDiv = styled.div`
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    background-color: ${palette.base5};
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
`;

const UserMetrics = () => {
    const { guests, members } = useSelector(state => state.users);
    const { screenIsSmall } = UseWindowDimensions();
    const isMember = useSelector(state => state.self.username);

    if (screenIsSmall && !isMember) {
        return null;
    }

    return (
        <HomeUserMetrics>
            <UserMetricsHeaderDiv>
                Players Online
            </UserMetricsHeaderDiv>
            <UserMetricsBodyDiv>
                <div style={{marginRight: '.5em'}}>
                    <div>{members}</div>
                    <div>{guests}</div>
                </div>
                <div>
                    <div>Member</div>
                    <div>Guest</div>
                </div>
            </UserMetricsBodyDiv>
        </HomeUserMetrics>
    )
};

export default UserMetrics;