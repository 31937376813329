import React from "react";
import {
    LineContainerDiv,
    LineLineDivTop,
    LineLineDivLeft,
    LineLineDivRight,
    LineTextDiv,
    LineLineDivBottom
} from './LineLabelStyle';

const getStyle = (title) => {
    switch (title) {
        case 'MY ITEMS':
            return `
                margin: 1em 0;
                z-index: 10;
                display: flex;
            `;
        case 'LOADOUT':
            return `
                display: flex;
                margin: 1em 0;
                transform: rotate(180deg);
                scaleX(-1);
            `;
    }
};

export const LineLabel = ({children, styles, orientation, inverted, loadoutWidth}) => {
    return (
        <LineContainerDiv
            loadoutWidth={loadoutWidth}
            styles={getStyle(styles)}
        >
            {orientation === 'vertical' && <LineLineDivTop />}
            {orientation === 'horizontal' && <LineLineDivLeft />}
            <LineTextDiv inverted={inverted}>
                {children}
            </LineTextDiv>
            {orientation === 'vertical' && <LineLineDivBottom />}
            {orientation === 'horizontal' && <LineLineDivRight />}

        </LineContainerDiv>
    )
};

export default LineLabel;