import React, { useState, useEffect, useRef } from 'react';
import Header from "../../../component/Header/Header";
import MatchChat from '../../../component/Chat/MatchChat'
import Teams from './Teams';
import Ballot from './Ballot/Ballot';
import palette from '../../../styled/Palette';
import {
    PhaserDiv,
    TitleDiv,
    Title2Div,
    RulesBodyDiv,
    RulesDescriptionDiv,
    RulesContentDiv,
    BallotSideDiv,
    UserSideDiv,
    RulesTimeToStartDiv,
    RulesReadyButton,
    RulesFooterDiv
} from './RulesStyle';
import { playSound } from '../../../data/sound';
import { useSelector, useDispatch } from 'react-redux';
import { updateMatchView } from "../../../redux/modules/match/matchActions";
import { gameSocket } from '../../../redux/middleware/wsMatch';
import {PageTypes} from "../../../system/types";

const Rules = ({history}) => {
    const dispatch = useDispatch();
    const ruleIsOpen = useSelector(state => state.match.view.rules);
    const ranked = useSelector(state => state.match.ranked);
    const { playerId, team } = useSelector(state => state.self);
    const { status, teams, timeUntilStart, sport, modifiers, roomId } = useSelector(state => state.match);
    const [sportFormatted] = useState(sport ? sport.toUpperCase() : '');
    const userIsCompeting = team;

    let [readyText, setReadyText] = useState('done voting');
    let [view, setView] = useState('ballot');
    let [voted, setVoted] = useState(false);

    useEffect(() => {
        dispatch(updateMatchView({
            rules: true,
            chat: false
        }));

        // Only show the context when the user first visits the rules page.
        // Don't show it to observers and don't show it when accessing rules from options.
        if (!history.location.state) {
            showContext();
        }
    }, []);

    const showContext = () => {
        if (userIsCompeting) {
            setTimeout(() => playSound('pressButton', 1), 1000);
            setView('context');
            setTimeout(() => {
                playSound('pressButton', 1);
                setView('ballot');
            }, 3000);
        } else {
            setView('ballot');
        }
    };

    const toggleReady = () => {
        if (status === 'waiting' && readyText !== 'waiting') {
            setVoted(true);
            setReadyText('waiting');
            gameSocket.send(JSON.stringify({
                'event': 'ready',
                'roomId': roomId,
                'playerId': playerId,
            }));
        }
    };


    if (view === 'context') {
        return (
            <PhaserDiv className="animated fadeIn">
                <div>
                    <TitleDiv>{sportFormatted} {teams.teamA.length}v{teams.teamB.length} </TitleDiv>
                    <Title2Div>- {ranked ? 'RANKED' : 'CASUAL'} -</Title2Div>
                </div>
            </PhaserDiv>
        )
    }
    else if (ruleIsOpen) {
        return (
            <div className="fullScreen">
                <Header
                    closeRoute={status !== 'waiting' ? PageTypes.MATCH : null}
                    title={view === 'ballot' ? `${sportFormatted} ${teams.teamA.length}v${teams.teamB.length}` : 'MATCH DETAIL'}/>
                <RulesBodyDiv>
                    <RulesContentDiv
                        className="animatedSlow fadeIn">
                        <BallotSideDiv>
                            <RulesDescriptionDiv>
                                MATCH RULE SELECTION
                            </RulesDescriptionDiv>
                            <Ballot
                                status={status}
                                modifiers={modifiers}
                                teams={teams}
                            />
                            <RulesFooterDiv>
                                {userIsCompeting &&
                                <RulesReadyButton
                                    tabIndex={0}
                                    voted={voted}
                                    onClick={() => toggleReady()}
                                >
                                    {readyText.toUpperCase()}
                                </RulesReadyButton>
                                }
                                <RulesTimeToStartDiv>
                                    <div style={{fontSize: '2em', fontWeight: 'bold'}}>
                                        {timeUntilStart > 0 && timeUntilStart}
                                    </div>
                                    <div>{status === 'waiting' ? 'MATCH START' : 'MATCH IN PROGRESS'}</div>
                                </RulesTimeToStartDiv>
                            </RulesFooterDiv>
                        </BallotSideDiv>
                        <UserSideDiv>
                            <MatchChat
                                history={history}
                            />
                            <Teams
                                history={history}
                                teams={teams}
                                gameMode={sport}
                            />
                        </UserSideDiv>
                    </RulesContentDiv>
                </RulesBodyDiv>
            </div>
        )
    }
    else return null
};

export default Rules;