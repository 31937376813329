import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import palette from "../styled/Palette";
import AnimationFade from "../animation/AnimationFade";
import {playSound} from "../data/sound";
import ItemSlot from "./Item/ItemSlot";
import {requestSwitchAccessory} from "../system/endpoints/match";
import {useDispatch, useSelector} from "react-redux";
import {gameSocket} from "../redux/middleware/wsMatch";
import {sandbox,swapAbilities} from "../system/physics/physics";
import {getAccessoryByAbility} from "../data/items";
import HotkeyIndicator from "./HotkeyIndicator";
import useSelfAbilities from "../hooks/useSelfAbilities";
import {updateActiveAbility} from "../redux/modules/self/selfActions";

const AgnosticFlex = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 1em;
`;
const ItemSwitchDiv = styled.div`
    border-radius: 4px;
    background-color: ${palette.midnightBlue};
    border: 5px solid ${palette.wetAsphalt};
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => !props.toggled && 'pointer-events: none'};
`;
const ItemSwitchIndexNameDiv = styled.div`
    color: ${palette.peterRiver};
    font-weight: bold;
    font-size: 1.5em;
    width: 20em;
`;
export const SvgDiv = styled.svg`
    width: 100%;
    height: 100%;
    font-size: 2em;
`;
export const StrokeDiv = styled.text`
    fill            : ${palette.belizeHole};
    stroke          : ${palette.midnightBlue};
    stroke-width    :  1px;
    stroke-linejoin : round;
    text-anchor: middle;
    font-family: Modak;
`;

/**
 * This component displays the currently equipped accessory when the user switches.
 * The user can use this button in the lobby, or in match to switch accessories.
 */
const ItemSwitchManager = ({gameMetadataProvider}) => {
    const dispatch = useDispatch();
    const match = useSelector(state => state.match);
    const gamepad = useSelector(state => state.gamepad);
    const [screenEnabled,setScreenEnabled] = useState(false);
    const [currentIndex,setCurrentIndex] = useState(null);
    const debounceRef = useRef(null);
    const self = useSelector(state => state.self);
    const sandboxData = useSelfAbilities(gameMetadataProvider, self);
    const currentItemName = sandboxData?.abilities[currentIndex]?.name;

    const handleItemSelect = (e,item,mouseIndex) => {
        const index = mouseIndex ?? currentIndex;
        const swapToAbilityName = sandboxData.abilities[index].abilityName;

        // So that I can update ControlHints by tracking current ability on the client
        dispatch(updateActiveAbility(swapToAbilityName));

        if (gameSocket) {
            // Hit the endpoint to request the change on the remote
            requestSwitchAccessory(self.username,swapToAbilityName);
        } else {
            // Update the client side sandbox directly
            swapAbilities(gameMetadataProvider.teams[self.team ?? 'teamA'][self.teamIndex ?? 0], sandbox, swapToAbilityName, false);
        }
        playSound('selectItem', 1);
        setScreenEnabled(false);
    };
    const handleItemEnter = (e,index) => {
        setCurrentIndex(index);
    };
    const handleItemLeave = (e,index) => {
    };

    const handleUserItemSwitch = (code) => {
        const userHasNoAbilities = sandboxData.abilities.length === 0;
        if (userHasNoAbilities) return;

        const pressQ = code === 'KeyQ';
        const pressEscape = code === 'Escape';

        // Map 'Digit1' through 'Digit4' to indices 0-3
        const abilityIndex =
            code === 'Digit1' ? 0 :
                code === 'Digit2' ? 1 :
                    code === 'Digit3' ? 2 :
                        code === 'Digit4' ? 3 :
                            code;

        // Handle ability selection if the screen is enabled
        if (screenEnabled && abilityIndex >= 0 && abilityIndex < sandboxData.abilities.length) {
            setCurrentIndex(abilityIndex);
            setScreenEnabled(false); // Close the screen
        }

        // Toggle screen with Q or controller L1
        if (pressQ) {
            if (debounceRef.current) return; // Skip if debounce is active

            setScreenEnabled((prev) => !prev);
            if (!screenEnabled) {
                playSound('selectItem', 1);
            } else {
                playSound('pressButton', 1);
            }

            // Set debounce timeout to prevent spamming
            debounceRef.current = setTimeout(() => {
                debounceRef.current = null;
            }, 300); // Adjust debounce duration as needed
        }

        // Close the screen with Escape
        if (pressEscape) {
            setScreenEnabled(false);
        }
    };

    useEffect(() => {
        if (currentIndex !== null) {
            handleItemSelect(); // Select the item
        }
    }, [currentIndex]);

    // Also listen to the controller inputs via redux
    useEffect(() => {
        if (gamepad.button_4) {
            handleUserItemSwitch('KeyQ');
        }
    }, [gamepad.button_2,gamepad.button_4, sandboxData]);

    useEffect(() => {
        if (screenEnabled) {
            // Map gamepad buttons to corresponding actions
            if (gamepad.shoulder_top_left) {
                handleUserItemSwitch(0); // Map to L1
            }
            if (gamepad.shoulder_top_right) {
                handleUserItemSwitch(1); // Map to R1
            }
            if (gamepad.shoulder_bottom_left) {
                handleUserItemSwitch(2); // Map to L2
            }
            if (gamepad.shoulder_bottom_right) {
                handleUserItemSwitch(3); // Map to R2
            }
        }
    }, [
        gamepad.shoulder_top_left,
        gamepad.shoulder_top_right,
        gamepad.shoulder_bottom_left,
        gamepad.shoulder_bottom_right,
        sandboxData,
        screenEnabled
    ]);

    // Generic configuration for handling a listener
    useEffect(() => {
        const keystrokeInstance = (e) => handleUserItemSwitch(e.code);
        window.addEventListener('keydown', keystrokeInstance);
        return () => {
            window.removeEventListener('keydown', keystrokeInstance);
        };
    }, [screenEnabled,currentIndex,sandboxData]);

    // When the user is observing a match, don't render this component
    if (match.sessionId === 'spectator') {
        return null;
    }
    return (
        <AgnosticFlex>
            <AnimationFade
                toggled={screenEnabled}
                componentToRender={
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <ItemSwitchDiv
                            toggled={screenEnabled}
                        >
                            {
                                Object.keys(sandboxData.abilities).map((key,index) => {
                                    return (
                                        <div key={index} style={{position: 'relative'}}>
                                            <HotkeyIndicator index={index} />
                                            <ItemSlot
                                                index={index}
                                                handleItemEnter={handleItemEnter}
                                                handleItemLeave={handleItemLeave}

                                                key={key}
                                                slotWidth={5}
                                                slotHeight={5}

                                                accessorySelected={currentIndex===index}
                                                item={sandboxData.abilities[key]}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </ItemSwitchDiv>
                        <ItemSwitchIndexNameDiv>
                            <SvgDiv
                                viewBox="0 0 300 50"
                            >
                                <StrokeDiv
                                    style={{fontSize: '.5em'}}
                                    x={'50%'}
                                    y={'30'}
                                >{currentItemName}</StrokeDiv>
                            </SvgDiv>
                        </ItemSwitchIndexNameDiv>
                    </div>
                }
            />
        </AgnosticFlex>
    )
};

export default ItemSwitchManager;