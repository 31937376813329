import palette from "../../styled/Palette";
import styled from 'styled-components';


const lineColor = palette.base2;
const lineTextColor = palette.base4;
const boldLine = '2px';
const widthLine = '1em';
const heightLine = '15%';

export const LineContainerDiv = styled.div`
    ${props => props.styles ? props.styles : ''};
    ${props => props.loadoutWidth ? `width: ${props.loadoutWidth}px` : ''};
`;
export const LineLineDivTop = styled.div`
    border-top: ${boldLine} solid ${lineColor};
    border-right: ${boldLine} solid ${lineColor};
    height: ${heightLine};
    width: ${widthLine};
`;
export const LineLineDivLeft = styled.div`
    margin-top: .5em;
    margin-left: 1em;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-top: ${boldLine} solid ${lineColor};
    border-left: ${boldLine} solid ${lineColor};
`;
export const LineLineDivRight = styled.div`
    margin-top: .5em;
    margin-right: 1em;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-top: ${boldLine} solid ${lineColor};
    border-right: ${boldLine} solid ${lineColor};
`;
export const LineLineDivBottom = styled.div`
    border-bottom: ${boldLine} solid ${lineColor};
    border-right: ${boldLine} solid ${lineColor};
    height: ${heightLine};
    width: ${widthLine};
`;
export const LineTextDiv = styled.div`
    font-family: Montserrat;
    font-weight: bold;
    color: ${lineTextColor};
    letter-spacing: .25em;
    font-size: 1em;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    ${props => props.inverted && `
        transform: rotate(180deg);
        scaleX(-1);
    `}
`;

