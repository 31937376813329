import React from 'react'
import { squadResponse } from "../../../redux/modules/squad/squadActions";
import {SquadButton} from "../../../component/Squad/SquadUser";
import {
    SquadInviteDiv,
    SquadInviteTextContainerDiv,
    SquadInviteTextDiv,
    SquadInviteSubTextDiv,
    InviteInstanceDiv
} from './SquadInviteStyle';

const SquadInvite = ({squad,dispatch}) => {
    //const stubInvites = [{leader: 'jimjam'},{leader: 'robyshnoby'},{leader: 'plizlplalazl'},{leader: 'awdw'},{leader: 'awdw'}];

    const Invite = ({index}) => {
        return (
            <InviteInstanceDiv>
                <SquadInviteTextContainerDiv>
                    <SquadInviteTextDiv>SQUAD INVITE&nbsp;</SquadInviteTextDiv>
                    <SquadInviteSubTextDiv>{squad.invites[index].leader}</SquadInviteSubTextDiv>
                </SquadInviteTextContainerDiv>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: '1em'}}>
                    <SquadButton small={true} type={'accept'} click={() => dispatch(squadResponse('accept'))} />
                    <SquadButton small={true} click={() => dispatch(squadResponse('decline'))} />
                </div>
            </InviteInstanceDiv>
        )
    };

    if (squad.invites.length === 0) return null;

    return (
        <SquadInviteDiv>
            <Invite index={0} />
        </SquadInviteDiv>
    )
};


export default SquadInvite;
