import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {togglePlaying} from "../../redux/modules/radio/radioActions";
import {useDispatch} from "react-redux";

const RadioOff = ({theme}) => {
    const dispatch = useDispatch();

    return (
        <div
            onClick={() => {dispatch(togglePlaying())}}
            style={{cursor: 'pointer', display: 'flex', height: '100%', alignItems: 'center'}}
        >
            <div style={{backgroundColor: 'rgba(86,86,86,.5)', borderRadius: '50%', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative'}}>
                <FontAwesomeIcon style={{color: theme.interfaceColorBright, width: '20px', height: '20px', fontSize: '30px'}}  icon={['fas', 'music-slash']} />
            </div>
        </div>
    )
};

export default RadioOff;