import React, { useEffect, useState } from 'react';

import Advert from "../../component/Advert";
import styled from 'styled-components';

import { loadAdvert } from '../../advertLoader';
import { leaveGame } from "../../redux/middleware/wsMatchThunks";
import { drawerToggle } from "../../redux/modules/socket/socketActions";
import { useDispatch, useSelector } from 'react-redux';
import RadioInterface from "../../component/RadioInterface";
import Squad from "../../component/Squad/Squad";
import MainBody from "./MainBody/MainBody";
import {
    MainContainerDiv, NavHeaderDiv,
} from './MainStyle';
import SteamDrawer from "../../component/SteamDrawer/SteamDrawer";
import isElectron from "is-electron";
import PingWarning from "../../component/PingWarning";
import InformationGamepad from "../../component/Information/InformationGamepad";
import {HomeHeader} from "../../component/Home/HomeHeader";
import ItemSwitchManager from "../../component/ItemSwitchManager";
import {sandbox, startCoinEvent} from "../../system/physics/physics";
import MainInfoBanner, {MainInfoBannerWrapperDiv} from "./MainInfoBanner";
import {PageTypes} from "../../system/types";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import {useIcon} from "../../data/icon";
import palette from "../../styled/Palette";
import {selectIsInSquad} from "../../data/squadSelector";
import {masterSocket} from "../../redux/middleware/wsMaster";
import DialogueCoinEvent from "../../component/Dialogue/type/coinEvent";
import DialoguePremium from "../../component/Dialogue/type/premium";
import ControlHints from "../../component/ControlHints/ControlHints";

const MainContentCollapseButton = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5em;
    margin-right: 0.5em;
    color: ${palette.base4};
`;


const Main = (props) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const squad = useSelector(state => state.squad);
    const { roomId, teams } = useSelector(state => state.match);
    const { username, newItems } = useSelector(state => state.self);
    const inQueue = useSelector(state => state.queue.active);
    const steamDrawerOpen = useSelector(state => state.layout.steamDrawerOpen);
    const { width, screenIsSmall } = UseWindowDimensions();
    const isPremium = useSelector(state => state.self.premium);
    const isMember = useSelector(state => state.self.username);

    const isBannerToShow = !isPremium || !isMember;
    const isSquadDisplayed = squad.current.length > 0;
    const isCollapseContent = isSquadDisplayed || isBannerToShow || (!screenIsSmall && !inQueue);

    const isInSquad = selectIsInSquad(squad);

    const [mainBodyVisible, setMainBodyVisible] = useState(true);

    useEffect(() => {
        dispatch(drawerToggle({open: true}));

        if (!isElectron()) {
            loadAdvert();
        }
        if (roomId !== 'lobby') {
            dispatch(leaveGame(teams));
        }

        //display self screen if user has unclaimed loot
        if (newItems.length > 0 ) {
            if (props.history.location.pathname !== PageTypes.DROP) {
                props.history.push({
                    pathname: PageTypes.DROP,
                    state: { from: props.history.location.pathname }
                });
            }
        }
    },[]);

    return (
        <>
            {props.history.location.state?.showDialogueCoinEvent &&
                <DialogueCoinEvent
                    history={props.history}
                />
            }
            <MainContainerDiv className="animatedQuick fadeIn">
                <HomeHeader
                    setMainBodyVisible={setMainBodyVisible}
                    mainBodyVisible={mainBodyVisible}
                    dispatch={dispatch}
                    squad={squad}
                    history={props.history}
                    username={username}
                    steamDrawerOpen={steamDrawerOpen}
                />
                <>
                    <div
                        style={{
                            margin: screenIsSmall ? '2em 1em 0 1em' : ( mainBodyVisible ? '4em 0 0 0' : '2em 0 0 0')
                        }}
                    />
                    {mainBodyVisible &&
                        <>
                            {isInSquad ?
                                <Squad history={props.history}/>
                            :
                                <MainInfoBanner history={props.history}/>
                            }
                            {!screenIsSmall &&
                            <MainBody
                                username={username}
                                theme={theme}
                                inQueue={inQueue}
                                history={props.history}
                            />
                            }
                        </>
                    }
                    {isCollapseContent &&
                        <>
                            {mainBodyVisible ? (
                                <MainInfoBannerWrapperDiv
                                    screenIsSmall={screenIsSmall}
                                    style={{
                                        borderRadius: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '1em'
                                    }}
                                >
                                    <MainContentCollapseButton
                                        onClick={() => setMainBodyVisible((prev) => !prev)}
                                        className={'buttonZoom'}>
                                        {useIcon('subtract') }
                                    </MainContentCollapseButton>
                                </MainInfoBannerWrapperDiv>
                            ) : (

                                <>
                                    <MainContentCollapseButton
                                        onClick={() => setMainBodyVisible((prev) => !prev)}
                                        style={{transform: 'rotate(180deg)'}}
                                    >
                                        {useIcon('subtract') }
                                    </MainContentCollapseButton>
                                </>
                            )}
                        </>
                    }
                    <ItemSwitchManager gameMetadataProvider={sandbox} />
                    <ControlHints
                        gameMetadataProvider={sandbox}
                    />
                </>
                <PingWarning />
                <SteamDrawer />
            </MainContainerDiv>
            <Advert />
            <RadioInterface
                theme={theme}
                history={props.history}
            />
        </>
    )
};

export default Main;