import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { playSound } from '../../data/sound';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import palette from "../../styled/Palette";

const ButtonOptionDiv = styled.div`
    display: flex;
    flex-grow: 1;
    max-width: 10em;
    flex-direction: column;
    margin-right: 0.5em;
    background-color: ${palette.midnightBlue};
    border: 1px solid ${palette.wetAsphalt};
    height: 5em;
    justify-content: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

const ButtonMode = ({ click, icon, label }) => {
    const theme = useSelector(state => state.theme);

    return (
        <ButtonOptionDiv
            tabIndex={0}
            onClick={() => {
                click();
                playSound('pressButton', 1);
            }}
            onMouseEnter={() => {
                playSound('itemHover', 1)
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FontAwesomeIcon
                    style={{ color: theme.interfaceColorBright, fontSize: '30px', marginBottom: '5px' }}
                    icon={['fas', icon]}
                />
                <div style={{ whiteSpace: 'nowrap' }}>{label}</div>
            </div>
        </ButtonOptionDiv>
    );
};


export default ButtonMode;
