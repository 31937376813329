import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import purplePotion from '../../images/potions/potionPurple.png';
import greenPotion from '../../images/potions/potionGreen.png';
import bluePotion from '../../images/potions/potionBlue.png';
import redPotion from '../../images/potions/potionRed.png';

export function createPotionParticles(player, potion, name) {
    let potionMap = {
        growthPotion: {
            image: greenPotion,
            color: '00ff00',
        },
        jumpPotion: {
            image: bluePotion,
            color: '00aaff',
        },
        airJumpPotion: {
            image: purplePotion,
            color: 'aa00aa',
        },
        speedPotion: {
            image: redPotion,
            color: 'ff0000',
        },
    };

    let usedPotion = potionMap[potion];

    if (name === 'consumePotion') {
        let burpEmitter = new Proton.Emitter();
        burpEmitter.rate = new Proton.Rate(200, updatePerFrame);
        burpEmitter.addInitialize(new Proton.Mass(1));
        burpEmitter.addInitialize(new Proton.Radius(2, 3));
        burpEmitter.addInitialize(new Proton.Life(1));
        burpEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
        burpEmitter.addInitialize(
            new Proton.Velocity(new Proton.Span(-5, 5), new Proton.Span(-5, 5), 'vector')
        );

        // burpEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
        burpEmitter.addBehaviour(new Proton.Color(usedPotion.color));
        burpEmitter.addBehaviour(new Proton.Gravity(-1));
        burpEmitter.addBehaviour(new Proton.Scale(1, 4));
        burpEmitter.addBehaviour(new Proton.Alpha(.7, 0));
        burpEmitter.addInitialize(
            new Proton.Position(
                new Proton.CircleZone(
                    player.position.x,
                    player.position.y,
                    100 * player.stats.size * player.scale
                )
            )
        );
        burpEmitter.damping = 0.04;
        burpEmitter.emit(updatePerFrame * 2, 3);

        let potionEmitter = new Proton.Emitter();
        potionEmitter.rate = new Proton.Rate(1, updatePerFrame);
        potionEmitter.addInitialize(new Proton.Body(usedPotion.image));
        potionEmitter.addInitialize(new Proton.Velocity(0, -2));
        //potionEmitter.addBehaviour(new Proton.Gravity(-1));
        potionEmitter.addBehaviour(new Proton.Scale(0.4, 0.7));
        potionEmitter.addInitialize(new Proton.Life(2));
        potionEmitter.addBehaviour(new Proton.Alpha(1, 0));
        potionEmitter.p.y = player.position.y - (player.stats.size * player.scale * 180) / 2;
        potionEmitter.p.x = player.position.x - 10;
        potionEmitter.emit(updatePerFrame * 2, 3);

        protonBackground.addEmitter(burpEmitter);
        setTimeout(() => {
            protonBackground.addEmitter(potionEmitter);
        }, updatePerFrame * 2);
    }
    //fade emitter
    else {
        let burpEmitter = new Proton.Emitter();
        burpEmitter.rate = new Proton.Rate(50, updatePerFrame);
        burpEmitter.addInitialize(new Proton.Mass(1));
        burpEmitter.addInitialize(new Proton.Radius(2, 3));
        burpEmitter.addInitialize(new Proton.Life(1));
        burpEmitter.addBehaviour(new Proton.RandomDrift(10, 3, 0.01));
        burpEmitter.addInitialize(
            new Proton.Velocity(new Proton.Span(-1, 1), new Proton.Span(-2, 2), 'vector')
        );

        // burpEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
        burpEmitter.addBehaviour(new Proton.Color('888888'));
        burpEmitter.addBehaviour(new Proton.Gravity(-1));
        burpEmitter.addBehaviour(new Proton.Scale(1, 4));
        burpEmitter.addBehaviour(new Proton.Alpha(1, 0));
        burpEmitter.addInitialize(
            new Proton.Position(
                new Proton.CircleZone(
                    player.position.x,
                    player.position.y,
                    100 * player.stats.size * player.scale
                )
            )
        );
        burpEmitter.damping = 0.04;
        burpEmitter.emit(updatePerFrame * 2, 2);


        let potionEmitter = new Proton.Emitter();
        potionEmitter.rate = new Proton.Rate(1, updatePerFrame);
        potionEmitter.addInitialize(new Proton.Body(usedPotion.image));
        potionEmitter.addInitialize(new Proton.Velocity(0, -2));
        //potionEmitter.addBehaviour(new Proton.Gravity(-1));
        potionEmitter.addBehaviour(new Proton.Scale(0.2, 0.4));
        potionEmitter.addInitialize(new Proton.Life(1));
        potionEmitter.addBehaviour(new Proton.Alpha(.5, 0));
        potionEmitter.p.y = player.position.y - (player.stats.size * player.scale * 180) / 2;
        potionEmitter.p.x = player.position.x - 10;
        potionEmitter.emit(updatePerFrame * 2, 2);

        let redDotEmitter = new Proton.Emitter();
        redDotEmitter.rate = new Proton.Rate(1, updatePerFrame);
        redDotEmitter.addInitialize(new Proton.Radius(45));
        redDotEmitter.addInitialize(new Proton.Velocity(0, -2));
        redDotEmitter.addBehaviour(new Proton.Color('990000'));
        redDotEmitter.addBehaviour(new Proton.Scale(.5, 1));
        redDotEmitter.addInitialize(new Proton.Life(1));
        redDotEmitter.addBehaviour(new Proton.Alpha(.5, 0));
        redDotEmitter.p.y = player.position.y - (player.stats.size * player.scale * 180) / 2;
        redDotEmitter.p.x = player.position.x - 10;
        redDotEmitter.emit(updatePerFrame * 2, 2);

        protonBackground.addEmitter(burpEmitter);
        setTimeout(() => {
            protonBackground.addEmitter(potionEmitter);
            protonBackground.addEmitter(redDotEmitter);
        }, updatePerFrame * 2);
    }
}
