

export const selectLeader = (squad, username) => {
    let result = false;
    if (squad.current.length === 0) {
        result = true;
    } else {
        squad.current.forEach(user => {
            if (user.name === username) {
                if (user.captain) {
                    result = true;
                }
            }
        });
    }
    return result;
};


export const selectIsInSquad = (squad) => {
    return squad.current.length > 0;
};