import React from 'react';
import styled from 'styled-components';
import palette from '../../../../styled/Palette';
import {shadow} from "../../../../data/globals";

export const OverlayDiv = styled.div`
    display: flex;
    flex-direction: column;
    color: ${palette.base4};
    
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.55);
    opacity: 1;
`;

export const OverlayButton = styled.button`
    font-weight: 600;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${props => props.interfaceColorBright};
    margin: 0 .5em;
`;
export const OverlayInput = styled.input`
    font-weight: 600;
    color: ${props => props.interfaceColorBright};
    text-align: center;
    font-size: 1em;
    width: 3em;
`;

export const OverlayHeaderDiv = styled.div`
    padding: .5em;
    width: 40em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${palette.wetAsphalt};
    color ${palette.base4};
`;
export const OverlayContentDiv = styled.div`
    width: 40em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid ${palette.wetAsphalt};
    background-color: ${palette.base6};
`;

export const BetsCloseButton = styled.div`
    display: flex;
    font-size: 2em;
`;

export const BetCreatorDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    justify-content: space-between;
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
    color: ${palette.base4};
`;

export const BetContentDiv = styled.div`
    box-shadow: ${shadow};
    background-color: ${palette.base0} !important;
    padding: .5em;
    border-radius: 4px;
`;