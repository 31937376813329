import React from "react";
import {useSelector} from "react-redux";
import Icon from "../../data/icon";
import palette from "../../styled/Palette";

const InformationGamepad = () => {
    const gamepad = useSelector(state => state.gamepad);

    if (!gamepad.navigating && gamepad.connected) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: palette.base4,
                padding: '0 .5em',
                flexDirection: 'column'
            }}>
                <div style={{fontSize: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{fontSize: '12px', marginBottom: '.1em'}}>
                        {Icon('gamepad-alt')}
                    </div>
                    <b>PRESS</b>
                    <b>SELECT</b>
                </div>
            </div>
        )
    }
    return null;
};

export default InformationGamepad;