import React, {Fragment, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import env from '../env/env';

import Nav from '../page/Nav';
import MatchMessageManager from './Manager/MatchMessageManager';
import StageManager  from './Manager/StageManager';
import BackgroundManager  from './Manager/BackgroundManager';
import Loading from './Loading/Loading';
import Drawer from "./Drawer/Drawer";
import bg from "../images/repeater.png";

import {updateColorName, updatePortrait} from "../redux/modules/self/selfActions";
import { updateTheme } from "../redux/modules/theme/themeActions";
import { initializeSocketMaster } from '../redux/middleware/wsActions';
import {handleLoadPostMatch} from "../redux/middleware/wsMatch";
import Radio from "./Radio/Radio";
import Modal from "./Modal/Modal";
import { gameSocket } from '../redux/middleware/wsMatch';
import {queueCooldown} from "../redux/modules/queue/queueActions";
import {Howl, Howler} from "howler";
import {getNextSong, loadRadio} from "../redux/modules/radio/radioActions";
import {FADE_OUT_SONG_DURATION} from "../redux/modules/radio/radioReducers";
import { masterSocket } from "../redux/middleware/wsMaster";
import workerGetPortrait from "../webworker/main";
import {socketCooldown, updateAssets} from "../redux/modules/socket/socketActions";
import {loadImagedAssets, setImageAssets} from "../data/images";
import ServerConsole from "./ServerConsole/ServerConsole";

import ConnectSteamAPI from "../greenworks";
import isElectron from "is-electron";
import {DURATION_COOLDOWN_PING_MATCH_SERVERS} from "../data/globals";
import styled from 'styled-components';

import UseWindowDimensions from '../hooks/useWindowDimensions';
import palette from "../styled/Palette";
import GamepadNotification from "./GamepadNotification/GamepadNotification";
import GamepadNavigateOverlay from "./GamepadNavigateOverlay";
import ModalItem from "../page/Atlas/ModalItem";
import NewsTicker from "./NewsTicker/NewsTicker";
import {PageTypes} from "../system/types";
import ScreenSkip from "./ScreenSkip/ScreenSkip";
import MockLobbyEvents from "../test/MockLobbyEvents";
import SignInManager from "./Manager/SignInManager";
const DELAY_WIN_SCREEN = 3000;

const WideBar = styled.div`
        height: 100%;
        position: absolute;
		width: ${props => (props.width - props.canvasSize.width) /2}px;
		z-index: 3;
		background-color: ${palette.base7};
`;

const Root = ({history}) => {
    const dispatch = useDispatch();
    const { roomId, teams, results, ranked } = useSelector(state => state.match);
    const queue = useSelector(state => state.queue);
    const { outfit, starterColor, newItems, rating } = useSelector(state => state.self);
    const { next, song, volume, analyser } = useSelector(state => state.radio);
    const { presentItems } = useSelector(state => state.present);
    const assetsLoaded = useSelector(state => state.socket.assetsLoaded);
    const { team } = useSelector(state => state.self);
    const master = useSelector(state => state.socket.master);
    const cooldown = useSelector(state => state.socket.cooldown);

    const gameLoaded = useRef(false);
    const refCanvas = useRef();
    const refCanvasBackground = useRef();
    const refCanvasFloor = useRef();

    const [selectedFloor, setSelectedFloor] = useState();
    // In order to serve wide screen users, we must maintain a certain aspect ratio.
    const { width, height } = UseWindowDimensions();

    //todo: Not sure if handling these at the root level is wise because it might be causing the app to rerender
    useEffect(() => {
        document.body.style.backgroundColor = '#505050';
        document.body.style.visibility = 'visible';
        dispatch(initializeSocketMaster());
        const loadImages = async () => {
            const imagedAssets = await loadImagedAssets();
            setImageAssets(imagedAssets);
            dispatch(updateAssets(true));
        };

        /**
         * It was easier to preload all images and be able to retrieve them synchronously than to have to use tons of awaits / callbacks.
         * In some areas where the user needs to generate a lot of portraits, like in a 4v4 for instance, we can't generate the match screen
         * without retrieving every image for every item, which could introduce delay to the page load. So we pull all the images on start up
         * so we can use them immediately in any circumstance.
         */
        loadImages();
    },[]);
    /**
     * When the user is given new items, show them the drop screen.
     */
    useEffect(() => {
        if (newItems.length > 0) {
            const currentPath = history.location.pathname;
            if ([PageTypes.MARKET, PageTypes.MAIN, PageTypes.CASH_SHOP,PageTypes.LEARN].includes(currentPath)) {
                // User is not on the match screen
                history.push({
                    pathname: PageTypes.DROP,
                    state: { from: currentPath }
                });
            }
        }
    }, [newItems]);
    /**
     * When the user purchases a new present, show the present screen.
     */
    useEffect(() => {
        if (presentItems.length > 0) {
            history.push(PageTypes.PRESENT);
        }
    }, [presentItems]);
    /**
     * When outfit changes, redraw portrait render.
     */
    useEffect(() => {
        const gameLoaded = masterSocket.readyState === 1 && assetsLoaded;
        if (outfit && starterColor && gameLoaded) {
            const useOutfit = {
                ...outfit,
                paint: outfit.paint ? outfit.paint : starterColor,
            };

            workerGetPortrait(useOutfit).then(dataUrl => {
                dispatch(updatePortrait({
                    portrait: dataUrl,
                    portraitCanvas: dataUrl
                }));
            });
            //updateFavIcon(loadedCanvas);
        }
    }, [outfit, starterColor, masterSocket.readyState, assetsLoaded]);
    /**
     * Update the theme colors when the Slime paint is changed.
     */
    useEffect(() => {
        if (starterColor) {
            const nextColor = outfit.paint?.colorHex ?? starterColor.colorHex;
            dispatch(updateTheme(nextColor));

            dispatch(updateColorName(outfit.paint ? outfit.paint.colorName : starterColor.colorName));
        }
    }, [outfit.paint, starterColor]);
    /**
     * When rating comes in from a ranked match, decide which screen to go too.
     */
    useEffect(() => {
        if (rating) {
            setTimeout(() => {
                if ([PageTypes.RULES,PageTypes.MATCH].includes(history.location.pathname)) {
                    dispatch(handleLoadPostMatch(ranked, rating));
                }
            }, DELAY_WIN_SCREEN);
        }
    }, [rating]);
    /**
     * When results come in from a casual match, go to win.
     */
    useEffect(() => {
        if (results) {
            const observer = !team;
            const tieGame = results.winner === 'tie';
            const casualGame = !ranked;
            const stillInMatch = roomId !== 'lobby';

            if ((tieGame || casualGame || observer) && stillInMatch) {
                setTimeout(() => {
                    if (gameSocket) {
                        history.push({
                            pathname: PageTypes.WIN,
                            state: results
                        });
                    }
                }, DELAY_WIN_SCREEN);
            }
        }
    }, [results]);
    /**
     * When the user is sent back to the local lobby, make sure that the main screen also mounts.
     */
    useEffect(() => {
        const gameLoaded = masterSocket.readyState === 1 && assetsLoaded;
        // The user also has to be connected to the server
        if (roomId === 'lobby' && gameLoaded) {
            if (history.location.pathname !== '/') {
                history.push(PageTypes.MAIN);
            }
        }
    },[roomId, assetsLoaded, masterSocket.readyState]);

    /**
     * When the user leaves queue, stop them from queuing for several seconds
     */
    useEffect(() => {
        setTimeout(() => {
            if (queue.cooldown > 0) {
                dispatch(queueCooldown(queue.cooldown-1));
            }
        }, 1000);
    },[queue.cooldown]);
    useEffect(() => {
        if (queue.active === false) {
            dispatch(queueCooldown(5));
        }
    },[queue.active]);

    /**
     * When the user queries the match servers, allow another request after a duration
     */
    useEffect(() => {
        if (cooldown.servers === false) {
            setTimeout(() => {
                dispatch(socketCooldown('servers'));
            }, DURATION_COOLDOWN_PING_MATCH_SERVERS);
        }
    },[cooldown.servers]);

    /**
     * When a song ends, we must play the next song.
     * The interface is not always mounted so this needs to happen globally.
     */
    const loadSong = (nextSong) => {
        let nextHowl = new Howl({
            "src": [`sounds/music/${nextSong.src}`],
            "volume": volume,
            "onend": () => {
                dispatch(getNextSong());
            },
            "onload": () => {
                if (volume !== '0') {
                    nextHowl.play();
                    nextHowl.fade(0, volume, FADE_OUT_SONG_DURATION);
                }

                dispatch(loadRadio({
                    "song": nextHowl,
                    "analyser": analyser ? null : Howler.ctx.createAnalyser(),
                    "title": nextSong.name,
                    "src": nextSong.src,
                    "paused": volume === '0'
                }));

            }
        });
    };
    useEffect(() => {
        //const DEV_DISABLE_RADIO = env.environment !== 'dev';
        if (next && !song) {
            loadSong(next);
        }
    }, [next]);

    useEffect(() => {
        if (master && isElectron()) {
            ConnectSteamAPI(dispatch);
        }
    }, [master]);

    const getCanvasSize = () => {
        let canvasWidth;
        let canvasHeight;
        const aspectRatio = 1920/950;
        const wideScreen = width / height > aspectRatio;
        if (wideScreen) {
            canvasWidth = height * aspectRatio;
            canvasHeight = height;
        } else {
            canvasWidth = width;
            canvasHeight = width / aspectRatio;
        }
        return {
            width: canvasWidth,
            height: canvasHeight
        }
    };
    const canvasSize = getCanvasSize();

    return (
        <>
            <BackgroundManager
                setSelectedFloor={setSelectedFloor}
                refCanvasBackground={refCanvasBackground}
                refCanvasFloor={refCanvasFloor}
            />
            <SignInManager />

            <Loading
                dispatch={dispatch}
                history={history}
            />
            <>
                <WideBar canvasSize={canvasSize} width={width} style={{left:0}}> </WideBar>
                <WideBar canvasSize={canvasSize} width={width} style={{right:0}}> </WideBar>

                <Modal dispatch={dispatch} />
                <ModalItem history={history}/>

                {assetsLoaded &&
                <StageManager
                    selectedFloor={selectedFloor}
                    refCanvas={refCanvas}
                    refCanvasFloor={refCanvasFloor}
                    refCanvasBackground={refCanvasBackground}
                    history={history}
                    gameLoaded={gameLoaded}
                />
                }

                <Drawer
                    history={history}
                />
                <Nav
                    history={history}
                />
                {assetsLoaded &&
                <Radio
                    history={history}
                />
                }
                <MatchMessageManager
                    teams={teams}
                />

                <GamepadNavigateOverlay
                    history={history}
                />

               {/*
                <ScreenSkip
                    history={history}
                    specificScreen={PageTypes.DROP}
                />
                <MockLobbyEvents />
                <ServerConsole />
                */}

            </>
        </>
    )
};

export default Root;


/**
 * We don't do this anymore because we don't want to pester users with a server rating pop-up
 * When the game socket exists, request a server rating from the client on socket close
 */
/*
useEffect(() => {
    // When the game socket is set
    if (gameSocket) {
        // And the game has ended
        gameSocket.onclose = () => {
            // If we're not an observer (match.server.region was set during joinServer event for players)
            if (match.server.region) {
                // Ask the client for a server rating
                ModalServerFeedback(dispatch);
            }
        };
    }
}, [gameSocket]);
*/
