import React, {useEffect, useState} from 'react'
import UserBanner from "./UserBanner/UserBanner";
import { useSelector } from 'react-redux';
import palette from "../../styled/Palette";
import {AutoSizer, List} from "react-virtualized";
import styled from 'styled-components';
import {USER_BANNER_SLOT_SIZE} from "../../data/globals";
import {nestedSort} from "../../data/generic/generic";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const ScrollableUsersListDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 20em;
    border: 1px solid black;
    background-color: ${palette.base5};
    border-width: 0 0 0 1px;
    color: palette.base4;
    font-size: 20px;
`;

const ActivePlayersHeaderDiv = styled.div`
    width: 100%;
    padding: 20px 0;
    background-color: ${palette.midnightBlue};
    color: ${palette.base4};
`;

const PublicChatUsers = (props) => {
    const usersData = useSelector(state => state.users.data);
    const playersSortedByRating = nestedSort('bestRank.rating', usersData);

    return (
        <ScrollableUsersListDiv>
            <ActivePlayersHeaderDiv>PLAYER LIST</ActivePlayersHeaderDiv>
            <div style={{height: '100%', fontSize: '10px'}}>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            className='List styleDarkScrollSquare'
                            width={width-1}
                            height={height}
                            rowCount={playersSortedByRating.length}
                            rowHeight={USER_BANNER_SLOT_SIZE}
                            overscanRowCount={0}
                            tabIndex={null}
                            rowRenderer={
                                ({ key, style, index }) => {
                                    return (
                                        <div className='Row' key={key} style={style}>
                                            <UserBanner
                                                history={props.history}
                                                type={'public'}
                                                setWhisper={props.setWhisper}
                                                player={playersSortedByRating[index]}
                                            />
                                        </div>
                                    )
                                }
                            }
                        />
                    )}
                </AutoSizer>
            </div>
        </ScrollableUsersListDiv>
    )
};

export default PublicChatUsers;