import React, {useRef, useEffect, useState} from "react";
import styled from 'styled-components';
import LineLabel from "../../component/LineLabel/LineLabel";
import palette from "../../styled/Palette";
import ItemSlot from "../../component/Item/ItemSlot";
import {useDispatch, useSelector} from "react-redux";
import {requestChangeLoadout} from "../../system/endpoints/master";
import {nestedSort} from "../../data/generic/generic";
import {getAccessoryByAbility} from "../../data/items";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalAchievements from "../../component/Modal/Type/ModalAchievements";
import ModalLoadout from "../../component/Modal/Type/ModalLoadout";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const LoadoutDiv = styled.div`
    background-color: ${palette.base7};
    padding-top: 1em;
    border-top: 1px solid black;
    background: repeating-linear-gradient(45deg,${palette.base6},${palette.base6} 10px,${palette.base7} 10px,${palette.base7} 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const emptySlot = {
    colorHex: 'red',
    tier: 0,
    category: 'accessory',
    empty: true
};


const LoadoutSlots = ({loadout,accessorySelected,setAccessorySelected, loadoutRef}) => {
    const { screenIsSmall } = UseWindowDimensions();

    const handleChangeLoadout = (item,index) => {
        const isEmptySlot = typeof(item.slot) !== 'number';

        if (accessorySelected) {
            // The user has selected an item and now needs to select which loadout position to slot it in
            requestChangeLoadout(accessorySelected,index+1);
            setAccessorySelected(null);
        }
        if (!accessorySelected && !isEmptySlot) {
            // The user is making a request to remove the slot item, or if there is no item, do nothing.
            requestChangeLoadout(null,index+1);
        }
    };

    return (
      <div
          style={{display: 'flex', justifyContent: 'center'}}
      >
          <div
            style={{display: 'flex',}}
            ref={loadoutRef}
          >
              {loadout.map((item,index) => {
                  return (
                      <ItemSlot
                          isLoadout={true}
                          click={() => handleChangeLoadout(item,index)}
                          accessorySelected={accessorySelected}
                          slotWidth={screenIsSmall ? 6 : 8}
                          slotHeight={screenIsSmall ? 6 : 8}
                          item={item}
                          key={index}
                      />
                  )
              })}
          </div>
      </div>
    )
};

export const determineLoadout = (accessories) => {
    accessories = accessories.map(accessory => {
        return {
            ...accessory,
            ...getAccessoryByAbility(accessory.ability)
        }
    });

    const slottedAccessories = accessories.filter(accessory => accessory.slot);
    // Iterate through number of slots which should exist
    return [...Array(4)].map((s,index) => {
        // Locate the correct item to put in the slot
        const accessoryIndex = slottedAccessories.map((accessory => accessory.slot)).indexOf(index+1);
        // Deliver the appropriate slot, or a stub
        return accessoryIndex !== -1 ? slottedAccessories[accessoryIndex] : emptySlot;
    });
};

const AccessoryLoadout = ({category,accessorySelected,setAccessorySelected,loadout,loadoutQuantity}) => {
    const dispatch = useDispatch();
    if (category !== 'accessory') {
        // Don't show the loadout outside of the accessory screen
        return null;
    }
    const loadoutRef = useRef(null);
    const [loadoutWidth, setLoadoutWidth] = useState('auto');

    useEffect(() => {
        if (loadoutRef.current) {
            setLoadoutWidth(loadoutRef.current.offsetWidth);
        }
    }, []);

    return (
        <LoadoutDiv>
            <LoadoutSlots
                loadoutRef={loadoutRef}
                accessorySelected={accessorySelected}
                setAccessorySelected={setAccessorySelected}
                loadout={loadout}
            />
            <LineLabel
                loadoutWidth={loadoutWidth}
                key={loadout}
                styles={'LOADOUT'}
                orientation={'horizontal'}
                inverted={true}
            >
                <div>
                    {`LOADOUT ${loadoutQuantity}/4`}
                </div>
            </LineLabel>
        </LoadoutDiv>
    )
};

export default AccessoryLoadout;