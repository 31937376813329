import Header from "../../component/Header/Header";
import {PageTypes} from "../../system/types";
import React, {useEffect, useRef, useState} from "react";
import LeftPanel from "./LeftPanel";
import ItemTrackPanel from "./ItemTrackPanel";
import RightPanel from "./RightPanel";
import {useDispatch, useSelector} from "react-redux";
import {playSound} from "../../data/sound";
import {toggleUserInput} from "../../data/generic/generic";
import {getRandomInt} from "../../data/format";
import {getTierData} from "../../data/tier";
import palette from "../../styled/Palette";
import {imageAssets} from "../../data/images";
import {nanoid} from "nanoid";
import {updateFinalItem, updatePossibleItems} from "../../redux/modules/slots/slotsActions";
import {selectTickets} from "../../redux/modules/self/selfReducers";
import {requestSpinFortuneWheel} from "../../system/endpoints/master";
import {
    FINAL_CARD,
    NUMBER_WHEEL_CARDS,
    OFFSET_CARD_INDEX,
    WAIT_BEFORE_SPIN, WAIT_FOR_WHEEL_FADE_OUT,
    WHEEL_SPIN_DURATION_SECONDS
} from "./config";
import styled from 'styled-components';
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import {DialogueDiv} from "../../component/Dialogue/DialogueStyle";

const FortuneWheelDiv = styled.div`
    display: flex;
    flex-grow: 1;
    margin: ${({screenIsSmall}) => screenIsSmall ? '1em' : '4em'};
    border: 1px solid ${palette.belizeHole};
    overflow: hidden;
`;

export const FortuneWheelTypes = {
    READY: 'ready',
    LIVE: 'live',
    DONE: 'done',
};

const FortuneWheel = ({ history }) => {
    const dispatch = useDispatch();

    const { possibleItems, finalItem } = useSelector(state => state.slots);
    const tickets = useSelector(selectTickets);

    const [currentItem, setCurrentItem] = useState(null);
    const [mode,setMode] = useState(FortuneWheelTypes.READY);
    //The card which is currently active on the wheel
    const [train, setTrain] = useState();
    // Since the train is a dom target that does not change we need a flag to trigger the train behaviors
    const [newSpin, setNewSpin] = useState(false);
    const refTrainDiv = useRef();
    const refCurrentItemContainer = useRef();
    const refCrosshairText = useRef();
    const currentItemIndex = useRef(null);
    const { screenIsSmall } = UseWindowDimensions();

    // 1. User attempts to spin
    const handleSpinWheel = () => {
        if (tickets > 0) {
            if (refCurrentItemContainer.current) {
                refCurrentItemContainer.current.classList.add('fadeIn');
                refCurrentItemContainer.current.classList.remove('fadeOut');
            }
            if (mode === FortuneWheelTypes.READY) {
                requestSpinFortuneWheel();
                setMode(FortuneWheelTypes.LIVE);
                playSound('pullSlots',.5);
                toggleUserInput(false);
            }
        }
    };
    // 2. Server responds with the users reward
    useEffect(() => {
        if (finalItem) {
            generateItems();
        }
    }, [finalItem]);
    // 3. Slot items are generated and set
    useEffect(() => {
        if (possibleItems.length > 0) {
            // Animate Train
            if (train) {
                train.style.transition = '';
                train.classList.remove('fadeOut');
                train.classList.remove('fadeIn');
                train.classList.add('fadeIn');
            }
            setTimeout(() => {
                toggleTrackVisibility(true);
                setTrain(refTrainDiv.current);
                setNewSpin(true);
                // todo: assuming that the dom has updated is bad
            }, 50);
        }
    }, [possibleItems]);
    // 4. Setting train triggers the wheel movement
    useEffect(() => {
        if (train && newSpin) {
            // Statically typed reference to the cards margin (Inconvenient to acquire dynamically)
            const CARD_MARGIN = 5;
            // The standard height of a card including the collapsed margin height
            const CARD_HEIGHT = document.getElementById('slot-0').getBoundingClientRect().height + CARD_MARGIN;
            // The total height of the wheel
            const WHEEL_HEIGHT = (CARD_HEIGHT * NUMBER_WHEEL_CARDS);
            // The offset required to reposition the wheel at the 5th card from the start or end
            const WHEEL_OFFSET = WHEEL_HEIGHT - (OFFSET_CARD_INDEX * CARD_HEIGHT);
            // The offset expressed in pixels
            const WHEEL_START_MARGIN_TOP = `-${WHEEL_OFFSET}px`;
            const WHEEL_END_MARGIN_TOP = `${WHEEL_OFFSET + crosshairOffset()}px`;
            // Update the view to show the first card as currently targeted
            setActiveCardText();
            // Set the starting position of the train
            train.style.marginTop = WHEEL_START_MARGIN_TOP;
            // Give the track a second to animate in to the view
            setTimeout(() => {
                watchIndexChange(CARD_HEIGHT*2);
                // Specify the duration of the spin and ease in / out of animating
                train.style.transition = `all ${WHEEL_SPIN_DURATION_SECONDS}s linear`;
                train.style.transitionTimingFunction = 'cubic-bezier(.5,0,0,1)';
                train.style.marginTop = WHEEL_END_MARGIN_TOP;
                //whatever duration it takes to complete translate animation
                setTimeout(() => {
                    setMode(FortuneWheelTypes.DONE);
                    toggleUserInput(true);
                    setTimeout(() => playSound('drop',1),500);
                }, WHEEL_SPIN_DURATION_SECONDS * 1000);
            }, WAIT_BEFORE_SPIN);
        }
    }, [train, newSpin]);
    // 5. A watcher kicks off to update the active card details on an interval
    useEffect(() => {
        if (currentItem) {
            playSound('tick' + getRandomInt(3),1);
            const styleCrosshairCard = () => {
                let crosshairCard = document.getElementById('slot-' + currentItemIndex.current);
                // Set the style of the active card
                if (crosshairCard) {
                    crosshairCard.style.backgroundColor = '#282828';
                    crosshairCard.style.borderColor = (['Coins','Tickets'].includes(currentItem.category) ? palette.sunFlower: getTierData(currentItem.tier).color);
                    crosshairCard.style.opacity = 1;
                }
            };
            const styleCrosshairText = () => {
                // Set the color of the text tier
                if (refCrosshairText && possibleItems.length > 0) {
                    if (currentItem.tier === 0) {
                        // The default gray hue is not suited for text, so we override
                        refCrosshairText.current.style.color = palette.base4;
                    } else {
                        refCrosshairText.current.style.color = getTierData(currentItem.tier).color;
                    }

                }
            };
            const stylePreviousCard = () => {
                //todo: This is a lot of DOM searching. There is most likely a more performant way to target this card.
                let previousCard = document.getElementById('slot-' + (currentItemIndex.current + 1));
                // Sometimes the previousCard is not declared, idk why.
                if (previousCard) {
                    // Reset the previous card back to its original appearance
                    previousCard.style.opacity = '.15';
                }
            };

            stylePreviousCard();
            styleCrosshairCard();
            styleCrosshairText();
        }
    },[currentItem]);

    // Get rid of the possibleItems and finalItem if the user navigates away
    useEffect(() => {
        return () => {
            dispatch(updatePossibleItems([]));
            dispatch(updateFinalItem(null));
        }
    }, []);

    // Generate random items and their portraits
    const generateItems = () => {
        // UIDs are appended for each card for when the are rendered by key property
        // See: https://www.npmjs.com/package/nanoid
        let generatedItems = [];
        // Load the possible cap and skin items
        let caps = [];
        let skins = [];
        Object.keys(imageAssets.Cap).forEach(item => {
            caps.push({
                'name': item,
                'category': 'cap',
            })
        });
        Object.keys(imageAssets.Skin).forEach(item => {
            skins.push({
                'name': item,
                'category': 'skin',
            })
        });
        const allPossibleDrops = skins.concat(caps);

        for (let x = 0; x < NUMBER_WHEEL_CARDS; x++) {
            let diceRoll = Math.random();
            // 1/10 chance to add a coin card
            if (diceRoll > .9) {
                let amount = Math.ceil(Math.random() * 10) * 1000;
                generatedItems.push({
                    category: 'Coins',
                    amount: amount,
                    tier: 0,
                    uid: nanoid(),
                });
            }
            // 2/10 chance to add a ticket card
            else if (diceRoll > .8) {
                generatedItems.push({
                    category: 'Tickets',
                    amount: Math.ceil(Math.random() * 2) + 1,
                    tier: 0,
                    uid: nanoid(),
                });
            }
            // 8/10 chance to add a cap or hat card
            else {
                let hexValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
                let hexValue = '#';
                for (let x = 0; x < 3; x++) {
                    hexValue += hexValues[Math.floor(Math.random() * hexValues.length)];
                }
                // Some tier setting stuff we do based on whether the user is premium
                let tier = Math.floor(Math.random() * 2);
                if (Math.random() > .95) {
                    tier = 2;
                }
                // add the cap or hat as an item
                let randomItem = allPossibleDrops[Math.floor(Math.random() * allPossibleDrops.length)];
                generatedItems.push({
                    name: randomItem.name,
                    colorHex: hexValue,
                    tier: tier,
                    category: randomItem.category,
                    uid: nanoid(),
                });
            }
        }

        // Replace the fifth item from the top with the item the user actually will receive
        generatedItems[4] = {
            ...finalItem,
            uid: 'uniqueIdentifierForKey'
        };

        setTimeout(() => {
            dispatch(updatePossibleItems(generatedItems));
        }, WAIT_FOR_WHEEL_FADE_OUT);
    };
    // The wheel is reset after the item is claimed so that the user may spin again
    const handleResetWheel = () => {
        setMode(FortuneWheelTypes.READY);
        currentItemIndex.current = null;
        if (train) { //ok
            train.classList.add('fadeOut');
            train.classList.remove('slideInRight');
        }
        setNewSpin(false);
        setCurrentItem(null);

        toggleTrackVisibility(false);

        if (refCurrentItemContainer.current) {
            refCurrentItemContainer.current.classList.add('fadeOut');
            refCurrentItemContainer.current.classList.remove('fadeIn');
        }

        dispatch(updatePossibleItems([]));
        dispatch(updateFinalItem(null));
        setTimeout(() => {
        }, WAIT_FOR_WHEEL_FADE_OUT);
    };
    // Sets the the crosshair text and tier
    const setActiveCardText = () => {
        if (mode === FortuneWheelTypes.LIVE) {
            const crosshairItem = possibleItems[currentItemIndex.current];
            setCurrentItem(crosshairItem);
        }
    };
    // While the wheel is spinning, update the index every time a new card is under the crosshair
    const watchIndexChange = (cardHeight) => {
        const indexInterval = setInterval(() => {
            if (currentItemIndex.current !== FINAL_CARD) {
                //Determine if the current card index has changed
                const currentMarginTop = window.getComputedStyle(train).getPropertyValue('margin-top');
                const index = Math.ceil(parseInt(currentMarginTop, 10) / cardHeight) + (Math.round(NUMBER_WHEEL_CARDS/2));
                const newIndex = NUMBER_WHEEL_CARDS - index;
                const indexHasChanged = currentItemIndex.current !== newIndex;
                if (indexHasChanged) {
                    currentItemIndex.current = NUMBER_WHEEL_CARDS - index;
                    setActiveCardText();
                }
            } else {
                clearInterval(indexInterval);
            }
        }, 100);
    };
    // Apply the styles to the track on load or unload
    const toggleTrackVisibility = (load) => {
        if (load) {
            refTrainDiv.current.classList.add('fadeIn');
            refTrainDiv.current.style.visibility = 'visible';
        } else {
            refTrainDiv.current.classList.add('fadeOut');
            // setTimeout(() => {
            //     //refTrainDiv.current.style.visibility = 'hidden';
            // }, WAIT_FOR_WHEEL_FADE_OUT);
        }

    };
    // Determine the variable position by which the crosshair will land on the final card, in relation to the cards center.
    const crosshairOffset = () => {
        const CARD_HEIGHT_PX = 230;
        const OFFSET_LIMIT = 10;
        const MAX_OFFSET = CARD_HEIGHT_PX - OFFSET_LIMIT;

        let offset = Math.floor(Math.random() * MAX_OFFSET);
        const landOnCardBottom = Math.random() > .5;

        if (landOnCardBottom) {
            offset *= -1;
        }
        return offset;
    };

    return (
      <div className="animatedFast slideInRight fullScreen">
          <Header closeRoute={PageTypes.MAIN} title="FORTUNE WHEEL"/>
          <FortuneWheelDiv
              screenIsSmall={screenIsSmall}
          >
              <LeftPanel
                  history={history}
                  handleResetWheel={handleResetWheel}
                  handleSpinWheel={handleSpinWheel}
                  mode={mode}
                  currentItem={currentItem}
                  possibleItems={possibleItems}
                  finalItem={finalItem}
                  dispatch={dispatch}
                  tickets={tickets}
              />
              {(!screenIsSmall || (screenIsSmall && mode !== FortuneWheelTypes.DONE)) &&
                <>
                    <ItemTrackPanel
                        refTrainDiv={refTrainDiv}
                        possibleItems={possibleItems}
                        history={history}
                    />
                    <RightPanel
                        refCurrentItemContainer={refCurrentItemContainer}
                        refCrosshairText={refCrosshairText}
                        currentItem={currentItem}
                    />
                </>
              }
          </FortuneWheelDiv>
      </div>
    )
};

export default FortuneWheel;