import React from 'react';
import Equipt from './Equipt/Equipt';
import Hexagon from '../../styled/Hexagon';
import LootStats from "./LootStats/LootStats";
import { useSelector } from 'react-redux';
import LootOutfits from "./LootOutfits/LootOutfits";
import Avatar from "../../component/Avatar/Avatar";
import {color} from "../../data/format";
import palette from "../../styled/Palette";
import styled from 'styled-components';
import ItemTooltip from "../../component/Item/ItemTooltip";
import {statsSortAlphabetical} from "../../data/stats";
import {useIcon} from "../../data/icon";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const LootScreenLeftBodyDiv = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
`;
const LootScreenLeftBodyStatCompare = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.base3};
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: .98;
    color: ${palette.base4};
`;
const LootScreenLeftBodyAccessoryInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding: 4em;
    
    position: absolute;
    background-color: ${palette.base3};
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: .98;
    color: ${palette.base4};
`;
const AccessoryInfoName  = styled.div`
    font-size: 3em;
    text-align: center;
    margin-bottom: .5em;
`;
const AccessoryInfoDescription  = styled.div`
    margin-bottom: .5em;
    color: ${palette.peterRiver};
    font-style: italic;
`;
const AccessoryInfoExtended  = styled.div`
    font-size: 1.5em;
    color: ${palette.belizeHole};
`;

const LootScreenLeftItemFocusOverlay = ({self,loot,theme}) => {
    const focusItem = loot.item;

    if (!focusItem) {
        // User is not hovering over the slot, don't show the overlay
        return null;
    }
    if (focusItem.userItemId === self.outfit[focusItem.category]?.userItemId) {
        // User is hovering over the currently equipped item, don't show the overlay
        return null
    }

    if (focusItem.category === 'accessory') {
        return (
            <LootScreenLeftBodyAccessoryInfo>
                <AccessoryInfoName>{focusItem.name}</AccessoryInfoName>
                <AccessoryInfoDescription>{focusItem.description}</AccessoryInfoDescription>
                <AccessoryInfoExtended>{focusItem.extended}</AccessoryInfoExtended>
            </LootScreenLeftBodyAccessoryInfo>
        )
    }
    if (focusItem.category === 'paint') {
        return (
            <LootScreenLeftBodyAccessoryInfo>
                <AccessoryInfoName>{focusItem.adjective} {focusItem.color}</AccessoryInfoName>
                <div style={{fontWeight: 'bold',minWidth: '10em'}}>{focusItem.adjective}</div>
                <div>{focusItem.adjectiveDefinition}</div>
                <div style={{fontWeight: 'bold',minWidth: '10em', marginTop: '1em'}}>{focusItem.color}</div>
                <div>{focusItem.colorDefinition}</div>
            </LootScreenLeftBodyAccessoryInfo>
        )
    }

    // skin / cap
    return (
        <LootScreenLeftBodyStatCompare>
            {self.outfit[focusItem.category] &&
            <ItemTooltip
                item={self.outfit[focusItem.category]}
                category={focusItem.category}
                statsOrdered={statsSortAlphabetical(self.outfit[focusItem.category].stats)}
            />
            }
            {useIcon('change',{fontSize:'5em',color: theme.interfaceColorBright, alignSelf: 'center'})}
            <ItemTooltip
                item={focusItem}
                category={focusItem.category}
                statsOrdered={statsSortAlphabetical(focusItem.stats)}
            />
        </LootScreenLeftBodyStatCompare>
    )
};

const LootScreenLeft = ({category, toggleCategory, history, demoColor, item}) => {
    const theme = useSelector(state => state.theme);
    const self = useSelector(state => state.self);
    const loot = useSelector(state => state.loot);
    const equippedPaint = self.outfit.paint;
    const { screenIsSmall } = UseWindowDimensions();

    return (
        <div style={{
            borderRight: '1px solid black',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 2,
            flexBasis: 0,
            backgroundColor: palette.base3,
            maxWidth: '750px'
        }}>
            <Equipt
                category={category}
                toggleCategory={toggleCategory.bind(this)}
            />
            <LootOutfits history={history}/>
            <LootScreenLeftBodyDiv>
                <LootScreenLeftItemFocusOverlay
                    theme={theme}
                    self={self}
                    loot={loot}
                />
                <Hexagon/>
                <div style={{position: 'absolute'}}>
                    <Avatar
                        equippedPaint={equippedPaint}
                        styles={{marginTop: '-3em'}}
                        size={'10em'}
                        hue={demoColor ? color(demoColor.colorHex,'light') : color(theme.interfaceColor, 'light')}
                        outfit={self.outfit}
                        starterColor={self.starterColor}
                        history={history}
                    />
                </div>
            </LootScreenLeftBodyDiv>
            {!screenIsSmall &&
            <LootStats focusItem={loot.item} />
            }
        </div>
    )
};

export default LootScreenLeft;
