import {useEffect} from "react";
import {keyTest, POSSIBLE_GAME_CONTROLS} from "../../gamepad/controller";
import {useSelector} from "react-redux";
import { sandbox, updateSandbox } from '../../system/physics/physics';
import {PageTypes} from "../../system/types";

export const playableScreen = [PageTypes.GAME_SELECT,PageTypes.MATCH,PageTypes.MAIN,PageTypes.OPTIONS,PageTypes.TUTORIAL,PageTypes.MAIN];

const InputManager = ({history}) => {
    const gamepad = useSelector(state => state.gamepad);
    const { roomId } = useSelector(state => state.match);

    // Initializes the previousControls
    let previousControls = {};
    POSSIBLE_GAME_CONTROLS.forEach(control => {
        previousControls = {
            ...previousControls,
            [control]: undefined
        }
    });

    const updateLobby = () => {
        // Applies the function from the singleton representing the game instance
        if (sandbox.teams.teamA[0]) {
            let controls = {};
            POSSIBLE_GAME_CONTROLS.forEach((control) => {
                controls[control] =  keyTest(control, null);
            });
            sandbox.teams.teamA[0].controls = controls;
        }
        if (sandbox.teams.teamB[0]) {
            let controls = {};
            POSSIBLE_GAME_CONTROLS.forEach((control) => {
                controls[control] =  keyTest(control, null);
            });
            sandbox.teams.teamB[0].controls = controls;
        }
        updateSandbox(sandbox);
        let ball = sandbox.ball;
        if (!ball.trail) {
            ball.trail = [];
        }
        let trailOpacity = Math.pow(ball.speed / 45, 6);
        if (trailOpacity > 0.8) trailOpacity = 0.8;
        ball.trail.unshift({
            x: ball.position.x,
            y: ball.position.y,
            opacity: trailOpacity
        });
        if (ball.trail.length > 30) {
            ball.trail.pop();
        }
    };

    const pauseLobby = () => {
        // Don't update the game if we're not on a playable screen
        playableScreen.includes(history.location.pathname);
        if (!playableScreen) {
            return true;
        }
        // Don't update the game if the user is navigating with the gamepad
        if (gamepad.connected && gamepad.navigating) {
            return true;
        }
        return false;
    };

    // The function that runs each game tick
    const updateGame = () => {
        if (pauseLobby()) {
           return null;
        }
        if(roomId === 'lobby'){
            updateLobby()
        }
    };

    /**
     * This is how the game is rendered via proxy to the singleton
     */
    let updateGameInterval;
    useEffect(() => {
        updateGameInterval = setInterval(updateGame, 1000/60);
        return () => {
            clearInterval(updateGameInterval);
        }
    }, [roomId,history,gamepad]);

    return null;
};

export default InputManager;