import React, {Fragment, useRef, useState} from "react";
import LootItemsFilter from "./LootItemsFilter";
import Items from "../Items/Items";
import LootItemsInfo from "./LootItemsInfo/LootItemsInfo";
import LineLabel from "../../../component/LineLabel/LineLabel";
import {requestChangeLoadout, requestEquipItem} from "../../../system/endpoints/master";
import ItemSlot from "../../../component/Item/ItemSlot";

const LootItems = (props) => {
    // This belongs here because Items should remain a pure component
    const handleSelectItem = (e,item) => {
        const isSlotted = item.slot;

        if (props.sellMode) {
            props.handleToggleSellWindow(item);
            return;
        }

        if (item.category === 'accessory') {
            if (isSlotted) {
                // Request to unequip the item
                requestChangeLoadout(null,item.slot);
            } else {
                // Equipping an item is a two click interaction for the accessory screen
                props.setAccessorySelected(item.userItemId);
            }
        } else if (item.userItemId) {
            requestEquipItem(item.userItemId);
        }
    };

    return (
        <Fragment>
            <LootItemsFilter
                shown={!['face','accessory'].includes(props.category)}

                history={props.history}
                setSellMode={props.setSellMode}
                sellMode={props.sellMode}

                sellWindow={props.sellWindow}
                category={props.category}
                itemNames={props.itemNames}

                setFilter={props.setFilter.bind(this)}
                filter={props.filter}
            />
            <LootItemsInfo category={props.category} history={props.history}/>
            <div style={{display: 'flex', flexGrow: 1, flexBasis: 0, flexDirection: 'column'}}>
                <LineLabel
                    styles={'MY ITEMS'}
                    orientation={'horizontal'}
                >
                    {`${props.category.toUpperCase()} ITEMS`}
                </LineLabel>
                <Items
                    history={props.history}
                    click={handleSelectItem}
                    tooltip={true}
                    filter={props.filter}
                    filtersOn={props.filtersOn}
                    setLootColorDemo={props.setLootColorDemo.bind(this)}
                    sellMode={props.sellMode}

                    handleToggleSellWindow={props.handleToggleSellWindow.bind(this)}

                    activeItem={props.activeItem}
                    category={props.category}
                    itemsFiltered={props.itemsFiltered}
                />
            </div>
        </Fragment>
    )
};

export default LootItems;