
import React from "react";
import Badges from "../../../component/Badges/Badges";
import Avatar from "../../../component/Avatar/Avatar";
import {color} from "../../../data/format";
import loader from "../../../images/load2.svg";

const ProfileAvatar = ({ stats, history, profile, starterColor }) => {
    const equippedPaint = profile?.outfit?.paint;

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center',
            width: '100%',
            height: '160px',
            flexGrow: 2
        }}>
            <div style={{display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '2em', top: '2em', width: '100%'}}>
                <Badges
                    profile={profile}
                    stats={stats}
                />
            </div>
            <div>
                {
                    profile.outfit ?
                        <Avatar
                            equippedPaint={equippedPaint}
                            size={'8em'}
                            hue={color(profile.outfit.paint.colorHex,'light')}
                            outfit={profile.outfit}
                            starterColor={starterColor}
                            history={history}
                        />
                        :
                        <img
                            style={{width: '2em'}}
                            src={loader}
                            alt="Loading"
                        />
                }
            </div>
        </div>
    );
};

export default ProfileAvatar;