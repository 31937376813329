import {ballInitialState} from "../redux/modules/match/matchInitialState";

/**
 * These are globals which we do not want in the redux store.
 * 1. They update so frequently it would hog the redux debugger.
 * 2. It actually causes a latency issue from updating redux so frequently.
 */
const HOOP_TALL = 10;
const HOOP_DIAMETER = 130;
const RIM_WIDTH = 15;
export const REGULAR_FLOOR_COLOR = '#bdbdbd';
const matchScopeInitialState = {
    ball: JSON.parse(JSON.stringify(ballInitialState)),
    teams: {
        teamA: [],
        teamB: []
    },
    ballCrit: [],
    powerShot: [],
    tickCounter: 0,
    netHeight: 250,
    hoopDiameter: HOOP_DIAMETER,
    hoopHeight: 575,
    hoopSize: HOOP_DIAMETER + RIM_WIDTH + RIM_WIDTH,
    hoopTall: HOOP_TALL,
    innerPlatformHeight: HOOP_TALL / 1.4,
    rimWidth: RIM_WIDTH,
    floorColor: REGULAR_FLOOR_COLOR,
    trail: [],
    p1Score: 0,
    p2Score: 0,
    abilityEffects: [],
    projectiles: [],
    matchId: crypto.randomUUID()
};

export function getMatchId() {
    return matchScope.matchId;
}

export function resetMatchId() {
    matchScope.matchId = crypto.randomUUID();
} 


export const matchScopeReset = () => {
    matchScope.ball = JSON.parse(JSON.stringify(ballInitialState));
    matchScope.teams.teamA =  [];
    matchScope.teams.teamB = [];
    matchScope.ballCrit = [];
    matchScope.powerShot = [];
    matchScope.tickCounter = 0;
    matchScope.netHeight = 250;
    matchScope.hoopDiameter = HOOP_DIAMETER;
    matchScope.hoopHeight = 575;
    matchScope.hoopSize = HOOP_DIAMETER + RIM_WIDTH + RIM_WIDTH;
    matchScope.hoopTall = HOOP_TALL;
    matchScope.innerPlatformHeight = HOOP_TALL / 1.4;
    matchScope.rimWidth = RIM_WIDTH;
    matchScope.floorColor = REGULAR_FLOOR_COLOR;
    matchScope.trail = [];
    matchScope.p1Score = 0;
    matchScope.p2Score = 0;
    matchScope.abilityEffects = [];
    matchScope.projectiles = [];
    matchScope.matchId = crypto.randomUUID();
};
let matchScope = {
    ...matchScopeInitialState
};

export default matchScope;