import React from "react";
import slimeWizard from "../../images/avatars/slimeWizard.png";
import slimeMeteorologist from "../../images/avatars/slimeMeteorologist.png";
import {AvatarTypes} from "../../system/types";


const DialogueAvatar = ({dialogueIndex, avatar}) => {
    const avatarImageSrc = () => {
      if (avatar === AvatarTypes.WIZARD) {
          return slimeWizard;
      } else if (avatar === AvatarTypes.METEOROLOGIST) {
          return slimeMeteorologist;
      }
    };

    return (
        <img
            src={avatarImageSrc()}
            className={dialogueIndex === 0 ? 'animated slideInUp' : null}
            style={{
                width: '100%',
            }}
        />
    )
};

export default DialogueAvatar;