import React, { useState, useEffect, useRef } from 'react';
import Header from '../../component/Header/Header';
import HighscoreOptions from './HighscoreOptions';
import {TabDiv, TabsContainerDiv} from '../../styled/Tabs';
import bg from "../../images/repeater.png";
import loader from "../../images/load2.svg";
import palette from '../../styled/Palette';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { playSound } from '../../data/sound';
import { masterSocket } from "../../redux/middleware/wsMaster";
import {useDispatch, useSelector} from "react-redux";
import {getDivision} from "../../data/rating";
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import {
    HighscoresDiv,
    RankDiv,
    ScoreDiv,
    NameDiv,
    UserDiv,
    RankRowDiv,
    RowsHeaderDiv,
    RowsTableDiv,
    RowsBodyDiv, HighscoresFindUserInput,
} from './HighscoresStyle';
import {PageTypes} from "../../system/types";

const Highscores = (props) => {
    const dispatch = useDispatch();
    const { username } = useSelector(state => state.self);

    const [tabNameSport, setTabNameSport] = useState('volley');
    const [hiscoreData, setHiscoreData] = useState({
        volley: [],
        soccer: [],
        hoops:  []
    });
    const [rankIndex, setRankIndex] = useState(1);

    const refHiscoreFindUserInput = useRef();

    const toggleTabSport = (tab) => {
        //Prevent users from spamming the same button by checking if the user already has that tab open
        if (tabNameSport !== tab) {
            playSound('pressButton', 1);
            setTabNameSport(tab);
        }
    };

    const getPlayerRank = (user) => {
        masterSocket.send(JSON.stringify({
            'event': 'getPlayerRank',
            'username': user ? user : username,
            'sport': tabNameSport,
        }));
    };

    const getRankedRows = (direction) => {
        if (direction) {
            if (direction === 'up') {
                if (rankIndex > 1) {
                    masterSocket.send(JSON.stringify({
                        'event': 'getRankedRows',
                        'index': rankIndex - 10,
                        'sport': tabNameSport,
                    }));
                }
            } else if (direction === 'down') {
                masterSocket.send(JSON.stringify({
                    'event': 'getRankedRows',
                    'index': rankIndex + 10,
                    'sport': tabNameSport,
                }));
            }
        } else {
            // Init path
            masterSocket.send(JSON.stringify({
                'event': 'getRankedRows',
                'index': 1,
                'sport': tabNameSport,
            }));
        }
    };

    const renderTabs = () => {
        const marketTabs = ['volley','soccer','hoops'];
        return (
            <TabsContainerDiv>
                {
                    marketTabs.map((tab) => {
                        return (
                            <TabDiv
                                tabIndex={0}
                                key={tab}
                                active={tabNameSport === tab}
                                onClick={() => toggleTabSport(tab)}
                            >
                                {tab.toUpperCase()}
                            </TabDiv>
                        )
                    })
                }
            </TabsContainerDiv>
        );
    };

    useEffect(() => {
        dispatch(drawerToggle({open: true}));
    }, []);

    useEffect(() => {
        getRankedRows();
        const handleButtonEvent = event => {
            let msg = JSON.parse(event.data);
            if (msg.event === 'returnRanks') {
                setHiscoreData(prevState => ({
                    ...prevState,
                    [tabNameSport]: msg.ranks
                }));
                setRankIndex(msg.ranks[0].rank);
            }
        };
        masterSocket.addEventListener('message', handleButtonEvent);
        return () => {
            masterSocket.removeEventListener('message', handleButtonEvent);
        }
    },[tabNameSport]);

    const hiscoreDataLoaded = hiscoreData.volley.length === 0;
    const lookupValue = refHiscoreFindUserInput.current ? refHiscoreFindUserInput.current.value.toLowerCase() : '';

    const handleViewPlayerProfile = (user) => {
        props.history.push({pathname: PageTypes.FEATS, state: {username: user.username,}});
    };

    return (
        <HighscoresDiv className="fullScreen animatedFast slideInRight">
            <Header closeRoute={PageTypes.MAIN} title="HISCORES"/>
            <RowsTableDiv
                className="panBackdrop"
                style={{backgroundImage: `url(${bg})`, backgroundRepeat: 'repeat'}}
            >
                <div style={{display: 'flex'}}>
                    {hiscoreDataLoaded ?
                        <div style={{padding: '10px', backgroundColor: palette.base2, borderRadius: '5px'}}>
                            <img style={{color: 'white', maxWidth: '75%'}} src={loader} alt="Loading"/>
                        </div>
                        :
                        <>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1
                            }}>
                                {renderTabs()}
                                <div style={{
                                    display: 'flex',
                                    height: '100%'}}
                                >
                                    <div>
                                        <RowsHeaderDiv>
                                            <RankDiv>RANK</RankDiv>
                                            <NameDiv>USER</NameDiv>
                                            <ScoreDiv>RATING</ScoreDiv>
                                        </RowsHeaderDiv>
                                        <RowsBodyDiv>
                                            {
                                                hiscoreData[tabNameSport].map((user) => {
                                                    return (
                                                        <RankRowDiv key={user.username}>
                                                            <RankDiv>
                                                                #{user.rank}
                                                            </RankDiv>
                                                            <UserDiv
                                                                tabIndex={0}
                                                                onClick={() => handleViewPlayerProfile(user)}
                                                                className="flexTruncate"
                                                                isUser={user.username === username || user.username.toLowerCase() === lookupValue}
                                                            >
                                                                {user.rank === 1 && <FontAwesomeIcon style={{marginRight: '10px', fontSize: '16px', color: palette.sunFlower}} icon={faCrown} />}
                                                                {user.rank === 2 && <FontAwesomeIcon style={{marginRight: '10px', fontSize: '16px', color: palette.silver}} icon={faCrown} />}
                                                                {user.rank === 3 && <FontAwesomeIcon style={{marginRight: '10px', fontSize: '16px', color: '#CD7F32'}} icon={faCrown} />}
                                                                {user.username}
                                                            </UserDiv>
                                                            <ScoreDiv>
                                                                <img
                                                                    src={getDivision(user.score).image}
                                                                    alt="player rank"
                                                                    style={{ width: '30px', marginRight: '.25em' }}
                                                                />
                                                                {user.score}
                                                            </ScoreDiv>
                                                        </RankRowDiv>
                                                    )
                                                })
                                            }
                                        </RowsBodyDiv>
                                    </div>
                                    <HighscoreOptions
                                        refHiscoreFindUserInput={refHiscoreFindUserInput}
                                        username={username}
                                        getRankedRows={getRankedRows}
                                        getPlayerRank={getPlayerRank}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </RowsTableDiv>
        </HighscoresDiv>
    )
};

export default Highscores;