import React from 'react';
import { toggleUserInput } from "../../data/generic/generic";
import {match, updateMatchStatus, updateTeams, updateMatchScore, toggleCenterWall, updateTimeLeft, updateBets} from "../modules/match/matchActions";
import {gameSocket} from "./wsMatch";
import matchScope, {matchScopeReset, REGULAR_FLOOR_COLOR} from "../../data/matchScope";
import {
    changeColorCanvas,
    createPlayerItemImage,
    createPlayerFaceImages,
	invertCanvas,
	invertItemAsset,
    renderSlimePlayerImages,
} from '../../generateSlimePortrait';
import tinycolor from 'tinycolor2';
import {resetMatchChat, updateMatchChat} from '../modules/chat/chatActions';
import { delay } from '../../data/generic/generic';
import { color } from '../../data/format';
import { updateTeam } from '../modules/self/selfActions';
import { playSound } from '../../data/sound';
import { history } from '../../index';
import { imageAssets } from '../../data/images';
import {requestForfeit} from "../../system/endpoints/match";
import {censor} from "../../system/chat";
import {chatTypes} from "../../component/Chat/MatchChat";

/**
 * Resets the game socket and returns the user to the main screen
 */
export const leaveGame = (teams) => {
    return (dispatch) => {
        if (gameSocket) {
            requestForfeit();
            gameSocket.close();
        }
        // Reset the variables existing in match scope.
        matchScopeReset();

        //destroy existing div elements that follow players
        if (teams) {
            teams.teamA.concat(teams.teamB).forEach((player) => {
                if (player.chatElement) {
                    player.chatElement.remove();
                }
            });
        }

        // Reset any team that the player may be associated with
        dispatch(
            updateTeam({
                team: null,
                teamIndex: null,
            })
        );

        dispatch(
            match({
                type: 'MATCH',
                payload: {
                    roomId: 'lobby',
                    sessionId: null
                },
            })
        );
    };
};

/**
 * This happens when the user connects to a new match game socket
 */
export const resetMatch = () => {
    return (dispatch) => {
        // Reset any existing match chat
        dispatch(resetMatchChat(null));
        // Reset any existing match state
        dispatch(updateMatchStatus(null));
        // Reset the score if one was previously set
        dispatch(
            updateMatchScore({
                p1Score: 0,
                p2Score: 0,
            })
        );
    };
};

export const setBets = (bets) => {
    return (dispatch) => {
        dispatch(updateBets(bets));
    };
};

export const timeLeft = (timeLeft) => {
    return (dispatch) => {
        dispatch(updateTimeLeft(timeLeft));
    };
};

export const updateAbilityEffects = (message) => {
    return (dispatch, getState) => {
        matchScope.abilityEffects.push(message.abilityEffect);
    }
}

export const updatePositions = (message) => {
    return (dispatch, getState) => {
        matchScope.tickCounter = 0;
        matchScope.updateRate = 1000 / 60;
        matchScope.lastUpdate = Date.now();
        matchScope.serverTime = message.serverTime;

        //
        let newTeamA = [];
        let newTeamB = [];
        const updatePosition = (player, index, team) => {
            let newPlayerState = message.teams[team][index];
            let lastPosition = JSON.parse(JSON.stringify(matchScope.teams[team][index].position));
            let lastAngle = JSON.parse(JSON.stringify(matchScope.teams[team][index].angle));
            return {
                ...player,
                lastPosition: lastPosition,
                lastAngle: lastAngle,
                direction: newPlayerState.direction,
                emote: newPlayerState.emote,
                position: JSON.parse(JSON.stringify(newPlayerState.position)),
                angle: newPlayerState.angle,
                abilities: newPlayerState.abilities,
                activeAbility: newPlayerState.activeAbility,
                scale: newPlayerState.scale
            };
        };
        matchScope.teams.teamA.forEach((player, index) => {
            newTeamA.push(updatePosition(player, index, 'teamA'));
        });
        matchScope.teams.teamB.forEach((player, index) => {
            newTeamB.push(updatePosition(player, index, 'teamB'));
        });
        matchScope.teams = {
            teamA: newTeamA,
            teamB: newTeamB,
        };

        //
        let trail = [...matchScope.trail];
        let trailOpacity = Math.pow(message.ball.speed / 45, 6);
        if (trailOpacity > .8) trailOpacity = .8;
        trail.unshift({
            x: message.ball.position.x,
            y: message.ball.position.y,
            opacity: trailOpacity
        });
        if (trail.length > 30) {
            trail.pop();
        }

        matchScope.projectiles = message.projectiles;

        //
        let lastPosition = JSON.parse(JSON.stringify(matchScope.ball.position));
        matchScope.ball = {
            ...matchScope.ball,
            lastPosition: lastPosition, //ball.lastPosition = JSON.parse(JSON.stringify(ball.position));
            position: message.ball.position,
            speed: message.ball.speed,
            velocity: message.ball.velocity,
        };
        matchScope.trail = trail;
    };
};

export const initPlayer = (player) => {
	let alternativeAssets = {}
    let assets = imageAssets;
    let addItemImage = (item) => {
        if (item != null) {
            let category = item.category.charAt(0).toUpperCase() + item.category.slice(1);
            let asset = assets[category][item.name];
			let image = createPlayerItemImage(asset, tinycolor(item.colorHex).toRgb());
            return {
				...item,
                image
            };
        }
    };
    let outfit = JSON.parse(JSON.stringify(player.outfit));
    outfit.skin = addItemImage(outfit.skin);
    outfit.cap = addItemImage(outfit.cap);
    if(!outfit.face){
        outfit.face = {
            name: 'Default',
            category: 'face',
        }
    }
    if (outfit.face) outfit.face.images = createPlayerFaceImages(assets['Face'][outfit.face.name]);
    let powerShot = [];
    assets['Other']['Power Shot'].forEach((el) => {
        powerShot.push({
            base: changeColorCanvas(el.base, tinycolor(player.colorHex).toRgb()),
            outline: el.outline,
        });
    });
	let base = changeColorCanvas(
		assets['Other']['Slime']['base'],
		tinycolor(player.colorHex).toRgb()
	);
	let glow = changeColorCanvas(
		assets['Other']['Slime']['glow'],
		tinycolor(player.colorHex).toRgb()
	);
    let renderedImages = renderSlimePlayerImages(base,outfit);

    if(player.abilityNames.includes('ghost')){
		alternativeAssets.ghost = {
			outfit: {
				skin: outfit.skin != null ? invertItemAsset(outfit.skin.image): null,
				cap: outfit.cap != null ? invertItemAsset(outfit.cap.image): null,
				face: {}
			},
			base: invertItemAsset(base),
			glow: invertItemAsset(glow),
			powerShot: [],
            renderedImages: {
                'left': {},
                'right': {}
            }
		}
		for(let face in outfit.face.images){
            alternativeAssets.ghost.renderedImages['left'][face] = invertItemAsset(renderedImages['left'][face]);
            alternativeAssets.ghost.renderedImages['right'][face] = invertItemAsset(renderedImages['right'][face]);
			alternativeAssets.ghost.outfit.face[face] = invertItemAsset(outfit.face.images[face]);
		}
		powerShot.forEach((frame, index) =>{
			alternativeAssets.ghost.powerShot.push({
				base: invertItemAsset(powerShot[index].base),
				outline: powerShot[index].outline
			});
		});
	}
    player = {
        ...player,
        outfit: outfit,
        powerShot: powerShot,
        disconnectTime: Date.now(),
        base,
		alternativeAssets,
        glow,
        phased: false,
        latestPosition: {
            x: -400,
            y: -400,
        },
        lastPosition: {
            x: -400,
            y: -400,
        },
        position: {
            x: -400,
            y: -400,
        },
        msgPosition: {
            x: -400,
            y: -400,
        },
        latestAngle: 0,
        msgAngle: 0,
        emote: 'idle',
        renderedImages
    };
    return player;
};

export const generatePlayers = (message) => {
    return (dispatch) => {
        let assets = imageAssets;
        // Store the generated players
        let newTeamA = [];
        let newTeamB = [];
        message.teams.teamA.forEach((player) => {
            newTeamA.push(initPlayer(player));
        });
        message.teams.teamB.forEach((player) => {
            newTeamB.push(initPlayer(player));
        });
        message.teams = {
            teamA: newTeamA,
            teamB: newTeamB
        };

        toggleUserInput(true);

        // Update the reference to teams on the redux store (yes we need this data info)
        dispatch(updateTeams(message.teams));

        // Update the reference for the canvas
        matchScope.teams = message.teams;
        matchScope.gameMode = message.sport;
    };
};

export const handlePointScore = (message) => {
    return (dispatch) => {
        // Wait to fire the score update at the end
        const updateScore = (message) => {
            // Update both the redux store
            dispatch(updateMatchScore({
                p1Score: message.p1Score,
                p2Score: message.p2Score
            }));
            // and the matchScope with the data change
            matchScope.p1Score = message.p1Score;
            matchScope.p2Score = message.p2Score;
        };
        // Determine the scoring team
        const teamThatScored = () => {
            const previousP1Score = matchScope.p1Score.valueOf();
            const previousP2Score = matchScope.p2Score.valueOf();
            if (message.p1Score > previousP1Score) {
                return 'left';
            }
            if (message.p2Score > previousP2Score) {
                return 'right';
            }
            return new Error('The updateScore event fired but no team earned any points.');
        };
        // Show the user feedback for the scoring
        animateFloor(teamThatScored());
        // Update the score
        updateScore(message);
    }
};

/**
 * When a point is scored, the floor flashes the color of the team that earned the point.
 * If there are multiple players on each team than the floor flashes white.
 */
const animateFloor = (pointEarner) => {
    const singlesMatch = matchScope.teams.teamA.length === 1;
    const earnerColor = pointEarner === 'left' ? matchScope.teams.teamA[0].colorHex : matchScope.teams.teamB[0].colorHex;
    const floorColor = singlesMatch ? color(earnerColor, 'dim') : '#FFF';
    const colorChange = async () => {
        matchScope.floorColor = floorColor;
        await delay(250);
        matchScope.floorColor = REGULAR_FLOOR_COLOR;
        await delay(250);
        matchScope.floorColor = floorColor;
        await delay(250);
        matchScope.floorColor = REGULAR_FLOOR_COLOR;
        await delay(250);
        matchScope.floorColor = floorColor;
        await delay(250);
        matchScope.floorColor = REGULAR_FLOOR_COLOR;
    };
    colorChange().then(r => {
    });
};

export const handleToggleWall = (enabled) => {
    return (dispatch) => {
        if (enabled) {
            dispatch(toggleCenterWall(true));
        } else {
            playSound('disable', 1);
            dispatch(toggleCenterWall(false));
            setTimeout(() => {
                dispatch(toggleCenterWall(true));
                setTimeout(() => {
                    dispatch(toggleCenterWall(false));
                }, 250);
            }, 250);
        }
    }
};

export const publishDropToChat = (message) => {
    return (dispatch) => {
        dispatch(updateMatchChat({
            chatType: chatTypes.drop,
            ...message
        }));
    }
};