import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import palette from "../../styled/Palette";
import { ConsoleClosedButton } from "../ServerConsole/ServerConsoleStyle";
import {devMode, sports} from "../../data/globals";
import { PageTypes } from "../../system/types";
import MockPresent from "../../test/MockPresent";
import MockDrop from "../../test/MockDrop";
import {randomItem} from "../../utility";
import MockWin from "../../test/MockWin";

// Styled component for the screen skip button
export const ScreenSkipButton = styled(ConsoleClosedButton)`
  position: absolute;
  bottom: 0.5em;
  right: 2em;
  font-size: 2em;
  color: ${palette.alizarin};
  z-index: 99999;
`;

const ScreenSkip = ({ history, specificScreen }) => {
    const dev = useSelector((state) => state.dev);
    const pageTypesArray = Object.values(PageTypes);
    const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
    const dispatch = useDispatch();

    // Function to navigate to a specific screen index
    const navigateToScreen = (index) => {
        const targetScreen = pageTypesArray[index];

        // Handle special cases for navigation
        if (targetScreen === PageTypes.GAME_SELECT) {
            history.push({ pathname: PageTypes.GAME_SELECT, state: { mode: "casual" } });
        } else if (targetScreen === PageTypes.PRESENT) {
            MockPresent(dispatch);
        } else if (targetScreen === PageTypes.DROP) {
            MockDrop(dispatch);
        } else if (targetScreen === PageTypes.RANK_UP) {
            history.push({
                pathname: PageTypes.RANK_UP,
                state: {
                    promotion: true,
                    sport: randomItem(sports),
                    word: 'Kickbox',
                    division: 'II',
                    rank: 'Kiwi'
                }
            });
        } else if (targetScreen === PageTypes.WIN) {
            MockWin(dispatch, history);
        } else {
            history.push(targetScreen);
        }

        setCurrentScreenIndex(index);
    };

    // Function to handle the button click or "Enter" key
    const handleNavigation = () => {
        if (specificScreen) {
            const targetIndex = pageTypesArray.indexOf(specificScreen);
            if (targetIndex !== -1) {
                navigateToScreen(targetIndex);
                return;
            }
        }
        // Default to navigating to the next screen in sequence
        const nextIndex = (currentScreenIndex + 1) % pageTypesArray.length;
        navigateToScreen(nextIndex);
    };

    // Render the skip button in dev mode
    if (devMode) {
        return (
            <ScreenSkipButton onClick={handleNavigation}>
                <FontAwesomeIcon icon={["fas", "arrow-alt-right"]} />
            </ScreenSkipButton>
        );
    }

    return null;
};

export default ScreenSkip;
