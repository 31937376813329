import {keyTest} from "../../../../gamepad/controller";

const Base = (player,context,lerpX,lerpY,lerpAngle,size,team,teamIndex) => {
	let glow = player.glow
	if(player.alternativeAssets[player.activeAbility]){
		glow = player.alternativeAssets[player.activeAbility].glow;
	}
    //DRAW BASE SLIME
    context.translate(lerpX, lerpY);
    context.rotate(lerpAngle);

    let playerImage = player.renderedImages[player.direction][player.emote];
	if(player.alternativeAssets[player.activeAbility]){
		playerImage = player.alternativeAssets[player.activeAbility].renderedImages[player.direction][player.emote];
	}
    context.drawImage(playerImage, -360 / 2 * size / 2, -200 / 2 * size / 2 - Math.ceil(7 * size), 360 / 2 * size, 200 / 2 * size);

    if (keyTest('shift')
        && team === player.team && teamIndex === player.teamIndex)
        context.drawImage(glow, -423 / 2 * size / 2, -260 / 2 * size / 2 - Math.ceil(7 * size), 423 / 2 * size, 260 / 2 * size);
    context.rotate(-lerpAngle);
    context.translate(-lerpX, -lerpY);
};

export default Base;