import React, {useEffect, useState} from 'react';
import {
    FooterDiv, ModesDiv
} from './GameSelectStyle';
import QueueButton from "../../component/QueueButton/QueueButton";
import ButtonMode from "../../component/Match/ButtonMode";
import {useDispatch, useSelector} from 'react-redux';
import ModalServerSelect from "../../component/Modal/Type/ModalServerSelect";
import {selectGameModesTitles} from "../../redux/modules/queue/queueSelectors";
import ModalPremiumBotToggle from "../../component/Modal/Type/ModalPremiumBotToggle";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const GameSelectFooter = ({history, queueSettings,updateQueue,inQueue,modeIsSelected,leader,queueFunc,queue}) => {
    const dispatch = useDispatch();
    const acceptableServers = useSelector(state => state.socket.acceptableServers);
    const cooldown = useSelector(state => state.socket.cooldown);
    const isPremium = useSelector(state => state.self.premium);
    const theme = useSelector(state => state.theme);
    const { screenIsSmall } = UseWindowDimensions();
    const [firstRun, setFirstRun] = useState(true);
    const [regionModal, setRegionModal] = useState(true);


    const { volley, soccer, hoops} = useSelector(selectGameModesTitles);

    /**
     * Hack that hydrates the modal with the latest data
     */
    useEffect(() => {
        if (!firstRun && regionModal) {
            ModalServerSelect(dispatch,acceptableServers,cooldown);
        } else {
            setFirstRun(false);
        }
    }, [acceptableServers, cooldown.servers]);

    return(
        <FooterDiv style={{marginBottom: '5px'}}>
            <div style={{ display: 'flex', flexGrow: 1 }}>
                <ButtonMode
                    click={() => updateQueue('stats')}
                    mode={queueSettings.stats ? 'items on' : 'items off'}
                    icon={queueSettings.stats ? 'check-circle' : 'times-circle'} // Dynamically choose the icon
                    label={queueSettings.stats ? 'ITEMS ON' : 'ITEMS OFF'} // Dynamically set the label
                />
                <ButtonMode
                    click={() => {
                        if (!isPremium) {
                            ModalPremiumBotToggle(dispatch, theme, history);
                            setRegionModal(false);
                        } else {
                            updateQueue('bots');
                        }
                    }}
                    mode={queueSettings.bots ? 'with bots' : 'NO BOTS'}
                    icon={queueSettings.bots ? 'robot' : 'user-circle'} // Dynamically choose the icon
                    label={queueSettings.bots ? 'WITH BOTS' : 'NO BOTS'} // Dynamically set the label
                />
                <div style={{ flexGrow: 1 }}>
                    <ButtonMode
                        click={() => {
                            ModalServerSelect(dispatch, acceptableServers, cooldown);
                            setRegionModal(true);
                        }}
                        mode="set region"
                        icon="globe-americas" // Static icon
                        label="SET REGION" // Static label
                    />
                </div>
            </div>
            {!screenIsSmall &&
                <ModesDiv>
                    {volley &&
                    <div>Volley {volley}</div>
                    }
                    {soccer &&
                    <div>Soccer {soccer}</div>
                    }
                    {hoops &&
                    <div>Hoops {hoops}</div>
                    }
                </ModesDiv>
            }
            <QueueButton
                queue={queue}
                queueFunc={queueFunc}
                inQueue={inQueue}
                leader={leader}
                modeIsSelected={modeIsSelected}
            />
        </FooterDiv>
    )
};

export default GameSelectFooter;