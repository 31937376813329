import Proton from './proton';
import { updatePerFrame } from './protonConfig';
import { getSound } from '../../data/sound';
import { protonBackground } from './protonBackground';
import { webGLEnabled } from '../../data/webGLEnabled';

let freezeSound = new Audio(getSound('freeze').noise);
let dampener = getSound('freeze').dampener;
freezeSound.currentTime = 0;
freezeSound.volume = 1;
let fadeOutTime = 1000;
let fadeTime = 0;

let slowBallParticles = {};
slowBallParticles.enabled = false;
slowBallParticles.emitterLoaded = false;

let emitter = new Proton.Emitter();
emitter.rate = new Proton.Rate(new Proton.Span(0, 0), 1000);
emitter.addInitialize(new Proton.Mass(1));
emitter.damping = 0;
emitter.addInitialize(new Proton.Radius(10, 15));
emitter.addInitialize(new Proton.Life(0.2, 0.5));
emitter.addBehaviour(new Proton.Color('8888ff', 'aaaaaff'));
emitter.addBehaviour(new Proton.Scale(1, 6));
emitter.addBehaviour(new Proton.Alpha(0.6, 0, 'easeIn'));
emitter.addInitialize(
    new Proton.Velocity(new Proton.Span(0.5, 1), new Proton.Span(0, 360), 'polar')
);
emitter.emit();

//EMITTER
slowBallParticles.emitter = emitter;

//ENABLE
slowBallParticles.enable = () => {
    if (!slowBallParticles.enabled) {
        protonBackground.addEmitter(emitter);
        slowBallParticles.emitterLoaded = true;
        freezeSound.currentTime = 0;
        freezeSound.volume = 1 * dampener;
        freezeSound.play();
        slowBallParticles.enabled = true;
        slowBallParticles.emitter.rate = new Proton.Rate(4, updatePerFrame * 4);
    }
};

//DISABLE
slowBallParticles.disable = () => {
    if (slowBallParticles.enabled) {
        slowBallParticles.enabled = false;
        fadeTime = Date.now();
        emitter.rate = new Proton.Rate(0, 1000);
    }
    if (!freezeSound.paused || freezeSound.currentTime !== 0) {
        let volume = (1 - (Date.now() - fadeTime) / fadeOutTime) * dampener;
        if (volume < 0) {
            volume = 0;
			freezeSound.pause();
			freezeSound.currentTime = 0;
        }
        if (volume > 1) {
            volume = 1;
        }
        freezeSound.volume = volume;
    }
};

//UPDATE
slowBallParticles.update = (ball) => {
    if (slowBallParticles.enabled) {
        emitter.p.x = 0;
        emitter.p.y = 0;
        emitter.addInitialize(
            new Proton.Position(
                new Proton.CircleZone(ball.position.x, ball.position.y, ball.radius)
            )
        );
    }
    else {
        if(emitter.particles.length === 0 && slowBallParticles.emitterLoaded){
            slowBallParticles.emitterLoaded = false;
            protonBackground.removeEmitter(emitter);
        }
    }
};

export { slowBallParticles };
