import styled from 'styled-components';
import palette from "../../styled/Palette";

export const MetricsDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 .5em;
`;

export const MetricsHeaderDiv = styled.div`
    display: flex;
    font-size: 24px;
    font-family: Luckiest Guy;
    border-bottom: 1px solid ${palette.base2};
    text-align: left;
    padding: 0 5px;
`;

// MetricsStatsDiv remains as flexbox and allows it to grow and fill remaining space
export const MetricsStatsDiv = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;