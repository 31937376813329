import {FONT_BIG, FONT_NORMAL} from "../../data/font";
import palette from "../../styled/Palette";
import React, {useRef, useState} from "react";
import styled from 'styled-components';
import {CloseButtonSpan} from "../Header/HeaderStyle";
import {useIcon} from "../../data/icon";
import {HomeButtonDiv} from "../../page/Main/MainStyle";

export const InfoBannerDiv = styled.div`
    cursor: ${props => props.onClick ? 'pointer':'default'};
    width: 100%;
    border-radius: 3px;
    font-family: Montserrat;
    text-align: left;
    background-color: ${palette.base6};
    border: 2px solid ${palette.base7};
    padding: 2em;
    margin: 1em 0;
    font-size: 10px;
    color: ${palette.base4};
   
    transition-delay: 2s;
    transition: all .5s;
        
    &:hover {
        ${({ selectable }) => selectable ? `border: 2px solid ${palette.belizeHole};` : ''}
        ${({ selectable }) => selectable ? `background-color: ${palette.midnightBlue};` : ''}
    }

    ${({ styles }) => styles}
`;

export const InfoBanner = ({title,body,click,styles,canClose = true}) => {
    const [isClosed, setIsClosed] = useState(false);

    if (isClosed) {
        return null;
    }

    return (
        <InfoBannerDiv
            style={styles}
            onClick={click}
            selectable={!!click}
        >
            <div style={{justifyContent: 'space-between', display: 'flex', fontSize: FONT_BIG, fontWeight: 'bold', paddingBottom: '.5em'}}>
                <div>
                    {title}
                </div>
                {!click && canClose &&
                    <CloseButtonSpan
                        style={{zIndex: 5, fontSize: '.75em'}}
                        onClick={() => setIsClosed(true)}
                    >
                        {useIcon('exit')}
                    </CloseButtonSpan>
                }
            </div>
            <div style={{fontSize: FONT_NORMAL, color: palette.base2}}>
                {body}
            </div>
        </InfoBannerDiv>
    )
};

export default InfoBanner;