import styled from 'styled-components';
import palette from '../../styled/Palette';
import {shadow} from "../../data/globals";
import React from "react";

export const HighscoresDiv = styled.div`
    font-size: 30px;
    color: ${palette.base4};   
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

export const ButtonHeaderDiv = styled.div`
    font-weight: bold;
`;

export const RankButtonsDiv = styled.div`
    text-align: left;
    font-size: .5em;
    display: flex;
    flex-grow: 1;
`;

export const RowsTableDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const RowsHeaderDiv = styled.div`
    font-size: .5em;
    text-align: left;
    justify-content: center;
    width: 100%;    
    background-color: ${palette.base1};
    display: flex;
    padding: 30px 0 10px 0;
    font-weight: bold;
`;


export const RowsBodyDiv = styled.div`
    box-shadow: ${shadow};
     background: repeating-linear-gradient(
          45deg,
          ${palette.wetAsphalt},
          ${palette.wetAsphalt} 10px,
          ${palette.midnightBlue} 10px,
          ${palette.midnightBlue} 20px
        );
    padding: 20px;
    border: 1px solid ${palette.wetAsphalt};
    background-color: ${palette.wetAsphalt};
`;

export const RankRowDiv = styled.div`
    text-align: left;
    justify-content: center;
    display: flex;
    font-family: Luckiest Guy;
    letter-spacing: 2px;
`;

export const RankDiv = styled.div`
    position: relative;
    width: 150px;
`;

export const NameDiv = styled.div`
    width: 300px;
    padding-right: 20px;
`;

export const UserDiv = styled.div`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    
    color: ${props => props.isUser ? props.theme.interfaceColor : palette.base4};
    width: 300px;
    padding-right: 20px;
`;

export const ScoreDiv = styled.div`
    width: 150px;
`;

export const HighscoresFindUserInput = styled.input`
    padding: .25em .5em;
    border-radius: 2px;
    border: 1px solid ${palette.base2};
    margin-bottom: 1em;
    width: 100%;
    background-color: ${palette.base0};
    &:focus {
        border: 1px solid ${palette.base4};
    }
    padding: 1em;
`;
export const HighscoresButton = styled.button`
    color: ${palette.base4};
    justify-content: space-between;
    display: flex;  
    &:active {
        background-color: ${palette.midnightBlue};
    }
    font-weight: bold;
    text-align: left;
    padding: 1em;
`;
export const HighscoresOptionsDiv = styled.div`
    box-shadow: ${shadow};
    background-color: ${palette.base3};
    border-radius: 5px;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 100%;
`;


