import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createGrenadeExplosionParticles(position) {
    let grenadeExplosionEmitter = new Proton.Emitter();
    grenadeExplosionEmitter.rate = new Proton.Rate(webGLEnabled ? 70: 7, updatePerFrame);
    grenadeExplosionEmitter.addInitialize(new Proton.Mass(1));
    grenadeExplosionEmitter.addInitialize(new Proton.Radius(40, 35));
    grenadeExplosionEmitter.addInitialize(new Proton.Life(.8,1));
    grenadeExplosionEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    grenadeExplosionEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(0,12), new Proton.Span(0, 360), 'polar')
    );
    grenadeExplosionEmitter.addBehaviour(new Proton.Color(['777777','888888','666666','999999','aaaaaa','bbbbbb','cccccc']));
    grenadeExplosionEmitter.addBehaviour(new Proton.Gravity(-1));
    grenadeExplosionEmitter.addBehaviour(new Proton.Scale(1, 4));
    grenadeExplosionEmitter.addBehaviour(new Proton.Alpha(1, 0));
    grenadeExplosionEmitter.p.x = position.x;
	grenadeExplosionEmitter.p.y = position.y;
    grenadeExplosionEmitter.damping = 0.04;
    grenadeExplosionEmitter.emit('once', 1.1);
    protonBackground.addEmitter(grenadeExplosionEmitter);
}

export function createGrenadeTickParticles(room, id) {
    let grenadeTickEmitter = new Proton.Emitter();
    grenadeTickEmitter.rate = new Proton.Rate(webGLEnabled ? 16: 2, updatePerFrame);
    grenadeTickEmitter.addInitialize(new Proton.Mass(1));
    grenadeTickEmitter.addInitialize(new Proton.Radius(1, 3));
    grenadeTickEmitter.addInitialize(new Proton.Life(0.1, .3));
    grenadeTickEmitter.addBehaviour(new Proton.RandomDrift(5, 0, 0.05));
    grenadeTickEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-1, 1), new Proton.Span(-3, -6), 'vector')
    );
    grenadeTickEmitter.addBehaviour(new Proton.Color('ff0000', 'ffff00'));
    grenadeTickEmitter.addBehaviour(new Proton.Gravity(-1));
    grenadeTickEmitter.addBehaviour(new Proton.Scale(1, 1.5));
    grenadeTickEmitter.addBehaviour(new Proton.Alpha(0.8, 0));
    grenadeTickEmitter.damping = 0.04;
    grenadeTickEmitter.emit();
    grenadeTickEmitter.p.x = 0;
    grenadeTickEmitter.p.y = 0;
    protonBackground.addEmitter(grenadeTickEmitter);
    let interval = setInterval(() => {
        let projectile = room.projectiles.filter(projectile => projectile.id === id)[0];
        if(projectile){
            grenadeTickEmitter.p.x = projectile.position.x;
            grenadeTickEmitter.p.y = projectile.position.y;
        }
        else{
            grenadeTickEmitter.stop();
            setTimeout(()=>{
                protonBackground.removeEmitter(grenadeTickEmitter);
            },3000)
            clearInterval(interval);
        }
    }, 1000 / 60);
}
