import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { webGLEnabled } from '../../data/webGLEnabled';

export function createReverseBallParticles(ball) {
    let reverseBallEmitter = new Proton.Emitter();
    reverseBallEmitter.rate = new Proton.Rate(webGLEnabled ? 20 : 5, updatePerFrame);
    reverseBallEmitter.addInitialize(new Proton.Mass(1));
    reverseBallEmitter.addInitialize(webGLEnabled ? new Proton.Radius(20, 35) : new Proton.Radius(10, 15));
    reverseBallEmitter.addInitialize(new Proton.Life(1));
    reverseBallEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    reverseBallEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-2, 2), new Proton.Span(-2, 2), 'vector')
    );

    // reverseBallEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
    reverseBallEmitter.addBehaviour(new Proton.Color(['777777','888888','666666','999999','aaaaaa','bbbbbb','cccccc']));
    reverseBallEmitter.addBehaviour(new Proton.Gravity(-1));
    reverseBallEmitter.addBehaviour(new Proton.Scale(1, webGLEnabled ? 4: 2));
    reverseBallEmitter.addBehaviour(new Proton.Alpha(1, 0));
    reverseBallEmitter.addInitialize(
        new Proton.Position(
            new Proton.CircleZone(
                ball.position.x,
                ball.position.y,
                ball.radius
            )
        )
    );
    //reverseBallEmitter.damping = 0.04;
    reverseBallEmitter.emit('once', 2);

    protonBackground.addEmitter(reverseBallEmitter);
}
