import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LootOutfitSpan, LootOutfitsDiv} from "./LootOutfitsStyle";
import InputStandard from "../../../styled/Input";
import styled from 'styled-components';
import palette from "../../../styled/Palette";
import ModalPremiumOutfit from "../../../component/Modal/Type/ModalPremiumOutfit";
import {
    requestEquipItem,
    requestOutfitCreate,
    requestOutfitDelete,
    requestOutfitUpdate
} from "../../../system/endpoints/master";
import {useIcon} from "../../../data/icon";
import {updateOutfit} from "../../../redux/modules/self/selfActions";
import slotTypes from '../../../data/slotTypes';
import {equip} from "../../../data/item";

const OutfitLabelDiv = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${palette.midnightBlue};
        color: ${palette.base4};
        font-weight: bold;
        padding: 0 1em;
        border: 1px solid ${palette.base0};
        border-radius: 3px;
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        font-size: .65em;
    `;
/**
 * The active outfit is updated with the server every time the user alters a currently equipped item.
 */
const LootOutfits = ({history}) => {
    const theme = useSelector(state => state.theme);

    const dispatch = useDispatch();
    const { outfits, outfit, outfitActiveId, premium } = useSelector(state => state.self);

    const [creatingOutfit,setCreatingOutfit] = useState(false);
    const [newOutfitName,setNewOutfitName] = useState(null);

    const switchOutfit = (outfitId) => {
        // The master does not need to know about an outfit switch when a new outfit was just created.
        // The change handler calls switchOutfit, so we can validate for an id.
        const validId = !isNaN(parseInt(outfitId));

        if (validId) {
            requestOutfitUpdate(outfitId);
        }
    };
    const createOutfit = () => {
        requestOutfitCreate(newOutfitName);
        setCreatingOutfit(false);
        equipPreviousOutfit(outfit);
    };

    const deleteOutfit = () => {
        requestOutfitDelete(outfitActiveId);
    };
    const equipPreviousOutfit = (storedOutfit) => {
        // Wait for server to receive new outfit id change.
        setTimeout(() => {
            // Equip each of the items previous equipped.
            slotTypes.map(type => {
                if (storedOutfit[type]) {
                    requestEquipItem(storedOutfit[type].userItemId);
                }
            });
        }, 1000);
    };

    // When the outfits change, we assume the user created a new one, which must be equipped.
    useEffect(() => {
        if (newOutfitName) {
            switchOutfit(newOutfitName);
            setNewOutfitName(null);
        }
    }, [outfits]);

    return (
        <LootOutfitsDiv
            onClick={() => {
                if (!premium) {
                    ModalPremiumOutfit(dispatch,theme,history);
                }
            }}
        >
            <div style={{display: 'flex', flexGrow: 1}}>
                <OutfitLabelDiv>OUTFIT</OutfitLabelDiv>
                {!creatingOutfit &&
                <>
                    <select
                        onChange={(e) => switchOutfit(e.target.value)}
                        style={{margin: 0, flexGrow: 1}}
                        value={outfitActiveId}
                        disabled={!premium}
                    >
                        {
                            outfits.map(outfit => {
                                return (
                                    <option
                                        key={outfit.outfitId}
                                        value={outfit.outfitId}
                                    >
                                        {outfit.name}
                                    </option>
                                )
                            })
                        }
                    </select>
                    {(outfits.length < 20 && premium) &&
                    <LootOutfitSpan
                        onClick={() => setCreatingOutfit(true)}
                    >
                        {useIcon('create')}
                    </LootOutfitSpan>
                    }
                    {outfits.length > 1 &&
                    <LootOutfitSpan
                        skull={true}
                        onClick={() => deleteOutfit()}
                    >
                        {useIcon('kill')}
                    </LootOutfitSpan>
                    }
                </>
                }

                {creatingOutfit &&
                <>
                    <InputStandard
                        onChange={(e) => setNewOutfitName(e.target.value)}
                        placeholder={`Insert new outfit name`}
                        maxLength="32"
                        style={{margin: 0}}
                    />
                    <LootOutfitSpan onClick={() => createOutfit()}>
                        {useIcon('approve')}
                    </LootOutfitSpan>
                </>
                }
            </div>
        </LootOutfitsDiv>
    )
};

export default LootOutfits;