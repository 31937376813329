import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import tinycolor from 'tinycolor2';
import { getTierData } from '../../data/tier';
import { playSound } from '../../data/sound';
import {
    selfAddItem,
    updateItems
} from "../../redux/modules/self/selfActions";
import {
    drawerToggle
} from "../../redux/modules/socket/socketActions";
import Card from "../../component/Card/Card";
import { imageAssets } from '../../data/images';
import { changeColorCanvas }from '../../generateSlimePortrait';
import workerGetPortrait from "../../webworker/main";
import {
    PresentContainerDiv,
    PresentSmallContainerDiv,
    PresentSpinPhaseDiv,
} from './PresentStyle';
import {masterSocket} from "../../redux/middleware/wsMaster";
import palette from "../../styled/Palette";
import Dialogue from "../../component/Dialogue";
import {easing} from "../../data/generic/easing";
import {capitalize} from "../../data/generic/generic";
import Button, {STANDARD_BUTTON_WIDTH} from "../../styled/Button";
import ButtonStandard from "../../component/ButtonStandard/ButtonStandard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getRandomInt} from "../../data/format";
import {DialogueContainerDiv} from "../../component/Dialogue/DialogueStyle";
import {ItemCategoryTypes, PageTypes} from "../../system/types";
import DialoguePresent from "../../component/Dialogue/type/present";

const NUMBER_RANDOM_PRESENTS = 60;

function getRandomHex() {
    let initialColor = `#`;
    for (let x = 0; x < 3; x++) {
        initialColor += (Math.floor(16 * Math.random()).toString(16));
    }
    return initialColor;
}

const FlashBangDiv = styled.div`
    opacity: 0;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
`;

// todo: We want to implement a curve on the random present color so that its a bit more exciting
// todo: Fix the portrait not rendering the items
const Present = ({history}) => {
    const dispatch = useDispatch();
    const { presentItems, presentColorSet, outfitId } = useSelector(state => state.present);
    const [presentPortrait, setPresentPortrait] = useState(null);
    const [presentImage,setPresentImage] = useState(null);
    const [phase,setPhase] = useState('ready');
    const [equipButtonText,setEquipButtonText] = useState('EQUIP ALL');
    const [flashInterp,setFlashInterp] = useState(0);
    const [flash, setFlash] = useState(true);
    const [equipped, setEquipped] = useState(false);

    const [dialogueVisible, setDialogueVisible] = useState(true);

    const refPresent = useRef(null);
    const refFlashBang = useRef(null);

    const presentDisplayDuration = (index) => {
        /*
            I take the values > .5 and inverse them in order to vertically mirror the curve.
            This forms the parabolic line which represents the gradual interpolation. Without it,
            the easing function would return values approaching 1. Which would appear as a deceleration curve.
         */
        const t = index / NUMBER_RANDOM_PRESENTS;
        const curve = t > .5 ? t : 1 - t;
        const result = easing.easeCubicBezier(curve, 0,.1,0,1) * 300;
        return result;
    };

    const handleEquipAll = () => {
        playSound('selectItem',1);
        masterSocket.send(JSON.stringify({
            event: 'switchOutfit',
            outfitId: outfitId,
        }));
        setEquipButtonText('DONE');
    };
    const setPresentColor = (index) => {
        const FLASH_SEQUENCE_START = index === NUMBER_RANDOM_PRESENTS - 3;
        let canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;
        let boxCanvas;
        let ribbonCanvas;
        let gooCanvas;
        if (index === 0) {
            // First load
            boxCanvas = changeColorCanvas(imageAssets.Other.Gift.box, tinycolor('#ff1618').toRgb());
            ribbonCanvas = changeColorCanvas(imageAssets.Other.Gift.ribbon, tinycolor('#ffac00').toRgb());
            gooCanvas = changeColorCanvas(imageAssets.Other.Gift.goo, tinycolor('#0f5').toRgb());
        } else {

            if (FLASH_SEQUENCE_START) {
                // Kicks off the flash process
                setFlashInterp(.01);
            }
            if (index === NUMBER_RANDOM_PRESENTS) {
                // Spinning complete
                boxCanvas = changeColorCanvas(imageAssets.Other.Gift.box, tinycolor(presentItems[0].colorHex).toRgb());
                ribbonCanvas = changeColorCanvas(imageAssets.Other.Gift.ribbon, tinycolor(presentItems[1].colorHex).toRgb());
                gooCanvas = changeColorCanvas(imageAssets.Other.Gift.goo, tinycolor(presentItems[2].colorHex).toRgb());
                handleSpinCompletion();
            } else {
                // Spinning iteration
                boxCanvas = changeColorCanvas(imageAssets.Other.Gift.box, tinycolor(getRandomHex()).toRgb());
                ribbonCanvas = changeColorCanvas(imageAssets.Other.Gift.ribbon, tinycolor(getRandomHex()).toRgb());
                gooCanvas = changeColorCanvas(imageAssets.Other.Gift.goo, tinycolor(getRandomHex()).toRgb());

                setTimeout(() => {
                    setPresentColor(index + 1);
                }, presentDisplayDuration(index))
            }
            playSound(`blip`,1);
        }

        let ctx = canvas.getContext('2d');
        ctx.drawImage(boxCanvas, 0, 0);
        ctx.drawImage(ribbonCanvas, 0, 0);
        ctx.drawImage(gooCanvas, 0, 0);
        ctx.drawImage(imageAssets.Other.Gift.outline, 0, 0);
        setPresentImage(canvas.toDataURL());
    };

    const handleSpin = () => {
        playSound('pullSlots',1);
        setTimeout(() => {
            setPresentColor(1);
        }, 500);
        setDialogueVisible(false);
    };
    const handleSpinCompletion = () => {
        refPresent.current.classList.add('animated');
        refPresent.current.classList.add('rubberBand');
        playSound('slotsWin', 1);
        setTimeout(() => {
            setPhase('avatar');
        }, 2000);
    };

    const flashIn = () => {
        if (flashInterp >= 1) {
            setFlash(false);
        }
        setFlashInterp(flashInterp + .01);
    };
    const flashOut = () => {
        setFlashInterp(flashInterp - .01);
    };

    useEffect(() => {
        if (flashInterp > 0) {
            setTimeout(() => {
                // Load the current value visually
                refFlashBang.current.style.opacity = flashInterp;
                // Load the following value
                flash ? flashIn() : flashOut();
            }, 5);
        }
    }, [flashInterp]);

    useEffect(() => {
        dispatch(drawerToggle({open: false}));
        setPresentColor(0);
    }, []);

    /**
     * When the presentItems change, we generate an example avatar with the items equipped
     */
    useEffect(() => {
        if (presentItems.length > 0) {
            // Build an outfit object from the items in the payload
            const loadedOutfit = {
                accessory: null,
                cap: presentItems.filter(item => item.category.toLowerCase() === ItemCategoryTypes.CAP)[0],
                skin: presentItems.filter(item => item.category.toLowerCase() === ItemCategoryTypes.SKIN)[0],
                face: presentItems.filter(item => item.category.toLowerCase() === ItemCategoryTypes.FACE)[0],
                paint: presentItems.filter(item => item.category.toLowerCase() === ItemCategoryTypes.PAINT)[0],
            };
            // Store an image representation of the users avatar using the generated outfit
            workerGetPortrait(loadedOutfit).then(dataUrl => {
                setPresentPortrait(dataUrl);
            });

            dispatch(selfAddItem(presentItems));
            // Locate the users current instance of the face
            //const faceInstance = items['face'].filter(x => x.name === loadedOutfit.face.name)[0];
            // Update the instance of the face to be equiptable from the loot screen based on the locked attribute
            //faceInstance.locked = 0;
        }
    }, [presentItems]);

    return(
        <div
            className="animated fadeIn"
            style={{backgroundColor: palette.base5, width: '100%', display: 'flex', height: '100%', }}
        >
            <FlashBangDiv ref={refFlashBang} />
            <div style={{pointerEvents: 'none', width: '100%', height: '100%', position: 'absolute',
                backgroundColor: 'fefefe',
                zIndex: 2}}> </div>
            {phase === 'ready' &&
                <PresentSpinPhaseDiv className="animated slideInDown">
                    <PresentContainerDiv
                        ref={refPresent}
                        presentImage={presentImage}
                    >
                    </PresentContainerDiv>
                </PresentSpinPhaseDiv>
            }
            {phase === 'avatar' &&
                <div className="animated zoomIn" style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <div className="headsUpText" style={{display: 'flex', marginBottom: '10px'}}>
                        <div style={{color: getTierData(presentItems[1].tier).color}}>
                            {getTierData(presentItems[1].tier).name.toUpperCase()}
                        </div>
                        <div>
                            &nbsp;PRESENT
                        </div>
                    </div>
                    <div style={{fontFamily: 'Indie Flower', fontSize: '25px', marginBottom: '20px', color: 'white'}}>{capitalize(presentColorSet)} color set!</div>
                    <div style={{display: 'flex'}}>
                        <div><img src={presentPortrait} style={{width: '350px'}} /></div>
                        <PresentSmallContainerDiv presentImage={presentImage}> </PresentSmallContainerDiv>
                    </div>
                    <div style={{display: 'flex', marginTop: '40px'}}>
                        <Button
                            click={() => history.push(PageTypes.CASH_SHOP)}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                            title={'Cash Shop'}
                            icon={<FontAwesomeIcon
                                style={{marginRight: '.5em'}}
                                icon={['fas', 'arrow-alt-left']} />}
                        />
                        <Button
                            click={() => setPhase('reward')}
                            title={'View Items'}
                            color={'green'}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                        />
                    </div>
                </div>
            }
            {phase === 'reward' &&
                <div className="animated zoomIn" style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <div style={{color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {presentItems.map((item, index) => {
                            //normalize
                            item = {
                                ...item,
                                category: item.category.toLowerCase()
                            };
                            //set backdrop
                            let backgroundColor = 'rgba(40,40,40,1)';
                            if (item.category === 'paint') backgroundColor = item.colorHex;
                            const shouldNotFlip = (['paint','face','accessory'].includes(item.category));
                            return (
                                <Card
                                    key={index}
                                    disabled={shouldNotFlip}
                                    index={index}
                                    flipped={false}
                                    item={item}
                                    history={history}
                                    backgroundColor={backgroundColor}
                                />
                            )
                        })}
                    </div>
                    <div style={{display: 'flex', paddingTop: '3em'}}>
                        <Button
                            tabIndex={0}
                            click={() => history.push(PageTypes.CASH_SHOP)}
                            title={'Cash Shop'}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                            icon={<FontAwesomeIcon
                                style={{marginRight: '.5em'}}
                                icon={['fas', 'arrow-alt-left']} />}
                        />
                        <Button
                            tabIndex={0}
                            click={() => {
                                handleEquipAll();
                                setEquipped(true);
                                document.activeElement.blur();
                                playSound('selectItem',1);
                            }}
                            title={!equipped ? 'Equip Set Now' : 'Success'}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                            icon={<FontAwesomeIcon
                                style={{marginRight: '.5em'}}
                                icon={['fas', !equipped ? 'link' : 'check']}
                            />}
                            isDisabled={equipped}
                        />
                    </div>
                </div>
            }
            <DialoguePresent
                dialogueVisible={dialogueVisible}
                handleSpin={handleSpin}
                history={history}
            />
        </div>
    )
};

export default Present;
