import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import SlimePan from "../styled/SlimePan";
import Header from "../component/Header/Header";
import Page from "../styled/Page";
import {animated, useSpring} from "react-spring";
import palette from "../styled/Palette";
import InfoBanner from "../component/InfoBanner/InfoBanner";
import {GAME_COST, shadow} from "../data/globals";
import {getTierData, tiers} from "../data/tier";
import {rank} from "../data/rating";
import TextUnderlineHover from "../styled/TextUnderlineHover";
import season from "../system/season";
import items from "../data/items";
import {
    RankImg,
    RankTextDiv
} from "../component/Modal/Type/ModalGameSelect";
import {ItemDetail} from "./Atlas/ItemDetail";
import Button from "../styled/Button";
import Icon, {useIcon} from "../data/icon";
import {CircleMountDiv} from "./Drop/style";
import {requestCashShopItemToken, requestSteamPurchase} from "../system/endpoints/master";
import isElectron from "is-electron";
import {useDispatch, useSelector} from "react-redux";
import {loadXsollaInterface} from "./Cash/CashItemView";
import {devMode} from "../data/globals";
import {selfAddNewItem} from "../redux/modules/self/selfActions";
import {PageTypes} from "../system/types";
import UseWindowDimensions from "../hooks/useWindowDimensions";

const LearnContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
`;

const LearnBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  width: ${({screenIsSmall}) => screenIsSmall ? '90%' : '70em'};
  margin: 5% 0;
`;

const LearnMountDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 4px;
`;

const LearnSectionContentDiv = styled.div`
    box-shadow: ${shadow};
    display: flex;
    flex-direction: column;
    border: 2px solid ${palette.wetAsphalt};
    background-color: ${palette.midnightBlue};
    border-radius: 2px;
    margin-bottom: 1em;
    padding: 1em;
    ul {
        color: ${palette.base2};
        font-weight: bold;
        border: 1px solid ${palette.wetAsphalt};
    }
    li {
        padding: .5em 1em;
    }   
`;
const LearnSectionTextHeaderDiv = styled.div`
    font-weight: bold;
    font-size: 1.1em;
    color: ${palette.base4};
    text-align: left;
`;
const LearnSectionFooterDiv = styled.div`
    color: ${palette.base4};
    background-color: ${palette.wetAsphalt};
    border: 1px solid ${palette.wetAsphalt};
    padding: 1em;
    text-align: left;
`;
const LearnSectionBodyDiv = styled.div`
    display: flex;
    padding: 2em 0;
`;
const LearnSectionCostDiv = styled.div` 
    margin-left: 1em;
    font-size: 2em;
    display: flex;
    justify-content: center;
    color: ${palette.belizeHole};
    font-weight: bold;
    font-family: Luckiest Guy;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
`;
const LearnSectionPurchaseDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    ul {
        border: 10px solid ${palette.wetAsphalt};
    }
    ul > li {
        color: ${palette.base4}   
    }
`;

const LearnSection = ({headerText,contentBody,contentFooter,theme}) => {
    return (<LearnSectionContentDiv interfaceColor={theme.interfaceColor}>
        <LearnSectionTextHeaderDiv>
            {headerText}
        </LearnSectionTextHeaderDiv>
        <LearnSectionBodyDiv>
            {contentBody}
        </LearnSectionBodyDiv>
        <LearnSectionFooterDiv>
            {contentFooter}
        </LearnSectionFooterDiv>
    </LearnSectionContentDiv>)
};

const Learn = ({self,theme,history}) => {
    const dispatch = useDispatch();
    const { xsollaToken, username, premium} = useSelector(state => state.self);
    const [opening,setOpening] = useState(false);
    const { screenIsSmall } = UseWindowDimensions();

    const contentProps = useSpring({
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: opening ? '0' : '100%',
        config: { duration: 150 },
    });

    useEffect(() => {
        setOpening(true);

        requestCashShopItemToken('premium');
    }, []);

    // Captures the server response for the current item token request
    useEffect(() => {
        if (xsollaToken) {
            loadXsollaInterface(xsollaToken);
        }
    }, [xsollaToken]);

    const handleTransactionPlatform = () => {
        if (isElectron()) {
            //todo: idk what is expected here by the endpoint, need to test this
            requestSteamPurchase('premium');
        } else {
            window.XPayStationWidget.open()
        }
    };

    // In order to avoid the payment integration and skip to the success state for development only.
    const stubFakePurchase = () => {
        dispatch(selfAddNewItem({
            category: 'premium',
        }));
    };

    return (
        <animated.div style={contentProps}>
                <Page>
                    <SlimePan>
                    <Header title="LEARN" closeRoute={PageTypes.MAIN} />
                    <LearnContainerDiv className="styleDarkScrollSquare">
                        <LearnBodyDiv
                            screenIsSmall={screenIsSmall}
                        >
                            <InfoBanner
                                title={`Learn More`}
                                body={
                                    <div>Get the competitive edge</div>
                                }
                            />
                            <LearnMountDiv>
                                <LearnSection
                                    theme={theme}
                                    headerText={'Premium Membership'}
                                    contentBody={
                                        <>
                                            <LearnSectionPurchaseDiv>
                                                <div style={{display: 'flex', flexGrow: 1}}>
                                                    <ul>
                                                        <li>50% increased drop rates on all items</li>
                                                        <li>Access to drops including abilities</li>
                                                        <li>Access to remove bots from the player pool</li>
                                                        <li>Access to all tier level drops</li>
                                                        <li>Access to create private matches</li>
                                                        <li>Access to save outfit presets in the loot screen</li>
                                                        <li>No more advertisements</li>
                                                    </ul>
                                                </div>
                                                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'center', margin: '0 1em'}}>
                                                    <div className="animated flipInX" style={{fontSize: '.75em', display: 'flex', justifyContent: 'center', }}>
                                                        <CircleMountDiv style={{margin: '0', animationDelay: '1.5s'}} className="animationFlip">
                                                            <div style={{zIndex: '3', marginTop: '.25em', verticalAlign: 'center', fontSize: '5em', color: theme.interfaceColorBright }}>
                                                                {useIcon('premiumUser')}
                                                            </div>
                                                        </CircleMountDiv>
                                                    </div>
                                                    <LearnSectionCostDiv>
                                                        {self.premium ?
                                                            <>
                                                                <div>YOU ARE</div>
                                                                <div style={{color: theme.interfaceColor}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PREMIUM</div>
                                                            </>
                                                        :
                                                            <>

                                                                <div>one time</div>
                                                                <div style={{color: theme.interfaceColor}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$${GAME_COST} Dollars</div>
                                                            </>
                                                        }
                                                    </LearnSectionCostDiv>
                                                </div>
                                            </LearnSectionPurchaseDiv>
                                        </>
                                    }
                                    contentFooter={self.premium ?
                                        <div>🎉 Your account&nbsp;
                                            <TextUnderlineHover
                                                theme={theme}
                                                bold={true}
                                                onClick={() => {
                                                    history.push(PageTypes.ATLAS);
                                                }}
                                            >
                                                {self.username}
                                            </TextUnderlineHover> is a premium member</div>
                                        :
                                        <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}}>
                                            <span>All perks above will be permanently bound to your account. All payments are non-refundable.</span>
                                            <div style={{display: 'flex'}}>
                                                {premium ?
                                                    <span>
                                                        Premium Activated
                                                    </span>
                                                    :
                                                    <>
                                                        {username ?
                                                            <Button
                                                                title={'Purchase Now'}
                                                                click={() => handleTransactionPlatform()}
                                                            />
                                                            :
                                                            <Button
                                                                title={"Sign In"}
                                                                click={() => history.push(PageTypes.SIGN_IN)}
                                                                icon={Icon('door-open')}
                                                                styles={{marginRight: '1px'}}
                                                            />
                                                        }
                                                    </>
                                                }
                                                {devMode &&
                                                    <Button
                                                        title={'Fake Purchase'}
                                                        color={'purple'}
                                                        click={() => stubFakePurchase()}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    }
                                />
                                <LearnSection
                                    theme={theme}
                                    headerText={'Item Quality'}
                                    contentBody={<div style={{display: 'flex', justifyContent: 'space-between',width: '100%',}}>
                                        {
                                            tiers.map((tier,index) => {
                                                return (
                                                    <div
                                                        key={tier}
                                                        style={{display: 'flex', alignItems: 'center', color: getTierData(index).color,}}
                                                    >
                                                        <div style={{
                                                            display: 'flex',justifyContent: 'center',alignItems: 'center',
                                                            width: screenIsSmall ? '3em' : '6em',
                                                            height: '2em',fontWeight: 'bold',
                                                            borderRadius: '2px', margin: '0 .5em 0 0', color: palette.wetAsphalt, backgroundColor: getTierData(index).color}}>
                                                            {getTierData(index).chance}
                                                        </div>
                                                        <div style={{fontSize: '.8em',fontWeight: 'bold'}}>{tier.toUpperCase()}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>}
                                    contentFooter={<div>Completing any kind of match (except private matches) will activate a dice roll for a 25% chance to receive an item drop. Better item qualities have more potential to contain high power levels.</div>}
                                />
                                <LearnSection
                                    theme={theme}
                                    headerText={'Ranked Match Ratings'}
                                    contentBody={<div style={{display: 'flex',justifyContent:'space-between',width:'100%',padding: '0 2.5em'}}>
                                        {rank.images.map((image,index) => (
                                            <div
                                                key={index}
                                                style={{display: 'flex', alignItems: 'center'}}
                                            >
                                                <RankImg src={image} />
                                                <RankTextDiv>
                                                    <span style={{fontWeight: 800,color: getTierData(index).color}}>{[...Array(index+1)].map(iterator => 'I')}</span>
                                                    <br/>
                                                    {rank.names[index].toUpperCase()}
                                                    <br/>
                                                    <span style={{fontSize: '.75em'}}>{rank.minimumRating[index]} rating</span>
                                                </RankTextDiv>
                                            </div>
                                        ))}
                                    </div>}
                                    contentFooter={
                                        <div>All players begin as a humble potato. A match win results in +25 rating points. A match loss results in the loss of rating points. What does it take to become a cherry? 660 consecutive wins. That's about 55 hours of full length matches.</div>
                                    }
                                />
                            </LearnMountDiv>
                            <LearnSection
                                theme={theme}
                                headerText={`Seasonal Item Drops`}
                                contentBody={<div style={{display: 'flex', width: '100%', justifyContent: 'space-between',paddingLeft:'2em'}}>
                                    {items[season.current].map(item => (
                                        <ItemDetail
                                            history={history}
                                            key={item.itemId}
                                            size={'small'}
                                            item={item}
                                            slotMargin={0}
                                        />
                                    ))}
                                </div>}
                                contentFooter={
                                    <>
                                        Some items only have a chance to drop when they are in season. Right now during the {season.current} season
                                        the items above can be found. Ability items only have a chance to drop for the winners of the match. View the&nbsp;
                                        <TextUnderlineHover
                                            theme={theme}
                                            bold={true}
                                            onClick={() => {
                                                history.push(PageTypes.ATLAS);
                                            }}
                                        >
                                            Game Items
                                        </TextUnderlineHover>
                                        &nbsp;to learn more about items.
                                    </>
                                }
                            />
                            <div style={{padding: '2.5em'}}> </div>
                        </LearnBodyDiv>
                    </LearnContainerDiv>
                    </SlimePan>
                </Page>
        </animated.div>
    )
};

export default Learn;