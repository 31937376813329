import {updateMatchResults} from "../redux/modules/match/matchActions";
const MockPlayerTeams = {
    teamA: [
        {
            colorName: 'Transcendentally Sunburn',
            rating: {
                volley: 0,
                soccer: 0,
                hoops: 0
            },
            colorHex: 'aef'
        },
        {
            colorName: 'Transcendentally Sunburn',
            rating: {
                volley: 99999999,
                soccer: 99999999,
                hoops: 99999999
            },
            colorHex: 'fe2',
        }
    ],
    teamB: [
        {
            colorName: 'Transcendentally Sunburn',
            rating: {
                volley: 0,
                soccer: 0,
                hoops: 0
            },
            colorHex: 'f44'
        },
        {
            colorName: 'Transcendentally Sunburn',
            rating: {
                volley: 9000,
                soccer: 9000,
                hoops: 9000
            },
            colorHex: '1ec'
        }
    ],
};
const MockWin = (dispatch, history) => {
    dispatch(updateMatchResults({
        winner: "Algonquin Tennis Ball",
        winnerHex: '#FF3',
        winningTeam: "teamA",
        allowRematch: true,
        p1Score: 6,
        p2Score: 0,
        reason: "reachPoints",
        teams: MockPlayerTeams
    }));
    history.push('/win');
};

export default MockWin;