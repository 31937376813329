import React, { useState, useRef, useEffect } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import ItemSlot from '../../../component/Item/ItemSlot';
import { ItemsDiv, ItemsContainerDiv } from './ItemsStyle';
import { useDispatch, useSelector } from "react-redux";
import { lootFocusItem, lootUnfocusItem } from "../../../redux/modules/loot/lootActions";
import { FONT_HUGE, FONT_LARGE } from "../../../data/font";
import UseWindowDimensions from "../../../hooks/useWindowDimensions";
import palette from "../../../styled/Palette";
import FaceAchievementMount from "../FaceAchievementMount/FaceAchievementMount";

const Items = ({
                   history,
                   category,
                   itemsFiltered,
                   sellMode,
                   handleToggleSellWindow,
                   setLootColorDemo,
                   filtersOn,
                   tooltip,
                   click,
                   slotWidth,
                   slotHeight,
                   filter,
                   rowHeight
               }) => {
    const dispatch = useDispatch();
    const [activeFace, setActiveFace] = useState(null);
    const [loading, setLoading] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const focusItem = useSelector(state => state.loot.item);
    const ITEMS_COUNT = itemsFiltered.length;
    const mounted = useRef(false);
    const refList = useRef(false);
    const { screenIsSmall } = UseWindowDimensions();

    const handleItemEnter = (e, index) => {
        if (setActiveFace) {
            setActiveFace(index);
        }
        setOverlay(true);
        dispatch(lootFocusItem(itemsFiltered[index]));
    };

    const handleItemLeave = (e, index) => {
        if (setActiveFace) {
            setActiveFace(index);
        }
        setOverlay(false);
        dispatch(lootUnfocusItem());
    };

    const handleSetFace = (index, hovering) => {
        if (hovering) {
            setActiveFace(index);
        } else {
            setActiveFace(null);
            const detail = document.getElementById('detailWindow');
            if (detail) detail.style.display = 'none';
        }
    };

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (category) {
            setLoading(true);
            setTimeout(() => {
                if (mounted.current) {
                    setLoading(false);
                }
            }, 250);
        }
    }, [category]);

    return (
        <ItemsDiv>
            {itemsFiltered.length === 0 ? (
                <>
                    {loading ? null : (
                        <div style={{ color: palette.base4 }} className="fontLarge">
                            <div style={{ marginTop: '5em' }}>
                                You have not earned any
                                <span style={{ fontSize: FONT_LARGE, padding: '0 10px' }}>
                                    {category === 'accessory' ? 'accessories' : `${category}s`}
                                </span>
                                {filtersOn && <span> with these filters applied</span>}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <ItemsContainerDiv>
                    <AutoSizer>
                        {({ height, width }) => {
                            const validSlotWidth = slotWidth || 100; // Use a default value like 100 if slotWidth is undefined
                            const itemsPerRow = itemsFiltered[0].category === 'face'
                                ? (screenIsSmall ? 1 : Math.min(2, Math.floor(width / validSlotWidth)))
                                : 5;

                            const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);

                            return (
                                <List
                                    ref={refList}
                                    className="List styleDarkScrollSquare"
                                    width={width}
                                    height={height}
                                    rowCount={rowCount}
                                    rowHeight={screenIsSmall ? 105 : 135}
                                    overscanRowCount={0}
                                    tabIndex={null}
                                    rowRenderer={({ index, key, style }) => {
                                        const slots = [];
                                        const fromIndex = index * itemsPerRow;
                                        const toIndex = Math.min(fromIndex + itemsPerRow, ITEMS_COUNT);

                                        for (let i = fromIndex; i < toIndex; i++) {
                                            slots.push(
                                                <ItemSlot
                                                    slotWidth={screenIsSmall ? 6 : 8}
                                                    slotHeight={screenIsSmall ? 6 : 8}
                                                    index={i}
                                                    item={itemsFiltered[i]}
                                                    filter={filter}
                                                    overlay={overlay}
                                                    handleItemLeave={handleItemLeave}
                                                    handleItemEnter={handleItemEnter}
                                                    location={history?.location?.pathname}
                                                    click={click}
                                                    tooltip={tooltip}
                                                    tabIndex={0}
                                                    className="Item"
                                                    setLootColorDemo={setLootColorDemo}
                                                    handleToggleSellWindow={handleToggleSellWindow}
                                                    sellMode={sellMode}
                                                    activeFace={activeFace}
                                                    setActiveFace={handleSetFace}
                                                />
                                            );
                                        }

                                        const isFaceCategory = category === 'face';
                                        const isEvenRow = index % 2 === 0;

                                        // Pass only the filteredItems for the current row
                                        const rowFilteredItems = itemsFiltered.slice(fromIndex, toIndex);

                                        return isFaceCategory ? (
                                            <FaceAchievementMount
                                                screenIsSmall={screenIsSmall}
                                                key={key}
                                                style={style}
                                                isEvenRow={isEvenRow}
                                                slots={slots}
                                                slotIndex={index}
                                                filteredItems={rowFilteredItems} // Only pass items for the current row
                                            />
                                        ) : (
                                            <div className="Row" key={key} style={style}>
                                                {slots}
                                            </div>
                                        );
                                    }}

                                />
                            );
                        }}
                    </AutoSizer>

                </ItemsContainerDiv>
            )}
        </ItemsDiv>
    );
};

export default React.memo(Items);
