import React, {useRef, useState} from 'react';
import palette from '../styled/Palette';
import { playSound } from '../data/sound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useSelector} from "react-redux";
import isElectron from "is-electron";
import {CloseButtonSpan} from "./Header/HeaderStyle";
import {useIcon} from "../data/icon";
import styled from 'styled-components';
import {FONT_FINE} from "../data/font";
import {devMode} from "../data/globals";

const AdvertContainerDiv = styled.div`
        position: absolute;
        bottom: 0;
        right: 0;
        animation-delay: 3s;
    `;
const AdvertSectionDiv = styled.div`
        overflow: hidden;
        min-width: 300px;
        max-width: 300px;
        min-height: 250px;
        max-height: 250px;
        display: flex;
        flex-direction: column;
    `;

export const Advert = () => {
    if (devMode) {
        return false;
    }

    if (isElectron()) {
        // Users on steam must not see an advert
        return false;
    }

    const { premium } = useSelector(state => state.self);
    const [advertClosed, setAdvertClosed] = useState(false);
    const adBlockEnabled = document.getElementById('slime-network_300x250')?.offsetHeight === 0;

    const closeAdvert = () => {
        playSound('pop',1);
        setAdvertClosed(true);
    };

    //const isProduction = (document.domain !== "") && (document.domain !== "localhost");
    const notPremium = (typeof premium === 'undefined' || !premium);

    if (!advertClosed && notPremium ) {
        return (
            <AdvertContainerDiv className="animated slideInRight">
                <div style={{cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: palette.base1, padding: '.5em'}}>
                    <div className="fontLarge" style={{color: palette.base4, flexGrow: 1, textAlign: 'left', paddingLeft: '10px'}}>ADVERT</div>
                    <CloseButtonSpan onClick={() => closeAdvert()}>
                        {useIcon('exit')}
                    </CloseButtonSpan>
                </div>
                <AdvertSectionDiv>
                    <div style={{overflow: 'hidden', display: 'flex', flexGrow: 1, backgroundColor: palette.base0}}>
                        {adBlockEnabled &&
                            <>
                                <div style={{zIndex: 1, margin: '1em', textAlign: 'left', color: 'white', fontSize: FONT_FINE}}>It appears this computer is blocking adverts. We use those to keep Slime alive! Please consider disabling your ad-block or purchasing the game to support us.</div>
                                <div style={{position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{marginTop: '10px', fontSize: '225px', position: 'absolute', color: palette.wetAsphalt, opacity: .5}}>
                                        <FontAwesomeIcon icon={['fas', 'sad-cry']}/>
                                    </div>
                                </div>
                            </>
                        }
                        <>
                            <div
                                // Used by the Adinplay script to target the div which the ad should be appended onto.
                                id='slime-network_300x250'
                            > </div>
                            <div id="preroll"> </div>
                        </>
                    </div>
                </AdvertSectionDiv>
            </AdvertContainerDiv>
        )
    }
    return null;
};

export default Advert;