
// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
import {getRandomInt} from "./data/format";

export const isMobileDevice = () => {
    // Check for mobile user agent
    const userAgentCheck = /(android|iphone|ipad|ipod|blackberry|windows phone|mobile|kindle|opera mini|mobile.+firefox|netfront|palm|phone|mobile)/i.test(navigator.userAgent);

    // Check for mobile screen size (usually below 1024px width for mobile devices)
    const screenSizeCheck = window.innerWidth < 1024;

    // Return true if it's a mobile device based on either the user agent or screen size
    return userAgentCheck && screenSizeCheck;
};

export const shuffle = (array) => {
    let counter = array.length;

    // While there are elements in the array
    while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }

    return array;
};

export const lowestValueObject = (attrib, array) => {
    return (array.length && array.reduce(function(prev, curr){
        return prev[attrib] < curr[attrib] ? prev : curr;
    })) || null;
};

export const randomItem = (array) => {
    return array[getRandomInt(array.length)];
};
