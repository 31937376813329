import React, {useEffect, useState} from "react";
import Dialogue from "../index";
import {PageTypes} from "../../../system/types";
import UseWindowDimensions from "../../../hooks/useWindowDimensions";

const dialogueSequence = [
    `Oh - so you finally decided to join the club?`,
    `Great, one second`,
    `..and...SHAZAM!`,
    `You now have the highly coveted premium status!`,
    `Thank you for helping us thrive!`
];
const responseSequence = [
    `Yeah!`,
    `Sure`,
    `Okay?`,
    `Sweet`,
    `You are welcome`
];
const DialoguePremium = ({ history, handleReturn }) => {
    const [dialogueVisible] = useState(true);
    const [dialogueIndex, setDialogueIndex] = useState(0);
    const [dialogueButtons,setDialogueButtons] = useState([]);
    const [renderDialogue,setRenderDialogue] = useState(true);
    const { screenIsSmall } = UseWindowDimensions();

    useEffect(() => {
        if (dialogueIndex === dialogueSequence.length) {
            handleReturn();
        } else {
            // dialogueIndex becomes stale if we don't update this
            setDialogueButtons(
                [
                    {
                        title: responseSequence[dialogueIndex],
                        action: () => setDialogueIndex(dialogueIndex + 1 )
                    }
                ]
            )
        }

        // Note: I'm forcing a component rerender here. Not the best, but not the worst
        setRenderDialogue(false);
        setTimeout(() => {
            setRenderDialogue(true);
        }, 0);
    }, [dialogueIndex]);

    return (
        <>
            {renderDialogue &&
                <Dialogue
                    styles={`bottom: ${screenIsSmall ? '-12em' : '-20em'};`}
                    history={history}
                    dialogueIndex={dialogueIndex}
                    dialogueSequence={dialogueSequence}
                    buttons={dialogueButtons}
                    visible={dialogueVisible}
                />
            }
        </>
    )
};

export default DialoguePremium;