import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputManager from './InputManager';
import {
  updateTimeUntilMatchStart,
  matchUpdateRebootTimer,
} from '../../redux/modules/match/matchActions';
import matchScope from '../../data/matchScope';
import { playSound } from '../../data/sound';
import UseWindowDimensions from '../../hooks/useWindowDimensions';
import { userOnChrome } from '../../data/generic/generic';
import isElectron from 'is-electron';
import { RenderAbility, RenderAbilityAfterAll } from '../../system/render/ability';
import RenderPowershot from '../../system/render/powerShot';
import RenderCriticalshot from '../../system/render/criticalShot';
import RenderCenterWall from '../../system/render/centerWall';
import RenderCooldown from '../../system/render/cooldown';
import RenderParticles from '../../system/render/particles';
import RenderOverheadChat from '../../system/render/overheadChat';
import RenderBallLerp from '../../system/render/lerp/ballLerp';
import RenderProjectiles from '../../system/render/lerp/projectiles';
import RenderPowerShotLerp from '../../system/render/lerp/powerShotLerp';
import RenderFloor from '../../system/render/floor';
import RenderTrailLerp from '../../system/render/lerp/trailLerp';
import RenderCriticalShotLerp from '../../system/render/lerp/criticalShotLerp';
import RenderBallTracker from '../../system/render/ballTracker';
import RenderGameMode from '../../system/render/mode/index';
import RenderAvatarLerp from '../../system/render/lerp/avatarLerp/index';
import { RenderLobbyAvatarLerp } from '../../system/render/lerp/avatarLerp/index';
import { FloorGeneric } from '../../styled/SlimePan';
import { setStage } from '../../system/physics/physics';
import { initPlayer } from '../../redux/middleware/wsMatchThunks.js';
import {sandbox} from "../../system/physics/physics.js";
import Proton from '../../system/particles/proton';
import { protonBackground } from '../../system/particles/protonBackground';
import { protonForeground } from '../../system/particles/protonForeground';
import {PageTypes} from "../../system/types";
import { webGLEnabled } from '../../data/webGLEnabled.js';
import { RenderFPS } from '../../system/fps/fps.js';
import {devMode} from "../../data/globals";

// todo: It would be great if we could create a singleton instance of the lobby class some other way
//  instead of using this 'react dom instance' pattern. Also, does it have to use the non-functional class style
//  I suck at the older paradigm.
const StageManager = ({ history, refCanvas, refCanvasBackground, refCanvasFloor, selectedFloor }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const abilityItems = useSelector((state) => state.self.items.accessory);
  const { assetsLoaded } = useSelector((state) => state.socket.assetsLoaded);
  const self = useSelector((state) => state.self);
  const match = useSelector((state) => state.match);
  const createdProton = useRef(false);
  // Destructuring
  const { backdrop } = useSelector((state) => state.match);
  const { team, teamIndex, stats } = self;
  const { roomId, timeUntilStart, timeUntilNextMatchStart, status, modifiers } = match;
  //console.log(match);


  // In order to serve wide screen users, we must maintain a certain aspect ratio.
  const { width, height } = UseWindowDimensions();
  const [canvasSize, setCanvasSize] = useState({
    width: null,
    height: null,
  });

  function implementSandboxAssets(sandbox) {
    sandbox.teams.teamA.forEach((player, index) => {
      sandbox.teams.teamA[index] = initPlayer(player);
    });
    sandbox.teams.teamB.forEach((player, index) => {
      sandbox.teams.teamB[index] = initPlayer(player);
    });
  }

  // Stop users on other browsers from crashing.
  const userOnAlternativeBrowser = !userOnChrome() && !isElectron();
  if (userOnAlternativeBrowser) {
    return null;
  }

  useEffect(() => {
    if(!createdProton.current){
      let rendererType = webGLEnabled ? 'WebGLRenderer' : 'CanvasRenderer';
      let renderer = new Proton[rendererType](document.getElementById('backgroundCanvas'));
      let renderer2 = new Proton[rendererType](document.getElementById('foregroundCanvas'));
      protonBackground.addRenderer(renderer);
      protonForeground.addRenderer(renderer2);
      createdProton.current = true;
    }
    sandbox.playSound = playSound;
  }, []);

  /**
   * When all the assets have loaded, spawn the player
   */
  useEffect(() => {
    let player = {
      outfit: JSON.parse(JSON.stringify(self.outfit)),
      colorHex: '#f00',
      stats: stats,
	  abilities: [],
	  activeAbility: null,
    };
    let teamz = {
      teamA: [player],
      teamB: [],
    };
    setStage(sandbox, teamz);
    implementSandboxAssets(sandbox);
  }, [assetsLoaded]);

  /**
   * When the teams stats change, respawn the player
   * 1. This happens when the player equips gear, changing their stats
   */
  useEffect(() => {
	let abilities = [];
	abilityItems.forEach(abilityItem => {
		if(abilityItem.slot != null){
			abilities.push({
				ability: abilityItem.ability,
				quantity: abilityItem.quantity,
        slot: abilityItem.slot,
			})
		}
	});
	let player = {
		colorHex: theme.hexValue,
		outfit: JSON.parse(JSON.stringify(self.outfit)),
		abilities,
		stats: stats,
	  };
    if (!player.outfit.paint) {
      player.outfit.paint = {
        colorHex: theme.hexValue,
        name: 'Starter Paint',
      };
    }
    let teamz = {
      teamA: [player],
      teamB: [],
    };

    setStage(sandbox, teamz);
    implementSandboxAssets(sandbox);
  }, [self.outfit, theme, roomId, stats, abilityItems]);

  /**
   * When the canvas element has loaded, render the contents
   */
  useEffect(() => {
    let animation;
    let context = refCanvas.current.getContext('2d');
    context.imageSmoothingEnabled = webGLEnabled ? true : false;
    const drawCanvas = () => {
      RenderFloor(refCanvas, context);
      if (roomId === 'lobby') {
        RenderTrailLerp(sandbox.ball.trail, context, sandbox.ball);
        RenderParticles(sandbox, protonBackground, protonForeground);
        let percentageOfFrame = (Date.now() - sandbox.lastUpdate) / (1000 / 60);
        RenderAbility(sandbox, context, 'teamA', 0, Date.now());
        RenderLobbyAvatarLerp(sandbox.teams, context, percentageOfFrame, 'teamA', 0);
        RenderPowershot(sandbox, context);
        RenderProjectiles(context, sandbox.projectiles);
        RenderBallLerp(context, sandbox.ball, percentageOfFrame, 'live');
        RenderCriticalshot(sandbox, context);
        RenderCooldown(sandbox, context, 'teamA', 0, Date.now(), percentageOfFrame,theme,'live');
        RenderAbilityAfterAll(sandbox, context, Date.now());
        if(devMode) RenderFPS(context);
      } else {
        const { gameMode, hoopHeight, hoopSize, powerShot, trail, teams, ball, ballCrit } =
          matchScope;
        const timeIntoFrame = Date.now() - matchScope.lastUpdate;
        const percentageOfFrame = timeIntoFrame / matchScope.updateRate;
        RenderParticles(matchScope, protonBackground, protonForeground);
        RenderCenterWall(context, modifiers);

        RenderTrailLerp(trail, context, ball);
        RenderAbility(matchScope, context, team,teamIndex, matchScope.serverTime);
        RenderAvatarLerp(teams, context, percentageOfFrame, status, team, teamIndex);
        RenderPowerShotLerp(powerShot, context, teams);
        RenderProjectiles(context, matchScope.projectiles);
        RenderBallLerp(context, ball, percentageOfFrame, status);
        RenderCriticalShotLerp(ballCrit, context, ball);

        RenderBallTracker(context, ball);
        RenderGameMode(gameMode, context, hoopHeight, hoopSize, teams);
        RenderOverheadChat(context, teams, percentageOfFrame, status);
        RenderCooldown(matchScope, context, team, teamIndex, matchScope.serverTime, percentageOfFrame,theme,status);
        RenderAbilityAfterAll(matchScope, context, matchScope.serverTime, team, teamIndex);
        if(devMode) RenderFPS(context);
      }
      animation = window.requestAnimationFrame(drawCanvas);
    };
    drawCanvas();

    return () => window.cancelAnimationFrame(animation);
  }, [roomId, modifiers, status]);

  /**
   * Tick down the time until the match starts timer
   */
  useEffect(() => {
    // todo: This should not continue to update if the match has started
    if (timeUntilStart > 0) {
      setTimeout(() => {
        dispatch(updateTimeUntilMatchStart(timeUntilStart - 1));
      }, 1000);
    }
  }, [timeUntilStart]);

  /**
   * Tick down the time until the players are booted following a match
   */
  useEffect(() => {
    if (timeUntilNextMatchStart > 0) {
      setTimeout(() => {
        dispatch(matchUpdateRebootTimer(timeUntilNextMatchStart - 1));
      }, 1000);
    }
  }, [timeUntilNextMatchStart]);

  /**
   * status is delivered as an update to all clients and when a client connects
   */
  useEffect(() => {
    if (status) {
      if (status === 'waiting') {
        history.push(PageTypes.RULES);
      }
      if (status === 'live') {
        history.push(PageTypes.MATCH);
      }
    }
  }, [status]);

  const getCanvasSize = () => {
    let canvasWidth;
    let canvasHeight;
    const aspectRatio = 1920 / 950;
    const wideScreen = width / height > aspectRatio;
    if (wideScreen) {
      canvasWidth = height * aspectRatio;
      canvasHeight = height;
    } else {
      canvasWidth = width;
      canvasHeight = width / aspectRatio;
    }
    return {
      width: canvasWidth,
      height: canvasHeight,
    };
  };
  useEffect(() => {
    if (width || height) {
      setCanvasSize(getCanvasSize());
    }
  }, [width, height]);

  return (
    <>
      <InputManager history={history} />
      <canvas
        id="backgroundCanvas"
        ref={refCanvasBackground}
        style={{
          width: canvasSize.width,
          height: canvasSize.height,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
        width={'1920'}
        height={'900'}
      />
      <canvas
	  	id="gameCanvas"
        ref={refCanvas}
        style={{
          width: canvasSize.width,
          height: canvasSize.height,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
        width={'1920'}
        height={'900'}
      />
      <canvas
        id="foregroundCanvas"
        style={{
          width: canvasSize.width,
          height: canvasSize.height,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
        width={'1920'}
        height={'900'}
      />
      <FloorGeneric
        ref={refCanvasFloor}
        floor={selectedFloor}
        width={'1920'}
        styles={{
          width: canvasSize.width,
          height: height - canvasSize.height,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      />
    </>
  );
};

export default StageManager;
