import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import {storeLootFilters} from "../../../data/storeLootImages";
import {reset} from "../../../webworker/main";
import {
    LootItemsFilterDiv
} from './LootItemsFilterStyle';
import {useIcon} from "../../../data/icon";

const LootItemsFilter = ({shown,category,sellWindow,itemNames, sellMode, setSellMode, filter, setFilter},history) => {
    const renderSellItemButton = () => {
        return (
            <Button
                color={sellMode ? 'red' : null}
                history={history}
                click={() => setSellMode(!sellMode)}
                isDisabled={['face', 'accessory'].includes(category)}
                icon={sellMode ? useIcon('burn') : useIcon('sell')}
            />
        )
    };

    const handleFilter = (e,type) => {
        // The event doesn't persist because the following call to reset is async so we store it here first.
        const target = e.target.value;
        // Clear all active workers trying to draw portraits
        reset().then(() => {
            // Than we update the memory stored filters for this category
            storeLootFilters.update = {
                key: type,
                value: target,
                category
            };
            // Finally we update the filters, triggering the filtered rerender
            setFilter({...filter, [type]: target});
        });
    };

    if (sellWindow === true) {
        return null;
    }

    return (
        <LootItemsFilterDiv shown={shown}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <FontAwesomeIcon
                    style={{
                        color: 'white',
                        fontSize: '1em',
                        margin: '0 1em'
                    }}
                    icon={['fas', 'filter']}
                />
                {(category === 'skin' || category === 'cap') &&
                <select
                    style={{width: '100px'}}
                    value={filter.tier}
                    onChange={(e) => handleFilter(e,'tier')}
                >
                    <option value=''>All Tiers</option>
                    <option value='0'>Common</option>
                    <option value='1'>Unusual</option>
                    <option value='2'>Remarkable</option>
                    <option value='3'>Extraordinary</option>
                    <option value='4'>Legendary</option>
                </select>
                }
                {(category === 'skin' || category === 'cap' || category === 'paint') &&
                <select style={{width: '100px'}}
                        value={filter.color}
                        onChange={(e) => handleFilter(e,'color')}
                >
                    <option value=''>All Colors</option>
                    <option value='red'>Red</option>
                    <option value='orange'>Orange</option>
                    <option value='yellow'>Yellow</option>
                    <option value='green'>Green</option>
                    <option value='blue'>Blue</option>
                    <option value='purple'>Purple</option>
                    <option value='brown'>Brown</option>
                    <option value='pink'>Pink</option>
                    <option value='white'>White</option>
                    <option value='grey'>Grey</option>
                    <option value='black'>Black</option>
                </select>
                }
                {(category === 'skin' || category === 'cap') &&
                <select style={{width: '100px'}}
                        value={filter.name}
                        onChange={(e) => handleFilter(e,'name')}
                >
                    <option value=''>All Items</option>
                    {itemNames.map((name,index) =>
                        <option key={index}
                                value={name}>
                            {name}
                        </option>
                    )};
                </select>
                }
            </div>
            <div>
                {renderSellItemButton()}
            </div>
        </LootItemsFilterDiv>
    );
};

export default LootItemsFilter;