import React, {useEffect, useRef, useState} from "react";
import slimeWizard from '../../images/avatars/slimeWizard.png';
import useTypeWriter from "../../hooks/useTypeWriter";
import palette from "../../styled/Palette";
import Button, {STANDARD_BUTTON_WIDTH} from "../../styled/Button";
import {
    DialogueWordsDiv,
    DialogueContainerDiv,
    DialogueDiv, DialogueCloseDiv
} from './DialogueStyle';
import ButtonClose from "../../atoms/ButtonClose";
import {AvatarTypes, PageTypes} from "../../system/types";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import DialogueAvatarNameplate from "./DialogueAvatarNameplate";
import DialogueAvatar from "./DialogueAvatar";

const ENOUGH_TIME_FOR_THE_SPOKEN_DIALOGUE_TO_FINISH = 2000;
const ENOUGH_TIME_FOR_THE_FADE_OUT = 2000;

const Dialogue = ({buttons = [], visible, dialogueSequence, dialogueIndex = 0, bottom, history, closable, styles, avatar = AvatarTypes.WIZARD}) => {
    const refContainer = useRef(null);
    const [spinInvoked,setSpinInvoked] = useState(false);
    const [dialogueCompleted,setDialogueCompleted] = useState(false);
    const writtenProgress = useTypeWriter(dialogueSequence[dialogueIndex], 500);
    const { screenIsSmall } = UseWindowDimensions();

    // Reset the complete button for each dialogue
    useEffect(() => {
        if (dialogueIndex) {
            setDialogueCompleted(false);
        }
    }, [dialogueIndex]);

    // This code is specific to the slots page
    useEffect(() => {
        if (!visible) {
            setSpinInvoked(true);
            handleDialogueFade();
        }
    }, [visible]);

    // Allow the user to move forward by flipping the dialogueComplete flag
    useEffect(() => {
        if (writtenProgress === dialogueSequence[dialogueIndex]) {
            // When dialogueCompleted is true we enable the go forward button
            setDialogueCompleted(true);

            if (avatar === AvatarTypes.METEOROLOGIST) {
                handleDialogueFade();
            }
        }
    }, [writtenProgress]);

    const handleDialogueFade = () => {
        setTimeout(() => {
            refContainer.current.classList.remove(`slideInUp`);
            refContainer.current.classList.add(`fadeOut`);
            setTimeout(() => {
                refContainer.current.style.visibility = 'hidden';
            }, ENOUGH_TIME_FOR_THE_FADE_OUT)
        }, ENOUGH_TIME_FOR_THE_SPOKEN_DIALOGUE_TO_FINISH);
    };
    return (
        <DialogueContainerDiv
            styles={styles}
            bottom={bottom}
            ref={refContainer}
            className={dialogueIndex === 0 ? `animated slideInUp` : `animated`}
        >
            <DialogueDiv
                screenIsSmall={screenIsSmall}
            >
                {closable &&
                <DialogueCloseDiv>
                    <ButtonClose
                        click={() => history.push(PageTypes.MAIN)}
                    />
                </DialogueCloseDiv>
                }
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <div
                        style={{
                            position: 'relative',
                            overflow: 'hidden',
                            marginTop: '-75px'
                        }}
                    >
                        <DialogueAvatar avatar={avatar} />
                    </div>
                </div>
                <DialogueWordsDiv>
                    <div
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '30%',
                            marginTop: '.5em',
                        fontWeight: 'bold',
                        color: '#7799ff'
                    }}>
                        <DialogueAvatarNameplate avatar={avatar} />
                    </div>
                    <div
                        className="styleDarkScrollSquare"
                        style={{
                        fontFamily: 'Indie Flower',
                        fontSize: '1.5em',
                        color: palette.base4,
                        height: '60%',
                        margin: '0 .5em .5em 0'
                    }}>
                        {writtenProgress}
                    </div>
                </DialogueWordsDiv>
            </DialogueDiv>
            {buttons &&
            <div style={{margin: '.5em', display: 'flex', width: screenIsSmall ? '80%' : '60em', justifyContent: 'flex-end'}}>
                {buttons.map((button,i) => {
                    return (
                        <Button
                            key={i}
                            isDisabled={spinInvoked || !dialogueCompleted}
                            title={button.title}
                            click={button.action}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                        />
                    )
                })}
            </div>
            }
        </DialogueContainerDiv>
    )
};

export default Dialogue;