import { useState, useEffect } from 'react';

const useCanvasSize = (width, height) => {
    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

    const getCanvasSize = () => {
        let canvasWidth;
        let canvasHeight;
        const aspectRatio = 1920 / 950;
        const wideScreen = width / height > aspectRatio;
        if (wideScreen) {
            canvasWidth = height * aspectRatio;
            canvasHeight = height;
        } else {
            canvasWidth = width;
            canvasHeight = width / aspectRatio;
        }
        return {
            width: canvasWidth,
            height: canvasHeight,
        };
    };

    useEffect(() => {
        if (width && height) {
            setCanvasSize(getCanvasSize());
        }
    }, [width, height]);

    return canvasSize;
};

export default useCanvasSize;
