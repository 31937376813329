
export const PageTypes = {
    TITLE: '/',
    MAIN: '/main',
    SIGN_IN: '/signin',
    OPTIONS: '/options',
    AFFILIATES: '/affiliates',
    FEATS: '/feats',
    LOOT: '/loot',
    PUBLIC_CHAT: '/publicChat',
    WATCH: '/watch',
    MARKET: '/market',
    HIGH_SCORES: '/highscores',
    SLOTS: '/slots',
    CASH_SHOP: '/cashShop',
    DROP: '/drop', //make screencase for this
    PRESENT: '/present',
    PRIVATE_MATCH: '/privateMatch',
    GAME_SELECT: '/gameSelect',
    RULES: '/rules',
    WIN: '/win',
    MATCH: '/match',
    TUTORIAL: '/tutorial',
    ATLAS: '/atlas',
    CONNECTION_TIMEOUT: '/connectionTimeout',
    ERROR_FALLBACK: '/errorFallback',
    LEARN: '/learn',
    RANK_UP: '/rankUp',
    CHANGE_LOG: '/changeLog',
    GAME_GUIDE: '/gameGuide',
    FIND_MATCH: '/findMatch'
};

export const GameModeTypes = {
    volley1: 'volley1',
    volley2: 'volley2',
    volley3: 'volley3',
    volley4: 'volley4',
    soccer1: 'soccer1',
    soccer2: 'soccer2',
    soccer3: 'soccer3',
    soccer4: 'soccer4',
    hoops1: 'hoops1',
    hoops2: 'hoops2',
    hoops3: 'hoops3',
    hoops4: 'hoops4',
};

export const DropTypes = {
    PAINT: 'paint',
    COINS: 'coins',
    TICKETS: 'tickets',
    PREMIUM: 'premium',
    TROPHY: 'trophy',
    SKIN: 'skin',
    CAP: 'cap',
    FACE: 'face',
    ACCESSORY: 'accessory',
    DOUBLE_PURCHASE: 'doublePurchase',
    // Empty symbol
    NON: 'non',
};
// The slots found on the players loot screen and profile screen
export const ItemSlotTypes = {
    PAINT: 'paint',
    SKIN: 'skin',
    CAP: 'cap',
    FACE: 'face',
    ACCESSORY: 'accessory'
};
// The possible category an item can have
export const ItemCategoryTypes = {
    ...ItemSlotTypes,
    COINS: 'coins',
    TICKETS: 'tickets'
};

export const AvatarTypes = {
    WIZARD: 'wizard',
    METEOROLOGIST: 'meteorologist'
};