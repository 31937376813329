import styled from 'styled-components';
import palette from '../../styled/Palette';

export const RankUpContainerDiv = styled.div`
    background-color: rgba(42,42,42,1);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
export const RankUpBodyDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const RankUpRankTextDiv = styled.div`
    color: ${props => props.color};
    font-weight: bold;
    font-size: 1.5em;
`;

export const RankUpPromotionTextDiv = styled.div`
    color: ${props => props.promotion ? palette.pomegranate : palette.nephritis}
`;


export const RankUpFooterDiv = styled.div`
    margin-top: 10em;
`;

