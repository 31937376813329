import styled from 'styled-components';
import palette from '../../styled/Palette';

export const PublicChatDiv = styled.div`
`;

export const PublicChatContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 97%;
`;

export const PublicChatBodyDiv = styled.div`
    border: 1px solid ${props => props.color};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;


export const FriendOptionsDiv = styled.div`
    display: none;
`;
//position and overflow are needed with respect to the public user bestRank icon
export const FriendDiv = styled.div`
        padding: 0 2em;
        
    background-color: ${palette.base0};
    :nth-of-type(even) {
        background-color: ${palette.base5};
    }
    
    min-height: 60px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    align-items: center;
    
    
    &:hover, :focus {
        background-color: ${palette.base6};
        .icon {
            color: ${theme => theme.interfaceColorBright};
            transform: scale(1.3);
            cursor: pointer;
        }
        ${FriendOptionsDiv} {
            display: flex;
        }
    }
    
    button {
        cursor: default;
        text-align: left;
        font-weight: bold;
        width: 100%;
        height: 100%;
        background-color: transparent;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;


export const PublicChatInput = styled.input`
        text-indent: 1em;
`;

export const PublicChatBestModeDiv = styled.div`
  position: absolute;
  font-size: 45px;
  top: -15px;
  right: -15px;
  opacity: .1;
`;