import React, { useState } from 'react';
import {socketSignInPhase, updateLoginToast} from "../../redux/modules/socket/socketActions";
import {useDispatch, useSelector} from "react-redux";
import FormSignIn from "./Form/FormSignIn";
import FormRecover from "./Form/FormRecover";
import FormReset from "./Form/FormReset";
import FormNewUser from "./Form/FormNewUser";
import Button, {STANDARD_BUTTON_WIDTH} from "../../styled/Button";

const SignInForms = () => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const { signInPhase } = useSelector(state => state.socket);
    const [validatedEmail,setValidatedEmail] = useState();
    const [existingUserIsPressed, setExistingUserIsPressed] = useState(false);

    const hideAlert = () => {
        dispatch(updateLoginToast(null));
    };

    const sharedProps = {
        dispatch,
        theme,
        hideAlert
    };

    switch (signInPhase) {
        case 'login': return (
            !existingUserIsPressed ? <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        styles={{width: STANDARD_BUTTON_WIDTH}}
                        click={() => {
                            setExistingUserIsPressed(true);
                        }}
                        title={"Existing User"}
                        type="button"
                        className="buttonNeutral"
                    > </Button>
                    <Button
                        styles={{width: STANDARD_BUTTON_WIDTH}}
                        click={() => {
                            hideAlert();
                            dispatch(socketSignInPhase('newUser'));
                        }}
                        color={'green'}
                        title={"New User"}
                        type="button"
                        className="buttonNeutral"
                    > </Button>
                </div>
                :
                <FormSignIn {...sharedProps} setExistingUserIsPressed={setExistingUserIsPressed} />
        );
        case 'recover': return <FormRecover {...sharedProps} validatedEmail={validatedEmail}/>;
        case 'reset': return <FormReset {...sharedProps} setValidatedEmail={setValidatedEmail}/>;
        case 'newUser': return <FormNewUser {...sharedProps} />;
    }
};

export default SignInForms;