import loader from "../../images/load2.svg";
import Icon, {useIcon} from "../../data/icon";
import palette from "../../styled/Palette";
import {imageAssets} from "../../data/images";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {getRandomInt} from "../../data/format";

export const SvgContainerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
`;

const IconPaint = require('react-icons/lib/fa/paint-brush');

const ItemLabelNewDiv = styled.div`
        font-family: Ubuntu;
        font-size: .5em;
        position: absolute;
        bottom: -.5em;
        left: -.5em;
        pointer-events: none;
        animation: rainbow-color-shift-color 8s infinite;
    `;
const AccessoryDiv = styled.div`
    position: relative;
    width: ${props => props.size ?? '100%'};
    height: ${props => props.size ??'100%'};
    z-index:3;
    vertical-align:center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s linear;
    
    
    ${props => props.shouldGlow && `
    svg * {
        animation: rainbow-color-shift-fill 8s infinite ${-32 + (props.index || 0)*.1}s forwards;
    }
    `};
   
`;

// Items are sometimes expressed as an image, an svg, or some html
export const getIcon = (image,count,imageSize) => {
    if (typeof image === 'string') {
        return <img src={image || loader} width={imageSize ?? '60%'}/>
    }
    if (typeof image === 'object') {
        return <SvgContainerDiv count={count}>{image}</SvgContainerDiv>
    }
    return Icon('ban',{fontSize: '1em',color: palette.wetAsphalt},'fas')
};

const AccessoryView = ({item,size,index}) => {
    return (
        <AccessoryDiv
            size={size}
            index={index}
            shouldGlow={!item.consumable && item.image}
        >
            {getIcon(item.image)}
        </AccessoryDiv>
    )
};
const SkinCapView = ({portrait}) => {
    return (
        <img style={{position: 'relative', zIndex: '3', verticalAlign: 'center', maxHeight: '100%', minWidth: '50%', maxWidth: '75%'}}
             src={portrait || loader}
             alt=""
        />
    )
};
const PaintView = () => {
    return (
        <div style={{position: 'relative'}}>
            <IconPaint
                style={{fontSize: '2em', //Hardcoded size because different ems in parent components
                    color: palette.base3 }}
            />
        </div>
    )
};
const FaceView = ({item,face}) => {
    if (item.locked) {
        return (
            <>
                <div style={{backgroundColor: 'palette.base0', position: 'absolute', zIndex: '4', width: '100%', height: '100%'}}> </div>
                <div style={{position: 'absolute', zIndex: '4', opacity: '.1'}}>
                    <div style={{color: palette.base4, fontSize: '4em'}} >
                        {Icon('lock')}
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <img style={{zIndex: '3', verticalAlign: 'center', maxWidth: '75%', maxHeight: '75%', minWidth: '50%' }}
                 src={imageAssets.Face[item.name][face].src}
                 alt={item.name}
            />
        )
    }
};

const Item = ({item, index, activeFace, portrait,size,key}) => {
    const itemContent = () => {
        switch (item.category?.toLowerCase() ?? '') {
            case 'skin':
            case 'cap':
                return <SkinCapView
                    portrait={portrait}
                />;
            case 'paint':
                return <PaintView
                    portrait={portrait}
                />;
            case 'accessory':
                return <AccessoryView
                    index={index}
                    size={size}
                    item={item}
                />;
            case 'face':
                const face = (activeFace === index && !item.locked) ? 'smug' : 'idle';
                return <FaceView
                    item={item}
                    face={face}
                />;
            default:
                return getIcon(item.image)
        }
    };
    const [content,setContent] = useState(itemContent());

    useEffect(() => {
        setContent(itemContent());
    }, [portrait]);

    return (
        <div
            key={key}
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            {content}
        </div>
    )
};

export default Item;