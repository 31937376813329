import {RowItemLi} from "../FeatsStyle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {getDivision} from "../../../data/rating";
import {getTierData} from "../../../data/tier";
import {sports} from "../../../data/globals";
import {capitalize} from "../../../data/generic/generic";
import ModalAchievements from "../../../component/Modal/Type/ModalAchievements";
import {HighscoresFindUserInput} from "../../Highscores/HighscoresStyle";
import {MetricsDiv,MetricsHeaderDiv,MetricsStatsDiv} from "../MetricsStyle";
import UseWindowDimensions from "../../../hooks/useWindowDimensions";

const Metrics = ({profile,stats, dispatch}) => {
    if (!profile || !stats) return null;
    const { screenIsSmall } = UseWindowDimensions();

    const numberAchievements = profile.achievements.filter(a => a.completed && !['loseStreak1','loserChallenger1'].includes(a.achievementName)).length;

    const getRankElement = (score) => {
        const division = getDivision(score);
        return (
            <div style={{
                fontWeight: 'bold',
                color: getTierData(division.tier).color
            }}>
                {division.word} {division.rank} {division.division}
            </div>
        )
    };
    const volley = getRankElement(profile.ratings.volley);
    const soccer = getRankElement(profile.ratings.soccer);
    const hoops = getRankElement(profile.ratings.hoops);

    const star = <FontAwesomeIcon icon={['fas', 'star']} />;
    const outline = <FontAwesomeIcon icon={['far', 'star']} />;
    const completed = (achievement) => {
        return profile.achievements.filter(a => a.achievementName === achievement)[0].completed;
    };
    const achievementStar = (achievement) => {
        return completed(achievement) ? star : outline;
    };

    const dropStars = () => {
        // So that they are left to right regardless of acquired order
        let rares = [
            completed('rare1'),
            completed('rare2'),
            completed('rare3')
        ].sort((a, b) => (a) ? -1 : 1);

        return rares.map(completed => completed ? star : outline);
    };

    const sportEmoji = ['🏐','⚽','🏀'];
    const sportRank = [volley,soccer,hoops];
    return (
        <MetricsDiv
            screenIsSmall={screenIsSmall}
            className="styleDarkScrollSquare"
        >
            <MetricsStatsDiv>
                <MetricsHeaderDiv style={{justifyContent: 'space-between'}}>
                    <div>ACHIEVEMENTS</div>
                    <div>{numberAchievements > 0 && numberAchievements}</div>
                </MetricsHeaderDiv>
                <ul>
                    <RowItemLi onClick={() => {
                        ModalAchievements(dispatch, 'CHALLENGER');
                    }}>
                        <div>
                            Challenger
                        </div>
                        <div>
                            {achievementStar('challenger1')}
                            {achievementStar('challenger2')}
                            {achievementStar('challenger3')}
                            {achievementStar('challenger4')}
                            <span >
                                <FontAwesomeIcon
                                    className="buttonZoom"
                                    style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                                    icon={['fas', 'info-circle']}
                                />
                            </span>
                        </div>
                    </RowItemLi>
                    <RowItemLi onClick={() => {
                        ModalAchievements(dispatch, 'STREAKER');
                    }}>
                        <div>
                            Streaker
                        </div>
                        <div>
                            {achievementStar('streaker1')}
                            {achievementStar('streaker2')}
                            <span >
                                <FontAwesomeIcon
                                    className="buttonZoom"
                                    style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                                    icon={['fas', 'info-circle']}
                                />
                            </span>
                        </div>
                    </RowItemLi>
                    <RowItemLi onClick={() => {
                        ModalAchievements(dispatch, 'JACK OF ALL TRADES');
                    }}>
                        <div>
                            Jack of All
                        </div>
                        <div>
                            {achievementStar('jack1')}
                            {achievementStar('jack2')}
                            {achievementStar('jack3')}
                            <span >
                                <FontAwesomeIcon
                                    className="buttonZoom"
                                    style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                                    icon={['fas', 'info-circle']}
                                />
                            </span>
                        </div>
                    </RowItemLi>
                    <RowItemLi onClick={() => {
                        ModalAchievements(dispatch, 'RARE DROPS');
                    }}>
                        <div>
                            Rare Faces Found
                        </div>
                        <div>
                            {dropStars()}
                            <span >
                                <FontAwesomeIcon
                                    className="buttonZoom"
                                    style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                                    icon={['fas', 'info-circle']}
                                />
                            </span>
                        </div>
                    </RowItemLi>
                    <RowItemLi onClick={() => {
                        ModalAchievements(dispatch, 'ABILITY');
                    }}>
                        <div>
                            Abilities Found
                        </div>
                        <div>
                            {achievementStar('ability1')}
                            {achievementStar('ability2')}
                            {achievementStar('ability3')}
                            {achievementStar('ability4')}
                            <span >
                                <FontAwesomeIcon
                                    className="buttonZoom"
                                    style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                                    icon={['fas', 'info-circle']}
                                />
                            </span>
                        </div>
                    </RowItemLi>
                </ul>
            </MetricsStatsDiv>
            <MetricsStatsDiv>
                <MetricsHeaderDiv>
                    <div>
                        Career Ratio
                    </div>
                    <div style={{alignItems: 'center', marginLeft: '.25em'}}>
                        <span >
                            <FontAwesomeIcon
                                onClick={() => ModalAchievements(dispatch, 'CAREER RATIO')}
                                className="buttonZoom"
                                style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                                icon={['fas', 'info-circle']}
                            />
                        </span>
                    </div>
                </MetricsHeaderDiv>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px'}}>
                    <div style={{fontSize: screenIsSmall ? '1em' : '3em', fontWeight: 'bold'}}>
                        {stats.totalRankedLose > 0 ? (stats.totalRankedWin / stats.totalRankedLose).toFixed(2) : stats.totalRankedWin}
                    </div>
                    <div style={{textAlign: 'right'}}>
                        {stats.totalRankedWin} Wins
                    </div>
                </div>
            </MetricsStatsDiv>
            <MetricsStatsDiv>
                <MetricsHeaderDiv>
                    <div>
                        Current Rating
                    </div>
                    <div style={{alignItems: 'center', marginLeft: '.25em'}}>
                        <FontAwesomeIcon
                            onClick={() => ModalAchievements(dispatch, 'CURRENT RATING')}
                            className="buttonZoom"
                            style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                            icon={['fas', 'info-circle']}
                        />
                    </div>
                </MetricsHeaderDiv>
                <ul>
                    {sports.map((sport,i) => {
                        return (
                            <RowItemLi key={sport}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <span role="image">{sportEmoji[i]}</span>
                                    <div style={{marginLeft: '.25em'}}>{sportRank[i]}</div>
                                </div>

                                <div>{profile.ratings[sport]}</div>
                            </RowItemLi>
                        )
                    })}
                </ul>
            </MetricsStatsDiv>
            <MetricsStatsDiv>
                <MetricsHeaderDiv>Matches Played</MetricsHeaderDiv>
                <ul>
                    {sports.map((sport,i) => {
                        return (
                            <RowItemLi key={sport}>
                                <div><span role="image">{sportEmoji[i]}</span> {capitalize(sport)}</div>
                                <div>{profile.metrics.win[sport] + profile.metrics.lose[sport]}</div>
                            </RowItemLi>
                        )
                    })}
                    <RowItemLi style={{fontSize: '20px', fontWeight: 'bold'}}>
                        <div>Total</div>
                        <div>{stats.totalWin + stats.totalLoss}</div>
                    </RowItemLi>
                </ul>
            </MetricsStatsDiv>
        </MetricsDiv>
    )
};

export default Metrics;