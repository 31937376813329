let frameTimeHolder = [];
let lastFrameTime = Date.now();

export function RenderFPS(context){
	frameTimeHolder.push(Date.now() - lastFrameTime);
	lastFrameTime = Date.now();
	if(frameTimeHolder.length > 60) {frameTimeHolder.shift()}
	let averageFrameTime = frameTimeHolder.reduce((a,b) => a+b) / frameTimeHolder.length;
	let fps = Math.round(1000 / averageFrameTime).toString();
	context.font = ('60px Arial');
	context.fillStyle = '#fff'
	context.textAlign = 'center';
	context.textBaseline = 'middle';
	context.fillText(fps, 1850,860);
}