import React, {useEffect, useState} from 'react';
import Header from "../../component/Header/Header";
import Page from "../../styled/Page";
import SlimePan from "../../styled/SlimePan";
import {setCookie} from "../../data/generic/generic";
import palette from "../../styled/Palette";
import changes from '../../system/changes';
import ChangeLogData from "./ChangeLogData";
import {UpdateContainerLiOld} from "./ChangeLogStyle";
import {PageTypes} from "../../system/types";
import UseWindowDimensions from "../../hooks/useWindowDimensions";

const changeDetailsOld = () => {
    return (
        <>
            <UpdateContainerLiOld>
                <div>Improved our approach for image loading. Transitioned to context state stores.</div>
                <div>3.27.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Absolutely massive refactor completed (upgraded to react hooks).</div>
                <div>3.4.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Fixed some nasty bugs. Added a warning recommending Chrome Browser.</div>
                <div>2.19.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The rating functionality has been refactored. A rating tracker has been added to the match win screen.</div>
                <div>2.5.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The chat functionality has been refactored.</div>
                <div>2.1.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The rules screen has been refactored.</div>
                <div>1.30.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The rank up screen has been refactored.</div>
                <div>1.27.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The watch games screen has been refactored.</div>
                <div>1.21.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Added a way to pawn off items. Reduced queue time.</div>
                <div>1.16.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The item drop screen has been refactored.</div>
                <div>1.1.2020</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Updated loot screen interface not properly fitting for smaller (laptop) screen sizes.</div>
                <div>12.23.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The game select screen has been refactored. Add ability to toggle music from match options.</div>
                <div>12.08.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>A notification bubble has been added for new public messages. Fixed bugs with Ranking display.</div>
                <div>11.25.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Joining match and voting sequence interface updated.</div>
                <div>11.19.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Added timestamps to public chat. Modified Cash Shop. Fixed bugs with Slots, Ranked Play. </div>
                <div>10.27.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The feats screen has been added, featuring achievements and the ability to view other users stats.</div>
                <div>10.16.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The user interface has been updated with many changes</div>
                <div>9.01.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>We are able to release into Early Access on Steam (Timeline TBD)</div>
                <div>8.04.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Players will now be notified in chat when someone joins a queue</div>
                <div>6.29.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>The rank system is now available to all players</div>
                <div>6.16.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Added some backgrounds to try out</div>
                <div>6.14.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Add glow for better feedback during Power Shot</div>
                <div>6.2.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Add Sandbox 'not in match' Room</div>
                <div>5.15.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Add Power Shot & Crit Hit Effects</div>
                <div>3.17.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>GamePads are compatible</div>
                <div>3.3.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Vote on updates in Discord</div>
                <div>2.28.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Increased rarity of items</div>
                <div>2.23.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Achievements drop loot</div>
                <div>2.19.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Introducing slime radio</div>
                <div>2.3.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Introducing present drops</div>
                <div>1.24.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Server is now self-healing</div>
                <div>1.21.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Add survey for players (expired)</div>
                <div>1.2.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Began publishing to earn traffic</div>
                <div>1.1.2019</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Improved ranking system</div>
                <div>12.09.2018</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Add filter item function</div>
                <div>12.01.2018</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Add primary screen interface</div>
                <div>11.13.2018</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Entering Alpha</div>
                <div>10.02.2018</div>
            </UpdateContainerLiOld>
            <UpdateContainerLiOld>
                <div>Started Development</div>
                <div>08.03.2017</div>
            </UpdateContainerLiOld>
            <div style={{ height: '3em' }}/>
        </>
    )
};

const ChangeLog = () => {
    const [activeLog, setActiveLog] = useState(changes[0].version);
    const { screenIsSmall } = UseWindowDimensions();

    const handleSelect = (version) => {
        if (activeLog === version) {
            setActiveLog(null);
        } else {
            setActiveLog(version);
        }
    };

    useEffect(() => {
        // Update the users reads to show he has read the change log, at least on the current machine.
        // todo: Presumably we would have some way to wipe this cookie when we push new builds.
        setCookie('readChangeLog', true, 365);
    }, []);


    return (
        <Page className="animatedFast slideInRight">
            <SlimePan style={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto'}}>
                <Header title="CHANGE LOG" closeRoute={PageTypes.MAIN} />
                <div
                    className="styleDarkScrollSquare"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '3em',
                    }}
                >
                    <ul
                        style={{
                        color: palette.base2,
                        width: screenIsSmall ? '90%' : '70em',
                        textAlign: 'left',
                        fontFamily: 'Montserrat',
                    }}>
                        <ChangeLogData
                            handleSelect={handleSelect}
                            activeLog={activeLog}
                        />
                        {changeDetailsOld()}
                    </ul>
                </div>
            </SlimePan>
        </Page>
    )
};

export default ChangeLog;