import React, { useState, useEffect } from "react";
import palette from "../../styled/Palette";
import { FortuneWheelTypes } from "./index";
import { FONT_BIG, FONT_HUGE, FONT_LARGE, FONT_NORMAL, FONT_SMALL, FONT_SMALLER } from "../../data/font";
import { useIcon } from "../../data/icon";
import { currency } from "../../data/format";
import ModalSlotsTickets from "../../component/Modal/Type/ModalSlotsTickets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ticket2 from "../../images/ticket2.png";
import ticket3 from "../../images/ticket3.png";
import coins from "../../images/slimeCoin.png";
import Card from "../../component/Card/Card";
import { useSelector } from "react-redux";
import {
    CenteredDiv,
    FortuneBodyDiv,
    FortuneFooterDiv,
    FortuneHeaderDiv,
    FortuneHeaderItemDiv,
    LeftPanelDiv,
    SpinButton
} from "./style";
import { getTierData } from "../../data/tier";
import { FontTypes } from "../../system/typography";
import { InfoMount2 } from "../../styled/InfoMount2";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import { randomItem } from "../../utility";
let IconTicket = require('react-icons/lib/fa/ticket');

const possibleMessages = [
    "AND THE FATE IS SEALED!",
    "THE WINDS OF DESTINY BLOW!",
    "TIME TO SEE WHERE IT LEADS!",
    "THE JOURNEY UNFOLDS!",
    "WHAT COMES NEXT IS ANYONE'S GUESS!",
    "THE PATH IS NOW CHOSEN!",
    "DESTINY HAS CAST ITS VOTE!",
    "AND SO THE WHEEL DECIDES!",
    "LET THE TIDES OF FORTUNE ROLL!",
    "THE WHEEL TURNS, AND SO DO THE TIDES OF FATE!"
];

const FortuneHeader = ({ mode, finalItem, screenIsSmall }) => {
    // Add state to store the random message
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (mode === FortuneWheelTypes.LIVE) {
            setMessage(randomItem(possibleMessages)); // Set random message when the component mounts or mode changes
        }
    }, [mode]); // Re-run the effect when mode changes

    return (
        <FortuneHeaderDiv>
            <div style={{ padding: '.5em 0', fontSize: screenIsSmall ? FONT_SMALLER : FONT_LARGE }}>
                {mode === FortuneWheelTypes.READY && <div className="animationFaderSlotsText"><b>USE ONE TICKET GET ONE REWARD</b></div>}
                {mode === FortuneWheelTypes.LIVE && <div className="animationFaderSlotsText"><div className={'fadeOutForeverSlow'}>{message}</div></div>}
                {mode === FortuneWheelTypes.DONE && <div><span style={{ color: getTierData(finalItem.tier).color }}>THE WHEEL HAS SPOKEN</span></div>}
            </div>
            <FortuneHeaderItemDiv>
                <li style={{ padding: '.5em 1em', display: 'flex', justifyContent: 'space-between' }}>
                    <b>REWARD CHANCE</b>
                    <div>80% item / 10% tickets / 10% coins</div>
                </li>
                <li style={{ padding: '.5em 1em', display: 'flex', justifyContent: 'space-between' }}>
                    <b>ITEM TIER CHANCE</b>
                    <div>
                        <span style={{ color: getTierData(0).color }}>10%</span> /&nbsp;
                        <span style={{ color: getTierData(1).color }}>40%</span> /&nbsp;
                        <span style={{ color: getTierData(2).color }}>35%</span> /&nbsp;
                        <span style={{ color: getTierData(3).color }}>10%</span> /&nbsp;
                        <span style={{ color: getTierData(4).color }}>5%</span>
                    </div>
                </li>
            </FortuneHeaderItemDiv>
        </FortuneHeaderDiv>
    );
};

const FortuneBody = ({ mode, tickets, currentItem, finalItem, history, theme }) => {
    const { screenIsSmall } = UseWindowDimensions();

    return (
        <FortuneBodyDiv>
            {(() => {
                    if (mode === FortuneWheelTypes.READY) {
                        return (
                            <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1, flexDirection: 'column'}}>
                                <CenteredDiv
                                    style={{fontSize: FontTypes.size.HUGE, fontWeight: 'bold', color: 'white'}}
                                    className="animated fadeIn "
                                >
                                    <div style={{color: theme.interfaceColorBright}}>
                                        {currency(tickets)}
                                    </div>
                                    <IconTicket style={{fontSize: '5em', position: 'absolute', opacity: '.03'}}/>
                                </CenteredDiv>
                            </div>
                        )
                    }
                    if (mode === FortuneWheelTypes.LIVE) {
                        return (
                            <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1, flexDirection: 'column'}}>
                                <CenteredDiv
                                    style={{color: 'white', position: 'relative', fontSize: FontTypes.size.HUGE}}
                                    className="animated fadeOutForeverSlow"
                                >
                                    <IconTicket style={{fontSize: '5em', position: 'absolute', opacity: '.03'}}/>
                                    <div
                                        style={{color: theme.interfaceColor}}
                                        className={'animationSpinSlow'}>
                                        {useIcon('cog')}
                                    </div>
                                </CenteredDiv>
                            </div>
                        )
                    }
                    if (mode === FortuneWheelTypes.DONE) {
                        const FortuneWinningTypes = {
                            TICKETS: 'tickets',
                            COINS: 'coins',
                        };

                        switch (currentItem.category.toLowerCase()) {
                            case FortuneWinningTypes.TICKETS:
                                return (
                                    <CenteredDiv>
                                        <InfoMount2 style={{fontFamily: FontTypes.family.READABLE, marginBottom: '2em'}}>
                                            {currentItem.amount} Tickets
                                        </InfoMount2>
                                        <img className="animated zoomIn"
                                             src={currentItem.amount === 2 ? ticket2 : ticket3}
                                             alt='Tickets'
                                        />
                                    </CenteredDiv>
                                );
                            case FortuneWinningTypes.COINS:
                                return (
                                    <CenteredDiv className="animated zoomIn" >
                                        <InfoMount2 style={{fontFamily: FontTypes.family.READABLE, marginBottom: '2em'}}>{currentItem.amount} Coins</InfoMount2>
                                        <img style={{width: '10em'}} src={coins} alt='Coins'/>
                                    </CenteredDiv>
                                );
                            default: {
                                return (
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, fontSize: screenIsSmall ? '.75em' : '1em'}}>
                                        <Card
                                            disabled={true}
                                            history={history}
                                            backgroundColor={palette.base7}
                                            item={finalItem}
                                        />
                                        <Card
                                            disabled={true}
                                            flipped={true}
                                            history={history}
                                            backgroundColor={palette.base7}
                                            item={finalItem}
                                        />
                                    </div>
                                )
                            }
                        }
                    }
                    return null;
            })()}
        </FortuneBodyDiv>
    );
};

const SpinButtonContent = ({ mode, tickets, possibleItems, handleResetWheel, dispatch, handleSpinWheel, finalItem }) => {
    return {
        [FortuneWheelTypes.READY]: {
            children: (
                tickets > 0 ?
                    possibleItems.length === 0 ? <div>SPIN NOW</div> : <div>PLEASE WAIT</div>
                    :
                    <div>
                        YOU ARE OUT OF TICKETS
                        <FontAwesomeIcon
                            className="buttonZoom"
                            style={{margin: '0 1em'}}
                            icon={['fas', 'info-circle']}
                        />
                    </div>
            ),
            click: () => tickets > 0 ? handleSpinWheel() : ModalSlotsTickets(dispatch),
            style: {fontSize: FONT_NORMAL},
        },
        [FortuneWheelTypes.LIVE]: {
            children: <div>SPIN IN PROGRESS</div>,
            disabled: true
        },
        [FortuneWheelTypes.DONE]: {
            click: () => handleResetWheel(),
            children: <div>CLAIM PRIZE NOW</div>,
        },
    }[mode]
};
const FortuneFooter = (props) => {
    const content = SpinButtonContent(props);
    const { style, click, children, disabled } = content;

    return (
        <FortuneFooterDiv>
            <SpinButton
                disabled={disabled}
                style={style}
                onClick={click}
            >
                {children}
            </SpinButton>
        </FortuneFooterDiv>
    );
};

const LeftPanel = ({ mode, currentItem, finalItem, tickets, handleResetWheel, possibleItems, dispatch, handleSpinWheel, history }) => {
    const theme = useSelector(state => state.theme);
    const { screenIsSmall } = UseWindowDimensions();

    return (
        <LeftPanelDiv>
          <FortuneHeader
              mode={mode}
              finalItem={finalItem}
              screenIsSmall={screenIsSmall}
          />
          <FortuneBody
              theme={theme}
              history={history}
              mode={mode}
              tickets={tickets}
              currentItem={currentItem}
              finalItem={finalItem}
          />
          <FortuneFooter
              finalItem={finalItem}
              mode={mode}
              tickets={tickets}
              handleResetWheel={handleResetWheel}
              possibleItems={possibleItems}
              dispatch={dispatch}
              handleSpinWheel={handleSpinWheel}
          />
        </LeftPanelDiv>
    )
};

export default LeftPanel;