import {
    disconnectReason,
    rooms,
    drawerToggle,
    updatePing,
    updateLoginToast,
    updateProfile,
    updateLoadingStatus,
    socketSignInPhase,
    socketMasterConnected,
    socketSetServerList
} from '../modules/socket/socketActions';
import {
    updateOutfit,
    updateTickets,
    updateStats,
    updateCoins,
    updatePremium,
    updateMetrics,
    updateColorName,
    updateStarterColor,
    updateRating,
    updateXsollaToken,
    updateItemSaleValue,
    loadOutfits,
    selfLoadInitialState, updateLoadout, selfRemoveItem, selfAddItem, selfUpdateConsumableItem
} from "../modules/self/selfActions";
import { fillShop, updateShop
} from "../modules/shop/shopActions";
import {
    friendsAlert, removeFriend, updateFriendGameStatus, updateFriends, updateFriendsStatus
} from "../modules/friends/friendsActions";
import {
    chatLoadHistory,
    updateBet, updateChat
} from '../modules/chat/chatActions';
import { updateSquad, updateSquadStatus, squadInviteReceived, squadLeave
} from "../modules/squad/squadActions";
import { updateFinalItem
} from "../modules/slots/slotsActions";
import { updateLoadingState
} from "../../redux/modules/socket/socketActions";
import { updatePresent
} from "../../redux/modules/present/presentActions";
import {createSocket, itemDrop} from './wsMasterThunks';

import {setCookie, toggleUserInput} from "../../data/generic/generic";
import { playSound } from '../../data/sound';

import { formatPublicMessage } from '../modules/chat/chatThunks';

import { history } from '../../index';
import {updateTheme} from "../modules/theme/themeActions";
import env from '../../env/env';
import {queueMatch} from "../modules/queue/queueActions";
import  axios  from "axios";
import {matchUpdateServerMetadata} from "../modules/match/matchActions";
import {usersUpdate} from "../modules/users/usersActions";
import {requestSignIn} from "../../system/endpoints/master";
import {banChatMessage} from "../../system/chat";
import {modalDisable} from "../modules/modal/modalActions";
import {settingChatBubble} from "../modules/setting/settingActions";
import {loadingTypes} from "../modules/socket/socketInitialState";
import {PageTypes} from "../../system/types";
import { startCoinEvent } from '../../system/physics/physics';

export let masterSocket;
function wsMaster() {
    return ({dispatch, getState}) => {
        masterSocket = new WebSocket(env.endpoint);
        masterSocket.onclose = () => {
            history.push(PageTypes.CONNECTION_TIMEOUT);
        };

        const setAvailableServers = (message) => {
            message.availableServers.forEach(availableServer =>{
                let startTime = Date.now();
                axios.get(availableServer.pingIp).then(res =>{
                    dispatch(socketSetServerList({
                        serverName: availableServer.serverName,
                        latency: (Date.now() - startTime) / 2,
                        acceptable: false,
                        region: availableServer.region,
                        provider: availableServer.provider,
                        gameServerKey: availableServer.gameServerKey,
                    }));
                }).catch(e =>{
                    console.log(e);
                });
            });
        };
        const assignSession = (message) => {
            dispatch(socketMasterConnected(true));
            dispatch(updateColorName(message.colorName));
            dispatch(updateTheme(message.colorHex));
        };
        const disconnect = (message) => {
            dispatch(disconnectReason(message.reason));
            history.push(PageTypes.CONNECTION_TIMEOUT);
        };
        const loginSuccess = (message) => {
            // Sets the user cookies in order to attempt auto-login when the user revisits the page
            setCookie('username', message.username,365);
            setCookie('accessToken', message.accessToken,365);

            dispatch(selfLoadInitialState(message));
            //todo: these dispatches remap or do something besides just map the state to redux
            const {colorHex, starterPaint, chatBubbleOwns, chatBubbleEnabled} = message;
            dispatch(updateStarterColor(starterPaint));
            dispatch(updateLoginToast('Signing In'));
            dispatch(updateOutfit({paint: colorHex}));
            dispatch(settingChatBubble({has: chatBubbleOwns, isEnabled: chatBubbleEnabled}));
        };
        const ping = (message) => {
            masterSocket.send(JSON.stringify({
                'event': 'pong',
                'pingString': message.pingString,
            }));
            dispatch(updatePing(message.latency));
        };

        /**
         * The event which only match players receive
         */
        const joinServer = (message) => {
            // This data is submitted along with the server performance feedback form
            dispatch(matchUpdateServerMetadata({
                region: message.region,
                provider: message.provider,
                alias: message.alias,
                userRated: false
            }));

            playSound('foundGame', .2);

            dispatch(updateLoadingStatus(loadingTypes.joiningMatch));

            // Only appears to fire for players, not observers.
            dispatch(updateLoadingState(true));
            // Give the loading screen a chance to fill
            setTimeout(() => {
                dispatch(drawerToggle({open: false}));

                dispatch(
                    createSocket({
                        joinUrl: message.joinUrl,
                        roomId: message.roomId,
                        sessionId: message.sessionId,
                    })
                );
                toggleUserInput(false);
            }, 1000);
        };
        const destroyXsollaWindow = () => {
            const xsollaWindow = document.getElementsByClassName('xpaystation-widget-lightbox')[0];
            if (xsollaWindow) {
                xsollaWindow.remove();
            }
        };
        const purchaseResponse = (message) => {
            if (message.status === 'success') {
                dispatch(updatePremium(1));
                playSound('slotsWin',1);
                dispatch(selfAddNewItem({
                    type: 'premium',
                    category: 'premium',
                }));
                destroyXsollaWindow();
            }
            if (message.status === 'failure') {
                //CashShop.setState({failureReason: msg.reason});
            }
        };
        const handleRegistration = (message) => {
            switch (message.status) {
                case 'success':
                    dispatch(updateLoginToast('Success'));
                    requestSignIn({
                        username: document.getElementById('user').value,
                        password: document.getElementById('pass').value,
                        steamFriendIds: getState().steam.steamFriendIds,
                    });
                    break;
                case 'usernameTaken':
                    dispatch(updateLoginToast('Username Taken'));
                    break;
                case 'emailTaken':
                    dispatch(updateLoginToast('Email Already In Use'));
                    break;
            }
        };
        const handleCoinEvent = (message) => {
            if (history.location.pathname === PageTypes.MAIN) {
                // We only pop-up the Meteorologist if the user is already on the main screen.
                // Otherwise this would be an annoyance.
                history.push({
                    pathname: PageTypes.MAIN,
                    state: { showDialogueCoinEvent: true },
                });
            }

            setTimeout(() => {
                startCoinEvent(message.maxRedemption, (value) => {
                    masterSocket.send(JSON.stringify({
                        event: 'redeemCoinEvent',
                        coins: value,
                    }));
                });
            }, 6000);
        };

        return next => action => {
            switch (action.type) {
                case 'INITIALIZE_SOCKET_MASTER': {
                    masterSocket.onmessage = (event) => {
                        const message = JSON.parse(event.data);
                        switch (message.event) {
                            // This event contains the number of guests and members currently playing
                            case 'userMetrics':
                                dispatch(usersUpdate(message));
                                break;
                            case 'availableServers':
                                setAvailableServers(message);
                                break;
                            // This event is associated with connecting (as a guest)
                            case 'assignSession':
                                assignSession(message);
                                const WAIT_FOR_LOAD_PROCESSES_SETTLE = 5000;
                                setTimeout(()=>{
                                    masterSocket.send(JSON.stringify({
                                        'event': 'getAvailableServers',
                                    }));
                                },WAIT_FOR_LOAD_PROCESSES_SETTLE);
                                break;
                            case 'disconnectPlayer':
                                disconnect(message);
                                break;
                            case 'updateRooms':
                                dispatch(rooms({rooms: message.rooms, serverTime: message.serverTime}));
                                break;
                            // This event fires when a participating player joins a match.
                            case 'joinServer':
                                joinServer(message);
                                break;
                            // These events are related to informing the client if they are in the match queue.
                            case 'inQueue':
                                dispatch(queueMatch(true));
                                break;
                            case 'leftQueue':
                                dispatch(queueMatch(false));
                                break;
                            case 'publicChatUserData':
                                dispatch(usersUpdate(message));
                                break;
                            case 'ping':
                                ping(message);
                                break;
                            // This event fires when the user attempts to buy premium.
                            case 'purchaseResponse':
                                (() => purchaseResponse(message))();
                                break;
                            case 'xsollaToken':
                                dispatch(updateXsollaToken(message.token));
                                break;
                            // This event fires after the user has purchased a present
                            case 'openPresent':
                                dispatch(updatePresent({presentItems: message.items, presentColorSet: message.setColor, outfitId: message.outfitId}));
                                destroyXsollaWindow();
                                break;
                            //
                            case 'getPlayerProfile':
                                dispatch(updateProfile(message.results));
                                break;
                            // These events are related to managing client user data.
                            case 'updateMetrics':
                                dispatch(updateMetrics(message.metrics));
                                break;
                            case 'updateCoins':
                                dispatch(updateCoins(message.coins));
                                break;
                            case 'updateStats':
                                (() => dispatch(updateStats(message.stats)))();
                                break;
                            case 'updateTickets':
                                (() => {
                                    dispatch(updateTickets(message.tickets));
                                    destroyXsollaWindow();
                                })();
                                break;
                            // These events are related to managing loot.
                            case 'equipped':
                                dispatch(updateOutfit({...message, equipped: true}));
                                break;
                            case 'unequipped':
                                dispatch(updateOutfit({...message, equipped: false}));
                                break;
                            case 'updateOutfits':
                                dispatch(loadOutfits(message));
                                break;
                            case 'loadoutResponse':
                                dispatch(updateLoadout(message));
                                break;
                            // These events are related to managing friends.
                            case 'removeFriend':
                                dispatch(removeFriend(message.friendName));
                                break;
                            case 'updateFriends':
                                dispatch(updateFriends(message.friends));
                                break;
                            case 'friendGameStatus':
                                dispatch(updateFriendGameStatus(message));
                                break;
                            case 'userStatusUpdate':
                                dispatch(updateFriendsStatus(message));
                                break;
                            // These events are related to managing chat.
                            case 'chat':
                                dispatch(formatPublicMessage(message));
                                break;
                            case 'banSuccessful':
                                dispatch(updateChat(banChatMessage(message)));
                                playSound('Birdy_Done5',.1);
                                dispatch(modalDisable());
                                break;
                            case 'chatBan':
                                // The user is also informed of chatBanned status during assignSession
                                dispatch(selfLoadInitialState({chatBanned: message.duration}));
                                break;
                            //
                            case 'betMade':
                            case 'betCanceled':
                            case 'betTaken':
                                dispatch(updateBet(message));
                                break;
                            // These events are related to managing market.
                            case 'updateShop':
                                // Updating an individual item in the shop. e.g. mark as SOLD
                                dispatch(updateShop(message));
                                break;
                            case 'fillShop':
                                // Restocking
                                dispatch(fillShop(message));
                                break;
                            // These events are related to signing in.
                            case 'loginSuccess':
                                loginSuccess(message);
                                break;
                            case 'loginFailed':
                                if (message.reason === 'unknownUsername') {
                                    dispatch(updateLoginToast('Unknown Username'));
                                }
                                if (message.reason === 'badPassword') {
                                    dispatch(updateLoginToast('Invalid Password'));
                                }
                                break;
                            case 'registration':
                                handleRegistration(message);
                                break;
                            // These events are related to reseting an account password
                            case 'resetSuccess':
                                dispatch(updateLoginToast('Password Change Successful'));
                                dispatch(socketSignInPhase('login'));
                                break;
                            case 'resetFailed':
                                dispatch(updateLoginToast('Access Code Invalid'));
                                break;
                            // These events are related to managing squad.
                            case 'squadInviteReceived':
                                dispatch(squadInviteReceived(message));
                                break;
                            case 'pendingSquadUpdate':
                                // Fires for all users once a squad is officially created.
                                dispatch(updateSquadStatus(message));
                                break;
                            case 'squadUpdate':
                                // Fires when the squad captain has changed hands.
                                dispatch(updateSquad(message));
                                break;
                            case 'leftSquad':
                                dispatch(squadLeave(message));
                                break;
                            // These events are related to managing the slots.
                            case 'spinResults':
                                dispatch(updateFinalItem(message.selectedItem));
                                dispatch(selfAddItem([message.selectedItem]));
                                dispatch(updateTickets(message.tickets));
                                break;
                            // These events are related to displaying the user rating change.
                            case 'updateRatings':
                                dispatch(updateRating(message));
                                break;
                            // These events are related to managing the drop screen.
                            case 'itemDrop':
                                dispatch(itemDrop(message));
                                // In case the user just purchased the Chat Bubble
                                destroyXsollaWindow();
                                break;
                            case 'sellItemReturn':
                                dispatch(updateItemSaleValue(message.value));
                                break;
                            case 'achievementsUnlocked':
                                // Normalize the scheme to suit the existing itemDrop
                                const achievementDrops = {};
                                achievementDrops.items = message.achievementNames.map((name) => {
                                    if (!['loseStreak1','loserChallenger1'].includes(name)) {
                                        return (
                                            {
                                                name: name,
                                                type: 'achievement',
                                                category: 'trophy'
                                            }
                                        )
                                    }
                                });
                                dispatch(itemDrop(achievementDrops));
                                break;
                            case 'chatHistory':
                                dispatch(chatLoadHistory(message.chatHistory));
                                break;
                            case 'findFriend':
                                dispatch(friendsAlert(message));
                                break;
                            case 'startCoinEvent':
                                handleCoinEvent(message);
                                break;
                            // The server has indicated what the new value of the users consumable should be
                            case 'updateQuantity':
                                if (message.quantity === 0) {
                                    dispatch(selfRemoveItem(message.userItemId));
                                } else {
                                    dispatch(selfUpdateConsumableItem(message));
                                }
                        }
                    }
                }
            }
            // Proceed
            return next(action);
        };
    }
}

export default wsMaster();

