import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import TextUnderlineHover from "../../../styled/TextUnderlineHover";
import {PageTypes} from "../../../system/types";

export let PremiumBotToggleData;

const ModalPremiumBotToggle = (dispatch,theme,history) => {

    PremiumBotToggleData = (
        <div>
            <div style={{margin: '1em'}}>
                Only <TextUnderlineHover
                theme={theme}
                bold={true}
                onClick={() => {
                    history.push(PageTypes.LEARN);
                    dispatch(modalDisable());
                }}
                style={{flexGrow: 1}}
            >
                premium members
            </TextUnderlineHover> are able to freely choose if a match will contain exclusively real human players.
            </div>
        </div>
    );

    dispatch(modalEnable({
        body: 'PremiumBotToggle',
        title: "This is a Premium Exclusive"
    }));
};

export default ModalPremiumBotToggle;