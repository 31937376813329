
import React, {useState} from "react";
import Card from "../../../component/Card/Card";
import {achievementText} from "../../../system/drop";
import {DropFooter} from "../style";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import {ItemCategoryTypes} from "../../../system/types";
import {requestEquipItem} from "../../../system/endpoints/master";

const DropCard = ({currentViewItem, backgroundColor, username, history, items, handleReturn}) => {
    const [userTriggeredEquip, setUserTriggeredEquip] = useState(false);
    const itemCanBeEquipped = [
        ItemCategoryTypes.PAINT,
        ItemCategoryTypes.SKIN,
        ItemCategoryTypes.CAP,
        ItemCategoryTypes.FACE
    ].includes(currentViewItem.category) && currentViewItem.type !== 'start';

    const body = (
        <>
            <div style={{color: 'white'}}>{currentViewItem.type === 'unlock' && achievementText(currentViewItem.name,username)}</div>
            <div style={{margin: '2em', display: 'flex', justifyContent: 'center'}}>
                <Card
                    disabled={![
                        ItemCategoryTypes.PAINT,
                        ItemCategoryTypes.SKIN,
                        ItemCategoryTypes.CAP,
                    ].includes(currentViewItem.category)}
                    history={history}
                    item={currentViewItem}
                    backgroundColor={backgroundColor}
                />
            </div>
        </>
    );

    return (
        <>
            {body}
            <DropFooter>
                <div style={{display: 'flex'}}>
                    <Button
                        styles={{width: STANDARD_BUTTON_WIDTH}}
                        title="Cool"
                        click={() => handleReturn()}
                    />
                </div>
            </DropFooter>
        </>
    )
};

export default DropCard;