import styled from 'styled-components';
import palette from "../../styled/Palette";

export const newsItems = [
 "SLIME SCIENTIST MAKES BREAKTHROUGH DISCOVERY ON JELLYFISH REGENERATION TECHNIQUES",
 "FAMOUS SLIME CHEF OPENS UP NEW RESTAURANT, INTRODUCES REVOLUTIONARY GOO-BASED CUISINE",
 "SLIME ARTISTS UNVEIL GIANT JELLY SCULPTURE IN HONOR OF FOUNDER OF GELATINOUS KINGDOM",
 "SLIME REVOLUTIONARIES TAKE TO THE STREETS, DEMAND EQUALITY FOR GELATINOUS CITIZENS",
 "SLIME CHILDREN DISCOVER UNIQUE AND COLORFUL SPECIES OF SLIMEBUG IN LOCAL POND",
 "SLIME MARTIAL ARTS EXPERT TRAINS NEXT GENERATION OF WARRIORS IN DEADLY OOZING TECHNIQUES",
 "SLIME ENVIRONMENTALISTS LEAD CAMPAIGN TO CLEAN UP POLLUTION IN MUCUS MARSHES",
 "SLIME ADVENTURERS DISCOVER UNDERGROUND LAKE OF HEALING GOO, HAILED AS MIRACLE CURE BY DOCTORS",
 "SLIME MAYOR ANNOUNCES NEW INITIATIVE TO PROMOTE GOOEY ARTS AND CULTURE IN CITY",
 "SLIME DOCTORS DEVELOP NEW SLIME-BASED MEDICINES FOR RARE DISEASES, GIVE HOPE TO THOUSANDS OF PATIENTS",
 "SLIME HACKERS BREACH SECURITY OF MAJOR CORPORATION, STEAL MILLIONS OF SLIME COIN IN CYBER-HEIST",
 "SLIME ASTRONOMERS DISCOVER NEW PLANETARY SYSTEM MADE ENTIRELY OF LIVING GOO",
 "SLIME TEACHERS AWARDED FOR INNOVATIVE USE OF GOOEY LEARNING TECHNIQUES IN THE CLASSROOM",
 "SLIME OLYMPICS DRAWS CROWDS FROM ACROSS THE WORLD, ATHLETES COMPETE IN OOZY AND SLIPPERY CHALLENGES",
 "SLIME FASHION DESIGNERS LAUNCH NEW LINE OF SHIMMERING SLIME APPAREL, SET TO TAKE THE WORLD BY STORM",
 "SLIME POLICE DEPARTMENT UNVEILS NEW HIGH-TECH CRIME-FIGHTING EQUIPMENT, EXPECTED TO MAKE CITY SAFER",
 "SLIME ENGINEERS CREATE GROUNDBREAKING NEW OOZE-POWERED MACHINERY, REVOLUTIONIZES INDUSTRIAL SECTOR",
 "SLIME MUSICIANS COLLABORATE ON ALBUM MADE ENTIRELY OF SLIME-BASED INSTRUMENTS, RECEIVES RAVE REVIEWS",
 "SLIME UNIVERSITY OFFERS NEW COURSE ON GOOEY PHILOSOPHY, EXPLORES THE NATURE OF SLIME EXISTENCE",
 "THE SLIME SQUAD CRUSHES THE COMPETITION IN VOLLEYBALL TOURNAMENT, LED BY SPIKE AND BLOBBY",
 "SOCCER SUPERSTARS SQUISHY AND STRETCH SCORE WINNING GOALS FOR THE SLIME STRIKERS",
 "HOOPS HEROES DRIBBLE TO VICTORY: SLIMY AND BOUNCY LEAD THE CHARGE",
 "SLIME VOLLEYBALL CHAMPS GO UNDEFEATED: WOBBLES AND JIGGLES DOMINATE THE COURT",
 "SOCCER SHOWDOWN: GOOEY AND SLINKY OUTMANEUVER RIVALS FOR THE WIN",
 "SLIME SPIKERS SERVE UP A STORM: SLIPPY AND STICKY LEAD THE CHARGE",
 "HOOPS HYPE: BOUNCE AND HOPPY SLAM-DUNK THE COMPETITION",
 "SLIME SOCCER SENSATIONS: SQUELCH AND PUDDLES LEAVE OPPONENTS IN THEIR WAKE",
 "VOLLEYBALL VICTORY: SLIMETASTIC DUO BLOOP AND GLOOP TAKE HOME THE GOLD",
 "HOOPS HYSTERIA: STRETCHY AND SQUISHY STEAL THE SHOW WITH GRAVITY-DEFYING MOVES",
 "SLIME SOCCER SAVANTS: WAVY AND FLOWY OUTSMART OPPONENTS AT EVERY TURN",
 "VOLLEYBALL VANDALS: OOZY AND GUNKY LEAVE THEIR MARK ON THE COMPETITION",
 "HOOPS HURDLES: JUMPY AND ELASTIC LEAP OVER THE OPPOSITION FOR THE SCORE",
 "SLIME STRIKERS SCORE BIG: DRIPPY AND MUCKY LEAD THE CHARGE TO VICTORY",
 "VOLLEYBALL VICTORY LAP: RUNNY AND GOOEY CELEBRATE THEIR CHAMPIONSHIP WIN",
 "SOCCER STARS SHINE BRIGHT: OOZY AND STICKY TAKE ON ALL COMERS",
 "HOOPS HYPE MACHINE: SLINKY AND SLIMY LIGHT UP THE SCOREBOARD",
 "SLIME VOLLEYBALL VIXENS: SQUISHY AND SLIPPY RULE THE NET",
 "SOCCER SUCCESS STORY: BLOBBY AND WOBBLES DOMINATE THE PITCH",
 "HOOPS HYSTERICS: BUBBLY AND BOUNCY SHOW OFF THEIR SLAM-DUNK SKILLS",
 "THE SLIMETASTIC HOOPS SQUAD SOARS TO VICTORY: TEAMMATES SLINKY AND WOBBLES MAKE IT HAPPEN",
 "THE GREEN GOO VOLLEYBALL TEAM WINS CHAMPIONSHIP LED BY TEAM CAPTAINS SLIMEY AND DRIPPY",
 "SLIMEBALL STRIKERS SCORE A HAT TRICK WITH STAR PLAYERS OOZY AND SQUISHY",
 "THE SLIMETASTIC HOOPS SQUAD SOARS TO VICTORY: TEAMMATES SLINKY AND WOBBLES MAKE IT HAPPEN",
 "THE WAVY WATERMELONS TAKE HOME THE GOLD IN A CLOSE VOLLEYBALL MATCH: STARRING JIGGLY AND BLOOP",
 "THE SLIMY STRIKERS SWEEP THE SOCCER FIELD: BLOBBY AND SQUISHY ARE A FORCE TO BE RECKONED WITH",
 "THE BOUNCING BALLERS SHOW OFF THEIR HOOPS SKILLS: FEATURING TEAMMATES BOUNCY AND HOPPER",
 "THE GLITTERING GOO VOLLEYBALL TEAM SHINES IN THE SPOTLIGHT: RUNNY AND SLIPPY LEAD THE WAY",
 "THE OOZING OWLS TAKE FLIGHT ON THE SOCCER FIELD: WAVY AND STRETCHY SCORE THE WINNING GOALS",
 "THE BOUNCING BOPPERS MAKE THEIR MARK ON THE HOOPS COURT: FEATURING SLIMY AND JUMPY",
 "THE SLIMY SPIKERS SPIKE THEIR WAY TO VICTORY: FEATURING DRIPPY AND GUNKY",
 "THE JIGGLING JELLYFISH DOMINATE THE VOLLEYBALL COURT: FLOWY AND BLOOP ARE UNSTOPPABLE",
 "THE SQUISHY SOCCER SQUAD TAKES DOWN RIVALS: FEATURING OOZY AND PUDDLES",
 "THE BOUNCY BUNCH HAS GOT GAME: ELASTIC AND SQUELCH RULE THE HOOPS COURT",
 "THE GLOWING GLOOPERS LIGHT UP THE VOLLEYBALL COURT: STARING SLIMEY AND SLIPPY",
 "THE GOOEY GLADIATORS SCORE BIG ON THE SOCCER FIELD: FEATURING BLOBBY AND STRETCH",
 "THE SLIPPERY SLAMMERS SHOW OFF THEIR HOOPS SKILLS: WOBBLES AND HOPPER TAKE THE LEAD",
 "THE WOBBLY WHALES TAKE HOME THE GOLD IN VOLLEYBALL: FEATURING JIGGLY AND DRIPPY",
 "THE DRIPPING DYNAMOS REIGN SUPREME ON THE SOCCER FIELD: FEATURING WAVY AND SQUISHY",
 "THE BOUNCING BOMBERS DUNK THEIR WAY TO VICTORY: FEATURING BOUNCY AND SLIMY",
 "THE FLOWING FLAMINGOS SERVE UP A STORM IN VOLLEYBALL: FEATURING FLOWY AND GUNKY"
];
const NEWS_TICKER_SPEED = newsItems.length * 30;

export const NewsTickerWrapperDiv = styled.div`
  position: fixed;
  margin-top: 3.5em;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 1.5rem;
  background-color: ${palette.midnightBlue};
  padding-left: 100%;
  box-sizing: content-box;
  border-bottom: 1px solid ${palette.base3};
 `;

export const NewsTickerDiv = styled.div`
    display: inline-block;
    height: 1.5rem;
    line-height: 1.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: ${NEWS_TICKER_SPEED}s;
    
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-animation-name: ticker;
    -webkit-animation-duration: ${NEWS_TICKER_SPEED}s;
 `;

export const NewsTickerItemDiv = styled.div`
  display: inline-block;

  padding: 0 2rem;
  font-size: 1.25rem;
  color: ${palette.belizeHole};
`;