import styled from 'styled-components';
import palette from "../../../styled/Palette";

export const InviteInstanceDiv = styled.div`
    align-items: center;
    height: 100%;
    display: flex;
`;

export const SquadInviteDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin: .5em;
    padding: 0 .5em;
    background-color: ${palette.base5};
    border-radius: 4px;
`;

export const SquadInviteTextContainerDiv = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: .5em;
`;
export const SquadInviteTextDiv = styled.div`
    margin-top: .5em;
    font-size: .7em;
    color: ${palette.base2};
    font-weight: bold;
`;

export const SquadInviteSubTextDiv = styled.div`
    color: ${palette.base4};
`;
