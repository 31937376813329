import React from "react";
import {TrainDiv} from "./SlotsTrackStyle";
import Card from "../../component/Card/Card";
import palette from "../../styled/Palette";
import styled from 'styled-components';
import {PageTypes} from "../../system/types";

const ItemTrackPanelDiv = styled.div`
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    border: solid white;
    border-width: 0 1px;
    width: 20em;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ItemTrackPanel = ({ refTrainDiv, possibleItems, history }) => {
  return (
      <ItemTrackPanelDiv
          id='slotsContainer'
      >
          <div
              className="track"
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
          >
              <TrainDiv
                  ref={refTrainDiv}
                  id="train"
                  className="train animated"
              >
                  {
                      possibleItems.map((item,index) => {
                          return (
                              <div
                                  key={item.uid}
                                  id={'slot-' + index}
                                  style={{opacity: .15}}
                              >
                                  <Card
                                      pathname={PageTypes.SLOTS}
                                      history={history}
                                      disabled={true}
                                      item={item}
                                      index={index}
                                      backgroundColor={palette.base7}
                                  />
                              </div>
                          )
                      })
                  }
              </TrainDiv>
          </div>
          <div style={{position: 'absolute', width: '100%', height: '1px', backgroundColor: 'white'}}> </div>
          <div
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100%'}}
          >
              <div className="triangle"> </div>
              <div style={{width: '100%'}}> </div>
              <div className="triangle" style={{transform: 'scaleX(-1)'}}> </div>
          </div>
      </ItemTrackPanelDiv>
  )
};

export default ItemTrackPanel;