import {
    HighscoresFindUserInput,
    RankButtonsDiv,
    HighscoresButton, HighscoresOptionsDiv,
} from "./HighscoresStyle";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const ENTER_KEY = 13;

const HighscoreOptions = ({refHiscoreFindUserInput,getPlayerRank,getRankedRows,username}) => {

    const performQuery = (e) => {
        if (e.keyCode === ENTER_KEY) {
            getPlayerRank(e.target.value)
        }
    };


    return (
        <RankButtonsDiv>
            <HighscoresOptionsDiv>
                <HighscoresFindUserInput
                    autoComplete="off"
                    ref={refHiscoreFindUserInput}
                    id='hiscorefinduserinput'
                    placeholder={'Find User'}
                    tabIndex={0}
                    onKeyUp={(e) => performQuery(e)}
                />
                <HighscoresButton
                    tabIndex={0}
                    onClick={(e)=> getRankedRows()}>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'trophy']}/>
                    </div>
                    <div>Top Players</div>
                </HighscoresButton>
                <HighscoresButton
                    style={{borderTop: 0}}
                    tabIndex={0}
                    onClick={() => getPlayerRank()}>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'location']}/>
                    </div>
                    <div>Jump to self ({username})</div>
                </HighscoresButton>
                <HighscoresButton
                    tabIndex={0}
                    style={{borderBottom: 0, borderTop: 0}}
                    onClick={(e)=> getRankedRows('up')}>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'arrow-up']}/>
                    </div>
                    <div>Previous</div>
                </HighscoresButton>
                <HighscoresButton
                    tabIndex={0} onClick={() => getRankedRows('down')}>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'arrow-down']}/>
                    </div>
                    <div>Next</div>
                </HighscoresButton>
            </HighscoresOptionsDiv>
        </RankButtonsDiv>
    )
};

export default HighscoreOptions;