import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import plusOne from '../../images/coinEvent/plus_one.png'
import plusFive from '../../images/coinEvent/plus_five.png'

export function createCoinParticles(value, position) {
    let coinMap = {
        1: {
            image: plusOne,
            colors: ['#e2c871', '7a6b38',]
        },
        5: {
            image: plusFive,
            colors: ['#71AAE2','#1D5B68']
        },

    };

    let usedImage = coinMap[value];

	let emitter = new Proton.Emitter();
    emitter.rate = new Proton.Rate(4, updatePerFrame);
    emitter.addInitialize(new Proton.Mass(1));
    emitter.damping = 0;
    emitter.addInitialize(new Proton.Radius(2, 5));
    emitter.addInitialize(new Proton.Life(.5, 1));
    emitter.addBehaviour(new Proton.Color(coinMap[value].colors[0], coinMap[value].colors[1]));
    emitter.addBehaviour(new Proton.Scale(.1, .5));
    emitter.addBehaviour(new Proton.Alpha(0.6, 0));
    emitter.addInitialize(
        new Proton.Velocity(new Proton.Span(0.5, 1), new Proton.Span(0, 360), 'polar')
    );
    emitter.emit('once', 1.5);
    emitter.p.x = position.x;
    emitter.p.y = position.y;
    protonBackground.addEmitter(emitter);



	let imageEmitter = new Proton.Emitter();
	imageEmitter.rate = new Proton.Rate(1, updatePerFrame);
	imageEmitter.addInitialize(new Proton.Body(usedImage.image));
	imageEmitter.addInitialize(new Proton.Velocity(0, -2));
	imageEmitter.addBehaviour(new Proton.Scale(.2, 1));
	imageEmitter.addInitialize(new Proton.Life(2));
	imageEmitter.addBehaviour(new Proton.Alpha(1, 0));
	imageEmitter.p.y = position.y;
	imageEmitter.p.x = position.x;
	imageEmitter.emit('once', 3);
	protonBackground.addEmitter(imageEmitter);
}
