import Proton from './proton';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';

export function createSnowballParticles(position) {
    let emitter = new Proton.Emitter();
    emitter.rate = new Proton.Rate(4, updatePerFrame);
    emitter.addInitialize(new Proton.Mass(1));
    emitter.damping = 0;
    emitter.addInitialize(new Proton.Radius(2, 5));
    emitter.addInitialize(new Proton.Life(.5, 1));
    emitter.addBehaviour(new Proton.Color('8888ff', 'aaaaaff'));
    emitter.addBehaviour(new Proton.Scale(1, 3));
    emitter.addBehaviour(new Proton.Alpha(0.6, 0));
    emitter.addInitialize(
        new Proton.Velocity(new Proton.Span(0.5, 1), new Proton.Span(0, 360), 'polar')
    );
    emitter.emit('once', 1.5);
    emitter.p.x = position.x;
    emitter.p.y = position.y;
    protonBackground.addEmitter(emitter);
}
