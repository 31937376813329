import {useSelector} from "react-redux";
import {masterSocket} from "../../redux/middleware/wsMaster";
import {useEffect} from "react";
import isElectron from "is-electron";
import {getCookie} from "../../data/generic/generic";

const SignInManager = () => {
    const username = useSelector(state => state.self.username);
    const assetsLoaded = useSelector(state => state.socket.assetsLoaded);

    const autoSignInUsingTokenCookie = () => {
        if (!username) {
            if (getCookie('accessToken') !== '') {
                masterSocket.send(
                    JSON.stringify({
                        'event': 'login',
                        'username': getCookie('username'),
                        'accessToken': getCookie('accessToken')
                    })
                );
            }
        }
    };

    // Attempt to sign-in the user after assets have loaded
    useEffect(() => {
        if (masterSocket?.readyState === 1 && assetsLoaded) {
            if (!isElectron()) {
                autoSignInUsingTokenCookie();
            }
        }
    }, [assetsLoaded,masterSocket.readyState]);

    return null;
};

export default SignInManager;